import { LoadingButton } from "@mui/lab";
import { Box, Grid, Link, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate, useOutletContext } from "react-router-dom";
import { ERROR, INFO, SUCCESS, WARNING } from "../../app/constants/common";
import { PUBLIC_ROUTES } from "../../app/constants/routes";
import { useRequestResetPasswordMutation, useResetPasswordMutation } from "../../hooks/ApiHooks";
import { setAlert } from "../Alerts/slice/alertSlice";
import { RenderFormField } from "./AuthUiComponents";
import { requestResetPasswordFields, resetPasswordFieldsExcludingEmail } from "./config/authConfig";

const ResetPasswordFieldData = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const [setAlertMessage] = useOutletContext();
  const [isTokenSuccess, setIsTokenSuccess] = useState(false);
  const { triggerMutation: resetPassword, isLoading: isResetPasswordLoading } = useResetPasswordMutation();
  const { triggerMutation: requestToken, isLoading: isRequestTokenLoading } = useRequestResetPasswordMutation();

  const onRequestResetPasswordToken = async (e) => {
    e.preventDefault();
    const [email] = new FormData(e.target).values();
    if (!email.trim()) {
      setAlertMessage("Fields are empty");
      return;
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email.trim())) {
      setAlertMessage("Invalid email! Please enter a valid email");
      return;
    }
    try {
      await requestToken(email).unwrap();
      dispatch(
        setAlert(`Reset Password Request Successful`, "We have sent the reset password token to your mail. Please proceed to reset password", INFO)
      );
      setAlertMessage("We have sent the reset password token to your mail. Please proceed to reset password", SUCCESS);
      setIsTokenSuccess(true);
    } catch (err) {
      const serviceUnavailable = err.status === 503;
      setAlertMessage(err.data.message);
      dispatch(
        setAlert(serviceUnavailable ? err.data.title : "Reset Password Request Failed", err.data.message, serviceUnavailable ? ERROR : WARNING)
      );
    }
  };

  const onResetPassword = async (e) => {
    e.preventDefault();
    const [email, token, password, confirmPassword] = new FormData(e.target).values();
    if (!email.trim() || !token.trim() || !password.trim() || !confirmPassword.trim()) {
      setAlertMessage("Some Fields are empty");
      return;
    } else if (!/^(?=\S*([A-Z]){1,})(?=\S*[!@#$%^&?]{1,})(?=\S*[0-9]{2,})(?=\S*[a-z]{1,})\S{8,16}$/.test(password.trim())) {
      setAlertMessage(
        "Your password should be 8-16 characters, including at least one uppercase letter, one lowercase letter, two consecutive digits, and at least one special character (!@#$%^&?)"
      );
      return;
    } else if (password.trim() !== confirmPassword.trim()) {
      setAlertMessage("Passwords do not match. Make sure new password is same as confirm password");
      return;
    }
    try {
      await resetPassword(email, token, password, confirmPassword).unwrap();
      dispatch(setAlert(`Password Reset Successful`, "We have successfully reset password. Please proceed to login", SUCCESS));
      navigateTo(PUBLIC_ROUTES.login);
    } catch (err) {
      const serviceUnavailable = err.status === 503;
      setAlertMessage(err.data.message);
      dispatch(setAlert(serviceUnavailable ? err.data.title : "Password Reset Failed", err.data.message, serviceUnavailable ? ERROR : WARNING));
    }
  };

  return (
    <Box
      component="form"
      onSubmit={isTokenSuccess ? onResetPassword : onRequestResetPasswordToken}
      id={isTokenSuccess ? "reset-password-form" : "request-reset-password"}
    >
      <Grid container>
        {requestResetPasswordFields.map((item) => (
          <RenderFormField field={item} key={item.id} inputProps={{ readOnly: isTokenSuccess }} />
        ))}
        {!!isTokenSuccess && resetPasswordFieldsExcludingEmail.map((item) => <RenderFormField field={item} key={item.id} />)}
      </Grid>
      <LoadingButton
        variant="contained"
        type="submit"
        fullWidth
        loading={isResetPasswordLoading || isRequestTokenLoading}
        sx={{ mt: 2, mb: 1 }}
        size="large"
      >
        <span>{isTokenSuccess ? "Reset Password" : "Verify Email"}</span>
      </LoadingButton>
    </Box>
  );
};

export default function ResetAuth() {
  return (
    <Box>
      <Typography variant="h5" sx={{ mt: 2 }} fontWeight={600}>
        Reset Password
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }} fontWeight={400} color="text.secondary">
        Don&apos;t Worry! We will help you get right back soon.
      </Typography>
      <ResetPasswordFieldData />
      <Link variant="body1" component={RouterLink} to={PUBLIC_ROUTES.login} color="text.secondary" fontWeight={500} underline="hover">
        Know Your Password? Login Here.
      </Link>
    </Box>
  );
}
