import { AddTaskOutlined, Article, ClearOutlined, IndeterminateCheckBox, ModeEditOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ERROR, SUCCESS, WARNING } from "../../app/constants/common";
import { RenderOnDemandField } from "../../components/ui/InputUI";
import { GridLabelValueUI } from "../../components/ui/UiUtils";
import { useUnassignVehicleMutation, useUpdateVehicleDetailMutation } from "../../hooks/ApiHooks";
import { sectionUiLookBase } from "../../styled/inlineCssStyles";
import { setAlert } from "../Alerts/slice/alertSlice";
import { vehicleUIDisplayConfig } from "./config/vehicleDetails";
import { VehicleDetailsActionsButtonMenu } from "./InventoryUIComponents";
import {
  selectIsVehicleEditOn,
  selectIsVehicleUpdateInProgress,
  setIsVehicleDrawerPageOpen,
  setIsVehicleEditOn,
  setIsVehicleUpdateInProgress,
} from "./slice/inventorySlice";
import VehicleUsedLogDrawer from "./VehicleUsedLogDrawer";

const RenderFieldContent = ({ isFieldDisabled, fieldConfig, value, isEditModeOn }) => (
  <Grid item xs={12} {...(fieldConfig.colSpan === 1 && { lg: 6 })}>
    <GridLabelValueUI
      valueSx={{ "& .MuiSvgIcon-root, & .MuiIcon-root": { color: "primary.main" }, "& .MuiFormControl-root": { m: 0 } }}
      boxContainerProps={{ flexDirection: fieldConfig.colSpan === 1 ? "row" : "column" }}
      labelSx={{
        textWrap: "nowrap",
        textAlign: fieldConfig.colSpan === 1 ? "start" : "center",
        mb: fieldConfig.colSpan === 1 ? 0 : 1,
        fontSize: 15,
      }}
      label={fieldConfig.label}
    >
      {isEditModeOn && fieldConfig.isEditable ? (
        <RenderOnDemandField
          isFieldDisabled={isFieldDisabled}
          field={fieldConfig}
          fieldType={fieldConfig.fieldType}
          fieldValue={value}
          optionsData={fieldConfig.options}
        />
      ) : (
        fieldConfig.renderData?.(value) || value || fieldConfig.alternateValue
      )}
    </GridLabelValueUI>
  </Grid>
);

const RenderLabelInputEditUI = ({ vehicleData, refetch }) => {
  const isEditModeOn = useSelector(selectIsVehicleEditOn);
  const { triggerMutation, data, isLoading, isError, error } = useUpdateVehicleDetailMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && data) {
      dispatch(setAlert(`Vehicle was updated successfully`, "Please wait while we fetch updated data", SUCCESS));
      dispatch(setIsVehicleEditOn(false));
      refetch();
    }
  }, [dispatch, refetch, isLoading, data]);

  useEffect(() => {
    dispatch(setIsVehicleUpdateInProgress(isLoading));
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (isError) {
      const serviceUnavailable = error.status === 503;
      dispatch(setAlert(serviceUnavailable ? error.data.title : "Vehicle Update Failed", error.data.message, serviceUnavailable ? ERROR : WARNING));
    }
  }, [dispatch, isError, error]);

  const onSaveChanges = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formDataObj = Object.fromEntries(formData.entries());

    vehicleUIDisplayConfig.forEach((fieldConfigObj) => {
      if (typeof fieldConfigObj.formatFormDataForServer === "function" && formDataObj[fieldConfigObj.id]) {
        formDataObj[fieldConfigObj.id] = fieldConfigObj.formatFormDataForServer(formDataObj[fieldConfigObj.id]);
      } else {
        formDataObj[fieldConfigObj.id] = formDataObj[fieldConfigObj.id] || vehicleData[fieldConfigObj.id];
      }
    });
    triggerMutation({ ...formDataObj, upd_tms: vehicleData["upd_tms"] });
  };

  return (
    <Grid container spacing={2} sx={{ p: 2 }} component="form" id="vehicle_details_update" onSubmit={onSaveChanges}>
      {vehicleUIDisplayConfig.map(
        (item) =>
          vehicleData && (
            <RenderFieldContent
              isFieldDisabled={isLoading}
              key={item.id}
              fieldConfig={item}
              value={vehicleData[item.id]}
              isEditModeOn={isEditModeOn}
            />
          )
      )}
    </Grid>
  );
};

const UnassignVehicleButton = ({ handleMenuClose, vehicleId, refetch }) => {
  const dispatch = useDispatch();
  const isEditModeOn = useSelector(selectIsVehicleEditOn);
  const { triggerMutation, isLoading, isError, error, isSuccess } = useUnassignVehicleMutation();

  useEffect(() => {
    if (isError) {
      const serviceUnavailable = error.status === 503;
      dispatch(
        setAlert(serviceUnavailable ? error.data.title : "Inventory Parts Insertion Failed", error.data.message, serviceUnavailable ? ERROR : WARNING)
      );
    }
  }, [dispatch, isError, error]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      handleMenuClose();
      refetch();
    }
  }, [isLoading, isSuccess, handleMenuClose, refetch]);

  return (
    <LoadingButton
      disabled={isEditModeOn}
      loading={isLoading}
      type="button"
      variant="outlined"
      color="error"
      startIcon={<IndeterminateCheckBox />}
      fullWidth
      onClick={() => triggerMutation(vehicleId)}
    >
      Unassign Vehicle
    </LoadingButton>
  );
};

const EditVehicleButton = () => {
  const isEditModeOn = useSelector(selectIsVehicleEditOn);
  const isVehicleUpdating = useSelector(selectIsVehicleUpdateInProgress);
  const dispatch = useDispatch();

  return (
    <Button
      disabled={isVehicleUpdating}
      type="button"
      variant="outlined"
      sx={{ mr: 1 }}
      color={isEditModeOn ? "error" : "primary"}
      startIcon={isEditModeOn ? <ClearOutlined /> : <ModeEditOutlined />}
      onClick={() => dispatch(setIsVehicleEditOn())}
    >
      {isEditModeOn ? "Cancel" : "Edit"}
    </Button>
  );
};

const UpdateVehicleButton = () => {
  const isVehicleUpdating = useSelector(selectIsVehicleUpdateInProgress);
  const isEditModeOn = useSelector(selectIsVehicleEditOn);

  return (
    <LoadingButton
      loading={isVehicleUpdating}
      variant="contained"
      type="submit"
      form="vehicle_details_update"
      sx={{ mr: 0.5 }}
      disabled={!isEditModeOn}
      startIcon={<AddTaskOutlined />}
    >
      Update Vehicle
    </LoadingButton>
  );
};

const VehicleHistoryDrawerButton = ({ handleMenuClose }) => {
  const dispatch = useDispatch();
  return (
    <Button
      fullWidth
      type="button"
      variant="outlined"
      color="primary"
      startIcon={<Article />}
      onClick={() => {
        dispatch(setIsVehicleDrawerPageOpen(true));
        handleMenuClose();
      }}
    >
      Vehicle History
    </Button>
  );
};

export default function Vehicle({ vehicleId, refetch, vehicleData }) {
  const dispatch = useDispatch();
  const isVehicleAvailable = vehicleData.vhc_available === "Y" || vehicleData.vhc_available === "y";

  useEffect(() => {
    dispatch(setIsVehicleEditOn(false));
    dispatch(setIsVehicleUpdateInProgress(false));
  }, [dispatch, vehicleId]);

  return (
    <Box sx={{ py: 2, px: 3 }}>
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pb: 1,
        }}
      >
        <Typography color="primary" variant="h6" fontWeight={600}>
          Vehicle Details
        </Typography>
        <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
          <VehicleDetailsActionsButtonMenu>
            <EditVehicleButton />
            <UpdateVehicleButton />
            <VehicleHistoryDrawerButton />
            {!isVehicleAvailable && <UnassignVehicleButton vehicleId={vehicleId} refetch={refetch} />}
          </VehicleDetailsActionsButtonMenu>
        </Box>
      </Box>
      <Box component="div" sx={{ ...sectionUiLookBase, p: 2, my: 1 }}>
        <RenderLabelInputEditUI vehicleData={vehicleData} refetch={refetch} />
      </Box>
      <VehicleUsedLogDrawer vehicleId={vehicleId} availability={isVehicleAvailable} />
    </Box>
  );
}
