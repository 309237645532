import { Autorenew, Edit, Inventory2Outlined } from "@mui/icons-material";
import { Box, Button, Stack, Table, TableBody, TableContainer, TableHead, Typography } from "@mui/material";
import { memo, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { routeTaskParam } from "../../../app/constants/routes";
import no_content_img from "../../../asset/images/no_content.png";
import { InventoryPartsModal } from "../../../components/ui/InventoryPartsModal";
import { EmptyDataVisualComp, UiIconButton } from "../../../components/ui/UiUtils";
import { useUpdateTaskInventoryPartsMutation } from "../../../hooks/ApiHooks";
import { sectionUiLookBase } from "../../../styled/inlineCssStyles";
import { StyledTableCell, StyledTableRow } from "../../../styled/tableStyles";
import { partsInventoryColumnConfig } from "./config/partsConfig";

const PartsInventoryTable = memo(({ partsData, onSetInventoryPartModalConfig }) => (
  <TableContainer style={{ height: "300px" }}>
    <Table size="small" stickyHeader sx={{ minWidth: "400px" }}>
      <TableHead>
        <StyledTableRow>
          {partsInventoryColumnConfig.map(({ id: columnId, align, label }) => (
            <StyledTableCell key={columnId} align={align}>
              {label}
            </StyledTableCell>
          ))}
          <StyledTableCell align="center">Row Action</StyledTableCell>
        </StyledTableRow>
      </TableHead>
      <TableBody>
        {partsData.map((row) => (
          <StyledTableRow key={row.part_id}>
            {partsInventoryColumnConfig.map(({ id: columnId, align, alternateValue }) => (
              <StyledTableCell key={columnId} align={align}>
                {row[columnId] || alternateValue}
              </StyledTableCell>
            ))}
            <StyledTableCell align="center">
              <UiIconButton title="Edit Inventory Part" onClickIcon={() => onSetInventoryPartModalConfig(true, row)}>
                <Edit />
              </UiIconButton>
            </StyledTableCell>
          </StyledTableRow>
        ))}
        {partsData.length === 0 && (
          <StyledTableRow
            sx={{
              pointerEvents: "none",
              backgroundColor: "transparent !important",
            }}
          >
            <StyledTableCell
              colSpan={partsInventoryColumnConfig.length + 1}
              sx={{
                border: "0 !important",
                borderTop: "1px solid !important",
                borderColor: (theme) => `${theme.palette.primary.sectionBorder600} !important`,
              }}
            >
              <EmptyDataVisualComp
                src={no_content_img}
                altSrc="No Inventory Parts Listed"
                title="No Inventory Parts Listed"
                subTitle="Looks like no inventory parts are currently listed for this event"
              />
            </StyledTableCell>
          </StyledTableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
));

export default function TasksInventoryParts({ partsData, refetchData }) {
  const { [routeTaskParam]: taskId } = useParams();
  const [inventoryPartModalConfig, setInventoryPartModalConfig] = useState({ open: false, row: undefined });

  const onSetInventoryPartModalConfig = useCallback(
    (open = false, row = undefined) => setInventoryPartModalConfig({ open, row }),
    [setInventoryPartModalConfig]
  );

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ py: 1, mt: 1, position: "sticky", top: 0, zIndex: 100, backgroundColor: "background.default" }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <Inventory2Outlined color="primary" sx={{ m: 1 }} />
          <Typography variant="h6">Task Used Inventory Parts</Typography>
        </Stack>
        <Stack direction="row" gap={1} alignItems="center">
          <Button variant="contained" onClick={() => onSetInventoryPartModalConfig(true)}>
            Add New Part
          </Button>
          <UiIconButton title="Refetch Data" onClickIcon={refetchData}>
            <Autorenew />
          </UiIconButton>
        </Stack>
      </Stack>
      <Box sx={{ ...sectionUiLookBase, p: 2, my: 1 }}>
        <PartsInventoryTable partsData={partsData} onSetInventoryPartModalConfig={onSetInventoryPartModalConfig} />
      </Box>
      {!!inventoryPartModalConfig.open && (
        <InventoryPartsModal
          parentEntityId={taskId}
          useApiHook={useUpdateTaskInventoryPartsMutation}
          modalTitle="Task Inventory Part"
          onHandleClose={() => onSetInventoryPartModalConfig()}
          refetchFn={() => {
            onSetInventoryPartModalConfig();
            refetchData();
          }}
          partRow={inventoryPartModalConfig.row}
        />
      )}
    </Box>
  );
}
