import { FiberManualRecord, Info } from "@mui/icons-material";
import { Avatar, Box, Link, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink, useParams } from "react-router-dom";
import { DATE_TIME_FORMAT } from "../../../../app/constants/common";
import { routeTaskParam, routeToSingleUserAnalysisPage } from "../../../../app/constants/routes";
import { serverToUi } from "../../../../app/utils/utilityFunctions";
import logo from "../../../../asset/images/logo.png";
import no_content from "../../../../asset/images/no_content.png";
import LoadAttachedFiles from "../../../../components/AttachFileHandler/LoadFileChip";
import ReadOnlyTextEditor from "../../../../components/RichTextEditor/ReadOnlyEditor";
import { EmptyDataVisualComp } from "../../../../components/ui/UiUtils";
import { commentListItemSx, sectionUiLookBase } from "../../../../styled/inlineCssStyles";
import { findEmployeeOptionById } from "../../../AppSettings/appSlice";

const TaskAuditComment = ({ comment }) => {
  const { [routeTaskParam]: taskId } = useParams();
  const employee = useSelector((state) => findEmployeeOptionById(state, comment.ern));
  const titleComment = useMemo(() => {
    return (
      <Stack>
        <Typography component="div" fontSize="inherit">
          <Link
            component={RouterLink}
            to={routeToSingleUserAnalysisPage(comment.ern)}
            color="primary"
            fontWeight={600}
            underline="hover"
            fontSize="inherit"
          >
            {employee.name}
          </Link>
          <Typography component="span" color="textPrimary" fontWeight={400} sx={{ mx: 1 }} fontSize="inherit">
            {comment.sys_msg}
          </Typography>
        </Typography>
        <Typography component="span" color="textSecondary" fontSize={12} fontWeight={500}>
          {comment.file_ref?.length ?? 0} File(s) Uploaded
          <FiberManualRecord sx={{ color: "text.secondary", fontSize: 8, mx: 1 }} />
          {serverToUi(comment.upd_tms, DATE_TIME_FORMAT.UI_FULL_FORMAT)}
        </Typography>
      </Stack>
    );
  }, [comment, employee.name]);

  return (
    <ListItem alignItems="flex-start" sx={commentListItemSx()}>
      <ListItemAvatar sx={{ position: "relative" }}>
        <Avatar alt={employee.ern} src={logo} />
        <Avatar
          sx={{
            position: "absolute",
            bottom: -4,
            right: 14,
            height: 20,
            width: 20,
            backgroundColor: `primary.sectionContainer`,
            "& .MuiSvgIcon-root, & .MuiIcon-root": {
              fontSize: 18,
              color: `info.main`,
            },
          }}
        >
          <Info />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={titleComment}
        primaryTypographyProps={{ fontSize: 14 }}
        secondaryTypographyProps={{ fontSize: 13, color: "text.secondary", fontWeight: 500 }}
        secondary={
          <Box sx={{ mt: 0.5, mb: 1 }}>
            {comment.file_ref.length > 0 && (
              <LoadAttachedFiles
                color="secondary"
                fileReferences={comment.file_ref}
                downloadAllRawName={`${taskId}_${employee.name}_${employee.ern}`}
              />
            )}
            <ReadOnlyTextEditor
              content={comment.msg_txt}
              sx={{ ...sectionUiLookBase, color: "inherit", backgroundColor: "background.default", mt: 1, p: 1, "&::before": null }}
            />
          </Box>
        }
      />
    </ListItem>
  );
};

export default function TaskAuditHistory({ commentsData }) {
  const auditComments = commentsData.filter((comment) => comment.msg_type === "AUDIT");
  return (
    <List sx={{ width: "100%" }}>
      {!auditComments.length ? (
        <ListItem>
          <EmptyDataVisualComp src={no_content} title="No Audits" subTitle="There are no audits registered by the system yet" />
        </ListItem>
      ) : (
        auditComments.map((comment) => !!comment.msg_txt && <TaskAuditComment comment={comment} key={comment.msg_id} />)
      )}
    </List>
  );
}
