import { AddCircle, AirportShuttle, Autorenew, DeleteForever, Layers, Mediation, RemoveCircle, Stars } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  InputAdornment,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { uniqueId } from "lodash";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ERROR, INFO, SUCCESS, WARNING } from "../../app/constants/common";
import { setAlert } from "../../features/Alerts/slice/alertSlice";
import { useGetAllInventoryPartsMutation, useGetVehicleDetailsMutation } from "../../hooks/ApiHooks";
import { MuiDropDownMenu } from "../../styled/commonStyles";
import { GMaterialIcon } from "./IconUtils";
import { AutocompleteOptionsField } from "./InputUI";
import { EmptyDataVisualComp, LoadingProgress, Transition, UiIconButton } from "./UiUtils";

/* INFO: Helper function to create a new part entry */
const createInitialPartEntry = (existingConfig = {}, inventoryOptions = []) => ({
  id: uniqueId("inventory-part-entry"),
  part: existingConfig.part_id ? inventoryOptions.find(({ part_id: partId }) => partId === existingConfig.part_id) || null : null,
  qty: existingConfig.qty || "",
  prev_qty: existingConfig.qty,
  retainParts: false,
  existingPartVhcId: existingConfig.vhc_id,
});

/* INFO: Function to create a new vehicle entry */
const createInitialVehicleEntry = (existingConfig = {}, inventoryOptions = [], vehicleOptions = []) => ({
  id: uniqueId("inventory-vehicle-entry"),
  vehicle: existingConfig.vhc_id ? vehicleOptions.find(({ vhc_id: vehicleId }) => vehicleId === existingConfig.vhc_id) || null : null,
  parts: [createInitialPartEntry(existingConfig, inventoryOptions)],
});

const SavedVehiclesDropdown = memo(({ uniqueSelectedVehicleEntries = [], onSelected }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleSelect = (vehicle) => {
    onSelected(vehicle);
    handleClose();
  };

  return (
    <div>
      <UiIconButton size="small" title="View Previous Selected Vehicles" onClickIcon={handleClick} arrow iconColor="secondary.main" iconSize={20}>
        <Stars />
      </UiIconButton>
      <MuiDropDownMenu
        anchorEl={anchorEl}
        id="previous-selected-vehicles"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        sx={{ "& .MuiPaper-root": { backgroundColor: "background.paper" } }}
      >
        {uniqueSelectedVehicleEntries.length === 0 && (
          <MenuItem disableRipple onClick={handleClose}>
            <ListItemText primary="No Previously Selected Vehicles" />
          </MenuItem>
        )}
        {uniqueSelectedVehicleEntries.map((vehicle) => (
          <MenuItem key={vehicle.vhc_id} onClick={() => handleSelect(vehicle)}>
            <ListItemText primary={vehicle.vhc_id} secondary={vehicle.vhc_available?.toLowerCase() === "n" ? "Not Available" : "Available"} />
          </MenuItem>
        ))}
      </MuiDropDownMenu>
    </div>
  );
});

SavedVehiclesDropdown.displayName = "SavedVehiclesDropdown";

const PartEntry = memo(({ partInfo, onEntryChange, onRemove, inventoryOptions, disabled, isLastEntry }) => {
  const handlePartChange = (newValue) => onEntryChange("part", newValue);
  const handleQtyChange = (e) => onEntryChange("qty", e.target.value);
  const handleRetainPartsChange = (e) => onEntryChange("retainParts", e.target.checked);

  return (
    <Stack gap={2}>
      <Stack gap={2} sx={{ my: 1 }} direction="row" alignItems="center">
        <AutocompleteOptionsField
          size="small"
          options={inventoryOptions}
          value={partInfo.part}
          onChange={handlePartChange}
          label="Select Part*"
          placeholder="Search or select parts..."
          disabled={disabled}
          noOptionsText="No Parts Available"
          getOptionLabel={(option) => option.part_name}
          isOptionEqualToValue={(option, value) => option.part_id === value.part_id}
          renderOptionComponent={(option) => <ListItemText primary={option.part_name} secondary={option.part_id} />}
          filterFunction={({ part_name, part_id }, inputValue) =>
            [part_id, part_name].some((part) => String(part).toLowerCase().includes(inputValue.toLowerCase()))
          }
          uniqueKeyId="part_id"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Mediation color="primary" />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          size="small"
          label="Enter Quantity"
          variant="outlined"
          fullWidth
          type="number"
          value={partInfo.qty}
          onChange={handleQtyChange}
          placeholder="Enter a numeric value [0 - 99999]*"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Layers color="primary" />
              </InputAdornment>
            ),
            inputProps: {
              inputMode: "numeric",
              min: 0,
              max: 99999,
            },
          }}
          sx={{ "& .MuiOutlinedInput-root": { pl: 1 } }}
        />
        <UiIconButton onClickIcon={onRemove} title="Remove Part Entry" arrow iconColor="error.main" disabled={disabled || isLastEntry}>
          <DeleteForever />
        </UiIconButton>
      </Stack>
      {!!partInfo.existingPartVhcId && (
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <FormControlLabel
            control={<Checkbox checked={partInfo.retainParts} onChange={handleRetainPartsChange} />}
            label={<ListItemText primary="Return Vehicle Parts" secondary={`Vehicle ID: ${partInfo.existingPartVhcId}`} />}
          />
          {!!partInfo.retainParts && (
            <Typography
              component="span"
              sx={{
                color: (Number(partInfo.prev_qty) || 0) - (Number(partInfo.qty) || 0) >= 0 ? "success.main" : "warning.main",
                fontWeight: "500",
              }}
            >
              {(Number(partInfo.prev_qty) || 0) - (Number(partInfo.qty) || 0) >= 0 ? "Parts Returned: " : "Parts Taken: "}
              {Math.abs((Number(partInfo.prev_qty) || 0) - (Number(partInfo.qty) || 0))}
            </Typography>
          )}
        </Box>
      )}
    </Stack>
  );
});

PartEntry.displayName = "PartEntry";

const VehicleEntry = memo(
  ({
    vehicleEntry,
    totalVehicleEntries,
    index,
    onEntryChange,
    onAddPart,
    onRemove,
    vehicleOptions,
    inventoryOptions,
    partConfigExists,
    uniqueSelectedVehicleEntries = [],
  }) => {
    const handleVehicleChange = useCallback(
      (newValue) => {
        onEntryChange()("vehicle", newValue);
      },
      [onEntryChange]
    );

    return (
      <Card
        sx={{
          height: "100%",
          borderRadius: 2,
          border: "0.8px solid",
          borderColor: "primary.sectionBorder600",
          position: "relative",
        }}
      >
        <CardHeader
          title={`Vehicle Entry - ${index + 1}`}
          subheader="Fill in the details"
          titleTypographyProps={{ variant: "h6" }}
          action={
            <Stack direction="row" alignItems="center" gap={2} sx={{ m: 1 }}>
              <AutocompleteOptionsField
                size="small"
                options={vehicleOptions}
                value={vehicleEntry.vehicle}
                onChange={handleVehicleChange}
                disabled={partConfigExists}
                label="Select Vehicle"
                placeholder="Search or select vehicle..."
                noOptionsText="No Vehicles Available"
                getOptionLabel={(option) => option.vhc_id}
                isOptionEqualToValue={(option, value) => option.vhc_id === value.vhc_id}
                renderOptionComponent={(option) => (
                  <ListItemText primary={option.vhc_id} secondary={option.vhc_available?.toLowerCase() === "n" ? "Not Available" : "Available"} />
                )}
                filterFunction={({ vhc_id }, inputValue) => String(vhc_id).toLowerCase().includes(inputValue.toLowerCase())}
                uniqueKeyId="vhc_id"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AirportShuttle color="primary" />
                    </InputAdornment>
                  ),
                  ...(!partConfigExists && {
                    endAdornment: (
                      <InputAdornment position="end">
                        {/* TODO: Optimization Needed */}
                        <SavedVehiclesDropdown
                          onSelected={handleVehicleChange}
                          uniqueSelectedVehicleEntries={uniqueSelectedVehicleEntries.filter(
                            (unique) => unique.vhc_id !== vehicleEntry.vehicle?.vhc_id
                          )}
                        />
                      </InputAdornment>
                    ),
                  }),
                }}
                sx={{ minWidth: "220px" }}
              />
              <UiIconButton
                onClickIcon={() => onRemove()}
                title="Delete Vehicle Entry"
                arrow
                iconColor="error.main"
                disabled={partConfigExists || totalVehicleEntries < 2}
              >
                <RemoveCircle />
              </UiIconButton>
            </Stack>
          }
          sx={{ "& .MuiCardHeader-action": { flex: 1 } }}
        />
        <Divider />
        <CardContent sx={{ width: "100%", gap: 2 }}>
          {vehicleEntry.parts.map((partInfo) => (
            <PartEntry
              key={partInfo.id}
              partInfo={partInfo}
              onEntryChange={(field, value) => onEntryChange(partInfo.id)(field, value)}
              onRemove={() => onRemove(partInfo.id)}
              inventoryOptions={inventoryOptions}
              disabled={partConfigExists}
              isLastEntry={vehicleEntry.parts.length < 2}
            />
          ))}
          {!partConfigExists && (
            <Box sx={{ py: 1, display: "flex", justifyContent: "flex-end" }}>
              <UiIconButton
                onClickIcon={() => onAddPart(vehicleEntry.id)}
                title="Add Part Entry"
                arrow
                iconColor="success.main"
                sx={{ flex: 1, textAlign: "center" }}
              >
                <AddCircle />
              </UiIconButton>
            </Box>
          )}
        </CardContent>
      </Card>
    );
  }
);

VehicleEntry.displayName = "VehicleEntry";

const useInventoryPartsForm = ({ useApiHook, setOnSubmitLoading, parentEntityId, refetchFn }) => {
  const dispatch = useDispatch();
  const { triggerMutation, data, isLoading, isError, error } = useApiHook();

  useEffect(() => {
    setOnSubmitLoading(isLoading);
  }, [setOnSubmitLoading, isLoading]);

  useEffect(() => {
    if (!isLoading && data) {
      dispatch(
        setAlert("Inventory Part Was Updated Successfully", `Part was updated to ${parentEntityId}. Please wait while we fetch updated data`, SUCCESS)
      );
      refetchFn();
    }
  }, [dispatch, isLoading, data, parentEntityId, refetchFn]);

  useEffect(() => {
    if (isError) {
      const serviceUnavailable = error.status === 503;
      dispatch(
        setAlert(serviceUnavailable ? error.data.title : "Inventory Parts Insertion Failed", error.data.message, serviceUnavailable ? ERROR : WARNING)
      );
    }
  }, [dispatch, isError, error]);

  return { triggerMutation };
};

const InventoryPartsFieldForm = ({
  useApiHook,
  setOnSubmitLoading,
  parentEntityId,
  inventoryOptions,
  vehicleOptions,
  refetchFn,
  existingPartConfig = {},
}) => {
  const dispatch = useDispatch();
  const entriesRef = useRef(null);

  if (entriesRef.current === null) {
    entriesRef.current = [createInitialVehicleEntry(existingPartConfig, inventoryOptions, vehicleOptions)];
  }

  const [partEntries, setPartEntries] = React.useState(entriesRef.current);

  const { triggerMutation } = useInventoryPartsForm({
    useApiHook,
    setOnSubmitLoading,
    parentEntityId,
    refetchFn,
  });

  const uniqueSelectedVehicleEntries = useMemo(
    () => Array.from(new Map(partEntries.filter((entry) => entry.vehicle?.vhc_id).map((entry) => [entry.vehicle.vhc_id, entry.vehicle])).values()),
    [partEntries]
  );

  const handleAddEntry = useCallback(
    (vehicleEntryId = null) =>
      setPartEntries((prev) =>
        vehicleEntryId
          ? prev.map((entry) => (entry.id !== vehicleEntryId ? entry : { ...entry, parts: [...entry.parts, createInitialPartEntry()] }))
          : [...prev, createInitialVehicleEntry()]
      ),
    []
  );

  const handleRemoveEntry = useCallback(
    (vehicleEntryId) =>
      (partEntryId = null) =>
        setPartEntries((prev) =>
          partEntryId
            ? prev.map((entry) => (entry.id === vehicleEntryId ? { ...entry, parts: entry.parts.filter((part) => part.id !== partEntryId) } : entry))
            : prev.filter((entry) => entry.id !== vehicleEntryId)
        ),
    []
  );

  const handleEntryChange = useCallback(
    (vehicleEntryId) =>
      (partEntryId = null) =>
      (field, value) =>
        setPartEntries((prev) =>
          prev.map((entry) =>
            entry.id !== vehicleEntryId
              ? entry
              : {
                  ...entry,
                  ...(partEntryId
                    ? {
                        parts: entry.parts.map((part) => (part.id !== partEntryId ? part : { ...part, [field]: value })),
                      }
                    : { [field]: value }),
                }
          )
        ),
    []
  );

  const validateEntries = useCallback(() => {
    // Helper function to get readable location for error messages
    const getLocation = (vehicleIndex, partIndex) => {
      const vehicleNum = vehicleIndex + 1;
      const partNum = partIndex + 1;
      return `Vehicle ${vehicleNum}, Part ${partNum}`;
    };

    // Check for basic validation (missing required fields)
    for (let vehicleIndex = 0; vehicleIndex < partEntries.length; vehicleIndex++) {
      const vehicle = partEntries[vehicleIndex];

      // Validate vehicle has required fields
      if (!vehicle.vehicle?.vhc_id) {
        dispatch(setAlert("Invalid Vehicle Data", `No vehicle specified at vehicle entry ${vehicleIndex + 1}`, INFO));
        return false;
      }

      // Validate each part in the vehicle
      for (let partIndex = 0; partIndex < vehicle.parts.length; partIndex++) {
        const { part, qty } = vehicle.parts[partIndex];

        if (!part || !qty || isNaN(qty)) {
          const location = getLocation(vehicleIndex, partIndex);
          const message = !part ? `No inventory part was selected at ${location}` : `The quantity passed is invalid at ${location}`;

          dispatch(setAlert("Invalid Part Data", message, INFO));
          return false;
        }
      }
    }

    // Check for duplicate parts within each vehicle
    const duplicatesFound = partEntries.some((vehicle, vehicleIndex) => {
      const partsMap = {};

      return vehicle.parts.some((partEntry, partIndex) => {
        const partId = partEntry.part.part_id;

        if (partsMap[partId]) {
          const firstLocation = getLocation(vehicleIndex, partsMap[partId] - 1);
          const secondLocation = getLocation(vehicleIndex, partIndex);

          dispatch(
            setAlert(
              "Duplicate Parts Found",
              `In Vehicle ${vehicleIndex + 1}, duplicate part found at ${firstLocation} and ${secondLocation}. Please combine quantities for duplicate parts.`,
              INFO
            )
          );
          return true;
        }

        partsMap[partId] = partIndex + 1;
        return false;
      });
    });

    return !duplicatesFound;
  }, [partEntries, dispatch]);

  const onSaveChanges = useCallback(
    (e) => {
      e.preventDefault();
      if (!validateEntries()) return;

      const formattedEntries = partEntries.flatMap(({ vehicle, parts }) =>
        parts.map((part) => ({
          part_id: part.part.part_id,
          qty: parseInt(part.qty),
          ...(!!part.prev_qty && { prev_qty: part.prev_qty }),
          ...(part.retainParts && { vhc_id: part.existingPartVhcId }),
          vhc_id: vehicle?.vhc_id,
        }))
      );

      triggerMutation(parentEntityId, formattedEntries);
    },
    [partEntries, validateEntries, triggerMutation, parentEntityId]
  );

  return (
    <Stack
      component="form"
      id="inventory-parts-form"
      gap={2}
      onSubmit={onSaveChanges}
      sx={{
        py: 1,
        "& .MuiFormControl-root": {
          backgroundColor: "primary.sectionContainer",
          borderRadius: "4px",
          boxShadow: "0 0 0 2px rgba(0,0,0,.1)",
        },
      }}
    >
      <Stack gap={2}>
        {partEntries.map((vehicleEntry, index) => (
          <VehicleEntry
            key={vehicleEntry.id}
            index={index}
            vehicleEntry={vehicleEntry}
            totalVehicleEntries={partEntries.length}
            onEntryChange={handleEntryChange(vehicleEntry.id)}
            onRemove={handleRemoveEntry(vehicleEntry.id)}
            onAddPart={handleAddEntry}
            vehicleOptions={vehicleOptions}
            inventoryOptions={inventoryOptions}
            partConfigExists={!!existingPartConfig?.part_id}
            uniqueSelectedVehicleEntries={uniqueSelectedVehicleEntries}
          />
        ))}
      </Stack>

      {!existingPartConfig?.part_id && partEntries.length < 10 && (
        <Button variant="outlined" size="large" onClick={() => handleAddEntry()} color="primary" startIcon={<GMaterialIcon icon="post_add" />}>
          Add Another Vehicle
        </Button>
      )}
    </Stack>
  );
};

export const InventoryPartsModal = ({ modalTitle, parentEntityId, onHandleClose, useApiHook, refetchFn, partRow = undefined }) => {
  const {
    triggerMutation: triggerGetInventoryPartsMutation,
    isLoading: isPartsLoading,
    isError: isPartsError,
    data: inventoryData,
  } = useGetAllInventoryPartsMutation();
  const {
    triggerMutation: triggerGetVehicleMutation,
    isLoading: isVehicleLoading,
    isVehicleError,
    data: vehicleData,
  } = useGetVehicleDetailsMutation();

  const refetchVehicleAndParts = useCallback(() => {
    triggerGetInventoryPartsMutation();
    triggerGetVehicleMutation(false);
  }, [triggerGetInventoryPartsMutation, triggerGetVehicleMutation]);

  useEffect(() => {
    refetchVehicleAndParts();
  }, [refetchVehicleAndParts]);

  const [onSubmitLoading, setOnSubmitLoading] = useState(false);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={true}
      onClose={onSubmitLoading ? null : onHandleClose}
      TransitionComponent={Transition}
      aria-labelledby="add-parts-dialog-title"
      aria-describedby="add-parts-dialog-description"
      PaperProps={{
        sx: {
          backgroundColor: "background.default",
          border: "1px solid",
          borderColor: "primary.sectionBorder100",
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: 500, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <ListItemText
          primary={`${partRow?.part_id ? "Edit Existing" : "Add New"} ${modalTitle}`}
          secondary={partRow?.part_id ? `${partRow.part_name ?? ""}` : ""}
          primaryTypographyProps={{ variant: "h6", fontWeight: 500 }}
          secondaryTypographyProps={{ variant: "body1", fontWeight: 500, color: "primary" }}
        />
        <UiIconButton title="Re-try fetching parts" loading={isPartsLoading || isVehicleLoading} onClickIcon={refetchVehicleAndParts}>
          <Autorenew />
        </UiIconButton>
      </DialogTitle>
      <DialogContent sx={{ my: 1 }}>
        {isPartsLoading || isVehicleLoading ? (
          <LoadingProgress sx={{ height: "150px" }} />
        ) : inventoryData?.data && vehicleData?.data ? (
          <InventoryPartsFieldForm
            useApiHook={useApiHook}
            setOnSubmitLoading={setOnSubmitLoading}
            parentEntityId={parentEntityId}
            inventoryOptions={inventoryData.data}
            vehicleOptions={vehicleData.data}
            refetchFn={refetchFn}
            existingPartConfig={partRow}
          />
        ) : null}
        {!!(isPartsError || isVehicleError) && <EmptyDataVisualComp />}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onHandleClose} disabled={onSubmitLoading}>
          Return Back
        </Button>
        <LoadingButton
          type="submit"
          form="inventory-parts-form"
          loading={isPartsLoading || isVehicleLoading || onSubmitLoading}
          disabled={isPartsError || isVehicleError}
          variant="contained"
        >
          {partRow?.part_id ? "Edit Part" : "Add New Part"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
