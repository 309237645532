import { Box, Tab, Tabs } from "@mui/material";
import { memo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import AdvancedTable from "../../components/advancedTable/AdvancedTable";
import { ModernTabLabelComponent } from "../../components/ui/UiUtils";
import { useFetchAttendanceData } from "../../hooks/advancedTableHook";
import { modernTabStyle } from "../../styled/inlineCssStyles";
import { ATTENDANCE_ADJUSTMENT } from "./config/attendanceUtils";
import { attendanceTabData } from "./config/tabOptions";
import { selectAttendanceDate } from "./slice/attendanceSlice";

const TabLayout = ({ activeId, onChange }) => {
  return (
    <Tabs
      centered
      value={activeId}
      sx={modernTabStyle}
      onChange={onChange}
      TabIndicatorProps={{ sx: { backgroundColor: `transparent` } }}
      variant="fullWidth"
    >
      {Object.values(attendanceTabData).map(({ id, label, icon, color }) => (
        <Tab
          sx={{ padding: 0.5 }}
          disableRipple
          key={id}
          value={id}
          label={<ModernTabLabelComponent color={color} isActive={id === activeId} label={label} icon={icon} />}
        />
      ))}
    </Tabs>
  );
};

const AttendanceTable = memo(({ activeId, config }) => {
  const dateValue = useSelector(selectAttendanceDate);
  const tableConfig = config(dateValue);
  const apiHookWithParams = [useFetchAttendanceData, [activeId, tableConfig.tableProps.columnProps, dateValue]];
  return <AdvancedTable config={tableConfig} apiHookWithParams={apiHookWithParams} />;
});

export default function AttendanceTableLayout() {
  const [activeId, setActiveId] = useState(ATTENDANCE_ADJUSTMENT);
  const onHandleChange = useCallback((_, changedId) => setActiveId(changedId), []);
  return (
    <Box>
      <TabLayout activeId={activeId} onChange={onHandleChange} />
      <AttendanceTable activeId={activeId} config={attendanceTabData[activeId].getConfig} />
    </Box>
  );
}
