import {
  AccessTimeOutlined,
  AddToPhotos,
  Cake,
  Call,
  DocumentScanner,
  Email,
  Fax,
  HomeRepairService,
  HowToRegOutlined,
  LocationCity,
  NotInterestedOutlined,
  Place,
  Report,
  Tag,
  VpnKey,
} from "@mui/icons-material";
import { uiToServer } from "../../../app/utils/utilityFunctions";
import { useSelectUserAnalysisDateRangeOrder } from "../../../hooks/valueProviderHooks";

export const profileDisplayIcons = {
  nric: <DocumentScanner />,
  auth_status: <VpnKey />,
  auth_id: <Tag />,
  email: <Email />,
  phone: <Call />,
  dob: <Cake />,
  join_dt: <AddToPhotos />,
  adr_zip: <Fax />,
  adr_state: <LocationCity />,
  adr_city: <LocationCity />,
  adr_1: <Place />,
  adr_2: <Place />,
  role: <HomeRepairService />,
};

const userAnalysisDateOptions = Array.from({ length: 12 }, (_, index) => {
  const today = new Date();
  today.setMonth(today.getMonth() - index);

  const year = today.getFullYear();
  const month = today.getMonth();
  const startOfMonth = new Date(year, month, 1);
  const endOfMonth = new Date(year, month + 1, 0);
  const monthName = startOfMonth.toLocaleString("default", { month: "long" });
  return {
    value: month,
    label: `${monthName} ${year}`,
    returnDataValue: {
      value: month,
      from: uiToServer(startOfMonth),
      to: uiToServer(endOfMonth),
    },
  };
}).reverse();

export const userAnalysisDateRangeSelectConfig = {
  display: true,
  id: "select-analysis-date-range",
  inputLabel: "Date Range",
  size: "small",
  defaultValue: new Date().getMonth(),
  defaultDataValue: userAnalysisDateOptions[new Date().getMonth()].returnDataValue,
  options: userAnalysisDateOptions,
  useValueProvider: useSelectUserAnalysisDateRangeOrder,
  customOption: {
    label: "Custom Range",
    value: "custom_date",
  },
  sx: { maxWidth: "250px" },
  datePickerPropsArray: [
    {
      maxDate: new Date(),
    },
  ],
};

export const profileCards = (count = {}) => ({
  tasksCount: {
    id: "tasksCount",
    count: count.tasksCount || 0,
    label: "Tasks Assigned",
    color: "info",
    icon: <Report />,
  },
  active_employees: {
    id: "present",
    count: count.present || 0,
    label: "Active Attendances",
    color: "success",
    icon: <HowToRegOutlined />,
  },
  employees_on_leave: {
    id: "conflict",
    count: count.conflict || 0,
    label: "Attendance Adjustments",
    color: "error",
    icon: <NotInterestedOutlined />,
  },
  late: {
    id: "lateCount",
    count: count.lateCount || 0,
    label: "Late Arrivals",
    color: "warning",
    icon: <AccessTimeOutlined />,
  },
});
