import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { differenceInHours } from "date-fns";
import { NOTIFICATION_ACTION } from "../../../app/constants/config/notificationSettings";
import { serverDate } from "../../../app/utils/utilityFunctions";

const notificationAdapter = createEntityAdapter({
  selectId: (notification) => notification.notf_id,
  sortComparer: (a, b) => serverDate(b.crt_tms) - serverDate(a.crt_tms),
});
const notificationSlice = createSlice({
  name: "notifications",
  initialState: notificationAdapter.getInitialState({
    isPopupOpened: false,
    isGlobalDismissLoading: false,
    searchStr: "",
  }),
  reducers: {
    setPopupStatus: (state, { payload }) => {
      state.isPopupOpened = payload !== undefined ? payload : !state.isPopupOpened;
    },

    setNotificationSearch: (state, { payload }) => {
      state.searchStr = payload !== undefined ? payload : "";
    },

    setGlobalDismissLoading: (state, { payload }) => {
      state.isGlobalDismissLoading = payload !== undefined ? payload : !state.isGlobalDismissLoading;
    },

    addNotifications: (state, { payload = [] }) => {
      notificationAdapter.upsertMany(
        state,
        payload.map((attributes) => {
          const existingNotification = notificationAdapter.getSelectors().selectById(state, attributes.notf_id);
          const isNew = differenceInHours(new Date(), serverDate(attributes.crt_tms)) < 24;

          return {
            ...attributes,
            ...(existingNotification || {}),
            [NOTIFICATION_ACTION.VISIBLE]: existingNotification ? existingNotification[NOTIFICATION_ACTION.VISIBLE] : true,
            [NOTIFICATION_ACTION.HOVER]: existingNotification ? existingNotification[NOTIFICATION_ACTION.HOVER] : false,
            [NOTIFICATION_ACTION.DISPLAY_ALERT]: existingNotification
              ? existingNotification[NOTIFICATION_ACTION.DISPLAY_ALERT]
              : !state.isPopupOpened,
            [NOTIFICATION_ACTION.IS_NEW]: isNew,
          };
        })
      );
    },

    updateNotificationAction: {
      reducer(state, { payload }) {
        notificationAdapter.updateOne(state, payload);
      },
      prepare(id, type, value) {
        return { payload: { id, changes: type !== undefined ? { [type]: value } : {} } };
      },
    },

    updateNotificationActionInBulk: (state, { payload: { updateAction, value, currentNotifications, isOnlyId = false } }) => {
      if (!updateAction || value === undefined) return;
      const entities = currentNotifications
        ? isOnlyId
          ? currentNotifications
          : currentNotifications.map(({ notf_id }) => notf_id)
        : notificationAdapter
            .getSelectors()
            .selectAll(state)
            .map(({ notf_id }) => notf_id);
      notificationAdapter.updateMany(
        state,
        entities.map((id) => ({
          id,
          changes: { [updateAction]: value },
        }))
      );
    },

    removeNotification: (state, { payload: id }) => {
      notificationAdapter.removeOne(state, id);
    },
  },
});
const notificationState = (state) => state.notifications;
export const { selectAll: selectAllNotifications } = notificationAdapter.getSelectors(notificationState);
export const selectIsGlobalDismissLoading = createSelector([notificationState], (state) => state.isGlobalDismissLoading);
export const selectNotificationSearchString = createSelector([notificationState], (state) => state.searchStr);
export const selectNotificationsForAlerts = createSelector([notificationState, (state) => selectAllNotifications(state)], (state, notifications) =>
  state.isPopupOpened
    ? []
    : notifications.filter(({ [NOTIFICATION_ACTION.DISPLAY_ALERT]: displayAlert, [NOTIFICATION_ACTION.IS_NEW]: isNew }) => displayAlert && isNew)
);
export const selectAllNotificationIds = createSelector([selectAllNotifications], (notifications) => notifications.map(({ notf_id }) => notf_id));
export const selectNewNotificationsCount = createSelector(
  [selectAllNotifications],
  (notifications) => notifications.filter(({ [NOTIFICATION_ACTION.IS_NEW]: isNew }) => isNew).length
);
export const {
  addNotifications,
  updateNotificationAction,
  removeNotification,
  setPopupStatus,
  updateNotificationActionInBulk,
  setGlobalDismissLoading,
  setNotificationSearch,
} = notificationSlice.actions;
export default notificationSlice.reducer;
