import { Typography } from "@mui/material";
import { useEffect } from "react";
import { ConfirmationDialog } from "../../../components/ui/UiUtils";
import { useDeleteDataEntryRecords } from "../../../hooks/advancedTableHook";

export const DeleteSingleRowOption = ({ dataTypeId, rowId, keyCol, onClose, refetchData }) => {
  const [deleteTrigger, isLoading, isSuccess] = useDeleteDataEntryRecords(dataTypeId, keyCol);

  useEffect(() => {
    if (isSuccess) {
      onClose();
      refetchData();
    }
  }, [onClose, refetchData, isSuccess]);

  return (
    <ConfirmationDialog
      open={true}
      onClose={onClose}
      title={
        <span>
          Confirm Deletion of Record ID:{" "}
          <Typography component="span" variant="inherit" color="primary">
            {rowId}
          </Typography>
        </span>
      }
      subTitle={
        <span>
          <b>Warning:</b> This action is permanent and cannot be undone. Please review carefully before proceeding.
          <br />
          <br />
          <b>Tip:</b> To delete multiple rows at once, select the desired rows and choose the <i>&lsquo;Delete Selected Rows&lsquo;</i> option from
          the <i>&lsquo;Table Actions&lsquo;</i> button.
        </span>
      }
      onCancelTitle="Go Back"
      onSubmitTitle="Delete Anyway"
      loading={isLoading}
      onSubmit={deleteTrigger}
    />
  );
};
