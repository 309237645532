import { Layers, LayersOutlined } from "@mui/icons-material";
import MapLibreGL from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { useCallback, useRef, useState } from "react";
import Map, { FullscreenControl, GeolocateControl, Layer, NavigationControl, Source } from "react-map-gl";
import { useSelector } from "react-redux";
import { MAP_CONFIG } from "../../app/constants/config/Map";
import { UiIconButton } from "../../components/ui/UiUtils";
import { selectIsThemeDark } from "../AppSettings/appSlice";

const ThreeDimMapControl = ({ threeDEnabled, onToggleThreeD }) => (
  <div className="maplibregl-ctrl-top-right" style={{ top: 175 }}>
    <div className="maplibregl-ctrl maplibregl-ctrl-group">
      <UiIconButton
        title={`${threeDEnabled ? "Disable" : "Enable"} 3D Mode`}
        iconColor="#000"
        arrow
        onClickIcon={onToggleThreeD}
        iconSize={22}
        sx={{ mt: 0.5 }}
      >
        {threeDEnabled ? <Layers /> : <LayersOutlined />}
      </UiIconButton>
    </div>
  </div>
);

export const LocationMap = ({ children }) => {
  const mapRef = useRef(null);
  const isModeDark = useSelector(selectIsThemeDark);
  const [threeDEnabled, setThreeDEnabled] = useState(true);
  const onToggleThreeD = useCallback(() => {
    mapRef.current?.easeTo({
      pitch: !threeDEnabled ? 45 : 0,
      ...(threeDEnabled && { bearing: 0 }),
      duration: 500,
    });
    setThreeDEnabled((prevState) => !prevState);
  }, [mapRef, threeDEnabled]);

  return (
    <Map
      maxZoom={19}
      minZoom={4}
      ref={mapRef}
      initialViewState={{
        latitude: 3.1491529894700534,
        longitude: 101.69320488554322,
        zoom: 16,
        bearing: 0,
        pitch: 45,
      }}
      mapLib={MapLibreGL}
      dragRotate={threeDEnabled}
      style={{ width: "100%", height: "100%" }}
      mapStyle={isModeDark ? MAP_CONFIG.DARK_VECTOR_STYLE : MAP_CONFIG.LIGHT_VECTOR_STYLE}
      attributionControl={MAP_CONFIG.ATTRIBUTION}
    >
      {!threeDEnabled && (
        <Source id="raster-source" type="raster" tiles={[isModeDark ? MAP_CONFIG.DARK_RASTER_STYLE : MAP_CONFIG.LIGHT_RASTER_STYLE]} tileSize={512}>
          <Layer id="raster-layer" type="raster" source="raster-source" />
        </Source>
      )}
      <GeolocateControl position="top-right" showAccuracyCircle={false} />
      <FullscreenControl position="top-right" />
      <NavigationControl position="top-right" />
      <ThreeDimMapControl onToggleThreeD={onToggleThreeD} threeDEnabled={threeDEnabled} />
      {children}
    </Map>
  );
};
