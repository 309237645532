const ENCRYPTED_KEY_TOKEN_STORAGE = window.btoa("user-access-token");
const ENCRYPTED_KEY_PROFILE_STORAGE = window.btoa("profile-data");
const ENCRYPTED_REACT_APP_VERSION = window.btoa("app-version");

const CHECK_APP_VERSION_COMPATIBILITY = () => {
  try {
    const VERSION = localStorage.getItem(ENCRYPTED_REACT_APP_VERSION);
    const LATEST_VERSION = process.env.REACT_APP_VERSION || "1.0.0";
    if (!VERSION || window.atob(VERSION) !== LATEST_VERSION) {
      localStorage.clear();
      localStorage.setItem(ENCRYPTED_REACT_APP_VERSION, window.btoa(LATEST_VERSION));
    }
  } catch (error) {
    console.debug("Failed to check app version compatibility:", error);
  }
};

export const SET_ENCRYPTED_USER_PROFILE = (accessToken, profileData) => {
  localStorage.setItem(ENCRYPTED_KEY_TOKEN_STORAGE, window.btoa(accessToken));
  localStorage.setItem(ENCRYPTED_KEY_PROFILE_STORAGE, window.btoa(profileData));
};

export function GET_UNENCRYPTED_USER_PROFILE() {
  CHECK_APP_VERSION_COMPATIBILITY();
  try {
    const accessToken = localStorage.getItem(ENCRYPTED_KEY_TOKEN_STORAGE);
    if (!accessToken) return [];

    const profileData = localStorage.getItem(ENCRYPTED_KEY_PROFILE_STORAGE);
    const parsedProfileData = profileData ? JSON.parse(window.atob(profileData)) : {};
    if (!parsedProfileData.ern?.trim() || !parsedProfileData.name?.trim()) {
      throw new Error("Authentication Failed. Login Again");
    }
    const userProfile = {
      ...parsedProfileData,
      picture: undefined,
    };

    return [window.atob(accessToken), userProfile];
  } catch (error) {
    console.debug("LOGIN ERROR:", error);
    REMOVE_USER_PROFILE();
    return [];
  }
}

export function REMOVE_USER_PROFILE() {
  localStorage.removeItem(ENCRYPTED_KEY_PROFILE_STORAGE);
  localStorage.removeItem(ENCRYPTED_KEY_TOKEN_STORAGE);
}
