import { Autorenew } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DATE_TIME_FORMAT, GROUP_BY_COLUMN_NAME } from "../../app/constants/common";
import { CONFIG_CONSTANTS_OPTIONS } from "../../app/constants/config/AppSettings";
import { TASKS_VALUE_MAPPING, TASK_FIELDS } from "../../app/constants/config/systemTasks";
import { serverToUi } from "../../app/utils/utilityFunctions";
import { UiIconButton } from "../../components/ui/UiUtils";
import { useGetEmployeeLocationCoordinatesMutation } from "../../hooks/ApiHooks";
import { employeeLocationMainPageStyles } from "../../styled/inlineCssStyles";
import { selectPresetConfig } from "../AppSettings/appSlice";
import { LocationMap } from "./Map";
import {
  EmployeeLocationDetails,
  MapCustomSearchEmployeeLocation,
  RenderMapMarkers,
  RenderPopUpForMarker,
  ShowOrHideAllLocations,
} from "./MapUIComponents";
import { resetData, setOriginalLocationData } from "./slice/empMonitorSlice";

const EmployeeSelectMapCoordinates = () => {
  const configOptions = useSelector((state) => selectPresetConfig(state, CONFIG_CONSTANTS_OPTIONS.EMPLOYEES));
  const dispatch = useDispatch();
  const { triggerMutation, isLoading, data } = useGetEmployeeLocationCoordinatesMutation();

  const refetchData = () => {
    dispatch(resetData());
    triggerMutation();
  };

  useEffect(() => {
    triggerMutation();
  }, [triggerMutation]);

  useEffect(() => {
    if (!isLoading && data?.data) {
      const mergedArray = configOptions
        .reduce((acc, configEmp) => {
          const match = data.data.find((locationEmp) => locationEmp.ern === configEmp.ern);
          if (match) {
            acc.push({
              id: match.ern,
              coordinates: match.lat_long,
              name: configEmp.name,
              [GROUP_BY_COLUMN_NAME]: TASKS_VALUE_MAPPING[TASK_FIELDS.ASSIGNED_EMPLOYEES.ID][configEmp.role].label,
              lastUpdatedAtDate: serverToUi(match.upd_tms),
              lastUpdatedAtTime: serverToUi(match.upd_tms, DATE_TIME_FORMAT.UI_TIME_FORMAT),
            });
          }
          return acc;
        }, [])
        .filter(Boolean)
        .sort((a, b) => -a[GROUP_BY_COLUMN_NAME].localeCompare(b[GROUP_BY_COLUMN_NAME]));
      dispatch(setOriginalLocationData(mergedArray));
    }
  }, [dispatch, configOptions, isLoading, data]);

  return (
    <Box component="div" className="select-location">
      <MapCustomSearchEmployeeLocation isLoadingData={isLoading} />
      <ShowOrHideAllLocations isLoadingData={isLoading} />
      <UiIconButton
        onClickIcon={!isLoading ? refetchData : null}
        title="Reload Employee Locations"
        arrow
        sx={{ visibility: isLoading ? "hidden" : "visible", "&:hover": { backgroundColor: "transparent" } }}
      >
        <Autorenew />
      </UiIconButton>
    </Box>
  );
};

export default function EmployeeLocation() {
  const dispatch = useDispatch();
  useEffect(() => () => dispatch(resetData()));
  return (
    <Box sx={employeeLocationMainPageStyles}>
      <LocationMap>
        <RenderMapMarkers />
        <RenderPopUpForMarker />
        <EmployeeSelectMapCoordinates />
        <EmployeeLocationDetails />
      </LocationMap>
    </Box>
  );
}
