import { Accordion, AccordionSummary } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

export const MuiAccordion = styled(
  // eslint-disable-next-line no-unused-vars
  React.forwardRef(({ nestedSvgColor, ...props }, ref) => <Accordion ref={ref} disableGutters elevation={0} square {...props} />)
)(({ theme, nestedSvgColor }) => ({
  "&.MuiAccordion-root": {
    borderRadius: theme.spacing(0.5),
    backgroundColor: "transparent",
    backgroundImage: "none !important",
    boxShadow: "none !important",
    marginTop: "0 !important",
  },
  "& .MuiSvgIcon-root, & .MuiIcon-root": {
    color: nestedSvgColor?.(theme) || theme.palette.primary.main,
  },
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

export const MuiAccordionSummary = styled((props) => <AccordionSummary {...props} />)(() => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
}));
