import { Box, darken, getContrastRatio, lighten } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import { differenceInCalendarDays, format, isValid, parse } from "date-fns";
import { matchIsValidColor } from "mui-color-input";
import { inlineHighlightTextStyle } from "../../styled/inlineCssStyles";
import { DATE_TIME_FORMAT } from "../constants/common";
import { SERVER_FIELD_TYPE } from "../constants/config/DynamicFields";
import { PRIVATE_ROUTES, routeToSingleTask, routeToSingleUserAnalysisPage } from "../constants/routes";

/* INFO: Ratio is 3 for large objects, 4.5 for text [WCAG AA standard] */
export const getAdjustedColorForContrast = (color, theme, desiredContrastRatio = 3) => {
  let adjustedColor = theme.palette[color]?.main || color;
  let currentContrastRatio = getContrastRatio(theme.palette.primary.sectionContainer, adjustedColor);
  let adjustmentCount = 0;
  const maxAdjustments = 100;
  while (currentContrastRatio < desiredContrastRatio && adjustmentCount < maxAdjustments) {
    adjustedColor = theme.palette.mode === "dark" ? lighten(adjustedColor, 0.1) : darken(adjustedColor, 0.1);
    currentContrastRatio = getContrastRatio(theme.palette.primary.sectionContainer, adjustedColor);
    adjustmentCount++;
  }

  if (adjustmentCount >= maxAdjustments) console.warn("Reached maximum color adjustments without achieving desired contrast ratio.");

  const backgroundContainerColor = theme.palette.mode === "dark" ? darken(adjustedColor, 0.78) : lighten(adjustedColor, 0.85);

  return [adjustedColor, backgroundContainerColor];
};

export const getMaxMinBounds = (markers) => {
  const latitudes = markers.map((marker) => parseFloat(marker.coordinates.split(",")[0]));
  const longitudes = markers.map((marker) => parseFloat(marker.coordinates.split(",")[1]));
  return [
    [Math.min(...longitudes), Math.min(...latitudes)],
    [Math.max(...longitudes), Math.max(...latitudes)],
  ];
};

export const isAccessTokenValid = (token) => token && Date.now() < JSON.parse(window.atob(token.split(".")[1])).exp * 1000;

/**
 * Matches a URL path against a route pattern and extracts route parameters.
 * 
 * @param {string} urlPath - The actual URL path to match (e.g., "/users/123")
 * @param {string} routePattern - The route pattern with parameter placeholders (e.g., "/users/:id")
 * @returns {{ isMatch: boolean, params: Record<string, string> }} - Match result and extracted parameters
 * 
 * @example
 * matchRoute("/users/123", "/users/:id")
 * // Returns { isMatch: true, params: { id: "123" } }
 * 
 * matchRoute("/users/123/posts", "/users/:id")
 * // Returns { isMatch: false, params: {} }
 */
export const matchRoute = (urlPath, routePattern) => {
  // Convert paths to segments and remove empty parts
  const getSegments = (path) =>
    path
      .replace(/^\/|\/$/g, "")
      .split("/")
      .filter(Boolean);
  const urlSegments = getSegments(urlPath);
  const routeSegments = getSegments(routePattern);

  // Early length validation
  const requiredSegments = routeSegments.filter((seg) => !seg.endsWith("?")).length;
  if (urlSegments.length < requiredSegments || urlSegments.length > routeSegments.length) {
    return { isMatch: false, params: {} };
  }

  const params = {};
  const isMatch = routeSegments.every((routeSeg, i) => {
    const urlSeg = urlSegments[i];

    // Handle optional parameter
    if (routeSeg.endsWith("?") && !urlSeg) return true;

    // Handle parameter capture
    if (routeSeg.startsWith(":")) {
      if (urlSeg) {
        params[routeSeg.replace(/^:|\?$/g, "")] = urlSeg;
        return true;
      }
      return false;
    }

    return routeSeg === urlSeg;
  });

  return { isMatch, params: isMatch ? params : {} };
};

export const normalizeConfigForTable = (config) => ({
  ...config,
  tableProps: {
    ...config.tableProps,
    columns: config.tableProps.columns
      .map(({ id, label, primaryKey, disabledColumnField }) => !disabledColumnField && { id, label, primaryKey })
      .filter(Boolean),
    columnProps: Object.fromEntries(config.tableProps.columns.map((column) => [column.id, column])),
  },
});

export const createTableColumn = ({
  order,
  id,
  label,
  align = "center",
  primaryKey = false,
  disablePadding = false,
  wrapText = true,
  storeCustomDataOnRender = false,
  isTrueColumnField = true,
  disabledColumnField = false,
  alternateValue = "",
  renderContent = (data, searchStr) => highlightText(data, searchStr),
  searchBoolean = (data, searchStr) => (data ? String(data).toString().toLowerCase().includes(searchStr) : false),
  filterBoolean = (dataValue, filterValue) => dataValue === filterValue,
  renderPDF = (data) => data,
  renderCSV = (data) => data,
}) => ({
  order,
  id,
  primaryKey,
  disablePadding,
  label,
  align,
  isTrueColumnField,
  disabledColumnField,
  whiteSpace: wrapText ? "normal" : "nowrap",
  storeCustomDataOnRender,
  searchBoolean,
  filterBoolean,
  alternateValue,
  renderContent,
  renderPDF,
  renderCSV,
});

const descendingComparator = (a, b, orderBy) => (b[orderBy] < a[orderBy] ? -1 : b[orderBy] > a[orderBy] ? 1 : 0);
export const getComparator = (order, orderBy) =>
  order === "Descending_Order" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

export const stableSort = (array, comparator) =>
  array
    .map((el, index) => [el, index])
    .sort((a, b) => comparator(a[0], b[0]) || a[1] - b[1])
    .map((el) => el[0]);

export const highlightText = (data, highlight) => {
  if (!highlight) return data;
  const text = String(data);
  const highlightedText = text.split(new RegExp(`(${highlight})`, "gi"));
  return highlightedText.map((part, i) =>
    part.toLowerCase() === highlight.toLowerCase() ? (
      <Box key={i} component="span" sx={inlineHighlightTextStyle}>
        {part}
      </Box>
    ) : (
      part
    )
  );
};

export function getDateReferenceString(dateInput, referenceDate) {
  if (!dateInput) return null;
  const iDate = serverDate(dateInput);
  const refDate = serverDate(referenceDate);
  switch (differenceInCalendarDays(iDate, refDate)) {
    case 0:
      return null;
    default:
      return serverToUi(iDate);
  }
}

export function dateObjectForInputString(dateInput, context) {
  if (!dateInput) return null;
  if (dateInput instanceof Date && isValid(dateInput)) return dateInput;

  let dateFormat;
  if (context === "server") {
    if (dateInput.length === DATE_TIME_FORMAT.SERVER_FULL_FORMAT.length) dateFormat = DATE_TIME_FORMAT.SERVER_FULL_FORMAT;
    else if (dateInput.length === DATE_TIME_FORMAT.SERVER_DATE.length) dateFormat = DATE_TIME_FORMAT.SERVER_DATE;
    else if (dateInput.length === DATE_TIME_FORMAT.SERVER_TIME.length) dateFormat = DATE_TIME_FORMAT.SERVER_TIME;
    else dateFormat = DATE_TIME_FORMAT.SERVER_DATE;
  } else {
    if (dateInput.length === DATE_TIME_FORMAT.UI_FULL_FORMAT.length) dateFormat = DATE_TIME_FORMAT.UI_FULL_FORMAT;
    else if (dateInput.length === DATE_TIME_FORMAT.UI_DATE_FORMAT.length) dateFormat = DATE_TIME_FORMAT.UI_DATE_FORMAT;
    else if (dateInput.length === DATE_TIME_FORMAT.UI_TIME_FORMAT.length) dateFormat = DATE_TIME_FORMAT.UI_TIME_FORMAT;
    else dateFormat = DATE_TIME_FORMAT.UI_DATE_FORMAT;
  }

  const parsedDate = parse(dateInput, dateFormat, new Date());
  return isValid(parsedDate) ? parsedDate : null;
}

export function serverToUi(dateInput, outputFormat = DATE_TIME_FORMAT.UI_DATE_FORMAT) {
  if (![DATE_TIME_FORMAT.UI_DATE_FORMAT, DATE_TIME_FORMAT.UI_TIME_FORMAT, DATE_TIME_FORMAT.UI_FULL_FORMAT].includes(outputFormat)) return null;
  const parsedDate = dateObjectForInputString(dateInput, "server");
  return parsedDate ? format(parsedDate, outputFormat) : null;
}

export function serverDate(dateInput) {
  return dateObjectForInputString(dateInput, "server");
}

export function uiToServer(dateInput, outputFormat = DATE_TIME_FORMAT.SERVER_FULL_FORMAT) {
  if (![DATE_TIME_FORMAT.SERVER_FULL_FORMAT, DATE_TIME_FORMAT.SERVER_DATE, DATE_TIME_FORMAT.SERVER_TIME].includes(outputFormat)) return null;
  const parsedDate = dateObjectForInputString(dateInput, "ui");
  return parsedDate ? format(parsedDate, outputFormat) : null;
}

export function uiDate(dateInput) {
  return dateObjectForInputString(dateInput, "ui");
}

export const parseFieldValue = (fieldType, input, regexString = ".*", containerColor) => {
  let value = input?.target?.value ?? input;
  let isInvalid = false;

  switch (fieldType) {
    case SERVER_FIELD_TYPE.NUMBER:
      value = !isNaN(Number(value)) && value.length ? Number(value) : value;
      break;
    case SERVER_FIELD_TYPE.DATE_TMS8:
      value = uiToServer(value, DATE_TIME_FORMAT.SERVER_DATE);
      break;
    case SERVER_FIELD_TYPE.DATE_TMS14:
      value = uiToServer(value, DATE_TIME_FORMAT.SERVER_FULL_FORMAT);
      break;
    case SERVER_FIELD_TYPE.TEXT:
      value = value.toString().trim();
      break;
  }
  isInvalid = typeof value === "string" && value.length > 0 && !new RegExp(regexString).test(value);
  if (fieldType === SERVER_FIELD_TYPE.COLOR && containerColor) {
    if (matchIsValidColor(value)) {
      const colorRatio = getContrastRatio(containerColor, value);
      isInvalid = colorRatio < 2 || colorRatio > 9.5;
    } else isInvalid = true;
  }
  return [value, isInvalid];
};

export const stringToColor = (string) => {
  if (!string || !string.length) return "#000000";

  let hash = 0;
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const reducedName = (name) => {
  if (!name) return "NA";

  const parts = name.trim().split(/\s+/);
  const reduced = parts
    .reduce((acc, part, index, arr) => {
      if (index === 0 || index === 1 || index === arr.length - 1) {
        const validChar = part.match(/[a-zA-Z]/);
        if (validChar) acc += validChar[0];
      }
      return acc;
    }, "")
    .trim();

  return reduced || "NA";
};

export const base64ToBlob = async (data, mimeType) => {
  if (!data) return null;
  const urlPattern = /^(https?:\/\/[^\s$.?#].[^\s]*)$/;
  if (urlPattern.test(data)) {
    try {
      const response = await fetch(data);
      return await response.blob();
    } catch (error) {
      return null;
    }
  }

  const byteCharacters = atob(data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mimeType });
};

export const convertFileToBase64File = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () =>
      resolve({
        fileId: nanoid(),
        fileName: file.name,
        fileMime: file.type,
        fileData: reader.result.split(",")[1],
      });
    reader.onerror = () => reject(reader.error);
    reader.readAsDataURL(file);
  });
};

const extractId = (url) => {
  if (!url) return null;
  const {
    isMatch: isTask,
    params: { taskId },
  } = matchRoute(url, PRIVATE_ROUTES.singleTask);
  const {
    isMatch: isUser,
    params: { userId },
  } = matchRoute(url, PRIVATE_ROUTES.userAnalysis);
  return isTask ? `taskId:${taskId}` : isUser ? `userId:${userId}` : null;
};

const getHref = (dataRef) => {
  if (!dataRef) return "#";
  const type = dataRef.split(":");
  if (type[0] === "userId") return routeToSingleUserAnalysisPage(type.pop());
  else if (type[0] === "taskId") return routeToSingleTask(type.pop());
  return "#";
};

/* INFO: Function to add or remove attributes from anchor elements within the HTML string before posting to server */
export const updateTextEditorAnchorAttributes = (htmlStr, alterAnchor = true) => {
  const doc = new DOMParser().parseFromString(htmlStr, "text/html");
  doc.querySelectorAll("a").forEach((anchor) => {
    if (alterAnchor) {
      anchor.target = "_blank";
      anchor.className = "ms";
      anchor.rel = "noopener noreferrer nofollow";
      anchor.href = getHref(anchor.getAttribute("data-ref"));
    } else {
      anchor.removeAttribute("target");
      anchor.removeAttribute("class");
      anchor.removeAttribute("contenteditable");
      anchor.removeAttribute("rel");
      anchor.setAttribute("data-ref", extractId(anchor.getAttribute("href")));
      anchor.removeAttribute("href");
    }
  });
  return doc.body.innerHTML;
};
