import { CheckCircle, Error, Info, OfflineBolt } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { ALERT_ICON, ERROR, INFO, SUCCESS, WARNING } from "../../app/constants/common";

export const AlertIcon = ({ type, sx = {} }) => {
  let color = `${type}.main !important`;
  switch (type) {
    case SUCCESS:
      return <CheckCircle sx={{ width: ALERT_ICON.ICON_WIDTH, height: ALERT_ICON.ICON_HEIGHT, color, ...sx }} />;
    case ERROR:
      return <Error sx={{ width: ALERT_ICON.ICON_WIDTH, height: ALERT_ICON.ICON_HEIGHT, color, ...sx }} />;
    case INFO:
      return <OfflineBolt sx={{ width: ALERT_ICON.ICON_WIDTH, height: ALERT_ICON.ICON_HEIGHT, color, ...sx }} />;
    case WARNING:
      return <Info sx={{ width: ALERT_ICON.ICON_WIDTH, height: ALERT_ICON.ICON_HEIGHT, color, ...sx }} />;
    default:
      return null;
  }
};

export const AlertTitle = ({ type, title, sx = {} }) => {
  sx = { color: `${type}.main`, fontWeight: 600, fontSize: "1rem", ...sx };
  return <Typography sx={sx}>{title}</Typography>;
};

export const AlertDescription = ({ description, sx = {} }) => {
  sx = { color: `text.primary`, fontWeight: 300, ...sx };
  return <Typography sx={sx}>{description}</Typography>;
};
