import { ListItemText, Stack } from "@mui/material";
import { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { CONFIG_CONSTANTS_OPTIONS } from "../../app/constants/config/AppSettings";
import { PRIVATE_ROUTES, routeUserAnalysisParam } from "../../app/constants/routes";
import no_content from "../../asset/images/no_content.png";
import { SelectOptionsFieldWithCustomConfig } from "../../components/ui/InputUI";
import { EmptyDataVisualComp } from "../../components/ui/UiUtils";
import { selectPresetConfig } from "../AppSettings/appSlice";
import UserActivityDetails from "./UserActivityDetails";
import UserProfileView from "./UserProfileView";
import UserTabLayout from "./UserTabLayout";
import { userAnalysisDateRangeSelectConfig } from "./config/profileUtils";
import { selectUserAnalysisDisplayDateLabel } from "./slice/userAnalysisSlice";

const DateDisplay = () => {
  const displayDateLabel = useSelector(selectUserAnalysisDisplayDateLabel);
  return (
    <Stack
      sx={{ pb: 2, pt: 1, px: 3, position: "sticky", top: 82, zIndex: 100, backgroundColor: "background.default" }}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap={2}
      flexWrap="wrap"
    >
      <ListItemText
        primary="Employee Summary Period"
        secondary={displayDateLabel}
        primaryTypographyProps={{ noWrap: true, fontSize: "1.3rem", fontWeight: 600, color: "primary" }}
        secondaryTypographyProps={{ variant: "h6", color: "text.primary" }}
      />
      <SelectOptionsFieldWithCustomConfig selectConfig={userAnalysisDateRangeSelectConfig} />
    </Stack>
  );
};

export default function UserAnalysis() {
  const { [routeUserAnalysisParam]: activeTabId = false } = useParams();
  const employeesItems = useSelector((state) => selectPresetConfig(state, CONFIG_CONSTANTS_OPTIONS.EMPLOYEES));

  const renderProfile = useMemo(() => {
    if (!activeTabId) return null;
    return (
      <Fragment>
        <DateDisplay />
        <UserProfileView />
        <UserActivityDetails />
      </Fragment>
    );
  }, [activeTabId]);

  if (activeTabId && !employeesItems.some(({ ern } = {}) => activeTabId === ern)) return <Navigate replace to={PRIVATE_ROUTES.errorRoute} />;
  return (
    <Stack sx={{ px: 2, pt: 0, flex: 1, overflowY: "auto" }}>
      <UserTabLayout activeTabId={activeTabId} tabItems={employeesItems} />
      {renderProfile || (
        <EmptyDataVisualComp
          src={no_content}
          title="No Employee Selected"
          subTitle="Please select a employee from the above options to display details"
        />
      )}
    </Stack>
  );
}
