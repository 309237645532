import { createEntityAdapter, createSlice, nanoid } from "@reduxjs/toolkit";
import { ALERT_MAX_TIME_TILL_EXIT, INFO } from "../../../app/constants/common";

const alertAdapter = createEntityAdapter();
const alertSlice = createSlice({
  name: "alerts",
  initialState: alertAdapter.getInitialState(),
  reducers: {
    setAlert: {
      reducer(state, { payload }) {
        alertAdapter.addOne(state, payload);
      },

      prepare(title, description, type = INFO, maxTimer = ALERT_MAX_TIME_TILL_EXIT) {
        return {
          payload: {
            id: nanoid(),
            title,
            description,
            type,
            maxTimer,
            date: new Date().toISOString(),
          },
        };
      },
    },

    destroyAlert: (state, { payload }) => {
      alertAdapter.removeOne(state, payload);
    },
  },
});

export const { setAlert, destroyAlert } = alertSlice.actions;
export const { selectAll: selectAllAlerts } = alertAdapter.getSelectors((state) => state.alerts);

export default alertSlice.reducer;
