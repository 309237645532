import { LoadingButton } from "@mui/lab";
import { Box, Grid, Link, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useOutletContext } from "react-router-dom";
import { ERROR, INFO, WARNING } from "../../app/constants/common";
import { PUBLIC_ROUTES } from "../../app/constants/routes";
import { useLoginMutation } from "../../hooks/ApiHooks";
import { setAlert } from "../Alerts/slice/alertSlice";
import { RenderFormField } from "./AuthUiComponents";
import { authLoginFields } from "./config/authConfig";

const LoginFieldData = () => {
  const dispatch = useDispatch();
  const [setAlertMessage] = useOutletContext();
  const { triggerMutation: loginUser, isLoading } = useLoginMutation();

  const onSubmitForm = async (e) => {
    e.preventDefault();
    const [email, password] = new FormData(e.target).values();
    if (!email.trim() || !password.trim()) {
      setAlertMessage("Either Fields are empty");
      return;
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email.trim())) {
      setAlertMessage("Invalid email! Please enter a valid email");
      return;
    }

    try {
      /* INFO: Redux change in login access token state redirects automatically. Check RedirectUser.js */
      await loginUser(email.trim(), password.trim()).unwrap();
      dispatch(setAlert(`Welcome Back !!`, "You have been logged in successfully", INFO));
    } catch (err) {
      const serviceUnavailable = err.status === 503;
      setAlertMessage(err.data.message);
      dispatch(setAlert(serviceUnavailable ? err.data.title : "Login Error Occurred", err.data.message, serviceUnavailable ? ERROR : WARNING));
    }
  };

  return (
    <Box component="form" onSubmit={onSubmitForm}>
      <Grid container>
        {authLoginFields.map((item) => (
          <RenderFormField field={item} key={item.id} />
        ))}
      </Grid>
      <Link
        variant="body1"
        component={RouterLink}
        to={PUBLIC_ROUTES.resetPassword}
        color="text.secondary"
        fontWeight={500}
        underline="hover"
        sx={{ float: "right" }}
      >
        Forgot Password?
      </Link>
      <LoadingButton variant="contained" type="submit" fullWidth loading={isLoading} sx={{ mt: 2, mb: 1 }} size="large">
        <span>login</span>
      </LoadingButton>
    </Box>
  );
};

export default function LoginAuth() {
  return (
    <Box>
      <Typography variant="h5" sx={{ mt: 2 }} fontWeight={600}>
        Welcome Back!👋
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }} fontWeight={400} color="text.secondary">
        Enter your valid credentials to proceed
      </Typography>
      <LoginFieldData />
      <Link variant="body1" component={RouterLink} to={PUBLIC_ROUTES.signup} color="text.secondary" fontWeight={500} underline="hover">
        First-time login? Set up your password here.
      </Link>
    </Box>
  );
}
