export const ALERT_ICON = {
  ICON_WIDTH: "40px",
  ICON_HEIGHT: "40px",
};

export const DATE_TIME_FORMAT = {
  SERVER_DATE: "yyyyMMdd",
  SERVER_TIME: "HHmmss",
  UI_DATE_FORMAT: "MMM d, yyyy",
  UI_TIME_FORMAT: "hh:mm:ss a",
  get UI_FULL_FORMAT() {
    return `${this.UI_DATE_FORMAT} - ${this.UI_TIME_FORMAT}`;
  },
  get SERVER_FULL_FORMAT() {
    return this.SERVER_DATE + this.SERVER_TIME;
  },
};

export const SUCCESS = "success";
export const ERROR = "error";
export const INFO = "info";
export const WARNING = "warning";
export const SECONDARY = "secondary";
export const PRIMARY = "primary";
export const DESCENDING_ORDER = "Descending_Order";
export const ASCENDING_ORDER = "Ascending_Order";
export const ALERT_MAX_TIME_TILL_EXIT = "10000";
export const SIDE_BAR_WIDTH = "250";
export const GROUP_BY_COLUMN_NAME = "group";
export const REFRESH_APP_CONFIG_AFTER_SECONDS = 40;
