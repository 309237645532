import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import "./Logger";
import { store } from "./app/redux/store";
import { initializeThemeMode } from "./features/AppSettings/appSlice";
import { tabItems } from "./features/SystemTasks/config/tabOptions";
import { initializeTabs } from "./features/SystemTasks/slice/taskSlice";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
store.dispatch(initializeThemeMode(window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches));
store.dispatch(initializeTabs(tabItems));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
