import { Autorenew, CalendarMonth } from "@mui/icons-material";
import { alpha, Box, Card, Fab, Grid, ListItemText, Popover, Stack, Typography } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isEqual } from "date-fns";
import frLocale from "date-fns/locale/en-IN";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DATE_TIME_FORMAT } from "../../app/constants/common";
import { getDateReferenceString, highlightText, serverDate, serverToUi, uiToServer } from "../../app/utils/utilityFunctions";
import { StatisticBoard, UiIconButton } from "../../components/ui/UiUtils";
import { useGetAttendanceStatsMutation } from "../../hooks/ApiHooks";
import { MuiTooltip } from "../../styled/commonStyles";
import { StyledChip } from "../../styled/tableStyles";
import { attendanceStatsConfig } from "./config/attendanceUtils";
import { selectAttendanceDate, setAttendanceDate } from "./slice/attendanceSlice";

export const renderAttendanceStatus = (mapping) => (data, searchStr) => {
  if (!mapping[data]) return "N/A";
  return <StyledChip label={highlightText(mapping[data].label, searchStr)} color={mapping[data].color || "primary"} />;
};

export const RenderInOutDateTime = ({ date, time, referenceDate }) => {
  return (
    <MuiTooltip title={serverToUi(date)} fontSize={10}>
      <ListItemText primary={serverToUi(time, DATE_TIME_FORMAT.UI_TIME_FORMAT)} secondary={getDateReferenceString(date, referenceDate)} />
    </MuiTooltip>
  );
};

export const AttendanceStatistics = () => {
  const [statConfig, setStatConfig] = useState(attendanceStatsConfig({}));
  const dateValue = useSelector(selectAttendanceDate);
  const { triggerMutation, isLoading, data } = useGetAttendanceStatsMutation();

  const refetchData = useCallback(() => {
    triggerMutation(dateValue);
  }, [triggerMutation, dateValue]);

  useEffect(() => {
    refetchData();
  }, [refetchData]);

  useEffect(() => {
    if (!isLoading && data?.data) {
      setStatConfig(attendanceStatsConfig(data.data));
    }
  }, [isLoading, data?.data]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack direction="row" rowGap={2} justifyContent="space-between" alignItems="center" sx={{ my: 1 }}>
        <Typography noWrap fontWeight={600} fontSize="1.24rem" color="primary" component="div">
          Dashboard Statistics
          <StyledChip label={serverToUi(dateValue ?? new Date())} color="primary" sx={{ borderRadius: 1, ml: 1 }} />
        </Typography>
        <UiIconButton title="Reload Stats" onClickIcon={refetchData} loading={isLoading}>
          <Autorenew />
        </UiIconButton>
      </Stack>
      <Grid container spacing={2}>
        {Object.values(statConfig).map(({ id, ...restProps }, index) => (
          <Grid key={id} item xs={12} sm={6} lg={index === Object.values(statConfig).length - 1 ? 12 : 4}>
            <StatisticBoard key={id} loading={isLoading} {...restProps} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export const ChooseCalendarDate = () => {
  const dateValue = useSelector(selectAttendanceDate);
  const dispatch = useDispatch();
  const onChange = (value) =>
    dispatch(setAttendanceDate(!isEqual(serverDate(dateValue), new Date(value)) ? uiToServer(new Date(value), DATE_TIME_FORMAT.SERVER_DATE) : null));

  return (
    <Card
      component={motion.div}
      sx={{
        height: "100%",
        p: 1,
        minWidth: "320px",
        overflow: "hidden",
        background: (theme) =>
          `linear-gradient(220deg, ${alpha(theme.palette.primary.main, 0.15)} 0%, ${alpha(theme.palette.background.paper, 1)} 60%)`,
        borderRadius: 2,
        border: (theme) => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          border: (theme) => `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
        },
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ pt: 2, pl: 3, pr: 1.5 }}>
        <Typography fontWeight={600} fontSize="1.24rem" color="primary">
          Select Report Date
        </Typography>
      </Stack>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
        <DateCalendar
          value={serverDate(dateValue)}
          onChange={onChange}
          showDaysOutsideCurrentMonth
          defaultValue={dateValue}
          sx={{
            m: 0,
            height: "290px",
            "& .MuiPickersDay-root, .MuiDayCalendar-weekDayLabel": { fontSize: "0.95rem" },
            "& .MuiPickersSlideTransition-root": { height: "192px" },
          }}
          views={["year", "month", "day"]}
          disableFuture
        />
      </LocalizationProvider>
    </Card>
  );
};

const MotionFAB = motion.create(Box);

export const FloatingCalendarButton = ({ show }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <AnimatePresence>
      {!!show && (
        <MotionFAB
          initial={{ y: -100, opacity: 0 }}
          animate={{
            y: 0,
            opacity: 1,
            transition: {
              type: "spring",
              stiffness: 200,
              damping: 20,
              mass: 1,
            },
          }}
          exit={{
            y: 100,
            opacity: 0,
            transition: {
              duration: 0.3,
              ease: "easeInOut",
            },
          }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          sx={{
            position: "fixed",
            bottom: 24,
            right: 24,
            zIndex: 1000,
          }}
        >
          <MuiTooltip title="Choose Date" placement="left">
            <Fab
              color="primary"
              onClick={(event) => setAnchorEl(event.currentTarget)}
              aria-label="choose date"
              size="large"
              sx={{
                width: 64,
                height: 64,
                backgroundColor: (theme) => theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.primary.dark,
                },
                boxShadow: (theme) => `0 8px 24px ${theme.palette.primary.main}40`,
                border: (theme) => `3px solid ${theme.palette.primary.light}`,
              }}
            >
              <CalendarMonth size={32} />
            </Fab>
          </MuiTooltip>

          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <motion.div initial={{ opacity: 0, scale: 0.9 }} animate={{ opacity: 1, scale: 1 }} transition={{ duration: 0.2 }}>
              <ChooseCalendarDate />
            </motion.div>
          </Popover>
        </MotionFAB>
      )}
    </AnimatePresence>
  );
};
