import { Clear, Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UiIconButton } from "./UiUtils";

export const GenericSearchBar = ({ searchSelector, setSearchAction, placeholder, clearButtonIconSize, sx = {} }) => {
  const dispatch = useDispatch();
  const search = useSelector(searchSelector);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(search);
  }, [search]);

  useEffect(() => {
    const debouncedSearch = debounce((searchValue) => {
      if (searchValue === "" || searchValue.trim() !== "") {
        dispatch(setSearchAction(searchValue));
      }
    }, 400);

    debouncedSearch(inputValue);

    return () => debouncedSearch.cancel();
  }, [inputValue, setSearchAction, dispatch]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const clearSearch = () => {
    setInputValue("");
    dispatch(setSearchAction(""));
  };

  return (
    <TextField
      name="search_bar"
      id="search_bar"
      type="text"
      placeholder={placeholder}
      fullWidth
      sx={{
        fontSize: "1.05rem",
        backgroundColor: "primary.sectionContainer",
        borderRadius: 1,
        "& .MuiInputBase-root": { paddingLeft: "12px", paddingRight: "4px" },
        "& .MuiInputBase-input": { py: "12.5px" },
        ...sx,
      }}
      value={inputValue}
      onChange={handleChange}
      size="medium"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search sx={{ color: "text.primary" }} />
          </InputAdornment>
        ),
        endAdornment: inputValue && (
          <InputAdornment position="end">
            <UiIconButton title="Clear" iconColor="text.secondary" onClickIcon={clearSearch} iconSize={clearButtonIconSize}>
              <Clear />
            </UiIconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
