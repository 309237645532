import { AccountCircle, KeyboardArrowDown, Logout, Settings } from "@mui/icons-material";
import { Button, CircularProgress, Divider, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { INFO, WARNING } from "../../app/constants/common";
import { MANAGE_DATA_FORM_TYPE } from "../../app/constants/config/DynamicFields";
import { PUBLIC_ROUTES, routeToManageDataForm, routeToSingleUserAnalysisPage } from "../../app/constants/routes";
import { setAlert } from "../../features/Alerts/slice/alertSlice";
import { selectUserProfile } from "../../features/Auth/slice/authSlice";
import { useLogoutMutation } from "../../hooks/ApiHooks";
import { MuiDropDownMenu } from "../../styled/commonStyles";
import { EmployeeAvatar } from "../ui/UiUtils";

export const HeaderProfileDropDownButton = ({ onSetRef }) => {
  const { ern: userId, name: userName } = useSelector(selectUserProfile);
  return (
    <Button sx={{ display: "flex", textAlign: "start" }} onClick={onSetRef}>
      <EmployeeAvatar empId={userId} size="35px" sx={{ borderRadius: 2 }} />
      <ListItemText
        sx={{ m: 0, ml: 1, mr: 2 }}
        primary={userName}
        secondary={userId}
        primaryTypographyProps={{ fontWeight: 600, fontSize: "1rem", color: "text.primary" }}
        secondaryTypographyProps={{ fontWeight: 400, fontSize: "0.875rem" }}
      />
      <KeyboardArrowDown sx={{ color: "primary.main" }} />
    </Button>
  );
};

export const HeaderProfileDropDownMenu = ({ anchorEl, onClose }) => {
  const { ern: userId, name: userName } = useSelector(selectUserProfile);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { triggerMutation: logoutUser, isLoading } = useLogoutMutation();
  const logoutCurrentUser = async () => {
    try {
      await logoutUser().unwrap();
      dispatch(setAlert(`Bye! See You Again`, "You have been logged out successfully", INFO));
      nav(PUBLIC_ROUTES.login);
    } catch (error) {
      dispatch(
        setAlert("Logout Successful (With Server Error) !!", "You have been logged out of your session but with an unexpected server error", WARNING)
      );
      console.debug(error);
    }
  };

  return (
    <MuiDropDownMenu
      anchorEl={anchorEl}
      id="account-menu"
      open={Boolean(anchorEl)}
      onClose={onClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <ListItemText sx={{ textAlign: "center", fontWeight: 600, py: 1, px: 2 }}>Welcome, {userName}</ListItemText>
      <Divider sx={{ mb: 1 }} />
      <MenuItem
        onClick={() => {
          onClose();
          nav(routeToSingleUserAnalysisPage(userId));
        }}
      >
        <ListItemIcon>
          <AccountCircle fontSize="small" sx={{ color: "text.primary" }} />
        </ListItemIcon>
        <ListItemText>View Profile</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          onClose();
          nav(routeToManageDataForm("employee", MANAGE_DATA_FORM_TYPE.UPDATE, userId));
        }}
      >
        <ListItemIcon>
          <Settings fontSize="small" sx={{ color: "text.primary" }} />
        </ListItemIcon>
        <ListItemText>Edit Profile</ListItemText>
      </MenuItem>
      <MenuItem onClick={logoutCurrentUser} sx={{ textAlign: isLoading ? "center" : "start" }}>
        {isLoading ? (
          <ListItemText>
            <CircularProgress size={22} />
          </ListItemText>
        ) : (
          <Fragment>
            <ListItemIcon sx={{ color: "text.primary" }}>
              <Logout fontSize="small" />
            </ListItemIcon>
            <ListItemText>Log Out</ListItemText>
          </Fragment>
        )}
      </MenuItem>
    </MuiDropDownMenu>
  );
};
