import { Album, FlagCircle, OfflineBolt } from "@mui/icons-material";
import logo from "../../../asset/images/logo.png";
import { routeToInventoryVehicle, routeToSingleTask } from "../routes";

export const NOTIFICATION_API_CALL_AFTER_SECONDS = 15;
export const NOTIFICATION_ACTION = {
  HOVER: "hover",
  VISIBLE: "visible",
  IS_NEW: "is_new_notification",
  DISPLAY_ALERT: "display_notification_alert",
};

export const NOTIFICATION_ROUTE_TO = {
  TASK: (dataId) => (dataId ? routeToSingleTask(dataId) : null),
  VEHICLE: (dataId) => (dataId ? routeToInventoryVehicle(dataId) : null),
};

export const NOTIFICATION_TYPES_ATTRIBUTES = {
  TASK: {
    icon: <FlagCircle color="textPrimary" />,
    color: "warning",
  },
  VEHICLE: {
    icon: <Album color="textPrimary" />,
    color: "secondary",
  },
  SYSTEM: {
    icon: <OfflineBolt color="textPrimary" />,
    color: "info",
    alternativeAttributes: { ern: "NMS", name: "SYSTEM MESSAGE", picture: logo },
  },
};
