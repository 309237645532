import LogRocket from "logrocket";
import LogrocketFuzzySanitizer from "logrocket-fuzzy-search-sanitizer";

// eslint-disable-next-line no-undef
const isProd = process.env.REACT_APP_ENV === "PROD";
if (isProd) {
  const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup([
    "authorization",
    "access_token",
    "expires_at",
    "expires_in",
    "profile",
    "picture",
  ]);
  LogRocket.init("5qylm5/nms-group", {
    network: { requestSanitizer, responseSanitizer },
    dom: { inputSanitizer: true },
  });
}

// eslint-disable-next-line no-undef
export const LogUserIdentity = (id, props) => (isProd ? LogRocket.identify(id, props) : undefined);
