export const THEME_MODE = {
  KEY: "themetype",
  LIGHT: "light",
  DARK: "dark",
};

export const CONFIG_CONSTANTS_OPTIONS = {
  PRIORITY: "task_prty",
  TYPE: "task_type",
  STATUS: "task_status",
  EMPLOYEES: "employee_list",
  EQUIPMENT: "equipment_list",
  STORE: "store_list",
  CUSTOMER: "customer_list",
  TASKS: "task_list",
  FIELD_REGEX_LIST: "fld_regex",
  TASK_TAGS: "task_tags",
  NEXT_TASK_STATUS: "task_next_status",
};
