import { Box, Stack } from "@mui/material";
import { Fragment, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ERROR, WARNING } from "../../../app/constants/common";
import { PRIVATE_ROUTES, routeTaskParam } from "../../../app/constants/routes";
import { EmptyDataVisualComp, LoadingProgress } from "../../../components/ui/UiUtils";
import { useGetTaskDetailsMutation } from "../../../hooks/ApiHooks";
import { setAlert } from "../../Alerts/slice/alertSlice";
import Task from "./Task";
import TaskAdditionalDetails from "./TaskAdditionalDetails";
import TaskTreeList from "./TaskTreeList";
import TasksInventoryParts from "./TasksInventoryParts";

export default function TaskDetails() {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { triggerMutation, isLoading, data, isError, error } = useGetTaskDetailsMutation();
  const { [routeTaskParam]: id } = useParams();
  const refetchData = useCallback(() => {
    triggerMutation(id);
  }, [triggerMutation, id]);

  useEffect(() => {
    /*
      INFO: Indented to clear timeout when initial configuration is loaded otherwise api will be called twice
      (one before config loads and one after config loads)
      */
    const timer = setTimeout(refetchData, 200);
    return () => clearTimeout(timer);
  }, [refetchData]);

  useEffect(() => {
    if (isError) {
      const serviceUnavailable = error.status === 503;
      dispatch(setAlert(serviceUnavailable ? error.data.title : "Error Loading Data", error.data.message, serviceUnavailable ? ERROR : WARNING));
      if (/Task ID \[([A-Z]+_[A-Z]+\d+)\] Not Found/i.test(error.data.message)) {
        nav(PRIVATE_ROUTES.errorRoute, { replace: true });
      }
    }
  }, [dispatch, isError, error, nav]);

  return (
    <Stack direction="row" gap={4} sx={{ px: 2, pt: 0, flex: 1, overflowY: "auto" }}>
      {isLoading ? (
        <LoadingProgress />
      ) : isError ? (
        <EmptyDataVisualComp />
      ) : (
        !!data?.data && (
          <Fragment>
            <Stack component="section" sx={{ flex: 1 }}>
              <Task taskData={data.data} refetchData={refetchData} />
              <TasksInventoryParts partsData={data.data.parts_used} refetchData={refetchData} />
              <TaskAdditionalDetails commentsData={data.data.messages} refetchData={refetchData} />
              {/* INFO: Extra Spacing at the end of page */}
              <Box sx={{ padding: "42px" }} />
            </Stack>
            <TaskTreeList />
          </Fragment>
        )
      )}
    </Stack>
  );
}
