import { COMMON_TRANSFORM_RESPONSE_FUNCTION, PROFILE_ACTION } from "../../../app/constants/api";
import { apiSlice } from "../../../app/redux/apiSlice";

export const UserProfileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    profileAction: builder.mutation({
      query: ({ action, payload = {} }) => ({
        url: PROFILE_ACTION.BASE,
        method: "POST",
        body: { action, payload },
      }),
      ...COMMON_TRANSFORM_RESPONSE_FUNCTION,
    }),
  }),
});

export const { useProfileActionMutation } = UserProfileApiSlice;
