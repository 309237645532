import { Box, Container, Unstable_Grid2 as Grid, Stack } from "@mui/material";
import { useCallback, useEffect } from "react";
import { DATE_TIME_FORMAT } from "../../app/constants/common";
import { uiToServer } from "../../app/utils/utilityFunctions";
import { useGetAttendanceStatsMutation, useGetDashboardConfigMutation } from "../../hooks/ApiHooks";
import { attendanceStatsDataConfig, tasksPriorityData, taskTypeCount } from "./config/dataConfig";
import CustomerMonthlyCountChart from "./CustomerMonthlyCountChart";
import StatCard from "./StatCard";
import TasksDonutChart from "./TasksDonutChart";

export default function Dashboard() {
  const { triggerMutation: getDashboardData, isLoading: isDashboardDataLoading, isSuccess, data } = useGetDashboardConfigMutation();
  const { triggerMutation: getAttendanceStats, isLoading: isAttendanceStatsLoading, data: attendanceStatsData } = useGetAttendanceStatsMutation();

  const refetchData = useCallback(() => {
    getDashboardData();
    getAttendanceStats(uiToServer(new Date(), DATE_TIME_FORMAT.SERVER_DATE));
  }, [getDashboardData, getAttendanceStats]);

  useEffect(() => {
    refetchData();
  }, [refetchData]);

  return (
    <Box sx={{ py: 8, flex: 1, overflowY: "auto" }}>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          {[...attendanceStatsDataConfig(attendanceStatsData?.data), ...taskTypeCount(data?.data.typeCount)].map((item) => (
            <Grid xs={12} sm={6} lg={3} key={item.id}>
              <StatCard
                icon={item.icon}
                label={item.label}
                count={item.count}
                color={item.color}
                loading={isAttendanceStatsLoading || isDashboardDataLoading}
              />
            </Grid>
          ))}
          <Grid xs={12} lg={8}>
            <TasksDonutChart refetchData={refetchData} data={data} loading={isDashboardDataLoading} />
          </Grid>
          <Grid xs={12} lg={4}>
            <Stack height="100%" gap={2}>
              {tasksPriorityData(data?.data.taskPriority).map((item) => (
                <StatCard key={item.id} color={item.color} icon={item.icon} count={item.count} label={item.label} loading={isDashboardDataLoading} />
              ))}
            </Stack>
          </Grid>
          <Grid xs={12}>
            <CustomerMonthlyCountChart
              refetchData={refetchData}
              data={data}
              loading={isDashboardDataLoading}
              success={!isDashboardDataLoading && isSuccess}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
