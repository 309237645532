export const baseActiveReduxConfig = (tableId, otherData) => {
  const paginationRowsPerPageCurrentSelected = otherData.paginationRowsOptions.currentRowsPerPageSelected;
  const options = otherData.paginationRowsOptions.options;

  return {
    id: tableId,
    searchStr: "",
    activePage: 0,
    rowsPerPageSelected: paginationRowsPerPageCurrentSelected,
    rowsPerPageOptions: options,
    showColumns: otherData.columns.map(({ id }) => id),
    columns: otherData.columns,
    filterTable: false,
    selectedRows: [],
    selectedDateRange: otherData.defaultDateRange,
    sortDataInOrder: otherData.defaultSort,
    savedFilters: [],
    activeFilters: [],
    originalRows: [],
    rowData: [],
    customData: {},
  };
};
