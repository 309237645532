import { Cancel, DeleteForever, FiberManualRecord, Launch } from "@mui/icons-material";
import { Avatar, Box, Link, ListItem, ListItemAvatar, ListItemText, MenuItem, Stack, Typography } from "@mui/material";
import { Fragment, memo, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { DATE_TIME_FORMAT, WARNING } from "../../app/constants/common";
import { NOTIFICATION_ACTION, NOTIFICATION_ROUTE_TO, NOTIFICATION_TYPES_ATTRIBUTES } from "../../app/constants/config/notificationSettings";
import { routeToSingleUserAnalysisPage } from "../../app/constants/routes";
import { serverToUi } from "../../app/utils/utilityFunctions";
import no_content from "../../asset/images/no_content.png";
import ReadOnlyTextEditor from "../../components/RichTextEditor/ReadOnlyEditor";
import { EmployeeAvatar, EmptyDataVisualComp, UiIconButton } from "../../components/ui/UiUtils";
import { useDismissProfileNotificationsMutation } from "../../hooks/ApiHooks";
import { MuiTooltip } from "../../styled/commonStyles";
import { setAlert } from "../Alerts/slice/alertSlice";
import { findEmployeeOptionById } from "../AppSettings/appSlice";
import {
  selectAllNotificationIds,
  selectIsGlobalDismissLoading,
  setGlobalDismissLoading,
  updateNotificationAction,
  updateNotificationActionInBulk,
} from "./slice/notificationSlice";

export const NotificationHeader = memo(() => {
  const dispatch = useDispatch();
  const notificationIdArray = useSelector(selectAllNotificationIds);
  const { triggerMutation, isLoading, isSuccess, isError, reset } = useDismissProfileNotificationsMutation();
  const onDismissAllNotification = () => {
    if (notificationIdArray.length === 0) return;
    dispatch(setGlobalDismissLoading(true));
    triggerMutation(notificationIdArray);
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(setGlobalDismissLoading(false));
      dispatch(
        updateNotificationActionInBulk({
          updateAction: NOTIFICATION_ACTION.VISIBLE,
          value: false,
          currentNotifications: notificationIdArray,
          isOnlyId: true,
        })
      );
      reset();
    }
  }, [dispatch, notificationIdArray, isLoading, isSuccess, reset]);

  useEffect(() => {
    if (!isLoading && isError) {
      dispatch(setAlert("Bulk Notification Dismiss Failed", "Unexpected Error Occurred", WARNING));
      dispatch(setGlobalDismissLoading(false));
    }
  }, [dispatch, isLoading, isError]);

  return (
    <ListItem divider sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
        <ListItemText
          primary="Notifications"
          primaryTypographyProps={{ fontSize: "18px", fontWeight: 700 }}
          secondary={
            <Fragment>
              <FiberManualRecord color="primary" sx={{ fontSize: 11, mr: 0.5 }} />
              Recent Message
            </Fragment>
          }
          secondaryTypographyProps={{
            variant: "body1",
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
          }}
          sx={{ flex: 1 }}
        />
        <UiIconButton title="Dismiss All Notifications" arrow iconColor="error.main" loading={isLoading} onClickIcon={onDismissAllNotification}>
          <DeleteForever />
        </UiIconButton>
      </Box>
    </ListItem>
  );
});

export const Notification = memo(
  ({
    notf_id: notificationId,
    ern_from: employeeId,
    notf_type: type,
    notf_text: message,
    notf_data_ref: dataRef,
    crt_tms: time,
    [NOTIFICATION_ACTION.HOVER]: isHovered,
    [NOTIFICATION_ACTION.IS_NEW]: isNew,
    nodeRef,
    onClose,
  }) => {
    const isGlobalDismissLoading = useSelector(selectIsGlobalDismissLoading);
    const { triggerMutation, isLoading, isSuccess, isError } = useDismissProfileNotificationsMutation();
    const dispatch = useDispatch();
    const nav = useNavigate();
    const employee = useSelector((state) => findEmployeeOptionById(state, employeeId));
    const { icon, color, alternativeAttributes } = NOTIFICATION_TYPES_ATTRIBUTES[employee?.ern ? type : employeeId];
    const notificationFrom = employee ?? alternativeAttributes;
    const routeTo = NOTIFICATION_ROUTE_TO[type];

    const dismissNotification = () => triggerMutation([notificationId]);
    useEffect(() => {
      if (!isLoading && isSuccess) {
        dispatch(updateNotificationAction(notificationId, NOTIFICATION_ACTION.VISIBLE, false));
      }
    }, [dispatch, notificationId, isLoading, isSuccess]);

    useEffect(() => {
      if (!isLoading && isError) {
        dispatch(setAlert("Notification Dismiss Failed", "Unexpected Error Occurred", WARNING));
      }
    }, [dispatch, isLoading, isError]);

    const onClickNotification = (e, func, shouldClose) => {
      e.stopPropagation();
      func?.();
      if (shouldClose) onClose();
    };

    if (!employee && !alternativeAttributes) return null;
    return (
      <MenuItem
        ref={nodeRef}
        sx={{ position: "relative", mt: 2.5 }}
        onMouseEnter={() => dispatch(updateNotificationAction(notificationId, NOTIFICATION_ACTION.HOVER, true))}
        onMouseLeave={() => dispatch(updateNotificationAction(notificationId, NOTIFICATION_ACTION.HOVER, false))}
        onClick={(e) => onClickNotification(e, () => routeTo?.(dataRef) && nav(routeTo(dataRef)), !!routeTo?.(dataRef))}
      >
        <ListItemAvatar sx={{ position: "relative", minWidth: "52px" }}>
          <EmployeeAvatar
            {...(alternativeAttributes ? { srcObject: alternativeAttributes, srcExists: true } : { empId: notificationFrom.ern })}
            sx={{
              ...(!!alternativeAttributes && { backgroundColor: "transparent" }),
            }}
            size="36px"
          />
          <Avatar
            sx={{
              position: "absolute",
              bottom: -6,
              right: 12,
              height: 20,
              width: 20,
              backgroundColor: `primary.sectionContainer`,
              "& .MuiSvgIcon-root, & .MuiIcon-root": {
                fontSize: 18,
                color: `${color}.main`,
              },
            }}
          >
            {icon}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <Stack>
              <Link
                {...(alternativeAttributes
                  ? { component: "div" }
                  : { component: RouterLink, to: routeToSingleUserAnalysisPage(notificationFrom.ern) })}
                onClick={(e) => onClickNotification(e, null, !alternativeAttributes)}
                color="primary"
                fontWeight={600}
                underline="hover"
                sx={{ fontSize: 12 }}
              >
                {notificationFrom.name}{" "}
                <Typography display="inline-block" fontSize={12} fontWeight={400} color="text.primary">
                  [{notificationFrom.ern}]
                </Typography>
              </Link>
              <Typography
                component="span"
                color="text.secondary"
                fontSize={12}
                fontWeight={500}
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                {serverToUi(time, DATE_TIME_FORMAT.UI_FULL_FORMAT)}
                {!!routeTo?.(dataRef) && (
                  <MuiTooltip title="Click to Navigate" fontSize={10}>
                    <Launch fontSize="12px" color="primary" />
                  </MuiTooltip>
                )}
              </Typography>
            </Stack>
          }
          secondary={<ReadOnlyTextEditor content={message} sx={{ pl: 1, mt: 1, py: 0, maxWidth: "345px" }} />}
        />
        {!!isNew && (
          <FiberManualRecord color="primary" sx={{ display: isHovered || isLoading || isGlobalDismissLoading ? "none" : "inline", fontSize: 14 }} />
        )}
        <UiIconButton
          disableRipple
          title="Dismiss Notification"
          arrow
          iconColor="error.main"
          loading={isLoading || isGlobalDismissLoading}
          onClickIcon={(e) => onClickNotification(e, dismissNotification, false)}
          sx={{
            position: "absolute",
            transition: "transform 300ms ease-in-out",
            right: 4,
            top: "50%",
            transform: isHovered || isLoading || isGlobalDismissLoading ? "translate(0, -50%)" : "translate(300%, -50%)",
          }}
        >
          <Cancel />
        </UiIconButton>
      </MenuItem>
    );
  }
);

export const NoNotificationAvailable = memo(({ visible }) => {
  const ref = useRef();
  return (
    <CSSTransition nodeRef={ref} in={visible} timeout={300} classNames="notification-slide" unmountOnExit>
      <ListItem ref={ref}>
        <EmptyDataVisualComp
          src={no_content}
          title="No New Notifications Available"
          subTitle="You have the time to chill for now. The notifications are automatically refreshed"
          titleSx={{ fontSize: "15px" }}
          subTitleSx={{ fontSize: "13px" }}
        />
      </ListItem>
    </CSSTransition>
  );
});
