import { Autorenew } from "@mui/icons-material";
import { Box, Card, CardContent, CardHeader, Skeleton, Stack, alpha, useTheme } from "@mui/material";
import { useMemo } from "react";
import Chart from "react-apexcharts";
import { EmptyDataVisualComp, UiIconButton } from "../../components/ui/UiUtils";

const getChartOptions = (theme, categories = []) => {
  return {
    chart: {
      background: "transparent",
      type: "bar",
      toolbar: { show: false },
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    colors: [theme.palette.primary.main],
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -30,
      style: {
        fontSize: "14px",
        colors: [theme.palette.primary.main],
      },
    },
    grid: {
      borderColor: alpha(theme.palette.divider, 0.1),
      xaxis: { lines: { show: true } },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        columnWidth: "40px",
        dataLabels: { position: "top" },
      },
    },
    stroke: { show: false },
    theme: { mode: theme.palette.mode },
    xaxis: {
      categories,
      axisBorder: {
        color: theme.palette.divider,
        show: true,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      labels: {
        offsetY: 5,
        style: { colors: theme.palette.text.secondary },
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => (value > 0 ? `${value}` : `${value}`),
        offsetX: -10,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    title: {
      text: "Customers x Count",
      floating: true,
      offsetY: 0,
      align: "right",
      style: {
        color: theme.palette.text.secondary,
        fontWeight: 600,
        fontFamily: "'Poppins', sans-serif",
        fontSize: "18px",
        marginBottom: "10px",
      },
    },
  };
};

const ChartSkeleton = () => {
  const skeletonBars = Array(13).fill(null);

  const getRandomHeight = () => {
    return Math.floor(Math.random() * 60 + 30);
  };

  return (
    <Box
      sx={{
        height: 350,
        position: "relative",
      }}
      display="flex"
      alignItems="stretch"
      gap={3}
    >
      {/* Y-axis labels */}
      <Stack justifyContent="space-evenly">
        {skeletonBars.map((_, index) => (
          <Skeleton
            animation="wave"
            key={index}
            variant="rectangular"
            width={20}
            height={10}
            sx={{
              borderRadius: 1,
            }}
          />
        ))}
      </Stack>

      {/* Main chart area */}
      <Stack flex="1" justifyContent="space-between" alignItems="end">
        {/* Title and legend */}
        <Skeleton
          variant="text"
          animation="wave"
          width="20%"
          height={32}
          sx={{
            mb: 2,
          }}
        />

        {/* Chart bars container */}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            height: "100%",
            width: "100%",
            gap: 2,
            px: 2,
            position: "relative",
          }}
        >
          {/* Bars */}
          {skeletonBars.map((_, index) => (
            <Box
              key={index}
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                height: `${getRandomHeight()}%`,
              }}
            >
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{
                  width: 40,
                  height: `100%`,
                  borderRadius: 1,
                  marginX: "auto", // Centers the bar in its container
                }}
              />
              {/* X-axis labels */}
              <Skeleton
                variant="text"
                width={40}
                height={20}
                sx={{
                  marginX: "auto",
                  mt: 2,
                }}
              />
            </Box>
          ))}
        </Box>
      </Stack>
    </Box>
  );
};

export default function CustomerMonthlyCountChart({ data, refetchData, loading = false, success = false }) {
  const theme = useTheme();
  const chartData = useMemo(() => {
    if (!data?.data?.bar_chart) {
      return {
        series: [
          {
            name: "This Month",
            data: [],
          },
        ],
        categories: [],
      };
    }

    return {
      series: [
        {
          name: data.data.bar_chart.label || "This Month",
          data: data.data.bar_chart.data || [],
        },
      ],
      categories: data.data.bar_chart.categories || [],
    };
  }, [data]);

  const getCurrentMonthYear = () => {
    const date = new Date();
    return `${date.toLocaleString("default", { month: "long" })}, ${date.getFullYear()}`;
  };

  return (
    <Card
      sx={{
        p: 3,
        height: "100%",
        borderRadius: 4,
        border: "1px solid",
        borderColor: "primary.sectionBorder600",
      }}
    >
      <CardHeader
        title={`Customer Monthly Trends - ${getCurrentMonthYear()}`}
        action={
          <UiIconButton title="Refetch Data" onClickIcon={refetchData} disabled={loading}>
            <Autorenew />
          </UiIconButton>
        }
      />
      <CardContent>
        {loading ? (
          <ChartSkeleton />
        ) : !success ? (
          <EmptyDataVisualComp title="Error Loading Data" subTitle="There is no data to display for the selected period." />
        ) : (
          <Chart height={350} options={getChartOptions(theme, chartData.categories)} series={chartData.series} type="bar" width="100%" />
        )}
      </CardContent>
    </Card>
  );
}
