import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "./app/constants/routes";
import { appNavigationItems } from "./components/config/sidebarConfig";
import AppLayout from "./components/layout/AppLayout";
import AuthLayout from "./components/layout/AuthLayout";
import { RedirectLoggedInOutUser, RequireAuthForRoutes } from "./components/layout/RedirectUser";
import LoginAuth from "./features/Auth/Login";
import ResetAuth from "./features/Auth/ResetPassword";
import SignupUser from "./features/Auth/SignupPassword";
import { selectUserProfile } from "./features/Auth/slice/authSlice";
import VerifyUserAccount from "./features/Auth/VerifyEmail";
import Error404UI from "./features/Error404/Error404";
import { ErrorBoundaryWrapper } from "./features/Error404/ErrorLog";
import { flattenNavigation } from "./hooks/navigationHook";

export const useCreateAppRouter = () => {
  const { role } = useSelector(selectUserProfile);

  const routes = useMemo(
    () =>
      createBrowserRouter(
        createRoutesFromElements(
          <Route path={PUBLIC_ROUTES.homeRoute} element={<AppLayout />}>
            <Route index element={<Navigate to={PRIVATE_ROUTES.dashboard} replace />} />
            <Route element={<RequireAuthForRoutes />}>
              {flattenNavigation(appNavigationItems, role).map(({ id, path, component }) => (
                <Route key={id} path={path} element={<ErrorBoundaryWrapper functionFeature={id}>{component}</ErrorBoundaryWrapper>} />
              ))}
              <Route path="*" element={<Error404UI />} />
            </Route>
            <Route element={<RedirectLoggedInOutUser />}>
              <Route element={<AuthLayout />}>
                <Route index path={PUBLIC_ROUTES.login} element={<LoginAuth />} />
                <Route path={PUBLIC_ROUTES.resetPassword} element={<ResetAuth />} />
                <Route path={PUBLIC_ROUTES.signup} element={<SignupUser />} />
                <Route path={PUBLIC_ROUTES.verifyEmail} element={<VerifyUserAccount />} />
              </Route>
            </Route>
            <Route path="*" element={<Error404UI />} />
          </Route>
        )
      ),
    [role]
  );
  return routes;
};
