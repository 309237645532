import { createSlice } from "@reduxjs/toolkit";

const attendanceSlice = createSlice({
  name: "employeeAttendance",
  initialState: {
    selectedDate: null,
  },
  reducers: {
    setAttendanceDate: (state, { payload: dateValue }) => {
      state.selectedDate = dateValue;
    },
  },
});

export const { setAttendanceDate } = attendanceSlice.actions;
export const selectAttendanceDate = (state) => state.employeeAttendance.selectedDate;

export default attendanceSlice.reducer;
