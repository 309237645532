import { createSelector, createSlice, nanoid } from "@reduxjs/toolkit";
import { LogUserIdentity } from "../../../Logger";
import { AUTH_ACTION } from "../../../app/constants/api";
import { GET_UNENCRYPTED_USER_PROFILE, REMOVE_USER_PROFILE, SET_ENCRYPTED_USER_PROFILE } from "../../../app/constants/auth";
import { apiSlice } from "../../../app/redux/apiSlice";
import { isAccessTokenValid } from "../../../app/utils/utilityFunctions";

const initialState = () => {
  const init = {
    token: null,
    profile: null,
  };
  const [JWT, profile] = GET_UNENCRYPTED_USER_PROFILE();
  if (isAccessTokenValid(JWT)) init.token = JWT;
  init.profile = profile;
  return init;
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState(),
  reducers: {
    logIn: (state, { payload: { access_token: accessToken, profile } = {} }) => {
      if (!accessToken) return;
      const newProfileObject = {
        ern: profile?.ern,
        email: profile?.email,
        name: profile?.name,
        role: profile?.role,
        picture: undefined,
      };
      state.token = accessToken;
      state.profile = newProfileObject;

      SET_ENCRYPTED_USER_PROFILE(accessToken, JSON.stringify(newProfileObject));
      LogUserIdentity(profile?.ern || nanoid(), { traceId: nanoid(), email: profile?.email, name: profile?.name, role: profile?.role });
    },

    logOut: (state) => {
      state.token = null;
      state.profile = null;
      REMOVE_USER_PROFILE();
    },

    updateProfilePicture: (state, { payload: [id, url] = [] }) => {
      if (!id) return;
      state.profile = { ...state.profile, picture: url };
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(apiSlice.endpoints.authAction.matchFulfilled, (state, { payload }) => {
        if (payload.originalArgs.action === AUTH_ACTION.LOGIN) {
          authSlice.caseReducers.logIn(state, { payload: payload.data || null });
        } else if (payload.originalArgs.action === AUTH_ACTION.LOGOUT) {
          authSlice.caseReducers.logOut(state);
        }
      })
      .addMatcher(apiSlice.endpoints.authAction.matchRejected, (state, { payload }) => {
        if (payload.originalArgs.action === AUTH_ACTION.LOGOUT) {
          authSlice.caseReducers.logOut(state);
        }
      }),
});

export const { logIn, logOut, updateProfilePicture } = authSlice.actions;

export const selectCurrentToken = (state) => state.auth.token;
export const isTokenValid = createSelector([selectCurrentToken], (token) => isAccessTokenValid(token));
export const selectUserProfile = createSelector([isTokenValid, (state) => state.auth.profile], (valid, profile) => (valid ? profile : {}));
export default authSlice.reducer;
