import { DATE_TIME_FORMAT } from "../../../app/constants/common";
import { createTableColumn, highlightText, normalizeConfigForTable } from "../../../app/utils/utilityFunctions";
import { generalTableConfig } from "../../../components/config/generalTableConfig";
import { EmployeeUIContent, TableTimeStampDisplay } from "../../../components/ui/UiUtils";
import { DisplayPrimaryFileIdContent, RenderActionsForRow, ViewDataFileDialog } from "../KBCellActionComponent";
import NewKBUploadDialog from "../NewKBUploadDialog";

const kbColumns = [
  createTableColumn({
    order: 1,
    id: "fileIdLabel",
    label: "File Id",
    align: "left",
    primaryKey: true,
    disablePadding: true,
    renderContent: ({ icon, color, label }, searchStr) => (
      <DisplayPrimaryFileIdContent icon={icon} color={color} label={label} searchStr={searchStr} />
    ),
    searchBoolean: (data, searchStr) =>
      String(data?.label || "")
        .toLowerCase()
        .includes(searchStr),
  }),
  createTableColumn({
    order: 2,
    id: "fileName",
    label: "File Name",
    align: "left",
  }),
  createTableColumn({
    order: 4,
    id: "owner",
    label: "File Uploaded By",
    align: "left",
    renderContent: (data, searchStr) => <EmployeeUIContent empId={data} searchStr={searchStr} />,
  }),
  createTableColumn({
    order: 3,
    id: "remark",
    label: "File Remark",
    align: "left",
  }),
  createTableColumn({
    order: 6,
    id: "contentType",
    label: "Content Type",
    renderContent: (data, searchStr) => <span style={{ textTransform: "capitalize" }}>{highlightText(data, searchStr)}</span>,
  }),
  createTableColumn({
    order: 5,
    id: "uploadTime",
    label: "File Uploaded At",
    align: "left",
    renderContent: (dateInput, searchStr) => (
      <TableTimeStampDisplay dateInput={dateInput} format={DATE_TIME_FORMAT.UI_FULL_FORMAT} searchStr={searchStr} />
    ),
  }),
  createTableColumn({
    order: 7,
    id: "actions",
    label: "Row Actions",
    isTrueColumnField: false,
    renderContent: (rowData, refetchData) => !!rowData && <RenderActionsForRow rowId={rowData.id} refetchData={refetchData} />,
  }),
].sort((a, b) => a.order - b.order);

export const kBColumnConfig = () =>
  normalizeConfigForTable(
    generalTableConfig({
      tableId: "knowledge-base-files",
      tableTitle: `Uploaded Knowledge Base Files`,
      tableViewColumns: kbColumns,
      preventDisplayOf: {
        selectDateRangeDisplay: false,
        filterOptionsDisplay: false,
        exportButtonDisplay: false,
        areRowsSelectableDisplay: false,
      },
      otherDefaultsVariables: {
        paginationTitle: "Files / Page",
        newRowTooltipTitle: "Upload New File",
      },
      paginationRowsOptions: {
        pageOptions: [10, 20, 30],
      },
      tableRowClickNavigate: {
        tableOpenDialogForRow: true,
        tableDialogComponent: (props) => <ViewDataFileDialog {...props} />,
      },
      addRowButtonNavigateOptions: {
        hasAddRowDialog: true,
        newRowDialogComponent: (props) => <NewKBUploadDialog {...props} />,
      },
    })
  );
