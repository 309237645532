import { Description, FolderZip, Image, PictureAsPdf, Slideshow, TableChart, VideoLibrary } from "@mui/icons-material";
import accept from "attr-accept";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { DATE_TIME_FORMAT } from "../../app/constants/common";
import { base64ToBlob, uiToServer } from "../../app/utils/utilityFunctions";

export const MAX_FILE_UPLOADS_ALLOWED = 5;
export const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB
export const VIEW_IMAGE = "image";
export const VIEW_VIDEO = "video";
export const VIEW_PDF = "pdf";

export const IMAGE_MIME_TYPES = {
  "image/jpeg": { ICON_NAME: "Image", ICON: <Image />, EXTENSION: ".jpeg", VIEW_TYPE: VIEW_IMAGE, COLOR: "#39918F", IS_VIEWABLE: true },
  "image/png": { ICON_NAME: "Image", ICON: <Image />, EXTENSION: ".png", VIEW_TYPE: VIEW_IMAGE, COLOR: "#39918F", IS_VIEWABLE: true },
  "image/webp": { ICON_NAME: "Image", ICON: <Image />, EXTENSION: ".webp", VIEW_TYPE: VIEW_IMAGE, COLOR: "#39918F", IS_VIEWABLE: true },
};

const VIDEO_MIME_TYPES = {
  "video/mp4": { ICON_NAME: "VideoLibrary", ICON: <VideoLibrary />, EXTENSION: ".mp4", VIEW_TYPE: VIEW_VIDEO, COLOR: "#F06292", IS_VIEWABLE: true },
};

const PDF_MIME_TYPES = {
  "application/pdf": {
    ICON_NAME: "PictureAsPdf",
    ICON: <PictureAsPdf />,
    EXTENSION: ".pdf",
    VIEW_TYPE: VIEW_PDF,
    COLOR: "#7EBC9A",
    IS_VIEWABLE: true,
  },
};

const WORD_MIME_TYPES = {
  "application/msword": {
    ICON_NAME: "Description",
    ICON: <Description />,
    EXTENSION: ".doc",
    VIEW_TYPE: "Word Document",
    COLOR: "#C18F3E",
    IS_VIEWABLE: false,
  },
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": {
    ICON_NAME: "Description",
    ICON: <Description />,
    EXTENSION: ".docx",
    VIEW_TYPE: "Word Document",
    COLOR: "#C18F3E",
    IS_VIEWABLE: false,
  },
};

const PPT_MIME_TYPES = {
  "application/vnd.ms-powerpoint": {
    ICON_NAME: "Slideshow",
    ICON: <Slideshow />,
    EXTENSION: ".ppt",
    VIEW_TYPE: "Powerpoint Pt.",
    COLOR: "#C96C40",
    IS_VIEWABLE: false,
  },
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": {
    ICON_NAME: "Slideshow",
    ICON: <Slideshow />,
    EXTENSION: ".pptx",
    VIEW_TYPE: "Powerpoint Pt.",
    COLOR: "#C96C40",
    IS_VIEWABLE: false,
  },
};

const EXCEL_MIME_TYPES = {
  "application/vnd.ms-excel": { ICON_NAME: "TableChart", ICON: <TableChart />, EXTENSION: ".xls", VIEW_TYPE: "Excel Sheet", COLOR: "#5b44d3" },
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": {
    ICON_NAME: "TableChart",
    ICON: <TableChart />,
    EXTENSION: ".xlsx",
    VIEW_TYPE: "Excel Sheet",
    COLOR: "#5b44d3",
    IS_VIEWABLE: false,
  },
};

const ZIP_FILE_MIME_TYPE = {
  "application/zip" : {
    ICON_NAME: "FolderZip",
    ICON: <FolderZip />,
    EXTENSION: ".zip",
    VIEW_TYPE: "Zip File",
    COLOR: "#3c9674",
    IS_VIEWABLE: false,
  }
}

export const GENERAL_MIME_TYPES = {
  ...IMAGE_MIME_TYPES,
  ...VIDEO_MIME_TYPES,
  ...PDF_MIME_TYPES,
  ...WORD_MIME_TYPES,
  ...PPT_MIME_TYPES,
  ...EXCEL_MIME_TYPES,
  ...ZIP_FILE_MIME_TYPE,
};
export const KB_ALLOWED_MIME_TYPES = {
  ...IMAGE_MIME_TYPES,
  ...VIDEO_MIME_TYPES,
  ...PDF_MIME_TYPES,
  ...WORD_MIME_TYPES,
  ...PPT_MIME_TYPES,
};

const sanitizeFileName = (inputName) => {
  return inputName.replace(/[^a-zA-Z0-9-_.]/g, "-");
};

const formattedAttachmentName = (fileName) => {
  const timestamp = uiToServer(new Date(), DATE_TIME_FORMAT.SERVER_FULL_FORMAT).replace(/[:.-]/g, "");
  const parts = fileName.split(".");
  const extension = parts.length > 1 ? parts.pop() : "bin";
  const rawName = parts.join(".") || "sample";
  return `${sanitizeFileName(rawName)}_${timestamp}.${sanitizeFileName(extension)}`;
};

export const getIconNameAndColor = (mimeType, fileName) => {
  const parts = fileName.split(".");
  if (parts.length > 1) parts.pop();
  const rawName = parts.join(".") || "sample";
  return [
    GENERAL_MIME_TYPES[mimeType]?.ICON_NAME || "InsertDriveFile",
    GENERAL_MIME_TYPES[mimeType]?.COLOR || "#E5C250",
    rawName,
    GENERAL_MIME_TYPES[mimeType]?.VIEW_TYPE || "file",
    GENERAL_MIME_TYPES[mimeType]?.IS_VIEWABLE || false,
  ];
};

export const getDownloadAttributes = async (base64, name = "download.png", mimeType = "image/png") => {
  if (!(base64 && mimeType)) return { blobURL: null, downloadName: null };
  return { blobURL: await base64ToBlob(base64, mimeType), downloadName: formattedAttachmentName(name) };
};

export const filterAcceptedFileTypes = (fileList = [], maxFileSize = MAX_FILE_SIZE, MIME_TYPES = GENERAL_MIME_TYPES) => {
  const filteredFiles = Array.from(fileList).filter((file) => accept(file, Object.keys(MIME_TYPES).join(", ")) && file.size <= maxFileSize);
  return [filteredFiles, fileList.length === filteredFiles.length];
};

export const downloadFilesAsZip = async (fileDataArray, zipFileName) => {
  const zip = new JSZip();
  await Promise.all(
    fileDataArray.map(async ({ file_name, file_mime, signed_url } = {}) => {
      if (signed_url) {
        const blobURL = await base64ToBlob(signed_url, file_mime);
        if (blobURL) zip.file(file_name, blobURL);
      }
    })
  );

  try {
    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, formattedAttachmentName(`${zipFileName}.zip`));
    return "fulfilled";
  } catch (error) {
    return "rejected";
  }
};
