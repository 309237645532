import { TASK_FIELDS } from "../../../../app/constants/config/systemTasks";
import { getConfigDataBy } from "../../config/tasksFieldConfig";

export const newFormConfig = getConfigDataBy([
  { id: TASK_FIELDS.CUSTOMER.ID, isRequired: true, colSpan: 1, value: "", isEditable: true },
  { id: TASK_FIELDS.STORE.ID, isRequired: true, colSpan: 1, value: "", isEditable: true },
  { id: TASK_FIELDS.EQUIPMENT.ID, isRequired: false, colSpan: 1, value: "", isEditable: true },
  { id: TASK_FIELDS.CUSTOMER_NUMBER.ID, isRequired: false, colSpan: 1, value: "", isEditable: true },
  { id: TASK_FIELDS.TITLE.ID, isRequired: true, colSpan: 1, value: "", isEditable: true },
  { id: TASK_FIELDS.PARENT_ID.ID, isRequired: false, colSpan: 1, value: "", isEditable: true },
  { id: TASK_FIELDS.TYPE.ID, isRequired: true, colSpan: 1, value: "", isEditable: true },
  { id: TASK_FIELDS.PRIORITY.ID, isRequired: true, colSpan: 1, value: "", isEditable: true },
  { id: TASK_FIELDS.OWNER.ID, isRequired: true, colSpan: 1, value: "", isEditable: true },
  { id: TASK_FIELDS.EXECUTOR_EMPLOYEES.ID, isRequired: true, colSpan: 1, value: [], isEditable: true },
  { id: TASK_FIELDS.TASK_ASSIGNED_DATE.ID, isRequired: false, colSpan: 1, value: "", isEditable: true },
  { id: TASK_FIELDS.TAGS.ID, isRequired: false, colSpan: 1, value: [], isEditable: true },
  { id: TASK_FIELDS.RETAIN_PARTS.ID, isRequired: false, colSpan: 1, value: "N", isEditable: true },
  { id: TASK_FIELDS.DESCRIPTION.ID, isRequired: false, colSpan: 2, value: "", isEditable: true },
  { id: TASK_FIELDS.PROBLEM.ID, isRequired: false, colSpan: 2, value: "", isEditable: true },
  { id: TASK_FIELDS.CAUSE.ID, isRequired: false, colSpan: 2, value: "", isEditable: true },
  { id: TASK_FIELDS.REPAIR.ID, isRequired: false, colSpan: 2, value: "", isEditable: true },
]);
