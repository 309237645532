import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import AdvancedTable from "../../components/advancedTable/AdvancedTable";
import { useFetchTasksDataForSystemTasks } from "../../hooks/advancedTableHook";
import { TabHeader } from "./Tabs";
import { slicesConfigReferenceMap, tabItems } from "./config/tabOptions";
import { selectActiveTaskTab } from "./slice/taskSlice";

const SystemsTable = ({ sliceItemConfig }) => {
  const systemTaskConfig = sliceItemConfig.getConfig();
  return (
    <AdvancedTable
      config={systemTaskConfig}
      apiHookWithParams={[useFetchTasksDataForSystemTasks, [sliceItemConfig.taskStatusArray, systemTaskConfig.tableProps.columnProps]]}
    />
  );
};

export default function TasksLayout() {
  const activeTab = useSelector(selectActiveTaskTab);
  return (
    activeTab && (
      <Box sx={{ flex: 1, overflowY: "auto" }}>
        <TabHeader tabItems={tabItems} />
        <SystemsTable sliceItemConfig={slicesConfigReferenceMap[activeTab]} />
      </Box>
    )
  );
}
