import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";

const originalDataAdapter = createEntityAdapter();
const initialState = {
  originalData: originalDataAdapter.getInitialState(),
  currentData: null,
  useOriginalData: false,
  activeMarkerId: null,
};

const employeeMonitorSlice = createSlice({
  name: "employeeMonitor",
  initialState,
  reducers: {
    setOriginalLocationData: (state, action) => {
      originalDataAdapter.setAll(state.originalData, action.payload);
      state.useOriginalData = true;
    },

    setCurrentLocationData: (state, action) => {
      if (state.useOriginalData) state.useOriginalData = false;
      state.currentData = action.payload;
    },

    toggleLocationDataUse: (state) => {
      state.currentData = null;
      state.activeMarkerId = null;
      state.useOriginalData = !state.useOriginalData;
    },

    setActiveMarkerId: (state, action) => {
      state.activeMarkerId = action.payload;
    },

    resetData: (state) => {
      state.currentData = null;
      state.activeMarkerId = null;
      state.useOriginalData = false;
      originalDataAdapter.removeAll(state.originalData);
    },
  },
});

export const { setOriginalLocationData, setCurrentLocationData, toggleLocationDataUse, setActiveMarkerId, resetData } = employeeMonitorSlice.actions;

const selectEmployeeState = (state) => state.employeeMonitor;

export const selectOriginalLocationData = originalDataAdapter.getSelectors((state) => selectEmployeeState(state).originalData).selectAll;
export const selectCurrentLocationData = createSelector([selectEmployeeState], (state) => state.currentData);
export const selectUseOriginalLocationData = createSelector([selectEmployeeState], (state) => state.useOriginalData);
export const selectActiveMarker = createSelector(
  [selectOriginalLocationData, selectEmployeeState],
  (originalData, state) => state.activeMarkerId && originalData.find(({ id }) => state.activeMarkerId === id)
);
export const selectEmployeeLocationData = createSelector(
  [selectOriginalLocationData, selectCurrentLocationData, selectUseOriginalLocationData],
  (originalData, currentData, useOriginalData) => (useOriginalData ? [originalData, true] : [currentData ? [currentData] : [], false])
);
export default employeeMonitorSlice.reducer;
