import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingProgress } from "../../components/ui/UiUtils";
import { useFetchSystemTaskCountMutation } from "../../hooks/ApiHooks";
import { tabStyle } from "../../styled/inlineCssStyles";
import { selectActiveTaskTab, selectTabItemById, setActiveTaskTab } from "./slice/taskSlice";

const LabelComp = ({ label, itemId }) => {
  const tabItem = useSelector((state) => selectTabItemById(state, itemId));
  const activeTab = useSelector(selectActiveTaskTab);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        opacity: activeTab === itemId ? 1 : 0.6,
        width: "180px",
        "& .MuiTypography-root": {
          lineHeight: 1,
        },
      }}
    >
      {tabItem.isLoading ? (
        <LoadingProgress size={28} sx={{ width: "40px" }} />
      ) : (
        <Typography variant="h4" sx={{ fontWeight: 700, color: "primary.main" }}>
          {tabItem.count || 0}
        </Typography>
      )}
      <Typography
        sx={{
          marginLeft: "8px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          color: "text.primary",
        }}
      >
        {String(label)
          .split(" ")
          .map((word, index) => (
            <span key={index} style={{ padding: "1px" }}>
              {word}
            </span>
          ))}
      </Typography>
    </Box>
  );
};

export const TabHeader = ({ tabItems }) => {
  const activeTab = useSelector(selectActiveTaskTab);
  const dispatch = useDispatch();
  const onHandleChange = (_, value) => dispatch(setActiveTaskTab(value));
  const { triggerMutation: fetchCount } = useFetchSystemTaskCountMutation();

  useEffect(() => {
    if (activeTab) fetchCount();
  }, [fetchCount, activeTab]);

  return (
    <Tabs value={activeTab} onChange={onHandleChange} centered sx={{ ...tabStyle(), position: "sticky", top: 0, zIndex: 100 }}>
      {tabItems.map((item) => (
        <Tab disableRipple key={item.id} value={item.id} label={<LabelComp itemId={item.id} label={item.label} />} />
      ))}
    </Tabs>
  );
};
