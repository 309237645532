import { createSelector, createSlice } from "@reduxjs/toolkit";
import { DATE_TIME_FORMAT } from "../../../app/constants/common";
import { serverToUi, uiToServer } from "../../../app/utils/utilityFunctions";

const userAnalysisSlice = createSlice({
  name: "userAnalysis",
  initialState: {
    tasksCount: 0,
    selectedDateRange: {
      value: new Date().getMonth(),
      from: uiToServer(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
      to: uiToServer(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)),
    },
  },
  reducers: {
    setUserAnalysisTasksCount: (state, { payload }) => {
      state.tasksCount = payload || 0;
    },

    setSelectedUserAnalysisDateRange: (state, { payload }) => {
      state.selectedDateRange = payload;
    },
  },
});

export const { setSelectedUserAnalysisDateRange, setUserAnalysisTasksCount } = userAnalysisSlice.actions;

export const selectUserAnalysisSelectedDateRange = (state) => state.userAnalysis.selectedDateRange;
export const selectUserAnalysisTasksCount = (state) => state.userAnalysis.tasksCount;
export const selectUserAnalysisDisplayDateLabel = createSelector(
  [selectUserAnalysisSelectedDateRange],
  (rangeOption) =>
    `${serverToUi(rangeOption.from, DATE_TIME_FORMAT.UI_DATE_FORMAT) || "No Min Date"} - ${serverToUi(rangeOption.to, DATE_TIME_FORMAT.UI_DATE_FORMAT) || "Present Date"}`
);

export default userAnalysisSlice.reducer;
