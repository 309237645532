import { uniqueId } from "lodash";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DATE_TIME_FORMAT, ERROR, SUCCESS, WARNING } from "../app/constants/common";
import { adjustedApiTimeFormatConfig } from "../app/constants/config/Attendance";
import { filterDataBasedOnAttributes } from "../app/utils/middleware";
import { serverDate, uiToServer } from "../app/utils/utilityFunctions";
import { getIconNameAndColor } from "../components/AttachFileHandler/fileUtils";
import {
  selectCheckedRows,
  selectDateRange,
  selectIfCurrentDataIsSameAsCustomData,
  setCustomData,
  setDataRows,
  setOriginalDataRows,
} from "../components/slices/advancedTable";
import { setAlert } from "../features/Alerts/slice/alertSlice";
import { ATTENDANCE_ADJUSTMENT, ATTENDANCE_OVERTIME, ROUTINE_ATTENDANCE } from "../features/EmployeeAttendance/config/attendanceUtils";
import { setHasInventoryPartsTableDataChanged } from "../features/InventoryManagement/slice/inventorySlice";
import { selectDataTypeFormId } from "../features/ManageData/slices/ManageDataSlice";
import {
  USER_ACTIVITY_ATTENDANCE_DETAILS,
  USER_ACTIVITY_CONFLICTED_DETAILS,
  USER_ACTIVITY_OVERTIME_DETAILS,
  USER_ACTIVITY_TASK_DETAILS,
} from "../features/UserAnalysis/config/tableDetailsTabData";
import { selectUserAnalysisSelectedDateRange } from "../features/UserAnalysis/slice/userAnalysisSlice";
import {
  useDeleteInventoryPartsMutation,
  useDeleteRowDataMutation,
  useFetchSystemTaskItemsMutation,
  useGetAllInventoryPartsUsedListMutation,
  useGetBrowseFormTableRecordsMutation,
  useGetConflictedAttendanceListMutation,
  useGetKnowledgeBaseFilesData,
  useGetOvertimeAttendanceListMutation,
  useGetRoutineAttendanceListMutation,
} from "./ApiHooks";

export const useFetchTasksDataForSystemTasks = (taskStatusArray, columnProps) => {
  const dispatch = useDispatch();
  const { from, to } = useSelector(selectDateRange);
  const { triggerMutation, isLoading, isError, data: { data: originalRows } = {} } = useFetchSystemTaskItemsMutation();

  const triggerAPI = useCallback(() => {
    triggerMutation([from, to], undefined, taskStatusArray);
  }, [triggerMutation, taskStatusArray, from, to]);

  useEffect(() => {
    triggerAPI();
  }, [triggerAPI]);

  useEffect(() => {
    if (isLoading) dispatch(setDataRows());
    if (!isLoading && originalRows) {
      const primaryKeyId = Object.values(columnProps).find(({ primaryKey }) => primaryKey).id;
      const rowData = originalRows.map((row) => ({ ...row, id: row[primaryKeyId] }));
      dispatch(filterDataBasedOnAttributes({ payload: { originalRows: rowData }, columnProps, dispatchFn: () => setOriginalDataRows(rowData) }));
    }
  }, [dispatch, columnProps, isLoading, originalRows]);

  return [isLoading, isError, triggerAPI];
};

export const useFetchPartsInventoryTableData = (columnProps, originalRows, columnId, refetchDataFn) => {
  const dispatch = useDispatch();
  const isCurrentCustomDataSame = useSelector((state) => selectIfCurrentDataIsSameAsCustomData(state, columnId));

  useEffect(() => {
    const primaryKeyId = Object.values(columnProps).find(({ primaryKey }) => primaryKey).id;
    const rowData = originalRows.map((row) => ({ ...row, id: row[primaryKeyId] }));
    dispatch(setCustomData({ columnId, clear: true }));
    dispatch(filterDataBasedOnAttributes({ payload: { originalRows: rowData }, columnProps, dispatchFn: () => setOriginalDataRows(rowData) }));
  }, [dispatch, columnProps, originalRows, columnId]);

  useEffect(() => {
    dispatch(setHasInventoryPartsTableDataChanged(!isCurrentCustomDataSame));
  }, [dispatch, isCurrentCustomDataSame]);

  return [false, false, refetchDataFn];
};

export const useFetchInventoryReportTableData = (columnProps) => {
  const dispatch = useDispatch();
  const { from, to } = useSelector(selectDateRange);
  const { triggerMutation, isLoading, isError, data: { data: originalRows } = {} } = useGetAllInventoryPartsUsedListMutation();

  const triggerAPI = useCallback(() => {
    triggerMutation([from, to]);
  }, [triggerMutation, from, to]);

  useEffect(() => {
    triggerAPI();
  }, [triggerAPI]);

  useEffect(() => {
    if (isLoading) dispatch(setDataRows());
    if (!isLoading && originalRows) {
      // INFO: Here Primary Key is not used as unique identifier for rows, instead part_audit_id is used (not shown)
      const rowData = originalRows.map((row) => ({ ...row, id: row.part_audit_id || uniqueId("inventory-report-") }));
      dispatch(filterDataBasedOnAttributes({ payload: { originalRows: rowData }, columnProps, dispatchFn: () => setOriginalDataRows(rowData) }));
    }
  }, [dispatch, columnProps, isLoading, originalRows]);

  return [isLoading, isError, triggerAPI];
};

export const useDeletePartsInventoryRowData = (vehicleId) => {
  const selectedRowIds = useSelector(selectCheckedRows);
  const dispatch = useDispatch();
  const { triggerMutation, isLoading, data, isError, error, isSuccess } = useDeleteInventoryPartsMutation();
  const deleteDataApi = useCallback(() => triggerMutation(vehicleId, selectedRowIds), [triggerMutation, selectedRowIds, vehicleId]);

  useEffect(() => {
    if (isError) {
      const serviceUnavailable = error.status === 503;
      dispatch(
        setAlert(
          serviceUnavailable ? error.data.title : "Row Deletion Failed. Error Occurred",
          error.data.message,
          serviceUnavailable ? ERROR : WARNING
        )
      );
    }
  }, [dispatch, isError, error]);

  useEffect(() => {
    if (data) {
      dispatch(
        setAlert(`Deletion Successful`, "Selected rows were deleted successfully. Please contact administrator, if this was an error", SUCCESS)
      );
    }
  }, [dispatch, data]);

  return [deleteDataApi, isLoading, isSuccess];
};

export const useFetchAttendanceData = (attendanceType, columnProps, dateValue) => {
  const dispatch = useDispatch();
  const mutations = {
    [ATTENDANCE_ADJUSTMENT]: useGetConflictedAttendanceListMutation(),
    [ATTENDANCE_OVERTIME]: useGetOvertimeAttendanceListMutation(),
    [ROUTINE_ATTENDANCE]: useGetRoutineAttendanceListMutation(),
  };
  const currentMutation = mutations[attendanceType];
  const { triggerMutation, isLoading, isError, data: { data } = {} } = currentMutation;

  const formatTimeData = useCallback(
    (row) => {
      const format = adjustedApiTimeFormatConfig(attendanceType);
      if (!format) return row;
      return Object.entries(format).reduce(
        (acc, [key]) => ({
          ...acc,
          [key]: row[key] && {
            ...row[key],
            reference_dt: dateValue,
          },
        }),
        {}
      );
    },
    [attendanceType, dateValue]
  );

  const refetchData = useCallback(() => {
    triggerMutation([dateValue]);
  }, [triggerMutation, dateValue]);

  useEffect(() => {
    refetchData();
  }, [refetchData]);

  useEffect(() => {
    if (isLoading) dispatch(setDataRows());
    if (data) {
      const primaryKeyId = Object.values(columnProps).find(({ primaryKey }) => primaryKey).id;
      const rowData = data.map((row) => ({
        ...row,
        ...formatTimeData(row),
        id: row[primaryKeyId],
      }));
      dispatch(filterDataBasedOnAttributes({ payload: { originalRows: rowData }, columnProps, dispatchFn: () => setOriginalDataRows(rowData) }));
    }
  }, [dateValue, formatTimeData, dispatch, columnProps, isLoading, data]);

  return [isLoading, isError, refetchData];
};

export const useFetchDataEntryRecords = (columnProps) => {
  const activeFormId = useSelector(selectDataTypeFormId);
  const dispatch = useDispatch();
  const { triggerMutation, isLoading, isError, data: { data: originalRows } = {} } = useGetBrowseFormTableRecordsMutation();

  const triggerRefetch = useCallback(() => triggerMutation(activeFormId), [triggerMutation, activeFormId]);
  useEffect(() => {
    triggerRefetch();
  }, [triggerRefetch]);

  useEffect(() => {
    if (isLoading) dispatch(setDataRows());
    if (!isLoading && originalRows) {
      // INFO: Here Primary Key is not used as unique identifier for rows, instead keycol is used
      const rowData = originalRows.map((row) => ({ ...row, id: row.keycol || uniqueId("keycol-") }));
      dispatch(filterDataBasedOnAttributes({ payload: { originalRows: rowData }, columnProps, dispatchFn: () => setOriginalDataRows(rowData) }));
    }
  }, [dispatch, columnProps, isLoading, originalRows]);

  return [isLoading, isError, triggerRefetch];
};

export const useDeleteDataEntryRecords = (formId, deleteSpecificRowId = undefined) => {
  const dispatch = useDispatch();
  const selectedRowIds = useSelector(selectCheckedRows);
  const { triggerMutation, isLoading, isError, error, data, isSuccess } = useDeleteRowDataMutation();
  const deleteDataApi = useCallback(
    () => triggerMutation(formId, deleteSpecificRowId ? [deleteSpecificRowId] : selectedRowIds),
    [formId, triggerMutation, selectedRowIds, deleteSpecificRowId]
  );

  useEffect(() => {
    if (!isLoading && data) {
      dispatch(
        setAlert(
          "Data Deletion Successfully",
          `We have successfully removed the desired data. Please check the data entry table for changes`,
          SUCCESS
        )
      );
    }
  }, [dispatch, isLoading, data]);

  useEffect(() => {
    if (isError) {
      const serviceUnavailable = error.status === 503;
      dispatch(setAlert(serviceUnavailable ? error.data.title : "Data Deletion Failed", error.data.message, serviceUnavailable ? ERROR : WARNING));
    }
  }, [dispatch, isError, error]);

  return [deleteDataApi, isLoading, isSuccess];
};

export const useFetchUserAnalysisData = (userAnalysisType, columnProps, activeErn) => {
  const dispatch = useDispatch();
  const { from, to } = useSelector(selectUserAnalysisSelectedDateRange);
  const mutations = {
    [USER_ACTIVITY_TASK_DETAILS]: useFetchSystemTaskItemsMutation(),
    [USER_ACTIVITY_ATTENDANCE_DETAILS]: useGetRoutineAttendanceListMutation(),
    [USER_ACTIVITY_OVERTIME_DETAILS]: useGetOvertimeAttendanceListMutation(),
    [USER_ACTIVITY_CONFLICTED_DETAILS]: useGetConflictedAttendanceListMutation(),
  };
  const currentMutation = mutations[userAnalysisType];
  const { triggerMutation, isLoading, isError, data: { data } = {} } = currentMutation;

  const formatTimeData = useCallback(
    (row) => {
      const format = adjustedApiTimeFormatConfig(userAnalysisType);
      if (!format) return row;
      return Object.entries(format).reduce(
        (acc, [key]) => ({
          ...acc,
          [key]: row[key] && {
            ...row[key],
            reference_dt: row.bus_dt,
          },
        }),
        {}
      );
    },
    [userAnalysisType]
  );

  const refetchData = useCallback(() => {
    triggerMutation(
      [uiToServer(serverDate(from), DATE_TIME_FORMAT.SERVER_DATE), uiToServer(serverDate(to), DATE_TIME_FORMAT.SERVER_DATE)],
      activeErn
    );
  }, [triggerMutation, from, to, activeErn]);

  useEffect(() => {
    refetchData();
  }, [refetchData]);

  useEffect(() => {
    if (isLoading) dispatch(setDataRows());
    if (data) {
      const primaryKeyId = Object.values(columnProps).find(({ primaryKey }) => primaryKey).id;
      const rowData = data.map((row) => ({
        ...row,
        ...formatTimeData(row),
        id: row[primaryKeyId],
      }));
      dispatch(filterDataBasedOnAttributes({ payload: { originalRows: rowData }, columnProps, dispatchFn: () => setOriginalDataRows(rowData) }));
    }
  }, [formatTimeData, dispatch, columnProps, isLoading, data]);

  return [isLoading, isError, refetchData];
};

export const useFetchFilesForKnowledgeBase = (columnProps) => {
  const dispatch = useDispatch();
  const { triggerMutation, isLoading, isError, data } = useGetKnowledgeBaseFilesData();

  useEffect(() => {
    triggerMutation();
  }, [triggerMutation]);

  useEffect(() => {
    if (isLoading) dispatch(setDataRows());
    if (!isLoading && data?.data) {
      const originalRows = data.data.map((row) => {
        const [icon, color, fileName, contentType, isViewable] = getIconNameAndColor(row.file_mime, row.file_name);
        return {
          id: row.file_ref,
          fileIdLabel: { icon, color, label: row.file_ref },
          fileName,
          owner: row.file_owner,
          remark: row.file_remark,
          contentType,
          uploadTime: row.upd_tms,
          isViewable,
        };
      });
      dispatch(filterDataBasedOnAttributes({ payload: { originalRows }, columnProps, dispatchFn: () => setOriginalDataRows(originalRows) }));
    }
  }, [dispatch, columnProps, isLoading, data]);

  return [isLoading, isError, triggerMutation];
};
