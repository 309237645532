import { CheckCircle, OfflineBolt } from "@mui/icons-material";
import { INFO, SUCCESS } from "../../../../app/constants/common";
import TaskAuditHistory from "../Tabs/TaskAuditHistory";
import TaskComments from "../Tabs/TaskComments";

export const taskTabItems = {
  activeItem: "task-comments",
  items: [
    {
      id: "task-comments",
      label: "Comments",
    },
    {
      id: "task-audit-history",
      label: "Task Audit History",
    },
  ],
  components: {
    "task-comments": (props) => <TaskComments {...props} />,
    "task-audit-history": (props) => <TaskAuditHistory {...props} />,
  },
};

export const auditCommentsIcon = {
  "NEW-COMMENT": {
    color: SUCCESS,
    icon: <CheckCircle />,
  },
  "UPDATE-STATUS": {
    color: INFO,
    icon: <OfflineBolt />,
  },
};
