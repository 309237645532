import { Autorenew, ListAlt } from "@mui/icons-material";
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import { Fragment, createElement, useState } from "react";
import { UiIconButton } from "../../../components/ui/UiUtils";
import { sectionUiLookBase } from "../../../styled/inlineCssStyles";
import { taskTabItems } from "./config/tabConfig";

const AdditionalDetailsTabComponent = ({ commentsData, refetchData }) => {
  const [activeTab, setActiveTab] = useState(taskTabItems.activeItem);
  const onHandleChange = (_, value) => setActiveTab(value);

  return (
    <Fragment>
      <Tabs
        value={activeTab}
        onChange={onHandleChange}
        centered
        sx={{
          py: 1,
          mt: 1,
          position: "sticky",
          top: 56,
          zIndex: 100,
          backgroundColor: "primary.sectionContainer",
          "& .Mui-selected": {
            borderTop: "2.5px solid",
            borderColor: "primary.main",
          },
        }}
      >
        {taskTabItems.items.map((item) => (
          <Tab
            disableRipple
            key={item.id}
            value={item.id}
            label={
              <Typography component="span" sx={{ fontSize: "1rem", fontWeight: 600 }}>
                {item.label}
              </Typography>
            }
          />
        ))}
      </Tabs>
      <Box sx={{ mt: 2 }}>
        {!!activeTab && !!taskTabItems.components[activeTab] && createElement(taskTabItems.components[activeTab], { commentsData, refetchData })}
      </Box>
    </Fragment>
  );
};

export default function TaskAdditionalDetails({ commentsData, refetchData }) {
  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ py: 1, mt: 1, position: "sticky", top: 0, zIndex: 100, backgroundColor: "background.default" }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <ListAlt sx={{ m: 1 }} color="primary" />
          <Typography variant="h6">Task Audit & Feedback</Typography>
        </Stack>
        <UiIconButton title="Refetch Data" onClickIcon={refetchData}>
          <Autorenew />
        </UiIconButton>
      </Stack>
      <Box
        component="div"
        sx={{
          ...sectionUiLookBase,
          my: 2,
        }}
      >
        <AdditionalDetailsTabComponent commentsData={commentsData} refetchData={refetchData} />
      </Box>
    </Box>
  );
}
