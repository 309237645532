import { Box } from "@mui/material";
import AdvancedTable from "../../components/advancedTable/AdvancedTable";
import { useFetchFilesForKnowledgeBase } from "../../hooks/advancedTableHook";
import { kBColumnConfig } from "./config/KBColumnConfig";

const KBUploadedFilesTable = () => {
  const tableConfig = kBColumnConfig();
  const apiHookWithParams = [useFetchFilesForKnowledgeBase, [tableConfig.tableProps.columnProps]];
  return <AdvancedTable config={tableConfig} apiHookWithParams={apiHookWithParams} />;
};

export default function FileKBManager() {
  return (
    <Box sx={{ flex: 1, overflowY: "auto" }}>
      <KBUploadedFilesTable />
    </Box>
  );
}
