import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { useCreateAppRouter } from "./AppRoutes";
import { THEME_MODE } from "./app/constants/config/AppSettings";
import { selectIsThemeDark } from "./features/AppSettings/appSlice";
import { designTheme } from "./features/AppSettings/theme/theme";
import { ErrorBoundaryWrapper } from "./features/Error404/ErrorLog";

function App() {
  const currentThemeMode = useSelector(selectIsThemeDark);
  const router = useCreateAppRouter();
  const theme = useMemo(() => createTheme(designTheme(currentThemeMode ? THEME_MODE.DARK : THEME_MODE.LIGHT)), [currentThemeMode]);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <ErrorBoundaryWrapper nativeError>
        <RouterProvider router={router} />
      </ErrorBoundaryWrapper>
    </ThemeProvider>
  );
}

export default App;
