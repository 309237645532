import { ListItemText } from "@mui/material";
import { format } from "date-fns";
import { createTableColumn, serverDate, serverToUi } from "../../../app/utils/utilityFunctions";
import {
  conflictedAttendanceColumnView,
  overtimeAttendanceColumnView,
  routineAttendanceColumnView,
} from "../../EmployeeAttendance/config/attendanceColumns";
import { viewTasksTableColumns } from "../../SystemTasks/config/viewTasks";

export const userAnalysisTaskDetailsColumnView = viewTasksTableColumns;
/* INFO: "reference_dt" attribute is dynamically attached after api fetch in advancedTableHooks.js */
export const userAnalysisRoutineAttendanceColumnView = [
  createTableColumn({
    order: 1,
    id: "bus_dt",
    label: "Day",
    align: "left",
    primaryKey: true,
    disablePadding: true,
    renderContent: (date) => <ListItemText primary={format(serverDate(date), "EEEE")} secondary={serverToUi(date)} />,
  }),
  ...routineAttendanceColumnView.filter(({ id }) => id !== "ern"),
];
/* INFO: "reference_dt" attribute is dynamically attached after api fetch in advancedTableHooks.js */
export const userAnalysisConflictedAttendanceColumnView = [
  createTableColumn({
    order: 1,
    id: "bus_dt",
    label: "Day",
    align: "left",
    primaryKey: true,
    disablePadding: true,
    renderContent: (date) => <ListItemText primary={format(serverDate(date), "EEEE")} secondary={serverToUi(date)} />,
  }),
  ...conflictedAttendanceColumnView.filter(({ id }) => id !== "ern"),
];

export const userAnalysisOvertimeAttendanceColumnView = [
  createTableColumn({
    order: 1,
    id: "bus_dt",
    label: "Day",
    align: "left",
    disablePadding: true,
    renderContent: (date) => <ListItemText primary={format(serverDate(date), "EEEE")} secondary={serverToUi(date)} />,
  }),
  ...overtimeAttendanceColumnView.filter(({ id }) => id !== "ern"),
];
