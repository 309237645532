import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";

/*
tasksForm has Objects with attributes:

@id: Represents the ID of the the field
@isRequired: Represents if field is required
@isEditable: Represents if field is editable
@value: Represents value associated to the field

*/
const tasksForm = createEntityAdapter();

const taskSlice = createSlice({
  name: "taskSlice",
  initialState: tasksForm.getInitialState({
    isConfigLoaded: false,
    isTasksEditModeOn: false,
  }),
  reducers: {
    setTasksFormConfig: (state, { payload = [] }) => {
      tasksForm.setAll(state, payload);
      state.isConfigLoaded = true;
    },

    setTasksFieldValue: (state, { payload: [id = null, dependentFields, value = null] }) => {
      if (!id) return;
      const manyChanges = [{ id, changes: { value } }];
      if (dependentFields) dependentFields.forEach((dependentFieldId) => manyChanges.unshift({ id: dependentFieldId, changes: { value: "" } }));
      tasksForm.updateMany(state, manyChanges);
    },

    setTaskFieldIsEditable: (state, { payload: [id = null, isEditable = undefined, isRequired = false] }) => {
      if (!id || isEditable === undefined) return;
      tasksForm.updateOne(state, {
        id,
        changes: { isEditable, isRequired },
      });
    },

    setConfigLoaded: (state, { payload }) => {
      state.isConfigLoaded = payload || !state.isConfigLoaded;
    },

    setTasksEditMode: (state, { payload }) => {
      if ((payload !== undefined && !payload) || !state.isTasksEditModeOn) state.isConfigLoaded = false;
      state.isTasksEditModeOn = payload || !state.isTasksEditModeOn;
    },

    clearAllTaskFormFields: (state) => {
      state.isConfigLoaded = false;
      state.isTasksEditModeOn = false;
      tasksForm.removeAll(state);
    },
  },
});

export const { setTasksFormConfig, setTasksFieldValue, setConfigLoaded, setTasksEditMode, clearAllTaskFormFields, setTaskFieldIsEditable } =
  taskSlice.actions;

const selectAdapterState = (state) => state.taskSlice;
const { selectById, selectAll } = tasksForm.getSelectors(selectAdapterState);

export const selectTaskFieldValue = createSelector([(state, fieldId) => selectById(state, fieldId)], (field) => (field ? field.value : null));
export const selectIsFieldRequired = createSelector([(state, fieldId) => selectById(state, fieldId)], (field) => (field ? field.isRequired : null));
export const selectIsFieldEditable = createSelector([(state, fieldId) => selectById(state, fieldId)], (field) => (field ? field.isEditable : null));
export const selectIsTasksEditModeOn = createSelector([selectAdapterState], (state) => state.isTasksEditModeOn);
export const selectIsConfigDataLoaded = createSelector([selectAdapterState], (state) => state.isConfigLoaded);

export const selectAreRequiredTaskFieldsFilled = createSelector([selectAll], (entities) =>
  entities.every((entity) => !(entity.isEditable && entity.isRequired && !entity.value.length))
);

export const selectAllTasksFieldsWithId = createSelector([selectAll], (entities) =>
  entities.reduce((acc, { id, value, isEditable }) => ({ ...acc, ...(value && isEditable && { [id]: value }) }), {})
);

export default taskSlice.reducer;
