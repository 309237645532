import { Clear, DoneAll } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ERROR, SUCCESS, WARNING } from "../../app/constants/common";
import { GridLabelValueUI, Transition } from "../../components/ui/UiUtils";
import { useResolveAttendanceConflictMutation } from "../../hooks/ApiHooks";
import { sectionUiLookBase } from "../../styled/inlineCssStyles";
import { setAlert } from "../Alerts/slice/alertSlice";
import { conflictDialogConfig } from "./config/attendanceColumns";

const CONFLICT_RESOLVE_STATUS = {
  APPROVAL_STATUS: "APR",
  REJECT_STATUS: "DCL",
};

export default function ResolveAttendanceDialog({ dialogProps, setDialogProps }) {
  const { triggerMutation, isError, isLoading, data, error } = useResolveAttendanceConflictMutation();
  const dispatch = useDispatch();
  const [status, setStatus] = useState();
  const { row, open } = dialogProps;
  const onHandleClose = () => setDialogProps((prev) => ({ ...prev, row: null, open: false }));

  const handleSubmit = (reqStatus) => {
    setStatus(reqStatus);
    triggerMutation(row.req_id, row.proposed.time, reqStatus, row.upd_tms);
  };

  useEffect(() => {
    if (!isLoading && data) {
      dispatch(
        setAlert(
          "Attendance Conflict Resolved",
          `You have successfully resolved attendance status with Status: ${status === CONFLICT_RESOLVE_STATUS.APPROVAL_STATUS ? "Approved" : "Rejected"}`,
          SUCCESS
        )
      );
      setDialogProps({ row: null, open: false, refreshData: true });
    }
  }, [dispatch, isLoading, data, setDialogProps, status]);

  useEffect(() => {
    if (isError) {
      const serviceUnavailable = error.status === 503;
      dispatch(
        setAlert(
          serviceUnavailable ? error.data.title : "Attendance Conflict Resolution Failed",
          error.data.message,
          serviceUnavailable ? ERROR : WARNING
        )
      );
    }
  }, [dispatch, isError, error]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={!isLoading ? onHandleClose : null}
      TransitionComponent={Transition}
      PaperProps={{ sx: sectionUiLookBase }}
    >
      <DialogTitle sx={{ fontWeight: 500 }} color="primary">
        Employee : {row.ern}
      </DialogTitle>
      <DialogContent sx={{ py: 2, px: 3 }}>
        <Grid container spacing={2}>
          {conflictDialogConfig(row).map(({ id, label, valueComponent }) => (
            <Grid item xs={12} key={id}>
              <GridLabelValueUI label={label}>{valueComponent}</GridLabelValueUI>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", px: 3, pb: 2, "& .MuiButton-root": { fontSize: "0.9rem" } }}>
        <Button variant="contained" onClick={onHandleClose} disabled={isLoading}>
          Close
        </Button>
        <ButtonGroup variant="outlined">
          <LoadingButton
            color="success"
            startIcon={<DoneAll />}
            onClick={() => handleSubmit(CONFLICT_RESOLVE_STATUS.APPROVAL_STATUS)}
            loading={status === CONFLICT_RESOLVE_STATUS.APPROVAL_STATUS && isLoading}
            disabled={isLoading}
          >
            Accept Time
          </LoadingButton>
          <LoadingButton
            color="error"
            startIcon={<Clear />}
            onClick={() => handleSubmit(CONFLICT_RESOLVE_STATUS.REJECT_STATUS)}
            loading={status === CONFLICT_RESOLVE_STATUS.REJECT_STATUS && isLoading}
            disabled={isLoading}
          >
            Reject Time
          </LoadingButton>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
