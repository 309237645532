import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";

const formAdapter = createEntityAdapter();

const manageDataSlice = createSlice({
  name: "manageData",
  initialState: formAdapter.getInitialState({
    activeForm: { id: false },
    tabOptions: [],
  }),
  reducers: {
    setActiveForm: (state, { payload: formId }) => {
      state.activeForm = state.tabOptions.find(({ id }) => id === formId?.toString().toUpperCase()) ?? { id: false };
    },

    setManageFormTabOptions: (state, { payload: options = [] }) => {
      state.tabOptions = options
        .map(({ form_id: id, form_name: label, form_seq: order, form_icon: icon }) => ({ id, icon, label, order }))
        .sort((a, b) => a.order - b.order);
    },

    setFieldConfigForCurrentForm: (state, { payload = [] }) => {
      formAdapter.setAll(state, payload);
    },

    setFieldValue: (state, { payload: [id = null, dependentFieldId, value = null, isError = false] }) => {
      if (!id) return;
      const manyChanges = [{ id, changes: { value, isError } }];
      if (dependentFieldId) manyChanges.unshift({ id: dependentFieldId, changes: { value: "", isError: false } });
      formAdapter.updateMany(state, manyChanges);
    },

    clearAllFormFields: (state) => {
      formAdapter.removeAll(state);
    },
  },
});

export const { setActiveForm, setManageFormTabOptions, setFieldConfigForCurrentForm, setFieldValue, clearAllFormFields } = manageDataSlice.actions;

const selectAdapterState = (state) => state.manageData;
const { selectById, selectAll } = formAdapter.getSelectors(selectAdapterState);

export const selectManageFormTabOptions = createSelector([selectAdapterState], (state) => state.tabOptions);
export const selectFormTypeById = createSelector([selectManageFormTabOptions, (_, formId) => formId], (tabs, formId) =>
  tabs.find((tab) => tab.id === formId?.toString().toUpperCase())
);
export const selectActiveDataTypeForm = createSelector([selectAdapterState], (state) => state.activeForm);
export const selectDataTypeFormId = createSelector([selectActiveDataTypeForm], (form) => form.id);

export const selectFieldValue = createSelector([(state, fieldId) => selectById(state, fieldId)], (field) => (field ? field.value : null));
export const selectFieldIsError = createSelector([(state, fieldId) => selectById(state, fieldId)], (field) => (field ? field.isError : null));
export const selectAreRequiredFieldsValid = createSelector([selectAll], (entities) =>
  entities.every((entity) => !(entity.isRequired && !entity.value?.toString().trim()) && !entity.isError)
);
export const selectAllFieldsWithId = createSelector([selectAll], (entities) =>
  entities.reduce((acc, { id, value, isEditable }) => ({ ...acc, ...(value && isEditable && { [id]: value }) }), {})
);

export default manageDataSlice.reducer;
