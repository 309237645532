import { DataArray, DataThresholding, DoneAll, PictureAsPdf, TableChart } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { addDays, addMonths, endOfDay, endOfMonth, endOfWeek, endOfYear, startOfDay, startOfMonth, startOfWeek, startOfYear } from "date-fns";
import { uiToServer } from "../../app/utils/utilityFunctions";
import { useOptionsForFilters, useSelectDateRangeOrder, useSelectSortOrder } from "../../hooks/valueProviderHooks";

const exportTypeOptions = [
  {
    id: "export-pdf",
    label: "PDF",
    icon: <PictureAsPdf />,
  },
  {
    id: "export-csv",
    label: "CSV",
    icon: <TableChart />,
  },
];

export const exportRowDataOptions = (innerOptions) => [
  {
    id: "export-all-rows",
    icon: <DataThresholding />,
    label: "Export All Rows",
    exportTypeOptions: innerOptions,
  },
  {
    id: "export-displayed-rows",
    icon: <DataArray />,
    label: "Export Current Rows",
    exportTypeOptions: innerOptions,
  },
  {
    id: "export-selected-rows",
    icon: <DoneAll />,
    label: "Export Selected Rows",
    exportTypeOptions: innerOptions,
  },
];

const dateOptions = [
  {
    value: 0,
    label: "This Week",
    returnDataValue: {
      value: 0,
      from: uiToServer(startOfWeek(new Date())),
      to: uiToServer(endOfWeek(new Date())),
    },
  },
  {
    value: 1,
    label: "This Month",
    returnDataValue: {
      value: 1,
      from: uiToServer(startOfMonth(new Date())),
      to: uiToServer(endOfMonth(addDays(new Date(), 1))),
    },
  },
  {
    value: 2,
    label: "Last Month",
    returnDataValue: {
      value: 2,
      from: uiToServer(startOfMonth(addMonths(new Date(), -1))),
      to: uiToServer(endOfMonth(addMonths(new Date(), -1))),
    },
  },
  {
    value: 3,
    label: "This Year",
    returnDataValue: {
      value: 3,
      from: uiToServer(startOfYear(new Date())),
      to: uiToServer(endOfYear(new Date())),
    },
  },
  {
    value: 4,
    label: "All Time",
    returnDataValue: {
      value: 4,
      from: null,
      to: null,
    },
  },
];

const sortOptions = [
  {
    value: "Ascending_Order",
    label: "Ascending",
    returnDataValue: { value: "Ascending_Order" },
  },
  { value: "Descending_Order", label: "Descending", returnDataValue: { value: "Descending_Order" } },
];

export const generalTableConfig = ({
  tableId,
  tableTitle = "",
  tableViewColumns = [],
  resetTableOnEveryRender = false,
  preventDisplayOf: {
    tableHeaderSelectsDisplay = true,
    tableActionsDisplay = true,
    selectSortDataByDisplay = true,
    selectDateRangeDisplay = true,
    refetchDataByDisplay = true,
    textTypographyComponentDisplay = true,
    tableSubHeaderDisplay = true,
    searchInputDisplay = true,
    filterOptionsDisplay = true,
    tablePageWithSelectColumnsDisplay = true,
    selectShowColumnsDisplay = true,
    paginationDisplay = true,
    areRowsSelectableDisplay = true,
    actionOptionsDisplay = true,
    exportButtonDisplay = true,
    addRowButtonDisplay = true,
    clearInputSearchDisplay = true,
    deleteRowsButtonDisplay = false,
  } = {},
  customValueProviderHooks: {
    useGetFilterOptionsForColumn = useOptionsForFilters,
    useGetDateRangeOrder = useSelectDateRangeOrder,
    useGetSortOrderForColumn = useSelectSortOrder,
  } = {},
  customFunctions: { extraButtonComponent = () => null, deleteHookWithParams = [() => [() => null, false, undefined], []] } = {},
  otherDefaultsVariables: {
    searchInputPlaceholder = "Search...",
    searchClearSearchIconLabel = "Clear Search",
    paginationTitle = "Tasks / Page",
    newRowTooltipTitle = "Add New Task",
    selectedRowsLabel = "Selected",
    refetchIconTooltipLabel = "Re-fetch Data",
    defaultSortByAscending = false,
    enableFiltersOnColumns = [],
    fromDateRangeInEpoch = new Date().getTime(),
    toDateRangeInEpoch = new Date().getTime(),
    exportOptionsFn = exportRowDataOptions,
    exportInnerOptions = exportTypeOptions,
    hideDateSelectorOptions = -1,
    defaultDateSelectedOption = -1,
  } = {},
  paginationRowsOptions: { pageSelectedIndex = 1, pageOptions = [10, 30, 50, 100] } = {},
  addRowButtonNavigateOptions: { addRowNavigateToURL = false, addRowURL = null, hasAddRowDialog = false, newRowDialogComponent = () => null } = {},
  tableRowClickNavigate: {
    tableClickNavigateToURL = false,
    tableRowURL = () => null,
    navigateUsingRowAttribute = "id",
    tableOpenDialogForRow = false,
    tableDialogComponent = () => null,
  } = {},
  customExtraExportButtonOptions = [],
}) => {
  return {
    id: tableId,
    resetTableOnEveryRender,
    selectSortDataByDefault: {
      defaultDataValue: { value: defaultSortByAscending ? "Ascending_Order" : "Descending_Order" },
    },
    selectDateRangeDefault: {
      defaultDataValue: {
        value: fromDateRangeInEpoch === -1 ? "" : "custom_date",
        from: fromDateRangeInEpoch === -1 ? null : uiToServer(startOfDay(fromDateRangeInEpoch)),
        to: toDateRangeInEpoch === -1 ? null : uiToServer(endOfDay(toDateRangeInEpoch)),
      },
    },
    tableHeaderSelects: {
      display: tableHeaderSelectsDisplay,
      ...(tableHeaderSelectsDisplay && {
        tableActions: {
          display: tableActionsDisplay,
          ...(tableActionsDisplay && {
            selectSortDataBy: {
              display: selectSortDataByDisplay,
              ...(selectSortDataByDisplay && {
                id: "select-date-sort",
                inputLabel: "Sort By",
                formControlSx: {},
                size: "small",
                defaultValue: sortOptions[1].value,
                defaultDataValue: sortOptions[1].returnDataValue,
                useValueProvider: useGetSortOrderForColumn,
                options: sortOptions,
              }),
            },
            selectDateRange: {
              display: selectDateRangeDisplay,
              ...(selectDateRangeDisplay && {
                id: "select-date-range",
                inputLabel: "Tasks Date Range",
                size: "small",
                defaultValue: 4,
                defaultDataValue: dateOptions[defaultDateSelectedOption === -1 ? 4 : defaultDateSelectedOption].returnDataValue,
                options: Array.isArray(hideDateSelectorOptions)
                  ? dateOptions.filter((option) => !hideDateSelectorOptions.includes(option.value))
                  : dateOptions,
                useValueProvider: useGetDateRangeOrder,
                customOption: {
                  label: "Custom Date ",
                  value: "custom_date",
                },
              }),
            },
            refetchDataIcon: {
              display: refetchDataByDisplay,
              ...(refetchDataByDisplay && {
                iconLabel: refetchIconTooltipLabel,
              }),
            },
          }),
        },
        textTypographyComponent: {
          display: textTypographyComponentDisplay,
          ...(textTypographyComponentDisplay && {
            component: (
              <Typography noWrap sx={{ fontSize: "1.24rem", color: "primary.main", fontWeight: 600 }} component="div">
                {tableTitle}
              </Typography>
            ),
          }),
        },
      }),
    },

    tableSubHeader: {
      display: tableSubHeaderDisplay,
      ...(tableSubHeaderDisplay && {
        searchInput: {
          display: searchInputDisplay,
          ...(searchInputDisplay && {
            inputPlaceholder: searchInputPlaceholder,
            clearSearchIconLabel: searchClearSearchIconLabel,
            clearSearchDisplay: clearInputSearchDisplay,
            applyOrClearFilterButton: filterOptionsDisplay,
          }),
        },
        filterTableConfig: {
          display: filterOptionsDisplay,
          ...(filterOptionsDisplay && {
            filterOptionsHook: useGetFilterOptionsForColumn,
            enableFiltersOnColumns,
          }),
        },
      }),
    },

    tablePageWithSelectColumns: {
      display: tablePageWithSelectColumnsDisplay,
      ...(tablePageWithSelectColumnsDisplay && {
        selectShowColumns: {
          display: selectShowColumnsDisplay,
        },
        pagination: {
          display: paginationDisplay,
          ...(paginationDisplay && {
            paginationRowsOptions: {
              currentRowsPerPageSelected: pageOptions && (pageOptions[pageSelectedIndex ?? 0] ?? pageOptions[0]),
              options: pageOptions ?? [],
            },
            paginationTitle: paginationTitle,
          }),
        },
      }),
    },

    dataTableHeaderOptions: {
      selectableRows: {
        display: areRowsSelectableDisplay,
        ...(areRowsSelectableDisplay && {
          title: selectedRowsLabel,
        }),
      },
      actionOptions: {
        display: actionOptionsDisplay,
        ...(actionOptionsDisplay && {
          extraButtonComponent,
          exportButton: {
            display: exportButtonDisplay,
            ...(exportButtonDisplay && {
              columnHeaders: tableViewColumns.map(({ id, label, renderPDF, renderCSV }) => ({
                key: id,
                displayLabel: label ?? id,
                "render-export-pdf": renderPDF ?? ((data) => data),
                "render-export-csv": renderCSV ?? ((data) => data),
              })),
              exportOptions: exportOptionsFn(exportInnerOptions),
              extraExportOptions: customExtraExportButtonOptions,
            }),
          },
          deleteRowsButton: {
            display: deleteRowsButtonDisplay,
            ...(deleteRowsButtonDisplay && {
              deleteHookWithParams,
            }),
          },
          addRowButton: {
            display: addRowButtonDisplay,
            ...(addRowButtonDisplay && {
              navigateToURL: addRowNavigateToURL,
              url: addRowURL,
              title: newRowTooltipTitle,
              hasAddRowDialog,
              newRowDialogComponent,
            }),
          },
        }),
      },
    },
    tableProps: {
      onClickProps: {
        navigateToURL: tableClickNavigateToURL,
        url: tableRowURL,
        navigateUsingRowAttribute,
        openDialogForRow: tableOpenDialogForRow,
        dialogComponent: tableDialogComponent,
      },
      areRowsSelectable: areRowsSelectableDisplay,
      columns: tableViewColumns,
      groupRows: null,
      defaultSortColumnId: tableViewColumns.find((column) => column.primaryKey)?.id || null,
    },
  };
};
