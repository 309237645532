import { COMMON_TRANSFORM_RESPONSE_FUNCTION, EMPLOYEE_MONITOR_ACTION } from "../../../app/constants/api";
import { apiSlice } from "../../../app/redux/apiSlice";

export const employeeMonitorApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    employeeMonitorAction: builder.mutation({
      query: ({ action, payload = {} }) => ({
        url: EMPLOYEE_MONITOR_ACTION.BASE,
        method: "POST",
        body: { action, payload },
      }),
      ...COMMON_TRANSFORM_RESPONSE_FUNCTION,
    }),
  }),
});

export const { useEmployeeMonitorActionMutation } = employeeMonitorApiSlice;
