import { useTheme } from "@mui/system";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ERROR, INFO, WARNING } from "../app/constants/common";
import { NOTIFICATION_ACTION, NOTIFICATION_API_CALL_AFTER_SECONDS } from "../app/constants/config/notificationSettings";
import { TASK_FIELDS } from "../app/constants/config/systemTasks";
import { parseFieldValue } from "../app/utils/utilityFunctions";
import logo from "../asset/images/logo.png";
import {
  makeSelectRowsBasedOnOption,
  selectDataInOrder,
  selectDateRange,
  setDataDateRange,
  sortDataInOrder,
} from "../components/slices/advancedTable";
import { setAlert } from "../features/Alerts/slice/alertSlice";
import { findEmployeeOptionById, selectFieldOptionsByCurrentValue, selectPresetConfig, updateEmployeeObject } from "../features/AppSettings/appSlice";
import { selectUserProfile, updateProfilePicture } from "../features/Auth/slice/authSlice";
import { selectFieldIsError, selectFieldValue, setFieldValue } from "../features/ManageData/slices/ManageDataSlice";
import { addNotifications, selectNotificationsForAlerts, updateNotificationActionInBulk } from "../features/Notifications/slice/notificationSlice";
import { selectTaskFieldValue, setTaskFieldIsEditable, setTasksFieldValue } from "../features/Tasks/slice/taskSlice";
import { selectUserAnalysisSelectedDateRange, setSelectedUserAnalysisDateRange } from "../features/UserAnalysis/slice/userAnalysisSlice";
import {
  useGetComboOptionsForFormMutation,
  useGetProfileNotificationsMutation,
  useGetUserProfilePictureMutation,
  useSetUserProfilePictureMutation,
} from "./ApiHooks";

export const useExportDataForTableByTypeHook = (colHeaders, optionId) => {
  const rows = useSelector((state) => makeSelectRowsBasedOnOption(state, optionId));

  const transformRowData = (type) =>
    rows.map((row) =>
      colHeaders.reduce(
        (acc, col) => ({
          ...acc,
          [col.key]: col[`render-${type}`] ? col[`render-${type}`](row[col.key]) : row[col.key],
        }),
        {}
      )
    );

  const exportToCSV = () => {
    const csvConfig = mkConfig({
      filename: "Generated Data",
      fieldSeparator: ",",
      decimalSeparator: ".",
      quoteStrings: true,
      columnHeaders: colHeaders,
    });
    download(csvConfig)(generateCsv(csvConfig)(transformRowData("export-csv")));
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    autoTable(doc, { head: [colHeaders.map(({ displayLabel }) => displayLabel)], body: transformRowData("export-pdf").map(Object.values) });
    doc.save("Generated Data.pdf");
  };

  const downloadData = (type) => {
    if (type === "export-pdf") exportToPDF();
    else if (type === "export-csv") exportToCSV();
  };

  return [rows, downloadData];
};

export const useOptionsForFilters = (configId, transformDataFn) => {
  const selectValueOptions = useSelector((state) => selectPresetConfig(state, configId));
  const optionsData = useMemo(() => transformDataFn?.(selectValueOptions) || selectValueOptions, [transformDataFn, selectValueOptions]);
  return [optionsData];
};

export const useOptionsForTaskForm = (
  currentFieldId,
  fieldType,
  {
    parentFieldId = null,
    dependentFields = [],
    filterBooleanFunction = null,
    findBooleanFunction = null,
    filterExcludedBoolean = null,
    fetchConfigId = null,
  },
  { currentDataValue = null, fromConfigId = null } = {},
  excludedValues = []
) => {
  const currentFieldValue = useSelector((state) => selectTaskFieldValue(state, currentFieldId));
  const parentFieldValue = useSelector((state) => selectTaskFieldValue(state, parentFieldId));
  const data = useSelector((state) => selectPresetConfig(state, fetchConfigId));
  const optionsByCurrentValue = useSelector((state) => selectFieldOptionsByCurrentValue(state, fromConfigId, currentDataValue));
  const dispatch = useDispatch();

  /*
  INFO: Not all fields have filterBooleanFunction. Thus the expression `?? true` resolves to give every data
  INFO: all valid "currentDataValue" should have findBooleanFunction
  */
  const optionsData = useMemo(() => {
    const excludedOptions = data.filter((option) => !filterExcludedBoolean || !filterExcludedBoolean(option, excludedValues));
    if (currentDataValue) {
      const option = excludedOptions.find((option) => findBooleanFunction?.(option, currentDataValue));
      return [...optionsByCurrentValue, ...(option ? [option] : [])];
    }
    return excludedOptions.filter((option) => filterBooleanFunction?.(option, parentFieldValue) ?? true);
  }, [
    data,
    parentFieldValue,
    filterBooleanFunction,
    findBooleanFunction,
    currentDataValue,
    optionsByCurrentValue,
    filterExcludedBoolean,
    excludedValues,
  ]);

  const onChange = useCallback(
    (input) => {
      const value = parseFieldValue(fieldType, input)[0];
      dispatch(setTasksFieldValue([currentFieldId, dependentFields, value]));
      if (currentFieldId === TASK_FIELDS.STATUS.ID)
        dispatch(setTaskFieldIsEditable([TASK_FIELDS.ASSIGNED_EMPLOYEES.ID, !["ONGOING", "PDG_START"].includes(value)]));
    },
    [currentFieldId, dependentFields, fieldType, dispatch]
  );

  return {
    currentFieldValue,
    optionsData,
    onChange,
    isFieldDisabled: !!parentFieldId && (!parentFieldValue || parentFieldValue.length === 0),
  };
};

export const useGetOptionsForManageRecordForm = (
  currentFieldId,
  fieldType,
  regexString,
  { parentFieldId = null, dependentFieldId = null, options = [], optionsId = null }
) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { triggerMutation, isLoading, isError, data } = useGetComboOptionsForFormMutation();
  const currentFieldValue = useSelector((state) => selectFieldValue(state, currentFieldId));
  const parentFieldValue = useSelector((state) => selectFieldValue(state, parentFieldId));
  const fieldIsError = useSelector((state) => selectFieldIsError(state, currentFieldId));

  useEffect(() => {
    if (optionsId && parentFieldId && parentFieldValue?.length) triggerMutation(optionsId, [parentFieldId, parentFieldValue]);
  }, [triggerMutation, optionsId, parentFieldId, parentFieldValue]);

  const optionsData = useMemo(
    () => (parentFieldId === null ? options : !isLoading && data?.data?.[optionsId] ? data.data[optionsId] : []),
    [isLoading, options, data?.data, parentFieldId, optionsId]
  );

  const onChange = useCallback(
    (input) => {
      const [value, isInvalid] = parseFieldValue(fieldType, input, regexString, theme.palette.primary.sectionContainer);
      dispatch(setFieldValue([currentFieldId, dependentFieldId, value, isInvalid]));
    },
    [currentFieldId, dispatch, dependentFieldId, fieldType, regexString, theme.palette.primary.sectionContainer]
  );

  return {
    currentFieldValue,
    fieldIsError,
    isLoading,
    isError,
    optionsData,
    onChange,
    isFieldDisabled: !!parentFieldId && (!parentFieldValue || parentFieldValue.length === 0),
  };
};

export const useSelectSortOrder = () => {
  const sortByOption = useSelector(selectDataInOrder);
  const dispatch = useDispatch();
  const onChangeData = (returnedData) => {
    dispatch(sortDataInOrder(returnedData));
  };
  return { selectedOption: sortByOption.value, onChangeData };
};

export const useSelectDateRangeOrder = () => {
  const dateRange = useSelector(selectDateRange);
  const dispatch = useDispatch();
  const onChangeData = useCallback((returnedData) => dispatch(setDataDateRange(returnedData)), [dispatch]);
  return {
    selectedOption: dateRange.value,
    from: dateRange.from,
    to: dateRange.to,
    onChangeData,
  };
};

export const useSelectUserAnalysisDateRangeOrder = () => {
  const dateRange = useSelector(selectUserAnalysisSelectedDateRange);
  const dispatch = useDispatch();
  const onChangeData = useCallback((returnedData) => dispatch(setSelectedUserAnalysisDateRange(returnedData)), [dispatch]);
  return {
    selectedOption: dateRange.value,
    from: dateRange.from,
    to: dateRange.to,
    onChangeData,
  };
};

export const useUpdateProfilePicture = (dataType, formKey) => {
  const dispatch = useDispatch();
  const profilePictureIncluded = useSelector(selectUserProfile).ern === formKey && dataType === "employee";
  const [profilePictureLoaded, setProfilePictureLoaded] = useState(!profilePictureIncluded);
  const [profilePictureData, setProfilePictureData] = useState();

  const { triggerMutation, isError, error, data } = useSetUserProfilePictureMutation();

  const onSetProfilePicture = useCallback((data) => setProfilePictureData(data), []);
  const onTriggerProfilePicture = useCallback(() => {
    if (!profilePictureLoaded) {
      if (profilePictureData && profilePictureData !== 0)
        triggerMutation(profilePictureData.fileData === null, profilePictureData.fileName, profilePictureData.fileMime, profilePictureData.fileData);
      else setProfilePictureLoaded(true);
    }
  }, [profilePictureData, profilePictureLoaded, triggerMutation]);

  useEffect(() => {
    if (data?.data?.file_ref || data?.data?.deleted === 1) {
      dispatch(updateProfilePicture([formKey, undefined]));
      setProfilePictureLoaded(true);
    }
  }, [formKey, data, dispatch, profilePictureData]);

  useEffect(() => {
    if (isError) {
      const serviceUnavailable = error.status === 503;
      dispatch(
        setAlert(
          serviceUnavailable ? error.data.title : "We were unable to update the profile picture",
          error.data.message,
          serviceUnavailable ? ERROR : WARNING
        )
      );
    }
  }, [dispatch, isError, error]);
  return [
    profilePictureLoaded,
    onSetProfilePicture,
    {
      triggerUploadProfilePicture: onTriggerProfilePicture,
      isProfilePictureUpdateFailed: isError,
    },
  ];
};

export const useRetrieveEmployeePicture = (empId, srcExists = false, srcObject = {}) => {
  const { ern = "null", name = "N/A", picture = logo } = srcObject;
  const requestRef = useRef();
  const dispatch = useDispatch();
  const employee = useSelector((state) => findEmployeeOptionById(state, empId));
  const target = useMemo(() => (srcExists ? { ern, name, picture } : employee), [employee, ern, name, picture, srcExists]);
  const { triggerMutation, isLoading, isError, data } = useGetUserProfilePictureMutation();

  const refetchData = useCallback(() => {
    if (!srcExists && target.ern !== "null") {
      if (requestRef.current) requestRef.current.abort();
      requestRef.current = triggerMutation(target.ern);
    }
  }, [srcExists, triggerMutation, target.ern]);

  useEffect(() => {
    if (!srcExists && target.picture === undefined) {
      if (requestRef.current) requestRef.current.abort();
      requestRef.current = triggerMutation(target.ern);
    }
    return () => {
      requestRef.current?.abort();
      requestRef.current = undefined;
    };
  }, [triggerMutation, target, srcExists]);

  useEffect(() => {
    if (!isLoading && data) {
      dispatch(updateEmployeeObject([empId, data.data?.signed_url || null]));
      requestRef.current = undefined;
    }
  }, [empId, isLoading, data, dispatch]);

  return { isLoading, isError, employeeName: target?.name, profilePicture: target?.picture, refetchData };
};

export const useGetNotificationInBackground = () => {
  const dispatch = useDispatch();
  const { triggerMutation, isLoading, data } = useGetProfileNotificationsMutation();
  const notificationsForAlert = useSelector(selectNotificationsForAlerts);

  // INFO: First Time API Call
  useEffect(() => {
    triggerMutation();
  }, [triggerMutation]);

  // INFO: Rhythmic API Calls
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isLoading) triggerMutation();
    }, NOTIFICATION_API_CALL_AFTER_SECONDS * 1000);
    return () => clearInterval(interval);
  }, [triggerMutation, isLoading]);

  useEffect(() => {
    if (!isLoading && data) dispatch(addNotifications(data.data));
  }, [dispatch, isLoading, data]);

  useEffect(() => {
    if (notificationsForAlert.length > 4) {
      dispatch(
        setAlert(
          `${notificationsForAlert.length} New Notifications`,
          "You have new notifications pending. Please check out the notification panel",
          INFO
        )
      );
    } else {
      notificationsForAlert.forEach((notification) =>
        dispatch(setAlert(`New Notification From ${notification.ern_from}`, notification.notf_text, INFO))
      );
    }
    if (notificationsForAlert.length > 0)
      dispatch(
        updateNotificationActionInBulk({ updateAction: NOTIFICATION_ACTION.DISPLAY_ALERT, value: false, currentNotifications: notificationsForAlert })
      );
  }, [notificationsForAlert, dispatch]);

  return null;
};
