import { Clear, DoneAll } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  ListItemText,
  TextField,
} from "@mui/material";
import { differenceInMinutes, formatDuration, intervalToDuration } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DATE_TIME_FORMAT, ERROR, SUCCESS, WARNING } from "../../app/constants/common";
import { serverDate, serverToUi, uiToServer } from "../../app/utils/utilityFunctions";
import { SingleTimePicker } from "../../components/ui/DateTimePickerModal";
import { GridLabelValueUI, Transition } from "../../components/ui/UiUtils";
import { useResolveOvertimeMutation } from "../../hooks/ApiHooks";
import { sectionUiLookBase } from "../../styled/inlineCssStyles";
import { setAlert } from "../Alerts/slice/alertSlice";
import { findEmployeeOptionById } from "../AppSettings/appSlice";
import { overtimeAttendanceStatusMapping } from "./config/attendanceUtils";

const OVERTIME_RESOLVE_STATUS = {
  APPROVAL_STATUS: "approve",
  REJECT_STATUS: "reject",
};

export default function ResolveOvertimeDialog({ dialogProps, setDialogProps }) {
  const { triggerMutation, isError, isLoading, data, error } = useResolveOvertimeMutation();
  const dispatch = useDispatch();
  const [status, setStatus] = useState();
  const { row, open } = dialogProps;
  const isPending = row.status === "PDG";
  const onHandleClose = () => setDialogProps((prev) => ({ ...prev, row: null, open: false }));
  const { name: employeeName } = useSelector((state) => findEmployeeOptionById(state, row.ern));
  const [resolvedData, setResolvedData] = useState({
    ot_id: row.ot_id,
    remark: row.appr_remark,
    start_time: row.start_time,
    end_time: row.end_time,
  });
  const [totalDuration, setTotalDuration] = useState(row.durationMinutes);

  const onChangeData = (id) => (data) =>
    setResolvedData((prev) => ({ ...prev, [id]: id === "start_time" || id === "end_time" ? uiToServer(data, DATE_TIME_FORMAT.SERVER_TIME) : data }));

  const handleSubmit = (reqStatus) => {
    setStatus(reqStatus);
    if (row.start_time === resolvedData.start_time) delete resolvedData.start_time;
    if (row.end_time === resolvedData.end_time) delete resolvedData.end_time;
    triggerMutation({ ...resolvedData, action: reqStatus });
  };

  useEffect(() => {
    setTotalDuration(differenceInMinutes(serverDate(resolvedData.start_time), serverDate(resolvedData.end_time)));
  }, [resolvedData.start_time, resolvedData.end_time]);

  useEffect(() => {
    if (!isLoading && data) {
      dispatch(setAlert("Overtime Resolved Successfully", `You have successfully resolved overtime for employee`, SUCCESS));
      setDialogProps({ row: null, open: false, refreshData: true });
    }
  }, [dispatch, isLoading, data, setDialogProps, status]);

  useEffect(() => {
    if (isError) {
      const serviceUnavailable = error.status === 503;
      dispatch(
        setAlert(serviceUnavailable ? error.data.title : "Overtime Resolution Failed", error.data.message, serviceUnavailable ? ERROR : WARNING)
      );
    }
  }, [dispatch, isError, error]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={!isLoading ? onHandleClose : null}
      TransitionComponent={Transition}
      PaperProps={{ sx: sectionUiLookBase }}
    >
      <DialogTitle sx={{ fontWeight: 500 }} color="primary" component="div">
        <ListItemText
          primary={`Overtime For Employee : ${row.ern}`}
          secondary={`Status: ${overtimeAttendanceStatusMapping[row.status].label}`}
          primaryTypographyProps={{ variant: "h6", fontWeight: 500 }}
          secondaryTypographyProps={{ variant: "body1", fontWeight: 500, sx: { color: `${overtimeAttendanceStatusMapping[row.status].color}.main` } }}
        />
      </DialogTitle>
      <DialogContent sx={{ py: 2, px: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GridLabelValueUI label="Employee Name">{employeeName}</GridLabelValueUI>
          </Grid>
          <Grid item xs={12}>
            <GridLabelValueUI label="Employee Remark">{row.remark || "-"}</GridLabelValueUI>
          </Grid>
          <Grid item xs={12}>
            <GridLabelValueUI label="Overtime Date">{serverToUi(row.bus_dt)}</GridLabelValueUI>
          </Grid>
          <Grid item xs={12}>
            <GridLabelValueUI label="Overtime Start">
              {!isPending ? (
                row.start_time
              ) : (
                <FormControl fullWidth sx={{ "& .MuiFormControl-root": { m: 0 } }}>
                  <SingleTimePicker defaultTime={resolvedData.start_time} size="small" onChange={onChangeData("start_time")} />
                </FormControl>
              )}
            </GridLabelValueUI>
          </Grid>
          <Grid item xs={12}>
            <GridLabelValueUI label="Overtime End">
              {!isPending ? (
                row.end_time
              ) : (
                <FormControl fullWidth sx={{ "& .MuiFormControl-root": { m: 0 } }}>
                  <SingleTimePicker defaultTime={resolvedData.end_time} size="small" onChange={onChangeData("end_time")} />
                </FormControl>
              )}
            </GridLabelValueUI>
          </Grid>
          <Grid item xs={12}>
            <GridLabelValueUI label="Overtime Duration">
              {totalDuration
                ? formatDuration(intervalToDuration({ start: 0, end: totalDuration * 60 * 1000 }), { format: ["hours", "minutes"] })
                : "-"}
              {row.start_time !== resolvedData.start_time || row.end_time !== resolvedData.end_time ? (
                <Box display="inline-block" sx={{ color: "warning.main", ml: 1 }}>
                  - Altered
                </Box>
              ) : null}
            </GridLabelValueUI>
          </Grid>
          <Grid item xs={12}>
            <GridLabelValueUI label="Admin Remark">
              {!isPending ? (
                row.appr_remark || "-"
              ) : (
                <TextField
                  onChange={(e) => onChangeData("remark")(e.target.value)}
                  defaultValue={resolvedData.appr_remark || ""}
                  size="small"
                  fullWidth
                />
              )}
            </GridLabelValueUI>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", px: 3, pb: 2, "& .MuiButton-root": { fontSize: "0.9rem" } }}>
        <Button variant="contained" onClick={onHandleClose} disabled={isLoading}>
          Close
        </Button>
        {!!isPending && (
          <ButtonGroup variant="outlined">
            <LoadingButton
              color="success"
              startIcon={<DoneAll />}
              onClick={() => handleSubmit(OVERTIME_RESOLVE_STATUS.APPROVAL_STATUS)}
              loading={status === OVERTIME_RESOLVE_STATUS.APPROVAL_STATUS && isLoading}
              disabled={isLoading}
            >
              Approve
            </LoadingButton>
            <LoadingButton
              color="error"
              startIcon={<Clear />}
              onClick={() => handleSubmit(OVERTIME_RESOLVE_STATUS.REJECT_STATUS)}
              loading={status === OVERTIME_RESOLVE_STATUS.REJECT_STATUS && isLoading}
              disabled={isLoading}
            >
              Decline
            </LoadingButton>
          </ButtonGroup>
        )}
      </DialogActions>
    </Dialog>
  );
}
