import { List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { EmployeeAvatar } from "../ui/UiUtils";

const UsersSuggestionListUI = forwardRef((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const itemsRef = useRef([]);

  const selectItem = (index) => {
    if (index >= props.items.length) return;
    const suggestion = props.items[index];
    const mentionItem = {
      id: suggestion.id,
      label: suggestion.label,
    };
    props.command(mentionItem);
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => {
    if (itemsRef.current[selectedIndex]) {
      itemsRef.current[selectedIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [selectedIndex]);

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return props.items.length > 0 ? (
    <Paper elevation={5} sx={{ maxHeight: "280px", overflowY: "auto", minWidth: "250px" }}>
      <List dense sx={{ p: 0 }}>
        {props.items.map((item, index) => (
          <ListItem key={item.id} disablePadding ref={(el) => (itemsRef.current[index] = el)}>
            <ListItemButton selected={index === selectedIndex} onClick={() => selectItem(index)}>
              <ListItemAvatar>
                <EmployeeAvatar empId={item.id} />
              </ListItemAvatar>
              <ListItemText primary={item.label} primaryTypographyProps={{ fontWeight: 500 }} secondary={item.id} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Paper>
  ) : null;
});

UsersSuggestionListUI.displayName = "UsersSuggestionListUI";
export default UsersSuggestionListUI;
