import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { matchRoute } from "../app/utils/utilityFunctions";
import { selectUserProfile } from "../features/Auth/slice/authSlice";

function getVisibleItems(items, role) {
  return items.reduce((acc, item) => {
    if (!hasPermissionToAccess(item, role)) return acc;
    if (item.type === "item") {
      return item.showInSidebar === false ? acc : [...acc, item];
    }
    const visibleItems = getVisibleItems(item.items, role);
    return visibleItems.length ? [...acc, { ...item, items: visibleItems }] : acc;
  }, []);
}

function findFirstAccessibleRoute(items, role) {
  for (const item of items) {
    if (item.type === "item") {
      if (hasPermissionToAccess(item, role) && item.showInSidebar !== false) {
        return item.path;
      }
    } else if (item.type === "group" && item.items) {
      const accessibleRoute = findFirstAccessibleRoute(item.items, role);
      if (accessibleRoute) return accessibleRoute;
    }
  }
  return null;
}

export function hasPermissionToAccess(item, role) {
  if (!item.permissions) return true;

  const { requiredRoles = [], excludedRoles = [] } = item.permissions;
  const hasRequiredRole = requiredRoles.length === 0 || requiredRoles.includes(role);
  const isNotExcluded = excludedRoles.length === 0 || !excludedRoles.includes(role);

  return hasRequiredRole && isNotExcluded;
}

// Flattens the navigation tree into a single array of items (Used for App Router)
export function flattenNavigation(items, role, parentGroup = null) {
  return items.reduce((acc, item) => {
    if (!hasPermissionToAccess(item, role)) return acc;
    if (item.type === "item") {
      return [...acc, { ...item, groupId: parentGroup }];
    }
    return [...acc, ...flattenNavigation(item.items, role, item.id)];
  }, []);
}

export const useAppNavigation = (navigationConfig) => {
  const { pathname } = useLocation();
  const { role } = useSelector(selectUserProfile);

  // Find the current active item based on current URL
  const findActiveItem = useCallback(
    (items) =>
      items.find((item) => matchRoute(pathname, item.path).isMatch) ||
      items
        .find((item) => item.items?.some((child) => matchRoute(pathname, child.path).isMatch))
        ?.items?.find((child) => matchRoute(pathname, child.path).isMatch) ||
      null,
    [pathname]
  );

  const allItems = useMemo(() => flattenNavigation(navigationConfig, role), [navigationConfig, role]);
  const activeItem = useMemo(() => findActiveItem(allItems), [allItems, findActiveItem]);
  const sidebarItems = useMemo(() => getVisibleItems(navigationConfig, role), [navigationConfig, role]);
  const currentRouteProperties = useMemo(() => {
    const defaultRoute = findFirstAccessibleRoute(navigationConfig, role);
    const currentItem = activeItem;

    if (!currentItem) {
      return {
        isAccessible: false,
        defaultRoute,
        reason: "You are not allowed to access this resource. Redirecting...",
      };
    }

    const hasRoutePermission = hasPermissionToAccess(currentItem, role);
    return {
      isAccessible: hasRoutePermission,
      defaultRoute,
      reason: hasRoutePermission ? null : "You are not allowed to access this resource. Contact Administrator",
      currentItem,
    };
  }, [activeItem, navigationConfig, role]);

  return {
    activeItem,
    sidebarItems,
    currentRouteProperties,
  };
};
