import { Box, Divider, Stack } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import AttendanceTableLayout from "./AttendanceTableLayout";
import { AttendanceStatistics, ChooseCalendarDate, FloatingCalendarButton } from "./AttendanceUIComponents";

const MemoizedAttendanceStatistics = memo(AttendanceStatistics);
const MemoizedAttendanceTableLayout = memo(AttendanceTableLayout);
const MemoizedFloatingCalendarButton = memo(FloatingCalendarButton);

export default function EmployeeAttendance() {
  const [showFloatingButton, setShowFloatingButton] = useState(false);
  const calendarRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (calendarRef.current) {
        const calendarPosition = calendarRef.current.getBoundingClientRect().top;
        setShowFloatingButton(calendarPosition < 64);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      handleScroll();
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <Box ref={containerRef} sx={{ flex: 1, overflowY: "auto", position: "relative" }}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="stretch"
        justifyContent="center"
        sx={{ px: 3, py: 2 }}
        flexWrap="wrap-reverse"
        useFlexGap
        ref={calendarRef}
      >
        <MemoizedAttendanceStatistics />
        <ChooseCalendarDate />
      </Stack>
      <Divider sx={{ my: 1 }} variant="middle" />
      <MemoizedAttendanceTableLayout />
      <MemoizedFloatingCalendarButton show={showFloatingButton} />
    </Box>
  );
}
