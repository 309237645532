import { EditNote, HowToReg, Timelapse } from "@mui/icons-material";
import { Fragment } from "react";
import { normalizeConfigForTable, serverToUi } from "../../../app/utils/utilityFunctions";
import { generalTableConfig } from "../../../components/config/generalTableConfig";
import { StyledChip } from "../../../styled/tableStyles";
import ResolveAttendanceDialog from "../ResolveAttendanceDialog";
import ResolveOvertimeDialog from "../ResolveOvertimeDialog";
import { conflictedAttendanceColumnView, overtimeAttendanceColumnView, routineAttendanceColumnView } from "./attendanceColumns";
import { ATTENDANCE_ADJUSTMENT, ATTENDANCE_OVERTIME, ROUTINE_ATTENDANCE } from "./attendanceUtils";

const attendanceTableConfig = (tableId, tableTitle, columnView, tableDialogComponent = null) =>
  normalizeConfigForTable(
    generalTableConfig({
      tableId,
      tableTitle,
      tableViewColumns: columnView,
      preventDisplayOf: {
        selectSortDataByDisplay: false,
        selectDateRangeDisplay: false,
        filterOptionsDisplay: false,
        addRowButtonDisplay: false,
      },
      otherDefaultsVariables: {
        paginationTitle: "Employees / Page",
      },
      paginationRowsOptions: {
        pageOptions: [10, 20, 30],
      },
      ...(tableDialogComponent && {
        tableRowClickNavigate: {
          tableOpenDialogForRow: true,
          tableDialogComponent,
        },
      }),
    })
  );

const TableTypography = ({ title, activeDate, defaultDate }) => (
  <Fragment>
    {title}
    <StyledChip
      label={activeDate ? serverToUi(activeDate) : defaultDate ? serverToUi(defaultDate) : "No Date Selected"}
      color="primary"
      sx={{ borderRadius: 1, ml: 1 }}
    />
  </Fragment>
);

export const attendanceTabData = {
  [ATTENDANCE_ADJUSTMENT]: {
    id: ATTENDANCE_ADJUSTMENT,
    label: "Attendance Adjustment",
    color: "error",
    getConfig: (activeDate) =>
      attendanceTableConfig(
        ATTENDANCE_ADJUSTMENT,
        <TableTypography title="Attendance Adjustment Data" activeDate={activeDate} />,
        conflictedAttendanceColumnView,
        (props) => <ResolveAttendanceDialog {...props} />
      ),
    get icon() {
      return <EditNote color={this.color} />;
    },
  },
  [ROUTINE_ATTENDANCE]: {
    id: ROUTINE_ATTENDANCE,
    label: "Attendance",
    color: "success",
    getConfig: (activeDate) =>
      attendanceTableConfig(
        ROUTINE_ATTENDANCE,
        <TableTypography title="Attendance Data" activeDate={activeDate} defaultDate={new Date()} />,
        routineAttendanceColumnView
      ),
    get icon() {
      return <HowToReg color={this.color} />;
    },
  },
  [ATTENDANCE_OVERTIME]: {
    id: ATTENDANCE_OVERTIME,
    label: "Overtime",
    color: "warning",
    getConfig: (activeDate) =>
      attendanceTableConfig(
        ATTENDANCE_OVERTIME,
        <TableTypography title="Overtime Data" activeDate={activeDate} />,
        overtimeAttendanceColumnView,
        (props) => <ResolveOvertimeDialog {...props} />
      ),
    get icon() {
      return <Timelapse color={this.color} />;
    },
  },
};
