import { Build, FactCheck, Route, ShutterSpeedOutlined, TimelapseOutlined } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import { DATE_TIME_FORMAT } from "../../../app/constants/common";
import { SERVER_FIELD_TYPE } from "../../../app/constants/config/DynamicFields";
import { serverToUi, uiToServer } from "../../../app/utils/utilityFunctions";

export const vehicleUIDisplayConfig = [
  {
    id: "vhc_id",
    label: "Vehicle ID",
    fieldType: "none",
    isEditable: false,
    order: 1,
    colSpan: 1,
    alternateValue: "N/A",
  },
  {
    id: "vhc_next_svc",
    fieldType: SERVER_FIELD_TYPE.DATE_TMS8,
    label: "Next Service Date",
    renderData: (data) => serverToUi(data),
    isEditable: true,
    formatFormDataForServer: (formData) => uiToServer(formData, DATE_TIME_FORMAT.SERVER_DATE),
    order: 2,
    colSpan: 1,
    alternateValue: "N/A",
    componentProps: {
      id: "vhc_next_svc",
      slotProps: {
        inputAdornment: {
          position: "start",
        },
      },
      slots: {
        openPickerIcon: Build,
      },
    },
  },
  {
    id: "vhc_rdtx_exp",
    label: "Road Tax Expiry Date",
    renderData: (data) => serverToUi(data),
    isEditable: true,
    formatFormDataForServer: (formData) => uiToServer(formData, DATE_TIME_FORMAT.SERVER_DATE),
    order: 1,
    colSpan: 1,
    alternateValue: "N/A",
    fieldType: SERVER_FIELD_TYPE.DATE_TMS8,
    componentProps: {
      id: "vhc_rdtx_exp",
      slotProps: {
        inputAdornment: {
          position: "start",
        },
      },
      slots: {
        openPickerIcon: TimelapseOutlined,
      },
    },
  },
  {
    id: "vhc_mlg",
    fieldType: SERVER_FIELD_TYPE.NUMBER,
    label: "Vehicle Mileage",
    order: 4,
    colSpan: 1,
    isEditable: true,
    formatFormDataForServer: (formData) => parseInt(formData),
    alternateValue: "N/A",
    textFieldProps: {
      id: "vhc_mlg",
      name: "vhc_mlg",
      placeholder: "Edit vehicle mileage...",
      inputProps: { type: "number", min: 1 },
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <Route />
          </InputAdornment>
        ),
      },
    },
  },
  {
    id: "vhc_mlg_upd_tms",
    label: "Latest Mileage Update",
    renderData: (data) => serverToUi(data),
    isEditable: true,
    formatFormDataForServer: (formData) => uiToServer(formData, DATE_TIME_FORMAT.SERVER_DATE),
    order: 5,
    colSpan: 1,
    alternateValue: "N/A",
    fieldType: SERVER_FIELD_TYPE.DATE_TMS8,
    componentProps: {
      id: "vhc_mlg_upd_tms",
      slotProps: {
        inputAdornment: {
          position: "start",
        },
      },
      slots: {
        openPickerIcon: ShutterSpeedOutlined,
      },
    },
  },
  {
    id: "vhc_last_inspect",
    label: "Latest Inspection Date",
    renderData: (data) => serverToUi(data),
    isEditable: true,
    formatFormDataForServer: (formData) => uiToServer(formData, DATE_TIME_FORMAT.SERVER_DATE),
    order: 6,
    colSpan: 1,
    alternateValue: "N/A",
    fieldType: SERVER_FIELD_TYPE.DATE_TMS8,
    componentProps: {
      id: "vhc_last_inspect",
      slotProps: {
        inputAdornment: {
          position: "start",
        },
      },
      slots: {
        openPickerIcon: FactCheck,
      },
    },
  },
  {
    id: "vhc_available",
    fieldType: "none",
    label: "Vehicle Status",
    isEditable: false,
    alternateValue: "N/A",
    order: 7,
    colSpan: 1,
    renderData: (data) => (data === "Y" ? "Available" : "Not Available"),
  },
].sort((a, b) => a.order - b.order);
