import { configureStore } from "@reduxjs/toolkit";
import advancedTableReducer from "../../components/slices/advancedTable";
import alertReducer from "../../features/Alerts/slice/alertSlice";
import appReducer from "../../features/AppSettings/appSlice";
import authReducer from "../../features/Auth/slice/authSlice";
import employeeAttendanceReducer from "../../features/EmployeeAttendance/slice/attendanceSlice";
import employeeMonitorReducer from "../../features/EmployeeLocation/slice/empMonitorSlice";
import inventoryReducer from "../../features/InventoryManagement/slice/inventorySlice";
import manageDataReducer from "../../features/ManageData/slices/ManageDataSlice";
import notificationsReducer from "../../features/Notifications/slice/notificationSlice";
import tabSliceReducer from "../../features/SystemTasks/slice/taskSlice";
import taskSliceReducer from "../../features/Tasks/slice/taskSlice";
import userAnalysisReducer from "../../features/UserAnalysis/slice/userAnalysisSlice";
import { apiSlice } from "./apiSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    app: appReducer,
    alerts: alertReducer,
    advancedTable: advancedTableReducer,
    tabSlice: tabSliceReducer,
    employeeAttendance: employeeAttendanceReducer,
    employeeMonitor: employeeMonitorReducer,
    auth: authReducer,
    inventory: inventoryReducer,
    manageData: manageDataReducer,
    taskSlice: taskSliceReducer,
    userAnalysis: userAnalysisReducer,
    notifications: notificationsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});
