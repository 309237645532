import { AddCircleOutlineOutlined, AddTaskOutlined, ClearOutlined, FiberManualRecord } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Link, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { DATE_TIME_FORMAT, ERROR, INFO, SUCCESS, WARNING } from "../../../../app/constants/common";
import { routeTaskParam, routeToSingleUserAnalysisPage } from "../../../../app/constants/routes";
import { serverToUi } from "../../../../app/utils/utilityFunctions";
import no_content from "../../../../asset/images/no_content.png";
import LoadAttachedFiles from "../../../../components/AttachFileHandler/LoadFileChip";
import ReadOnlyTextEditor from "../../../../components/RichTextEditor/ReadOnlyEditor";
import MultiLineRTE from "../../../../components/RichTextEditor/RichTextEditor";
import { EmployeeAvatar, EmptyDataVisualComp, ProgressIndicator } from "../../../../components/ui/UiUtils";
import { useAddTaskCommentMutation, useUploadFileMutation } from "../../../../hooks/ApiHooks";
import { commentListItemSx } from "../../../../styled/inlineCssStyles";
import { setAlert } from "../../../Alerts/slice/alertSlice";
import { findEmployeeOptionById } from "../../../AppSettings/appSlice";
import { selectUserProfile } from "../../../Auth/slice/authSlice";

const Comments = ({ comment }) => {
  const { [routeTaskParam]: taskId } = useParams();
  const employee = useSelector((state) => findEmployeeOptionById(state, comment.ern));
  return (
    <ListItem alignItems="flex-start" sx={commentListItemSx()}>
      <ListItemAvatar>
        <EmployeeAvatar empId={employee.ern} />
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={
          <Stack sx={{ fontSize: "14px" }}>
            <Link
              component={RouterLink}
              to={routeToSingleUserAnalysisPage(employee.ern)}
              color="primary"
              fontWeight={600}
              underline="hover"
              fontSize="inherit"
            >
              {employee.name}{" "}
              <Typography display="inline-block" fontSize="inherit" fontWeight={400} color="text.primary">
                [{employee.ern}]
              </Typography>
            </Link>
            <Stack direction="row" alignItems="center">
              <Typography component="span" color="text.secondary" fontSize={12} fontWeight={500}>
                {comment.file_ref?.length ?? 0} File(s) Uploaded
                <FiberManualRecord sx={{ color: "text.secondary", fontSize: 8, mx: 1 }} />
                {serverToUi(comment.upd_tms, DATE_TIME_FORMAT.UI_FULL_FORMAT)}
              </Typography>
            </Stack>
          </Stack>
        }
        secondary={
          <Box sx={{ mt: 0.5, mb: 1, fontSize: "13px" }}>
            {comment.file_ref.length > 0 && (
              <LoadAttachedFiles
                color="secondary"
                fileReferences={comment.file_ref}
                downloadAllRawName={`${taskId}_${employee.name}_${employee.ern}`}
              />
            )}
            <ReadOnlyTextEditor content={comment.msg_txt} sx={{ mt: 1 }} />
          </Box>
        }
      />
    </ListItem>
  );
};

const NewCommentListBox = ({ refetchData }) => {
  const { ern: currentUserId, name: currentUsername } = useSelector(selectUserProfile);
  const { [routeTaskParam]: taskId } = useParams();
  const commentBoxRef = useRef();
  const dispatch = useDispatch();
  const { triggerMutation: triggerFileUpload } = useUploadFileMutation();
  const { triggerMutation: triggerSaveComment, isLoading, isError, error, data } = useAddTaskCommentMutation();
  const [isNewCommentEnabled, setIsNewCommentEnabled] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [isSaveTriggered, setIsSaveTriggered] = useState({ loading: false, count: 0 });

  const onSaveComment = async () => {
    if (!newComment) {
      dispatch(setAlert("Comment Message Missing", "You must type at least one character before adding new comment.", INFO));
      return;
    }

    setIsSaveTriggered((prev) => ({ ...prev, loading: true }));
    const totalCountIncrease = Math.round(100 / (attachments.length + 2));
    const promises = attachments.map(({ fileName, fileMime, fileData }) =>
      triggerFileUpload(fileName, fileMime, fileData)
        .unwrap()
        .then((response) => {
          setIsSaveTriggered((prev) => ({ ...prev, count: prev.count + totalCountIncrease }));
          return response.data.file_ref;
        })
    );

    try {
      const results = await Promise.all(promises);
      setIsSaveTriggered((prev) => ({ ...prev, count: prev.count + totalCountIncrease }));
      triggerSaveComment(taskId, newComment, results);
    } catch (error) {
      dispatch(setAlert("Attachment Uploads Failed", "There was an error uploading attachments to server. Please try again.", WARNING));
      setIsSaveTriggered({ count: 0, loading: false });
    }
  };

  useEffect(() => {
    if (!isLoading && data) {
      setIsSaveTriggered((prev) => ({ ...prev, count: 100 }));
      dispatch(setAlert("New Comment Was Added", "New Comment has been successfully added", SUCCESS));
      setTimeout(refetchData, 600);
    }
  }, [dispatch, refetchData, isLoading, data]);

  useEffect(() => {
    if (isError) {
      setIsSaveTriggered({ count: 0, loading: false });
      const serviceUnavailable = error.status === 503;
      dispatch(
        setAlert(serviceUnavailable ? error.data.title : "Failed To Insert New Comment", error.data.message, serviceUnavailable ? ERROR : WARNING)
      );
    }
  }, [dispatch, isError, error]);

  const scrollElementIntoView = () =>
    commentBoxRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });

  return (
    <Fragment>
      <ListItem sx={{ flexDirection: "column", alignItems: "flex-end", width: "100%" }}>
        <Stack justifyContent="flex-end" direction="row" alignItems="center" gap={1}>
          <Button
            variant="outlined"
            color={isNewCommentEnabled ? "error" : "primary"}
            startIcon={isNewCommentEnabled ? <ClearOutlined /> : <AddCircleOutlineOutlined />}
            onClick={() => setIsNewCommentEnabled(!isNewCommentEnabled)}
            disabled={isSaveTriggered.loading}
          >
            {isNewCommentEnabled ? "Cancel" : "New Comment"}
          </Button>
          <LoadingButton
            variant="contained"
            disabled={!isNewCommentEnabled}
            loading={isSaveTriggered.loading}
            startIcon={<AddTaskOutlined />}
            onClick={onSaveComment}
          >
            <div>Save Comment</div>
          </LoadingButton>
        </Stack>
        {!!isSaveTriggered.loading && (
          <ProgressIndicator displayPercentage outerProgressSx={{ marginLeft: "60px" }} boxSx={{ mt: 1, mb: -1 }} value={isSaveTriggered.count} />
        )}
      </ListItem>
      <CSSTransition
        nodeRef={commentBoxRef}
        onEntered={scrollElementIntoView}
        in={isNewCommentEnabled}
        timeout={300}
        unmountOnExit
        classNames="new-comment"
      >
        <ListItem ref={commentBoxRef} alignItems="flex-start" sx={commentListItemSx()}>
          <ListItemAvatar>
            <EmployeeAvatar empId={currentUserId} />
          </ListItemAvatar>
          <ListItemText
            disableTypography
            primary={
              <Box sx={{ mb: 1 }}>
                <Link
                  component={RouterLink}
                  to={routeToSingleUserAnalysisPage(currentUserId)}
                  color="primary"
                  fontWeight={600}
                  underline="hover"
                  fontSize="inherit"
                >
                  {currentUsername}{" "}
                  <Typography display="inline-block" fontSize="inherit" fontWeight={400} color="text.primary">
                    [{currentUserId}]
                  </Typography>
                </Link>
              </Box>
            }
            secondary={
              <MultiLineRTE
                value={newComment}
                currentAttachedFiles={attachments}
                onChange={setNewComment}
                fileUploadEnabled
                onChangeFileData={setAttachments}
                placeholder="new comment"
                id="new-comment"
                name="new-comment"
                disabled={isSaveTriggered.loading}
                maxCharactersAllowed={2000}
                fileRenderColor="info"
              />
            }
          />
        </ListItem>
      </CSSTransition>
    </Fragment>
  );
};

export default function TaskComments({ commentsData, refetchData }) {
  const comments = commentsData.filter((comment) => comment.msg_type === "COMMENT");
  return (
    <List sx={{ width: "100%" }}>
      <NewCommentListBox refetchData={refetchData} />
      {!comments.length ? (
        <ListItem>
          <EmptyDataVisualComp src={no_content} title="No Comments" subTitle="There are no comments for this task yet" />
        </ListItem>
      ) : (
        comments.map((comment) => !!comment.msg_txt && <Comments comment={comment} key={comment.msg_id} />)
      )}
    </List>
  );
}
