import { EditCalendar, HowToReg, Splitscreen, Timelapse } from "@mui/icons-material";
import { TASK_FIELDS } from "../../../app/constants/config/systemTasks";
import { routeToSingleTask } from "../../../app/constants/routes";
import { normalizeConfigForTable } from "../../../app/utils/utilityFunctions";
import { generalTableConfig } from "../../../components/config/generalTableConfig";
import {
  userAnalysisOvertimeAttendanceColumnView,
  userAnalysisRoutineAttendanceColumnView,
  userAnalysisTaskDetailsColumnView,
} from "./tableDetailsColumns";

export const USER_ACTIVITY_TASK_DETAILS = "user-analysis-task-details";
export const USER_ACTIVITY_ATTENDANCE_DETAILS = "user-analysis-attendance-details";
export const USER_ACTIVITY_CONFLICTED_DETAILS = "user-analysis-conflict-details";
export const USER_ACTIVITY_OVERTIME_DETAILS = "user-analysis-overtime-details";

const userDetailsTableConfig = (tableId, tableTitle, columnView, tableClickNavigate = false) =>
  normalizeConfigForTable(
    generalTableConfig({
      tableId,
      tableTitle,
      tableViewColumns: columnView,
      preventDisplayOf: {
        selectSortDataByDisplay: false,
        selectDateRangeDisplay: false,
        filterOptionsDisplay: false,
        addRowButtonDisplay: false,
      },
      otherDefaultsVariables: {
        paginationTitle: "Items / Page",
        defaultSortByAscending: true,
      },
      paginationRowsOptions: {
        pageOptions: [10, 20, 30],
      },
      ...(tableClickNavigate && {
        tableRowClickNavigate: {
          tableClickNavigateToURL: true,
          tableRowURL: (id) => routeToSingleTask(id),
          navigateUsingRowAttribute: TASK_FIELDS.TASK.ID,
        },
      }),
    })
  );

export const userAnalysisTableDetailsTabData = {
  [USER_ACTIVITY_TASK_DETAILS]: {
    id: USER_ACTIVITY_TASK_DETAILS,
    label: "Tasks",
    color: "info",
    get icon() {
      return <Splitscreen color={this.color} />;
    },
    getConfig: () => userDetailsTableConfig(USER_ACTIVITY_TASK_DETAILS, "Task Assigned Details", userAnalysisTaskDetailsColumnView, true),
  },
  [USER_ACTIVITY_ATTENDANCE_DETAILS]: {
    id: USER_ACTIVITY_ATTENDANCE_DETAILS,
    label: "Attendance",
    color: "primary",
    get icon() {
      return <HowToReg color={this.color} />;
    },
    getConfig: () => userDetailsTableConfig(USER_ACTIVITY_ATTENDANCE_DETAILS, "Employee Attendance Details", userAnalysisRoutineAttendanceColumnView),
  },
  [USER_ACTIVITY_CONFLICTED_DETAILS]: {
    id: USER_ACTIVITY_CONFLICTED_DETAILS,
    label: "Adjustments",
    color: "secondary",
    get icon() {
      return <EditCalendar color={this.color} />;
    },
    getConfig: () =>
      userDetailsTableConfig(USER_ACTIVITY_CONFLICTED_DETAILS, "Employee In-Out Adjustments", userAnalysisOvertimeAttendanceColumnView),
  },
  [USER_ACTIVITY_OVERTIME_DETAILS]: {
    id: USER_ACTIVITY_OVERTIME_DETAILS,
    label: "Overtime",
    color: "warning",
    get icon() {
      return <Timelapse color={this.color} />;
    },
    getConfig: () => userDetailsTableConfig(USER_ACTIVITY_OVERTIME_DETAILS, "Employee Overtime Details", userAnalysisOvertimeAttendanceColumnView),
  },
};
