import { Stack } from "@mui/material";
import { Fragment, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { PRIVATE_ROUTES, routeVehicleParam } from "../../app/constants/routes";
import no_content from "../../asset/images/no_content.png";
import AdvancedTable from "../../components/advancedTable/AdvancedTable";
import { BlockerComponent, EmptyDataVisualComp, LoadingProgress } from "../../components/ui/UiUtils";
import { useGetVehicleDetailsMutation } from "../../hooks/ApiHooks";
import { useDeletePartsInventoryRowData, useFetchInventoryReportTableData, useFetchPartsInventoryTableData } from "../../hooks/advancedTableHook";
import TabLayout from "./TabLayout";
import Vehicle from "./Vehicle";
import { partsInventoryTableConfig, quantityId } from "./config/inventoryPartsTable";
import { inventoryReportTableConfig } from "./config/inventoryReportTable";
import { selectHasInventoryPartsTableDataChanged, selectIsVehicleEditOn } from "./slice/inventorySlice";

const InventoryPartsTable = memo(({ vehicleId, refetchDataFn, partsData }) => {
  const tableConfig = partsInventoryTableConfig(vehicleId, [useDeletePartsInventoryRowData, [vehicleId]]);
  return (
    <AdvancedTable
      config={tableConfig}
      apiHookWithParams={[useFetchPartsInventoryTableData, [tableConfig.tableProps.columnProps, partsData, quantityId, refetchDataFn]]}
    />
  );
});

const BlockNavigationFromVehicle = () => {
  const isDataAltered = useSelector(selectHasInventoryPartsTableDataChanged);
  const isEditModeOn = useSelector(selectIsVehicleEditOn);
  return <BlockerComponent when={isDataAltered || isEditModeOn} />;
};

export const InventoryReportTable = () => {
  const tableConfig = inventoryReportTableConfig;
  return <AdvancedTable config={tableConfig} apiHookWithParams={[useFetchInventoryReportTableData, [tableConfig.tableProps.columnProps]]} />;
};

export function VehicleInventoryManagement() {
  const [transformedData, setTransformedData] = useState({ vehicle: {}, tabItems: [] });
  const { [routeVehicleParam]: activeTabId = false } = useParams();
  const { triggerMutation, isLoading, isError, data } = useGetVehicleDetailsMutation();
  const [loadingCompleted, setLoadingCompleted] = useState(false);

  const refetchData = useCallback(() => {
    setLoadingCompleted(false);
    triggerMutation();
  }, [triggerMutation]);

  useEffect(() => {
    refetchData();
  }, [refetchData]);

  const processData = useCallback(
    (data) => {
      const vehicles = {};
      const tabData = [];

      data.data.forEach((item) => {
        const { parts, ...vehicleData } = item;
        vehicles[item.vhc_id] = { vehicle_data: vehicleData, parts_data: parts };

        tabData.push({
          id: item.vhc_id,
          label: item.vhc_id,
          availability: item.vhc_available === "Y",
          isActive: item.vhc_id === activeTabId,
        });
      });

      return { vehicle: vehicles, tabItems: tabData };
    },
    [activeTabId]
  );

  useEffect(() => {
    if (!isLoading && data) {
      setTransformedData(processData(data));
      setLoadingCompleted(true);
    }
  }, [isLoading, data, processData]);

  useEffect(() => {
    if (isError) {
      setLoadingCompleted(true);
    }
  }, [isError, setLoadingCompleted]);

  const renderVehicleDetails = useMemo(() => {
    if (!activeTabId) return null;
    const vehicleInfo = transformedData.vehicle[activeTabId] || {};
    const { vehicle_data = {}, parts_data = [] } = vehicleInfo;
    return (
      <Fragment>
        <Vehicle refetch={refetchData} vehicleId={activeTabId} vehicleData={vehicle_data} />
        <InventoryPartsTable vehicleId={activeTabId} partsData={parts_data} refetchDataFn={refetchData} />
      </Fragment>
    );
  }, [activeTabId, transformedData, refetchData]);

  return (
    <Stack sx={{ flex: 1, overflowY: "auto" }}>
      {!loadingCompleted ? (
        <LoadingProgress />
      ) : isError ? (
        <EmptyDataVisualComp />
      ) : (
        !!loadingCompleted &&
        (data ? (
          activeTabId && !transformedData.tabItems.some(({ id } = {}) => id === activeTabId) ? (
            <Navigate replace to={PRIVATE_ROUTES.errorRoute} />
          ) : (
            <Fragment>
              <TabLayout activeTabId={activeTabId} tabItems={transformedData.tabItems} />
              {renderVehicleDetails || (
                <EmptyDataVisualComp
                  src={no_content}
                  title="No Vehicle Selected"
                  subTitle="Please select a vehicle from the above options to display details"
                />
              )}
            </Fragment>
          )
        ) : null)
      )}
      <BlockNavigationFromVehicle />
    </Stack>
  );
}