import { Attachment } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { debounce } from "lodash";
import { MenuButton, RichTextEditor } from "mui-tiptap";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WARNING } from "../../app/constants/common";
import { CONFIG_CONSTANTS_OPTIONS } from "../../app/constants/config/AppSettings";
import { convertFileToBase64File, updateTextEditorAnchorAttributes } from "../../app/utils/utilityFunctions";
import { setAlert } from "../../features/Alerts/slice/alertSlice";
import { selectPresetConfig } from "../../features/AppSettings/appSlice";
import { richTextEditorStyles } from "../../styled/inlineCssStyles";
import { RenderFileChip } from "../AttachFileHandler/RenderFileChip";
import { GENERAL_MIME_TYPES, MAX_FILE_SIZE, MAX_FILE_UPLOADS_ALLOWED, filterAcceptedFileTypes } from "../AttachFileHandler/fileUtils";
import { BaseEditorMenuControls, extensions } from "./Extensions";

export default function MultiLineRTE({
  maxHeight = "400px",
  placeholder,
  id,
  name,
  value = "",
  currentAttachedFiles = [],
  onChange = null,
  onChangeFileData = null,
  fileUploadEnabled = false,
  fileRenderColor = undefined,
  maxUploadsAllowedLength = MAX_FILE_UPLOADS_ALLOWED,
  maxFileSizeAllowedInBytes = MAX_FILE_SIZE,
  allowedFileTypes = GENERAL_MIME_TYPES,
  disabled = false,
  maxCharactersAllowed = false,
  styleEnabled = true,
  sx = {},
}) {
  const fileInputRef = useRef();
  const dispatch = useDispatch();
  const taskListOptions = useSelector((state) => selectPresetConfig(state, CONFIG_CONSTANTS_OPTIONS.TASKS));
  const employeeListOptions = useSelector((state) => selectPresetConfig(state, CONFIG_CONSTANTS_OPTIONS.EMPLOYEES));
  const [multiInput, setMultiInput] = useState({ pureString: value ?? "", sanitizedHtml: updateTextEditorAnchorAttributes(value ?? `<p></p>`) });
  const [fileUploads, setFileUploads] = useState(currentAttachedFiles);

  const debouncedOnChangeRef = useRef(
    debounce((input, updatedFiles) => {
      if (input && (input.pureString === "" || input.pureString.trim() !== ""))
        onChange(
          input.pureString === ""
            ? ""
            : styleEnabled
              ? updateTextEditorAnchorAttributes(input.sanitizedHtml, false)
              : input.pureString.replace(/\s+/g, " ").trim()
        );
      if (fileUploadEnabled) onChangeFileData(updatedFiles);
    }, 400)
  );

  /* INFO: Initialize the debounced function once, Capture the current debounced function in a variable to avoid react warning   */
  useEffect(() => {
    const debouncedFunction = debouncedOnChangeRef.current;
    return () => debouncedFunction.cancel();
  }, []);

  useEffect(() => debouncedOnChangeRef.current(null, fileUploads), [fileUploads]);

  const handleUpdateEditor = ({ editor }) => {
    /* INFO: Manually Controlling the content to simulate disabled attribute since MUI-Tip-Tap does not allow controlled value for content */
    if (disabled) {
      editor.commands.setContent(multiInput.sanitizedHtml);
      return;
    }

    if (maxCharactersAllowed && updateTextEditorAnchorAttributes(editor.getHTML(), false).length > maxCharactersAllowed) {
      editor.commands.setContent(multiInput.sanitizedHtml);
      return;
    }

    const newInput = { pureString: editor.getText(), sanitizedHtml: editor.getHTML() };
    setMultiInput(newInput);
    debouncedOnChangeRef.current(newInput, fileUploads);
  };

  const handleSaveFileUploads = (targetFiles) => {
    if (disabled) return;
    const [files, areAllFilesUploaded] = filterAcceptedFileTypes(targetFiles, maxFileSizeAllowedInBytes, allowedFileTypes);
    if (!areAllFilesUploaded)
      dispatch(setAlert("Attachment Error", "Some files were not attached due to file size or extension limits. Max File Size : 50MB.", WARNING));

    Promise.all(files.map(convertFileToBase64File))
      .then((fileObjects) =>
        /* INFO: Here functional update method of state is used to get the latest state. The "stale closure" problem of state is resolved using this */
        setFileUploads((current) => {
          if (current.length >= maxUploadsAllowedLength) {
            dispatch(setAlert("Limit Exceeded", `Attachment limit reached. Max Attachment Limit: ${maxUploadsAllowedLength}`, WARNING));
            return current;
          }

          let isUploadLimitReached = false;
          const newFiles = fileObjects.filter((newFile, index) => {
            const isDuplicate = current.some((existingFile) => existingFile.fileName === newFile.fileName);
            const exceedsAttachmentLimit = current.length + index >= maxUploadsAllowedLength;

            if (isDuplicate) {
              dispatch(setAlert("Duplicate File", `The file "${newFile.fileName}" exists. Rename and retry.`, WARNING));
              return false;
            } else if (exceedsAttachmentLimit) {
              /* INFO: Only dispatch the alert once */
              if (!isUploadLimitReached) {
                dispatch(
                  setAlert(
                    "File Attachment Limit Reached",
                    `Some files were not attached since max attachment limit was reached. Max ${maxUploadsAllowedLength} files allowed`,
                    WARNING
                  )
                );
                isUploadLimitReached = true;
              }
              return false;
            }
            return true;
          });
          return [...current, ...newFiles];
        })
      )
      .catch(() => dispatch(setAlert("Upload Error", "Unexpected error while attaching files. Try again.", WARNING)))
      .finally(() => {
        if (fileInputRef.current) fileInputRef.current.value = "";
      });
  };

  const handleFileCancel = useCallback((fileId) => () => setFileUploads((currentFiles) => currentFiles.filter((file) => file.fileId !== fileId)), []);

  const handleFileRename = useCallback(
    (fileId) => (fileName) =>
      setFileUploads((currentFiles) => {
        const fileIndex = currentFiles.findIndex((file) => file.fileId === fileId);
        return fileIndex > -1 ? currentFiles.map((file, index) => (index === fileIndex ? { ...file, fileName } : file)) : currentFiles;
      }),
    []
  );

  /* INFO: Handler invoked when file is dropped into the editor */
  const handleDrop = (_, event) => {
    if (!(event instanceof DragEvent) || !event.dataTransfer) return false;
    event.preventDefault();
    handleSaveFileUploads(event.dataTransfer.files);
    return true;
  };

  /* INFO: Handler invoked when file is pasted into the editor */
  const handlePaste = (_, event) => {
    if (!event.clipboardData?.files.length) return false;
    handleSaveFileUploads(event.clipboardData.files);
    return true;
  };

  /* INFO: Handler invoked when file is upload to the editor */
  const handleUpload = (event) => handleSaveFileUploads(event.target.files);

  const RenderUploadedContent = useMemo(
    () =>
      !!fileUploads.length && (
        <Stack gap={1} direction="row" alignItems="center" sx={{ mb: 1 }} flexWrap="wrap">
          {fileUploads.map((file) => (
            <RenderFileChip
              key={file.fileId}
              fileData={file.fileData}
              fileMime={file.fileMime}
              fileName={file.fileName}
              onCancelFile={handleFileCancel(file.fileId)}
              onRenameFileName={handleFileRename(file.fileId)}
              color={fileRenderColor}
            />
          ))}
        </Stack>
      ),
    [fileUploads, fileRenderColor, handleFileCancel, handleFileRename]
  );

  return (
    <Box
      component="div"
      sx={{
        "& .MuiTiptap-RichTextContent-root .tiptap.ProseMirror": { maxHeight, minHeight: fileUploadEnabled ? "160px" : "130px" },
        ...richTextEditorStyles,
        ...sx,
      }}
    >
      <input type="hidden" id={id} name={name} value={multiInput.sanitizedHtml} />
      {!!fileUploadEnabled && (
        <Fragment>
          <input
            accept={Object.keys(GENERAL_MIME_TYPES).join(",")}
            style={{ display: "none" }}
            id="contained-button-file"
            multiple={maxUploadsAllowedLength > 1}
            type="file"
            ref={fileInputRef}
            onChange={handleUpload}
          />
          {RenderUploadedContent}
        </Fragment>
      )}
      <RichTextEditor
        autofocus
        content={multiInput.sanitizedHtml}
        onUpdate={handleUpdateEditor}
        editorProps={
          fileUploadEnabled
            ? {
                handlePaste,
                handleDrop,
              }
            : undefined
        }
        extensions={extensions(
          `Start typing or pasting your "${placeholder}" content here... ✍️ ${fileUploadEnabled ? "\n- Attach file(s) via drag and drop, paste in the editor or 'Attach' button." : ""}${styleEnabled ? "\n- Mention a task by '!' or an employee by '@' and continue typing the Name or ID.\n- Format text using the toolbar (bold, italic, underline, strike).\n- Use paragraphs, headings, and alignment (left, center, right, justify).\n- Add numbered, bullet, or checklists for clarity.\n\nStart with a word if unsure where to begin." : ""}`,
          taskListOptions,
          employeeListOptions,
          styleEnabled
        )}
        renderControls={() => (
          <BaseEditorMenuControls styleEnabled={styleEnabled}>
            {!!fileUploadEnabled && (
              <MenuButton tooltipLabel="Add Attachments" onClick={() => fileInputRef?.current.click()}>
                <Attachment />
              </MenuButton>
            )}
          </BaseEditorMenuControls>
        )}
      />
    </Box>
  );
}
