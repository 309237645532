import { ATTENDANCE_ACTION, COMMON_TRANSFORM_RESPONSE_FUNCTION } from "../../../app/constants/api";
import { apiSlice } from "../../../app/redux/apiSlice";

const employeeAttendance = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    attendanceAction: builder.mutation({
      query: ({ action, payload = {} }) => ({
        url: ATTENDANCE_ACTION.BASE,
        method: "POST",
        body: { action, payload },
      }),
      ...COMMON_TRANSFORM_RESPONSE_FUNCTION,
    }),
  }),
});

export const { useAttendanceActionMutation } = employeeAttendance;
