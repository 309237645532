import { nanoid } from "@reduxjs/toolkit";
import { useCallback, useEffect, useRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useSelector } from "react-redux";
import { LOGGER_ACTION } from "../../app/constants/api";
import { useLogErrorDataMutation } from "../../hooks/ApiHooks";
import { isTokenValid, selectUserProfile } from "../Auth/slice/authSlice";
import ErrorUI from "./Error404";

const onLogError = (trace, error, { componentStack }) => {
  if (!window.loggedErrors) {
    window.loggedErrors = new Set();
  }
  if (window.loggedErrors.has(`native-${trace}`)) {
    return;
  }
  window.loggedErrors.add(`native-${trace}`);
  const url = `${process.env.REACT_APP_BE_URL}${LOGGER_ACTION.BASE}`;
  const errorData = {
    timestamp: new Date().toISOString(),
    trace,
    errorMessage: error.toString(),
    js_error_stack: error.stack,
    component_stack: componentStack,
    url: window.location.href,
  };

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      action: "log-error",
      payload: {
        error: errorData,
      },
    }),
  })
    .then((response) => {
      if (!response.ok) console.error("Error logging to server:", response.statusText);
    })
    .catch((error) => {
      console.error("Error sending error log:", error);
    });
};

const FallbackComponent = ({ trace = "Unknown - 550" }) => (
  <ErrorUI
    errorTitle="Ohh, No! We Encountered Internal Application Error"
    errorSubtitle={`Contact Admin Immediately For Assistance. Provide the following trace for details : ${trace}`}
    errorInt={500}
    buttonTitle="Logout For Safety"
    logoutEnabled
  />
);

const FallbackComponentWithLogs = ({ trace, functionFeature = null }) => {
  const { triggerMutation } = useLogErrorDataMutation();
  const isUserLoggedIn = useSelector(isTokenValid);
  const userDetails = useSelector(selectUserProfile);

  useEffect(() => {
    const logError = async () => {
      try {
        await triggerMutation({
          timestamp: new Date().toISOString(),
          trace,
          user_logged_in: isUserLoggedIn,
          current_user: userDetails,
          function_feature: functionFeature,
          url: window.location.href,
        }).unwrap();
      } catch (error) {
        console.error("Error logging to server", error);
      }
    };

    if (!window.loggedErrors) {
      window.loggedErrors = new Set();
    }
    if (!window.loggedErrors.has(`complex-${trace}`)) {
      logError();
      window.loggedErrors.add(`complex-${trace}`);
    }
  }, [trace, triggerMutation, isUserLoggedIn, userDetails, functionFeature]);

  return <FallbackComponent trace={trace} />;
};

export const ErrorBoundaryWrapper = ({ children, functionFeature = null, nativeError = false }) => {
  const traceId = useRef(nanoid());
  const errorHandler = useCallback((error, errorInfo) => {
    onLogError(traceId.current, error, errorInfo);
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={() =>
        nativeError ? (
          <FallbackComponent trace={traceId.current} />
        ) : (
          <FallbackComponentWithLogs trace={traceId.current} functionFeature={functionFeature} />
        )
      }
      onError={errorHandler}
    >
      {children}
    </ErrorBoundary>
  );
};
