import { DarkMode, Error, FiberManualRecord, LightMode } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  alpha,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Slide,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import { forwardRef, memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useBlocker } from "react-router-dom";
import { getAdjustedColorForContrast, highlightText, reducedName, serverToUi, stringToColor } from "../../app/utils/utilityFunctions";
import no_connection_img from "../../asset/images/no_connection.png";
import { findEmployeeOptionById, selectIsThemeDark, toggleThemeMode } from "../../features/AppSettings/appSlice";
import { useRetrieveEmployeePicture } from "../../hooks/valueProviderHooks.js";
import { MuiTooltip } from "../../styled/commonStyles";
import { loaderStyles } from "../../styled/inlineCssStyles.js";

export const AppThemeMode = (iconButtonProps) => {
  const isDarkMode = useSelector(selectIsThemeDark);
  const dispatch = useDispatch();

  return (
    <MuiTooltip title={`Turn ${isDarkMode ? "on" : "off"} the light`} arrow>
      <IconButton onClick={() => dispatch(toggleThemeMode())} aria-label="switch theme" {...iconButtonProps}>
        {isDarkMode ? <LightMode color="primary" /> : <DarkMode color="primary" />}
      </IconButton>
    </MuiTooltip>
  );
};

export const LoadingProgress = ({ sx = {}, innerSx, alignX = "center", alignY = "center", size = 40, color = "primary" }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: alignY,
        alignItems: alignX,
        height: "100%",
        width: "100%",
        ...sx,
      }}
    >
      <Box
        sx={{
          ...innerSx,
          "--loader-size": isNaN(size) ? size : `${size - 10}px`,
          "--color": theme.palette[color].main,
          ...loaderStyles,
        }}
      />
    </Box>
  );
};

export const UiIconButton = ({
  component = "iconButton",
  title,
  titleFontSize = 10,
  iconColor = "primary.main",
  children,
  arrow = false,
  iconSize = 24,
  onClickIcon = () => {},
  sx = {},
  loadingSx = {},
  loading = false,
  disabled,
  ...otherButtonProps
}) => {
  const theme = useTheme();
  const ButtonComponent = component === "button" ? Button : IconButton;

  const buttonSx = {
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.06)",
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
    "& .MuiSvgIcon-root, & .MuiIcon-root": {
      fontSize: iconSize,
      transition: "transform 0.2s ease-in-out",
    },
    ...(iconColor && { color: iconColor }),
    ...sx,
  };

  return loading ? (
    <LoadingProgress size={iconSize} sx={{ padding: "8px", width: "fit-content", ...loadingSx }} />
  ) : (
    <MuiTooltip title={!disabled && title} fontSize={titleFontSize} arrow={arrow}>
      <span>
        <ButtonComponent disabled={disabled} {...otherButtonProps} sx={buttonSx} onClick={onClickIcon}>
          {children}
        </ButtonComponent>
      </span>
    </MuiTooltip>
  );
};

export const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const EmptyDataVisualComp = ({
  src = no_connection_img,
  altSrc = "No Connection",
  title = "Error Loading Data",
  subTitle = "There was an error connecting to server. Please try again later",
  srcSize = {},
  titleSx = {},
  subTitleSx = {},
}) => {
  srcSize = { width: "150px", height: "fit-content", ...srcSize };
  return (
    <Box
      component="div"
      sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", flex: 1, height: "100%", width: "100%" }}
    >
      <Avatar alt={altSrc} src={src} sx={{ width: `${srcSize.width} !important`, height: `${srcSize.height} !important`, my: 2 }} />
      <Typography component="span" variant="h5" sx={{ fontWeight: 600, ...titleSx }}>
        {title}
      </Typography>
      <Typography component="span" sx={{ fontSize: 14, color: "text.secondary", textAlign: "center", ...subTitleSx }}>
        {subTitle}
      </Typography>
    </Box>
  );
};

export const StatisticBoard = ({ loading, label, count, icon, color, statBoxSx = {}, cardProps = {} }) => {
  const theme = useTheme();
  const cardColor = theme.palette[color].main;

  const statisticBoardSkeleton = () => (
    <CardContent>
      <Stack spacing={1}>
        <Skeleton variant="text" animation="wave" width={80} height={40} sx={{ backgroundColor: alpha(cardColor, 0.1) }} />
        <Skeleton variant="text" animation="wave" width={120} height={20} sx={{ backgroundColor: alpha(cardColor, 0.1) }} />
      </Stack>
      <Skeleton
        variant="rounded"
        animation="wave"
        sx={{
          height: 56,
          width: 56,
          borderRadius: 2,
          backgroundColor: alpha(cardColor, 0.1),
        }}
      />
    </CardContent>
  );

  return (
    <Card
      variant="outlined"
      component={motion.div}
      whileHover={{ y: -5 }}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 3,
        p: 1,
        height: "100%",
        background: `linear-gradient(135deg, ${alpha(cardColor, 0.1)} 0%, ${alpha(theme.palette.background.paper, 1)} 60%)`,
        borderRadius: 2,
        border: `1px solid ${alpha(cardColor, 0.1)}`,
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          boxShadow: `0 4px 16px ${alpha(cardColor, 0.15)}`,
        },
        "& .MuiSvgIcon-root, & .MuiIcon-root": { fontSize: 24 },
        ...statBoxSx,
        "& .MuiCardContent-root": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flex: 1,
        },
      }}
      {...cardProps}
    >
      {loading ? (
        statisticBoardSkeleton()
      ) : (
        <CardContent>
          <Stack spacing={1}>
            <Typography variant="body2" color="text.secondary" noWrap fontWeight={500}>
              {label}
            </Typography>
            <Typography variant="h4" fontWeight="bold">
              {count}
            </Typography>
          </Stack>
          <Avatar
            sx={{
              p: 2,
              borderRadius: 2,
              backgroundColor: alpha(cardColor, 0.1),
              color: cardColor,
              height: 56,
              width: 56,
            }}
          >
            {icon}
          </Avatar>
        </CardContent>
      )}
    </Card>
  );
};

export const GridLabelValueUI = ({ label, children, divider = false, boxContainerProps = {}, labelSx = {}, valueSx = {}, dividerSx = {} }) => {
  const commonTypographyStyle = {
    fontSize: 14,
    width: "100%",
    textAlign: "start",
  };

  return (
    <Box component="div" display="flex" height="100%" {...boxContainerProps}>
      {!!divider && <Divider sx={dividerSx} />}
      {!!label && (
        <Box flex="1" pr="24px" display="flex" alignItems="center">
          <Typography component="div" sx={{ textTransform: "capitalize", ...commonTypographyStyle, ...labelSx }} color="textSecondary">
            {label}
          </Typography>
        </Box>
      )}
      <Box flex="1" display="flex" alignItems="center">
        <Typography component="div" sx={{ ...commonTypographyStyle, ...valueSx }}>
          {children}
        </Typography>
      </Box>
    </Box>
  );
};

export const ConfirmationDialog = ({
  open,
  onClose,
  onSubmit,
  onCancel,
  title = "Confirmation",
  subTitle = "Do you want to continue?",
  onSubmitTitle = "Yes",
  onCancelTitle = "No",
  loading,
  children,
}) => {
  return (
    <Dialog
      open={open === undefined ? true : open}
      onClose={loading !== undefined && loading ? null : onClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      TransitionComponent={Transition}
    >
      <DialogTitle sx={{ fontWeight: 500 }}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{subTitle}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="success" autoFocus onClick={onCancel || onClose} disabled={loading !== undefined && loading}>
          {onCancelTitle}
        </Button>
        <LoadingButton variant="outlined" color="error" onClick={onSubmit} loading={loading !== undefined && loading}>
          {onSubmitTitle}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export const BlockerComponent = ({ when = false }) => {
  const blocker = useBlocker(useCallback(({ currentLocation, nextLocation }) => when && currentLocation.pathname !== nextLocation.pathname, [when]));
  const handleBeforeUnload = useCallback(
    (e) => {
      if (when) {
        e.preventDefault();
        return (e.returnValue = "Are you sure you want to leave?");
      }
    },
    [when]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [handleBeforeUnload]);

  return (
    <ConfirmationDialog
      open={blocker.state === "blocked"}
      onClose={blocker.reset}
      title="Oops! You still have unsaved changes"
      subTitle="Looks like you've made some changes that haven't been saved yet. If you leave now, you'll lose those updates. Take a moment to make sure everything's in order."
      onCancelTitle="Go Back & Save"
      onSubmitTitle="Leave Anyway"
      onSubmit={blocker.proceed}
    />
  );
};

export const ProgressIndicator = ({ value, displayPercentage = false, boxSx = {}, outerProgressSx = {}, outerPercentageSx = {}, ...otherProps }) => (
  <Stack direction="row" alignItems="center" gap={1} width="100%" sx={boxSx}>
    <Box sx={{ flex: 1, ...outerProgressSx }}>
      <LinearProgress variant="determinate" value={value} sx={{ width: "100%", borderRadius: 2 }} {...otherProps} />
    </Box>
    {!!displayPercentage && (
      <Box sx={{ minWidth: 35, ...outerPercentageSx }}>
        <Typography variant="body2" color="text.secondary">
          {value}%
        </Typography>
      </Box>
    )}
  </Stack>
);

export const EmployeeAvatar = memo(({ size = "40px", fontSize = "1rem", empId, sx = {}, srcExists = false, srcObject = undefined }) => {
  const { isLoading, isError, employeeName, profilePicture, refetchData } = useRetrieveEmployeePicture(empId, srcExists, srcObject);
  const theme = useTheme();
  return isLoading ? (
    <Skeleton variant="circular" height={size} width={size} animation="wave" sx={sx} />
  ) : isError && !profilePicture ? (
    <Avatar sx={{ height: size, width: size, fontSize, fontWeight: 500, ...sx, backgroundColor: "error.background" }}>
      <Error color="error" />
    </Avatar>
  ) : (
    <Avatar
      slotProps={{ img: { onError: refetchData } }}
      sx={{
        height: size,
        width: size,
        fontSize,
        fontWeight: 500,
        backgroundColor: getAdjustedColorForContrast(stringToColor(employeeName), theme)[0],
        ...sx,
      }}
      src={profilePicture}
    >
      {reducedName(employeeName)}
    </Avatar>
  );
});

export const EmployeeUIContent = ({ empId, searchStr }) => {
  const employee = useSelector((state) => findEmployeeOptionById(state, empId));
  return (
    <ListItem alignItems="flex-start" disablePadding>
      <ListItemAvatar sx={{ minWidth: "45px" }}>
        <EmployeeAvatar size="35px" empId={empId} />
      </ListItemAvatar>
      <ListItemText
        primary={highlightText(employee.name, searchStr)}
        secondary={highlightText(`${employee.ern} - ${employee.role}`, searchStr)}
        primaryTypographyProps={{ fontSize: "inherit", fontWeight: 500, fontFamily: "inherit", textTransform: "capitalize" }}
        secondaryTypographyProps={{ fontFamily: "inherit", textTransform: "capitalize" }}
      />
    </ListItem>
  );
};

export const TableTimeStampDisplay = ({ dateInput, format, searchStr }) => {
  const uiFormattedDate = serverToUi(dateInput, format).split("-");
  return (
    <ListItemText
      sx={{ "& .MuiListItemText-primary": { fontSize: "inherit" } }}
      primary={highlightText(uiFormattedDate[0], searchStr)}
      secondary={uiFormattedDate[1] ? `At ${uiFormattedDate[1]}` : null}
    />
  );
};

export const ModernTabLabelComponent = memo(({ isActive, label, icon, color }) => {
  const theme = useTheme();
  const cardColor = theme.palette[color].main;
  return (
    <Card
      variant="outlined"
      elevation={0}
      sx={{
        width: "100%",
        p: 1.5,
        minHeight: "48px",
        height: "fit-content",
        borderRadius: 1.5,
        backgroundColor: "background.default",
        border: `1px solid ${alpha(cardColor, 0.12)}`,
        ...(isActive && { background: `linear-gradient(170deg, ${alpha(cardColor, 0.18)} 0%, ${alpha(theme.palette.background.paper, 1)} 60%)` }),
        transition: "background, box-shadow 0.3s ease-in-out",
        "&:hover": { boxShadow: `0 0px 12px ${alpha(cardColor, 0.15)}` },
        "& .MuiSvgIcon-root, & .MuiIcon-root": { fontSize: 20 },
        "& .modern-dot.MuiSvgIcon-root,& .modern-dot.MuiIcon-root": {
          fontSize: 12,
          color: cardColor,
          display: isActive ? "inline-block" : "none",
        },
        "& .MuiCardContent-root": {
          padding: "0 !important",
          mx: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
    >
      <CardContent>
        {icon}
        <Typography variant="body1" noWrap fontWeight={500} sx={{ mx: 1, color: cardColor }}>
          {label}
        </Typography>
        <FiberManualRecord className="modern-dot" sx={{ fontSize: 2 }} />
      </CardContent>
    </Card>
  );
});
