import { Box, Button, Typography } from "@mui/material";
import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { ERROR, SUCCESS } from "../../app/constants/common";
import { PUBLIC_ROUTES, verifyEmailQueryParam } from "../../app/constants/routes";
import { LoadingProgress } from "../../components/ui/UiUtils";
import { useVerifyEmailMutation } from "../../hooks/ApiHooks";
import { setAlert } from "../Alerts/slice/alertSlice";

export default function VerifyUserAccount() {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const emailQueryParam = new URLSearchParams(useLocation().search).get(verifyEmailQueryParam);
  const [setMessage] = useOutletContext();
  const { triggerMutation: verifyEmail, data, isError, error, isLoading } = useVerifyEmailMutation();
  useEffect(() => {
    if (emailQueryParam) {
      verifyEmail(emailQueryParam);
    } else {
      dispatch(setAlert("Account Verification Failed", "The link provided was not correct or has expired. Please try again later.", ERROR));
      navigateTo(PUBLIC_ROUTES.login);
    }
  }, [navigateTo, dispatch, emailQueryParam, verifyEmail]);

  useEffect(() => {
    if (!isLoading && data) {
      setMessage("Your account was verified successfully. You may now proceed to login to your account", SUCCESS);
      dispatch(setAlert("Account Verified", "Please login to your account to proceed", SUCCESS));
    }
  }, [dispatch, isLoading, data, setMessage]);

  return (
    <Box sx={{ width: "370px" }}>
      {isLoading ? (
        <Fragment>
          <LoadingProgress />
          <Typography marginTop={3}>Verifying Account. Please wait...</Typography>
        </Fragment>
      ) : isError && error ? (
        <Typography variant="h6" sx={{ my: 2, textTransform: "capitalize" }}>
          {error.data.message}
        </Typography>
      ) : data ? (
        <Typography variant="h6" sx={{ my: 2 }}>
          Your account was verified. Proceed to login
        </Typography>
      ) : null}
      {!isLoading && (
        <Button variant="contained" size="large" component={Link} to={PUBLIC_ROUTES.login} sx={{ minWidth: "370px", mb: 1 }}>
          Go To Login
        </Button>
      )}
    </Box>
  );
}
