import { Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import { differenceInSeconds } from "date-fns";
import { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { REFRESH_APP_CONFIG_AFTER_SECONDS } from "../../app/constants/common";
import { isAppSettingLoading, selectInFlightApiCount, selectLastFetchedConfigTime } from "../../features/AppSettings/appSlice";
import { useGetSystemConfigMutation } from "../../hooks/ApiHooks";
import { useAppNavigation } from "../../hooks/navigationHook";
import { useGetNotificationInBackground } from "../../hooks/valueProviderHooks";
import AppHeaderBar from "../common/AppHeaderBar";
import AppSidebar from "../common/AppSideBar";
import { appNavigationItems } from "../config/sidebarConfig";
import { LoadingProgress, ProgressIndicator } from "../ui/UiUtils";

const TopDeterminantProgress = memo(() => {
  const inFlightApiCount = useSelector(selectInFlightApiCount);
  const [progress, setProgress] = useState(0);
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    setProgress(Math.max(100 - Math.round((inFlightApiCount / 5) * 100), 0));
    if (inFlightApiCount === 0 && !timeoutIdRef.current) {
      timeoutIdRef.current = setTimeout(() => {
        setProgress(0);
        timeoutIdRef.current = null;
      }, 1500);
    } else if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }
    };
  }, [inFlightApiCount]);

  return (
    <ProgressIndicator
      color="primary"
      value={progress}
      outerProgressSx={{ "& .MuiLinearProgress-root": { backgroundColor: "transparent" } }}
      boxSx={{
        "& .MuiLinearProgress-bar": {
          transition: `background-color 0.8s linear ${inFlightApiCount ? 0 : "0.4s"}, transform .4s linear`,
          ...(!inFlightApiCount && { backgroundColor: "transparent" }),
        },
        position: "absolute",
        left: 0,
        right: 0,
        zIndex: 1302,
        top: "62.52px",
      }}
    />
  );
});

const LayoutSectionWrapper = ({ activeItem }) => {
  useGetNotificationInBackground();
  const { triggerMutation } = useGetSystemConfigMutation();
  const lastFetchedApiTime = useSelector(selectLastFetchedConfigTime);
  const location = useLocation();
  const isLoading = useSelector(isAppSettingLoading);

  useEffect(() => {
    if (
      !lastFetchedApiTime ||
      (activeItem?.refreshAppConfig && differenceInSeconds(new Date().getTime(), lastFetchedApiTime) > REFRESH_APP_CONFIG_AFTER_SECONDS)
    )
      triggerMutation();
  }, [location.pathname, lastFetchedApiTime, triggerMutation, activeItem]);

  return (
    <Box sx={{ pt: 1, flexGrow: 1, display: "flex", flexDirection: "column", height: "0%", position: "relative" }}>
      {isLoading ? <LoadingProgress sx={{ height: "100vh" }} /> : <Outlet />}
    </Box>
  );
};

export default function DashBoardLayout() {
  const [open, setOpen] = useState(false);
  const { activeItem, sidebarItems } = useAppNavigation(appNavigationItems);
  const onHandleDrawer = () => setOpen(!open);
  return (
    <Box sx={{ display: "flex", height: "100vh", position: "relative" }}>
      <TopDeterminantProgress />
      <AppHeaderBar open={open} handleDrawerOpen={onHandleDrawer} activeItem={activeItem} />
      <AppSidebar open={open} handleDrawerClose={onHandleDrawer} navItems={sidebarItems} />
      <Box component="main" sx={{ flexGrow: 1, display: "flex", flexDirection: "column", width: "0%" }}>
        <Toolbar />
        <LayoutSectionWrapper activeItem={activeItem} />
      </Box>
    </Box>
  );
}
