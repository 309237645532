import { PublicOff, TravelExplore } from "@mui/icons-material";
import { Box, Grid, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { Fragment, useEffect, useRef } from "react";
import { Marker, Popup, useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { GROUP_BY_COLUMN_NAME } from "../../app/constants/common";
import { getMaxMinBounds } from "../../app/utils/utilityFunctions";
import { AutocompleteOptionsField } from "../../components/ui/InputUI";
import { LocationPinIcon } from "../../components/ui/LocationPin";
import { EmployeeAvatar, GridLabelValueUI, LoadingProgress, UiIconButton } from "../../components/ui/UiUtils";
import {
  selectActiveMarker,
  selectCurrentLocationData,
  selectEmployeeLocationData,
  selectOriginalLocationData,
  selectUseOriginalLocationData,
  setActiveMarkerId,
  setCurrentLocationData,
  toggleLocationDataUse,
} from "./slice/empMonitorSlice";

export const RenderMapMarkers = () => {
  const [markerOptions, showAllMarkers] = useSelector(selectEmployeeLocationData);
  const currentEmpLocationData = useSelector(selectCurrentLocationData);
  const mapRef = useMap();
  const dispatch = useDispatch();

  useEffect(() => {
    if (showAllMarkers && markerOptions.length)
      mapRef.current?.fitBounds(getMaxMinBounds(markerOptions), { padding: 120, maxZoom: 17, duration: 1000 });
  }, [mapRef, markerOptions, showAllMarkers]);

  useEffect(() => {
    if (currentEmpLocationData) {
      const coordinates = currentEmpLocationData.coordinates.split(",").map(parseFloat).reverse();
      mapRef.current.flyTo({ center: coordinates, zoom: 17, duration: 5000 });
    }
  }, [mapRef, currentEmpLocationData]);

  return markerOptions.map(({ coordinates, id }) => {
    if (!coordinates) return null;
    const [latitude, longitude] = coordinates.split(",");
    return (
      <Marker
        onClick={() => dispatch(setActiveMarkerId(id))}
        key={id}
        latitude={parseFloat(latitude)}
        longitude={parseFloat(longitude)}
        style={{ cursor: "pointer" }}
      >
        <LocationPinIcon />
      </Marker>
    );
  });
};

export const RenderPopUpForMarker = () => {
  const markerDetails = useSelector(selectActiveMarker);
  const dispatch = useDispatch();

  if (!markerDetails) return null;
  const [latitude, longitude] = markerDetails.coordinates.split(",");
  return (
    <Popup
      latitude={parseFloat(latitude)}
      longitude={parseFloat(longitude)}
      closeOnClick={false}
      closeButton={false}
      anchor="bottom-right"
      closeOnMove
      onClose={() => dispatch(setActiveMarkerId(null))}
    >
      <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>{markerDetails.name}</Typography>
      <Typography sx={{ fontSize: "0.875rem", color: "text.secondary", fontWeight: 300, mb: 1 }}>{markerDetails.id}</Typography>
      <ListItemText primary={markerDetails.lastUpdatedAtDate} secondary={markerDetails.lastUpdatedAtTime} />
    </Popup>
  );
};

export const ShowOrHideAllLocations = ({ isLoadingData }) => {
  const showAllLocations = useSelector(selectUseOriginalLocationData);
  const dispatch = useDispatch();
  const onChangeShow = () => dispatch(toggleLocationDataUse());

  return (
    <UiIconButton
      title={isLoadingData ? "Loading Locations..." : `${showAllLocations ? "Hide" : "Show"} all employee locations`}
      arrow
      onClickIcon={isLoadingData ? null : onChangeShow}
      sx={{
        backgroundColor: "primary.sectionContainer",
        "&:hover": { backgroundColor: "primary.sectionContainer" },
        ml: 1.5,
        boxShadow: "0 0 0 2px rgba(0,0,0,.1)",
      }}
    >
      {isLoadingData ? <LoadingProgress size={24} sx={{ p: 0.6 }} /> : showAllLocations ? <PublicOff /> : <TravelExplore />}
    </UiIconButton>
  );
};

export const MapCustomSearchEmployeeLocation = ({ isLoadingData }) => {
  const options = useSelector(selectOriginalLocationData);
  const currentEmpLocationData = useSelector(selectCurrentLocationData);
  const dispatch = useDispatch();
  const onChange = (selectedValue) => dispatch(setCurrentLocationData(selectedValue));

  return (
    <AutocompleteOptionsField
      sx={{
        "& .MuiFormControl-root": {
          backgroundColor: "primary.sectionContainer",
          borderRadius: "4px",
          boxShadow: "0 0 0 2px rgba(0,0,0,.1)",
        },
        "& .MuiFilledInput-root": {
          backgroundColor: "transparent",
          "&::before": {
            borderColor: "primary.sectionBorder600",
          },
        },
      }}
      loading={isLoadingData}
      size="medium"
      options={options}
      groupBy={(option) => option[GROUP_BY_COLUMN_NAME]}
      value={currentEmpLocationData}
      onChange={onChange}
      label="Search Employees"
      placeholder="Search or select employee..."
      variant="filled"
      noOptionsText="No Locations Available"
      getOptionLabel={(option) => option.name}
      filterFunction={({ id, name }, inputValue) => [id, name].some((entity) => String(entity).toLowerCase().includes(inputValue.toLowerCase()))}
      renderOptionComponent={(option) => (
        <Fragment>
          <ListItemAvatar>
            <EmployeeAvatar empId={option.id} />
          </ListItemAvatar>
          <ListItemText primary={`${option.id} - ${option.name}`} secondary={option.coordinates.split(",").join(" || ")} />
        </Fragment>
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      uniqueKeyId="id"
    />
  );
};

export const EmployeeLocationDetails = () => {
  const employeeDetails = useSelector(selectCurrentLocationData);
  const ref = useRef();

  return (
    <CSSTransition nodeRef={ref} in={!!employeeDetails} timeout={300} unmountOnExit classNames="location-fade">
      <Box component="div" className="location-details" ref={ref}>
        <Grid container spacing={1}>
          {!!employeeDetails &&
            [
              { label: "Employee ID", value: employeeDetails.id },
              { label: "Name", value: employeeDetails.name },
              { label: "Latitude", value: employeeDetails.coordinates.split(",")[0] },
              { label: "Longitude", value: employeeDetails.coordinates.split(",")[1] },
              { label: "Role", value: employeeDetails.group },
              {
                label: "Updated At",
                value: <ListItemText primary={employeeDetails.lastUpdatedAtDate} secondary={employeeDetails.lastUpdatedAtTime} />,
              },
            ].map(({ label, value }) => (
              <Grid item xs={12} key={label}>
                <GridLabelValueUI label={label}>{value}</GridLabelValueUI>
              </Grid>
            ))}
        </Grid>
      </Box>
    </CSSTransition>
  );
};
