import { AddCircle, Autorenew, CheckCircle, Info, MoreVert, PlaylistAdd, RemoveCircle, RestartAlt, SaveAs } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, Fade, Grid, InputAdornment, ListItem, Paper, Popover, Popper, Stack, TextField, Typography } from "@mui/material";
import React, { Fragment, memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DATE_TIME_FORMAT, ERROR, SUCCESS, WARNING } from "../../app/constants/common";
import { routeToInventoryVehicle } from "../../app/constants/routes";
import { highlightText, serverToUi } from "../../app/utils/utilityFunctions";
import { selectAllCurrentDataWithPrimaryKey, selectAllCustomDataWithPrimaryKey } from "../../components/slices/advancedTable";
import { EmptyDataVisualComp, GridLabelValueUI, LoadingProgress, UiIconButton } from "../../components/ui/UiUtils";
import { useGetInventoryPartAudit, useUpdateVehiclePartsMutation } from "../../hooks/ApiHooks";
import { MuiDropDownMenu, MuiTooltip } from "../../styled/commonStyles";
import { quantityPopperCellPaperStyle, quantityPopperCellStyles, sectionUiLookBase } from "../../styled/inlineCssStyles";
import { setAlert } from "../Alerts/slice/alertSlice";
import { findEmployeeOptionById } from "../AppSettings/appSlice";
import { selectHasInventoryPartsTableDataChanged } from "./slice/inventorySlice";

const RenderEachListItem = ({ audit }) => {
  const employee = useSelector((state) => findEmployeeOptionById(state, audit.ern));

  return (
    <Fragment>
      <GridLabelValueUI
        label={
          <Box sx={{ "& .MuiTypography-root": { fontSize: "0.875rem" } }}>
            <Typography>{serverToUi(audit.upd_tms, DATE_TIME_FORMAT.UI_DATE_FORMAT)}</Typography>
            <Typography>{serverToUi(audit.upd_tms, DATE_TIME_FORMAT.UI_TIME_FORMAT)}</Typography>
          </Box>
        }
      >
        <Typography sx={{ fontSize: "1rem", lineHeight: 1.4, fontWeight: 600, color: "primary.main" }}>{employee.name || "None"}</Typography>
        <Stack direction="row">
          <Typography sx={{ backgroundColor: "error.background", color: "error.main", textDecoration: "line-through", px: 1, mr: 0.5 }}>
            QTY: {audit.prev_qty}
          </Typography>
          <Typography sx={{ backgroundColor: "success.background", color: "success.main", px: 1, ml: 0.5 }}>QTY: {audit.new_qty}</Typography>
        </Stack>
      </GridLabelValueUI>
      <Divider sx={{ my: 1 }} />
    </Fragment>
  );
};

const RenderChangeLogPopup = memo(({ vehicleId, open, handlePopperFocus, handleClose, anchorEl, partId, onChangeQuantity, currQuantity }) => {
  const { triggerMutation, data, isLoading, isError } = useGetInventoryPartAudit();
  const [addOnQty, setAddOnQty] = useState(0);
  const onAddToQuantity = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (/^\d*$/.test(currQuantity) && Number(currQuantity) + Number(addOnQty) >= 0) onChangeQuantity(Number(currQuantity) + Number(addOnQty));
  };

  useEffect(() => {
    if (open && !data) triggerMutation(vehicleId, partId);
  }, [triggerMutation, vehicleId, partId, open, data]);

  return (
    <Popper open={open} anchorEl={anchorEl} transition placement="left" disablePortal={true}>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper tabIndex={0} onFocus={handlePopperFocus} onBlur={handleClose} component="div" sx={{ backgroundColor: "transparent" }}>
            <Paper component="div" sx={{ ...quantityPopperCellPaperStyle, height: "74px" }}>
              <Box component="form" sx={{ p: 2, display: "flex", alignItems: "center", justifyContent: "space-between" }} onSubmit={onAddToQuantity}>
                <TextField
                  color={Number(addOnQty) >= 0 ? "primary" : "error"}
                  fullWidth
                  label={`${Number(addOnQty) >= 0 ? "Add to" : "Remove from"} Current Quantity`}
                  variant="outlined"
                  id="bulkQty"
                  name="bulkQty"
                  type="number"
                  placeholder="Numeric value [-99999 - 99999]"
                  InputProps={{
                    inputProps: { inputMode: "numeric", min: -99999, max: 99999 },
                    startAdornment: (
                      <InputAdornment position="start">
                        {Number(addOnQty) >= 0 ? <AddCircle color="primary" /> : <RemoveCircle color="error" />}
                      </InputAdornment>
                    ),
                  }}
                  value={addOnQty}
                  size="small"
                  onChange={(e) => setAddOnQty(e.target.value)}
                  sx={{ mr: 1 }}
                />
                <UiIconButton
                  type="submit"
                  iconColor={Number(addOnQty) >= 0 ? "primary.main" : "error.main"}
                  title={`${Number(addOnQty) >= 0 ? "Add to" : "Remove from"} Current Quantity`}
                  arrow
                >
                  <PlaylistAdd />
                </UiIconButton>
              </Box>
            </Paper>
            <Paper component="div" sx={quantityPopperCellPaperStyle}>
              <Stack component="div" className="popper-paper-head-box" direction="row" alignItems="center" justifyContent="space-between">
                <Box component="div">
                  <Typography className="popper-paper-heading">Recent Quantity Updates</Typography>
                  <Typography className="popper-paper-subheading">Review the recent record changes below</Typography>
                </Box>
                <UiIconButton title="Reload Audit Stats" onClickIcon={() => triggerMutation(vehicleId, partId)} loading={isLoading}>
                  <Autorenew />
                </UiIconButton>
              </Stack>
              <Box sx={{ p: 2 }}>
                {data && !isLoading ? (
                  data.data.length ? (
                    data.data.map((audit) => <RenderEachListItem key={audit.part_audit_id} audit={audit} />)
                  ) : (
                    <EmptyDataVisualComp title="No Updates Found" subTitle="There were no updates on this quantity" srcSize={{ width: "100px" }} />
                  )
                ) : isError ? (
                  <EmptyDataVisualComp srcSize={{ width: "100px" }} />
                ) : (
                  <LoadingProgress sx={{ minHeight: "150px" }} />
                )}
              </Box>
            </Paper>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
});

export const TabLabelComp = memo(({ isActive, label, availability, extendedView }) => (
  <MuiTooltip
    title={!extendedView ? `Status: ${availability === undefined ? "Not Chosen" : availability ? " Available" : "Not Available"}` : null}
    arrow
    fontSize={10}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: isActive ? 1 : 0.6,
        minWidth: "150px",
        mx: "auto",
        textTransform: "capitalize",
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 500, color: "primary.main", mr: 1 }}>
        {label}
      </Typography>
      {availability === undefined ? <Info color="info" /> : availability ? <CheckCircle color="success" /> : <RemoveCircle color="error" />}
    </Box>
  </MuiTooltip>
));

export const ExtendedTabViewPopper = memo(({ tabItems, handleClose, anchorEl }) => (
  <Popover
    open={!!anchorEl}
    anchorEl={anchorEl}
    onClose={handleClose}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    slotProps={{
      paper: {
        sx: {
          minWidth: 280,
          maxWidth: "calc(100vw - 280px)",
          maxHeight: "450px",
          overflowX: "hidden",
          overflowY: "auto",
          mt: 1.5,
          ...sectionUiLookBase,
        },
      },
    }}
  >
    <Grid component="div" container spacing={2} sx={{ p: 2 }}>
      {tabItems.map(({ id, label, availability, isActive }) => {
        return (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={id} sx={{ flex: 1, textAlign: "center" }}>
            <Button
              onClick={() => handleClose()}
              LinkComponent={Link}
              to={routeToInventoryVehicle(id)}
              size="small"
              sx={{ border: "1px solid", borderRadius: 1, borderColor: isActive ? "primary.main" : "transparent", display: "block" }}
            >
              <TabLabelComp isActive={isActive} label={label} availability={availability} extendedView={true} />
            </Button>
          </Grid>
        );
      })}
    </Grid>
  </Popover>
));

export const RenderQuantityDataCell = ({ vehicleId, dataQuantity, searchStr, currQuantity, onChangeQuantity, partRow }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isNumeric = (value) => /^\d*$/.test(value);
  const isFieldChanged = isNumeric(currQuantity) && !isEditable && Number(dataQuantity) !== Number(currQuantity);
  const closingTimer = useRef(null);

  const toggleEdit = useCallback(
    (e, enable) => {
      // INFO: Save Data For Invalid Values On Exit Edit Event
      if (!enable && (!isNumeric(currQuantity) || currQuantity === "")) onChangeQuantity(dataQuantity);
      setIsEditable(enable);
      setAnchorEl(enable ? e.currentTarget : null);
    },
    [currQuantity, dataQuantity, onChangeQuantity]
  );

  const handleClose = useCallback(() => (closingTimer.current = setTimeout(() => toggleEdit(null, false), 100)), [toggleEdit]);
  const handlePopperFocus = useCallback(() => clearTimeout(closingTimer.current), []);
  const handleReset = () => onChangeQuantity(isNumeric(dataQuantity) ? Number(dataQuantity) : 0);

  const renderContentBasedOnEdit = () =>
    isEditable ? (
      <TextField
        onBlur={handleClose}
        onFocus={handlePopperFocus}
        autoFocus
        size="small"
        type="number"
        variant="outlined"
        value={isNumeric(currQuantity) ? currQuantity : dataQuantity}
        onChange={(e) => onChangeQuantity(e.target.value)}
        sx={{ maxWidth: "75px" }}
        onKeyDown={(e) => e.key === "Enter" && handleClose()}
        InputProps={{ inputProps: { inputMode: "numeric", min: 0 } }}
      />
    ) : (
      <Fragment>
        <div>{isFieldChanged ? currQuantity : highlightText(dataQuantity, searchStr)}</div>
        {!!isFieldChanged && (
          <UiIconButton
            title="Reset Quantity"
            iconColor="warning.main"
            arrow
            iconSize={18}
            sx={{ marginRight: "-42px" }}
            onClickIcon={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleReset();
            }}
          >
            <RestartAlt />
          </UiIconButton>
        )}
      </Fragment>
    );

  return (
    <MuiTooltip title={isFieldChanged ? null : "Double click to edit"} fontSize={10} arrow>
      <Box
        component="div"
        sx={{
          cursor: "pointer",
          ...quantityPopperCellStyles,
          ...(isFieldChanged && { backgroundColor: "warning.background", color: "warning.main", fontWeight: 600 }),
        }}
        onDoubleClick={(e) => toggleEdit(e, true)}
      >
        {renderContentBasedOnEdit()}
        <RenderChangeLogPopup
          open={isEditable}
          handleClose={handleClose}
          handlePopperFocus={handlePopperFocus}
          anchorEl={anchorEl}
          partId={partRow.part_id}
          vehicleId={vehicleId}
          currQuantity={isNumeric(currQuantity) ? currQuantity : dataQuantity}
          onChangeQuantity={onChangeQuantity}
        />
      </Box>
    </MuiTooltip>
  );
};

export const RenderSaveButton = memo(({ vehicleId, customDataId, refetchFn }) => {
  const customData = useSelector((state) => selectAllCustomDataWithPrimaryKey(state, customDataId));
  const currentData = useSelector((state) => selectAllCurrentDataWithPrimaryKey(state, customDataId));
  const isDataAltered = useSelector(selectHasInventoryPartsTableDataChanged);

  const { triggerMutation, data, isLoading, isError, error } = useUpdateVehiclePartsMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && data) {
      dispatch(setAlert(`Vehicle parts were updated successfully`, "Please wait while we fetch updated data", SUCCESS));
      refetchFn();
    }
  }, [dispatch, isLoading, data, refetchFn]);

  useEffect(() => {
    if (isError) {
      const serviceUnavailable = error.status === 503;
      dispatch(
        setAlert(serviceUnavailable ? error.data.title : "Inventory Parts Update Failed", error.data.message, serviceUnavailable ? ERROR : WARNING)
      );
    }
  }, [dispatch, isError, error]);

  const onSaveChanges = (e) => {
    e.preventDefault();
    const partsArrayOfObj = Array.from(new Set([...Object.keys(customData)])).map((key) => ({
      part_id: key,
      prev_qty: currentData[key].data,
      qty: customData[key].data,
    }));
    triggerMutation(vehicleId, partsArrayOfObj);
  };

  return (
    !!vehicleId && (
      <LoadingButton loading={isLoading} disabled={!isDataAltered} variant="outlined" sx={{ mr: 1.5 }} startIcon={<SaveAs />} onClick={onSaveChanges}>
        Save Changes
      </LoadingButton>
    )
  );
});

export const VehicleDetailsActionsButtonMenu = ({ children }) => {
  const dropdownChildren = React.Children.toArray(children);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleMenuOpen = (event) => setMenuAnchorEl(event.currentTarget);
  const handleMenuClose = () => setMenuAnchorEl(null);

  return (
    <div>
      {dropdownChildren.slice(0, 2)}
      {React.Children.count(children) > 2 && (
        <Fragment>
          <UiIconButton title="More Options" iconColor="text.secondary" onClickIcon={handleMenuOpen} iconSize={22}>
            <MoreVert />
          </UiIconButton>
          <MuiDropDownMenu
            anchorEl={menuAnchorEl}
            id="vehicle-more-actions-menu"
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {dropdownChildren.slice(2).map((child, index) => (
              <div key={index}>
                <div style={{ padding: "8px" }}>
                  <ListItem sx={{ p: 0 }}>{React.cloneElement(child, { handleMenuClose })}</ListItem>
                </div>
                {index < dropdownChildren.slice(2).length - 1 && <Divider />}
              </div>
            ))}
          </MuiDropDownMenu>
        </Fragment>
      )}
    </div>
  );
};
