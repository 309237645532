import { Box } from "@mui/material";
import { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import AdvancedTable from "../../components/advancedTable/AdvancedTable";
import { EmptyDataVisualComp, LoadingProgress } from "../../components/ui/UiUtils";
import { useGetBrowseFormTableColumnConfigMutation } from "../../hooks/ApiHooks";
import { useFetchDataEntryRecords } from "../../hooks/advancedTableHook";
import { generateBrowseTableConfig } from "./config/dataTableConfig";
import { selectActiveDataTypeForm, selectDataTypeFormId } from "./slices/ManageDataSlice";

const ManageFormTableData = memo(({ renderChipFieldsData, serverColumns }) => {
  const activeDataTypeForm = useSelector(selectActiveDataTypeForm);
  const tableConfig = generateBrowseTableConfig(activeDataTypeForm.id, activeDataTypeForm.label, serverColumns, renderChipFieldsData);
  return <AdvancedTable config={tableConfig} apiHookWithParams={[useFetchDataEntryRecords, [tableConfig.tableProps.columnProps]]} />;
});

export default function FormBrowseTable() {
  const { triggerMutation, isLoading, data, isError } = useGetBrowseFormTableColumnConfigMutation();
  const activeDataTypeFormId = useSelector(selectDataTypeFormId);
  useEffect(() => {
    if (activeDataTypeFormId) triggerMutation(activeDataTypeFormId);
  }, [triggerMutation, activeDataTypeFormId]);

  return (
    <Box sx={{ flex: 1, overflow: "auto" }}>
      {isLoading ? (
        <LoadingProgress />
      ) : isError ? (
        <EmptyDataVisualComp />
      ) : (
        !!data?.data && (
          <ManageFormTableData renderChipFieldsData={data.data?.["chip_data"] || {}} serverColumns={data.data?.["browse_fields"] || []} />
        )
      )}
    </Box>
  );
}
