import { Box, ListItemAvatar, ListItemText, Stack } from "@mui/material";
import { Fragment } from "react";
import { DATE_TIME_FORMAT, GROUP_BY_COLUMN_NAME, PRIMARY } from "../../../app/constants/common";
import { TASKS_VALUE_MAPPING, TASK_FIELDS } from "../../../app/constants/config/systemTasks";
import { PRIVATE_ROUTES, routeToSingleTask } from "../../../app/constants/routes";
import {
  createTableColumn,
  getAdjustedColorForContrast,
  highlightText,
  normalizeConfigForTable,
  serverToUi,
} from "../../../app/utils/utilityFunctions";
import { generalTableConfig } from "../../../components/config/generalTableConfig";
import { EmployeeAvatar, TableTimeStampDisplay } from "../../../components/ui/UiUtils";
import { ExportFullReport, RenderAssignedEmployee, RenderMappedTaskContent, RenderTaskTags } from "./CellComponents";

const createSearchBoolean = (DATA_KEY) => (dataValue, searchStr) =>
  String(TASKS_VALUE_MAPPING[DATA_KEY.ID][dataValue]?.label || "")
    .toLowerCase()
    .includes(searchStr);

const createRenderContent =
  (DATA_KEY, showKey = false, minWidth = "110px") =>
  (dataValue, searchStr) => {
    return (
      <RenderMappedTaskContent
        minWidth={minWidth}
        mappedData={TASKS_VALUE_MAPPING[DATA_KEY.ID][dataValue] || { nullValue: DATA_KEY.ALTERNATE }}
        searchStr={searchStr}
        {...(showKey && { dataValue })}
      />
    );
  };

const createDisplayRawContent =
  (DATA_KEY, showKey = false) =>
  (dataValue) =>
    `${TASKS_VALUE_MAPPING[DATA_KEY.ID][dataValue]?.label || DATA_KEY.ALTERNATE} ${showKey ? " - " + String(dataValue) : ""}`;

export const viewTasksTableColumns = [
  createTableColumn({
    id: TASK_FIELDS.CUSTOMER.ID,
    disabledColumnField: true,
  }),
  createTableColumn({
    id: TASK_FIELDS.STORE.ID,
    disabledColumnField: true,
  }),
  createTableColumn({
    id: TASK_FIELDS.EXECUTOR_EMPLOYEES.ID,
    disabledColumnField: true,
    searchBoolean: (dataValue, searchStr) => dataValue.some((name) => String(name).toLowerCase().includes(searchStr)),
    filterBoolean: (dataValue, filterValue) => dataValue.some((name) => name === filterValue),
  }),
  createTableColumn({
    id: TASK_FIELDS.OWNER.ID,
    disabledColumnField: true,
  }),
  createTableColumn({
    order: 1,
    id: TASK_FIELDS.TASK.ID,
    label: TASK_FIELDS.TASK.LABEL,
    align: "left",
    primaryKey: true,
    disablePadding: true,
  }),
  createTableColumn({
    order: 2,
    id: TASK_FIELDS.TITLE.ID,
    label: TASK_FIELDS.TITLE.LABEL,
    renderContent: (data, searchStr) => <span style={{ display: "inline-block", maxWidth: "235px" }}>{highlightText(data, searchStr)}</span>,
    align: "left",
  }),
  createTableColumn({
    order: 3,
    id: TASK_FIELDS.STORE_TITLE.ID,
    label: TASK_FIELDS.STORE_TITLE.LABEL,
  }),
  createTableColumn({
    order: 4,
    id: TASK_FIELDS.ASSIGNED_EMPLOYEES.ID,
    label: TASK_FIELDS.ASSIGNED_EMPLOYEES.LABEL,
    disablePadding: true,
    storeCustomDataOnRender: true,
    searchBoolean: (dataValue, searchStr) => dataValue.some((name) => String(name).toLowerCase().includes(searchStr)),
    filterBoolean: (dataValue, filterValue) => dataValue.some((name) => name === filterValue),
    renderContent: (dataValue, searchStr, openedProp, setOpenedProp) => (
      <RenderAssignedEmployee
        dataValue={dataValue}
        searchStr={searchStr}
        openedProp={openedProp}
        setOpenedProp={setOpenedProp}
        alternate={TASK_FIELDS.ASSIGNED_EMPLOYEES.ALTERNATE}
      />
    ),
    renderPDF: (name) => name.join("\n"),
    renderCSV: (name) => name.join("\n"),
  }),
  createTableColumn({
    order: 5,
    id: TASK_FIELDS.PRIORITY.ID,
    label: TASK_FIELDS.PRIORITY.LABEL,
    searchBoolean: createSearchBoolean(TASK_FIELDS.PRIORITY),
    renderContent: createRenderContent(TASK_FIELDS.PRIORITY, true),
    renderPDF: createDisplayRawContent(TASK_FIELDS.PRIORITY, true),
    renderCSV: createDisplayRawContent(TASK_FIELDS.PRIORITY, true),
  }),
  createTableColumn({
    order: 6,
    id: TASK_FIELDS.TYPE.ID,
    label: TASK_FIELDS.TYPE.LABEL,
    searchBoolean: createSearchBoolean(TASK_FIELDS.TYPE),
    renderContent: createRenderContent(TASK_FIELDS.TYPE),
    renderCSV: createDisplayRawContent(TASK_FIELDS.TYPE),
    renderPDF: createDisplayRawContent(TASK_FIELDS.TYPE),
  }),
  createTableColumn({
    order: 7,
    id: TASK_FIELDS.STATUS.ID,
    label: TASK_FIELDS.STATUS.LABEL,
    searchBoolean: createSearchBoolean(TASK_FIELDS.STATUS),
    renderContent: createRenderContent(TASK_FIELDS.STATUS, false, "145px"),
    renderCSV: createDisplayRawContent(TASK_FIELDS.STATUS),
    renderPDF: createDisplayRawContent(TASK_FIELDS.STATUS),
  }),
  createTableColumn({
    order: 8,
    id: TASK_FIELDS.START_TIME.ID,
    label: TASK_FIELDS.START_TIME.LABEL,
    alternateValue: TASK_FIELDS.START_TIME.ALTERNATE,
    searchBoolean: (dataValue, searchStr) => serverToUi(dataValue)?.toString().toLowerCase().includes(searchStr) ?? false,
    renderContent: (dateInput, searchStr) => (
      <TableTimeStampDisplay dateInput={dateInput} format={DATE_TIME_FORMAT.UI_FULL_FORMAT} searchStr={searchStr} />
    ),
    renderPDF: (dataValue) => serverToUi(dataValue, DATE_TIME_FORMAT.UI_FULL_FORMAT),
    renderCSV: (dataValue) => serverToUi(dataValue, DATE_TIME_FORMAT.UI_FULL_FORMAT),
    wrapText: false,
  }),
  createTableColumn({
    order: 9,
    id: TASK_FIELDS.END_TIME.ID,
    label: TASK_FIELDS.END_TIME.LABEL,
    alternateValue: TASK_FIELDS.START_TIME.ALTERNATE,
    searchBoolean: (dataValue, searchStr) => serverToUi(dataValue)?.toString().toLowerCase().includes(searchStr) ?? false,
    renderContent: (dateInput, searchStr) => (
      <TableTimeStampDisplay dateInput={dateInput} format={DATE_TIME_FORMAT.UI_FULL_FORMAT} searchStr={searchStr} />
    ),
    renderPDF: (dataValue) => serverToUi(dataValue, DATE_TIME_FORMAT.UI_FULL_FORMAT),
    renderCSV: (dataValue) => serverToUi(dataValue, DATE_TIME_FORMAT.UI_FULL_FORMAT),
    wrapText: false,
  }),
  createTableColumn({
    order: 10,
    id: TASK_FIELDS.TAGS.ID,
    label: TASK_FIELDS.TAGS.LABEL,
    searchBoolean: () => false,
    filterBoolean: (data, filterValue) => data.includes(filterValue),
    renderContent: (data = [], searchStr = "", theme) =>
      data.length ? (
        <RenderTaskTags theme={theme} data={data} searchStr={searchStr} configKey={TASK_FIELDS.TAGS.LIST_CONFIG_ID} />
      ) : (
        TASK_FIELDS.TAGS.ALTERNATE
      ),
    renderPDF: (data = []) => data.join(", "),
    renderCSV: (data = []) => data.join(", "),
  }),
].sort((a, b) => a.order - b.order);

export const tasksConfig = (id, title) =>
  normalizeConfigForTable(
    generalTableConfig({
      tableId: id,
      tableTitle: title,
      tableViewColumns: viewTasksTableColumns,
      addRowButtonNavigateOptions: {
        addRowNavigateToURL: true,
        addRowURL: PRIVATE_ROUTES.newTask,
      },
      tableRowClickNavigate: {
        tableClickNavigateToURL: true,
        tableRowURL: (id) => routeToSingleTask(id),
        navigateUsingRowAttribute: TASK_FIELDS.TASK.ID,
      },
      customExtraExportButtonOptions: [ExportFullReport],
      otherDefaultsVariables: {
        /* 
          @transformOptions: Values used to filter using the valueId and render display using valueLabel. Optionally, you may render display using renderOptionContent
                             Make sure to pass in the original data in the transformOptions along with valueId and valueLabel to access original data if needed.
          @otherAttrs: attributes that are part of choosing column to filter by
        */
        enableFiltersOnColumns: [
          {
            columnId: TASK_FIELDS.STORE.ID,
            columnLabel: TASK_FIELDS.STORE.LABEL,
            fetchOptionsById: TASK_FIELDS.STORE.LIST_CONFIG_ID,
            groupOptionsBy: GROUP_BY_COLUMN_NAME,
            transformOptions: (options = []) =>
              [...options]
                .map(({ store_id, store_id_ext, store_name, cust_id }) => ({
                  valueId: store_id,
                  valueLabel: `${store_name} - ${store_id_ext}`,
                  [GROUP_BY_COLUMN_NAME]: cust_id,
                }))
                .sort((a, b) => -a[GROUP_BY_COLUMN_NAME].localeCompare(b[GROUP_BY_COLUMN_NAME])),
          },
          {
            columnId: TASK_FIELDS.CUSTOMER.ID,
            columnLabel: TASK_FIELDS.CUSTOMER.LABEL,
            fetchOptionsById: TASK_FIELDS.CUSTOMER.LIST_CONFIG_ID,
            transformOptions: (options = []) =>
              options.map(({ cust_id, cust_name }) => ({
                valueId: cust_id,
                valueLabel: `${cust_name} - ${cust_id}`,
              })),
          },
          {
            columnId: TASK_FIELDS.OWNER.ID,
            columnLabel: TASK_FIELDS.OWNER.LABEL,
            fetchOptionsById: TASK_FIELDS.OWNER.LIST_CONFIG_ID,
            groupOptionsBy: GROUP_BY_COLUMN_NAME,
            transformOptions: (options = []) =>
              [...options]
                .map((employee) => ({
                  valueId: employee.name,
                  valueLabel: employee.name,
                  originalData: employee,
                  [GROUP_BY_COLUMN_NAME]: TASKS_VALUE_MAPPING[TASK_FIELDS.ASSIGNED_EMPLOYEES.ID][employee.role].label,
                }))
                .sort((a, b) => -a[GROUP_BY_COLUMN_NAME].localeCompare(b[GROUP_BY_COLUMN_NAME])),
            filterOptionsFunction: ({ originalData: { name, ern } = {} }, inputValue) =>
              [name, ern].some((entity) => String(entity).toLowerCase().includes(inputValue.toLowerCase())),
            renderOptionComponent: ({ option: { originalData: { name, ern } = {} } }) => (
              <Fragment>
                <ListItemAvatar>
                  <EmployeeAvatar empId={ern} />
                </ListItemAvatar>
                <ListItemText primary={name} secondary={ern} />
              </Fragment>
            ),
          },
          {
            columnId: TASK_FIELDS.EXECUTOR_EMPLOYEES.ID,
            columnLabel: TASK_FIELDS.EXECUTOR_EMPLOYEES.LABEL,
            fetchOptionsById: TASK_FIELDS.EXECUTOR_EMPLOYEES.LIST_CONFIG_ID,
            groupOptionsBy: GROUP_BY_COLUMN_NAME,
            transformOptions: (options = []) =>
              [...options]
                .map((employee) => ({
                  valueId: employee.name,
                  valueLabel: employee.name,
                  originalData: employee,
                  [GROUP_BY_COLUMN_NAME]: TASKS_VALUE_MAPPING[TASK_FIELDS.ASSIGNED_EMPLOYEES.ID][employee.role].label,
                }))
                .sort((a, b) => -a[GROUP_BY_COLUMN_NAME].localeCompare(b[GROUP_BY_COLUMN_NAME])),
            filterOptionsFunction: ({ originalData: { name, ern } = {} }, inputValue) =>
              [name, ern].some((entity) => String(entity).toLowerCase().includes(inputValue.toLowerCase())),
            renderOptionComponent: ({ option: { originalData: { name, ern } = {} } }) => (
              <Fragment>
                <ListItemAvatar>
                  <EmployeeAvatar empId={ern} />
                </ListItemAvatar>
                <ListItemText primary={name} secondary={ern} />
              </Fragment>
            ),
          },
          {
            columnId: TASK_FIELDS.ASSIGNED_EMPLOYEES.ID,
            columnLabel: TASK_FIELDS.ASSIGNED_EMPLOYEES.LABEL,
            fetchOptionsById: TASK_FIELDS.ASSIGNED_EMPLOYEES.LIST_CONFIG_ID,
            groupOptionsBy: GROUP_BY_COLUMN_NAME,
            transformOptions: (options = []) =>
              [...options]
                .map((employee) => ({
                  valueId: employee.name,
                  valueLabel: employee.name,
                  originalData: employee,
                  [GROUP_BY_COLUMN_NAME]: TASKS_VALUE_MAPPING[TASK_FIELDS.ASSIGNED_EMPLOYEES.ID][employee.role].label,
                }))
                .sort((a, b) => -a[GROUP_BY_COLUMN_NAME].localeCompare(b[GROUP_BY_COLUMN_NAME])),
            filterOptionsFunction: ({ originalData: { name, ern } = {} }, inputValue) =>
              [name, ern].some((entity) => String(entity).toLowerCase().includes(inputValue.toLowerCase())),
            renderOptionComponent: ({ option: { originalData: { name, ern } = {} } }) => (
              <Fragment>
                <ListItemAvatar>
                  <EmployeeAvatar empId={ern} />
                </ListItemAvatar>
                <ListItemText primary={name} secondary={ern} />
              </Fragment>
            ),
          },
          {
            columnId: TASK_FIELDS.PRIORITY.ID,
            columnLabel: TASK_FIELDS.PRIORITY.LABEL,
            fetchOptionsById: TASK_FIELDS.PRIORITY.LIST_CONFIG_ID,
            transformOptions: (options = []) =>
              [...options]
                .sort((a, b) => a.map_key - b.map_key)
                .map(({ map_key }) => ({
                  valueId: map_key,
                  valueLabel: `${map_key} - ${TASKS_VALUE_MAPPING[TASK_FIELDS.PRIORITY.ID][map_key].label}`,
                })),
          },
          {
            columnId: TASK_FIELDS.TYPE.ID,
            columnLabel: TASK_FIELDS.TYPE.LABEL,
            fetchOptionsById: TASK_FIELDS.TYPE.LIST_CONFIG_ID,
            transformOptions: (options = []) =>
              [...options]
                .sort((a, b) => -a.map_key.localeCompare(b.map_key))
                .map(({ map_key }) => ({
                  valueId: map_key,
                  valueLabel: `${TASKS_VALUE_MAPPING[TASK_FIELDS.TYPE.ID][map_key].label}`,
                })),
          },
          {
            columnId: TASK_FIELDS.STATUS.ID,
            columnLabel: TASK_FIELDS.STATUS.LABEL,
            fetchOptionsById: TASK_FIELDS.STATUS.LIST_CONFIG_ID,
            transformOptions: (options = []) =>
              [...options]
                .sort((a, b) => -a.map_key.localeCompare(b.map_key))
                .map(({ map_key, map_val }) => ({
                  valueId: map_key,
                  valueLabel: map_val,
                })),
          },
          {
            columnId: TASK_FIELDS.TAGS.ID,
            columnLabel: TASK_FIELDS.TAGS.LABEL,
            fetchOptionsById: TASK_FIELDS.TAGS.LIST_CONFIG_ID,
            transformOptions: (options = []) =>
              [...options]
                .sort((a, b) => -a.tag_id.localeCompare(b.tag_id))
                .map((tag) => ({
                  valueId: tag.tag_id,
                  valueLabel: tag.tag_name,
                  originalData: tag,
                })),

            renderOptionComponent: ({ option: { originalData: { tag_id, tag_name, tag_desc, tag_colour = PRIMARY } = {} }, theme }) => (
              <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                <ListItemText primary={tag_name} secondary={`${tag_id}${!!tag_desc && " - " + String(tag_desc)}`} />
                <Box sx={{ height: 24, width: 24, borderRadius: 1, backgroundColor: getAdjustedColorForContrast(tag_colour, theme)[0] }} />
              </Stack>
            ),
          },
        ],
      },
    })
  );
