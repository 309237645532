import { Box } from "@mui/material";
import { RichTextReadOnly } from "mui-tiptap";
import { useSelector } from "react-redux";
import { CONFIG_CONSTANTS_OPTIONS } from "../../app/constants/config/AppSettings";
import { updateTextEditorAnchorAttributes } from "../../app/utils/utilityFunctions";
import { selectPresetConfig } from "../../features/AppSettings/appSlice";
import { richTextEditorReadOnlyStyles } from "../../styled/inlineCssStyles";
import { extensions } from "./Extensions";

export default function ReadOnlyTextEditor({ content = "<p></p>", sx = {}, styleEnabled = true }) {
  const taskListOptions = useSelector((state) => selectPresetConfig(state, CONFIG_CONSTANTS_OPTIONS.TASKS));
  const employeeListOptions = useSelector((state) => selectPresetConfig(state, CONFIG_CONSTANTS_OPTIONS.EMPLOYEES));
  return (
    <Box sx={{ ...richTextEditorReadOnlyStyles, ...sx }}>
      <RichTextReadOnly
        content={updateTextEditorAnchorAttributes(content)}
        extensions={extensions(undefined, taskListOptions, employeeListOptions, styleEnabled)}
      />
    </Box>
  );
}
