export const AUTH_ACTION = {
  BASE: "/auth",
  LOGIN: "admin-login",
  LOGOUT: "sign_out",
  REFRESH: "refresh",
  RESET_PASSWORD_REQUEST: "request-reset-password",
  RESET_PASSWORD: "reset-password",
  SIGNUP: "sign-up",
  VERIFY_EMAIL: "verify-email",
};

export const TASKS_ACTION = {
  BASE: "/tasks",
  COUNT: "get-task-count",
  ITEMS: "view-all",
  TASKS_LIST: "get-task-list",
  TASKS_TREE_LIST: "get-child-task-list",
  CREATE_TASK: "create-task",
  UPDATE_TASK: "update-task",
  TASK_DETAILS: "get-details",
  UPDATE_TASK_PART: "update-task-part",
  ADD_COMMENT: "add-comment",
  EXPORT_REPORT: "export-report",
};

export const CONFIG_ACTION = {
  BASE: "/config",
  TASK_CONFIG: "get-data",
  FORM_LIST: "get-form-list",
  FORM_TABLE_COLUMNS: "get-browse-config",
  FORM_TABLE_RECORDS: "get-browse-records",
  FORM: "get-form",
  OPTIONS: "get-combosingle",
  SUBMIT_FORM: "submit-form",
  DASHBOARD: "get-dashboard",
  DELETE_DATA: "delete-form",
};

export const LOGGER_ACTION = {
  BASE: "/logger",
  LOG_ERROR: "log-error",
};

export const FILE_STORAGE = {
  BASE: "/file",
  DOWNLOAD_FILE: "download-file",
  UPLOAD_FILE: "upload-file",
  GET_KNOWLEDGE_BASE: "get-knowledge-base",
  DELETE_KNOWLEDGE_BASE: "delete-knowledge-base",
  UPLOAD_KNOWLEDGE_BASE: "upload-knowledge-base",
};

export const EMPLOYEE_MONITOR_ACTION = {
  BASE: "/monitoring",
  GET_COORDINATES: "get-all-last-location",
};

export const INVENTORY_ACTION = {
  BASE: "/inventory",
  GET_ALL_VEHICLES: "get-vehicle",
  GET_ALL_PARTS: "get-parts",
  REMOVE_VEHICLE_PARTS: "remove-vehicle-parts",
  UPDATE_VEHICLE_DETAILS: "update-vehicle-details",
  UPDATE_VEHICLE_PARTS: "update-vehicle-parts",
  GET_VEHICLE_PART_AUDIT: "get-vehicle-part-audit",
  GET_PARTS_USED_LIST: "get-parts-used-list",
  GET_VEHICLE_HISTORY: "get-vehicle-history",
  UNASSIGN_VEHICLE: "unassign-vehicle",
};

export const ATTENDANCE_ACTION = {
  BASE: "/attendance",
  GET_CONFLICTED_ATTENDANCE: "get-adjustment-requests",
  GET_ROUTINE_ATTENDANCE: "get-attendance",
  GET_OVERTIME_ATTENDANCE: "get-OT-list",
  GET_ATTENDANCE_STATS: "get-attendance-stats",
  RESOLVE_ATTENDANCE_CONFLICT: "resolve-adjustment-request",
  RESOLVE_OVERTIME_REQUEST: "process-OT",
};

export const PROFILE_ACTION = {
  BASE: "/profile",
  GET_USER_PROFILE: "get-profile",
  GET_USER_PROFILE_PICTURE: "get-profile-pic",
  SET_USER_PROFILE_PICTURE: "update-profile-pic",
  GET_NOTIFICATIONS: "get-notifications",
  DISMISS_NOTIFICATIONS: "dismiss-notifications",
};

export const API_LABEL = {
  BASE: "api/",
  PENDING: "/pending",
  SUCCESS: "/fulfilled",
  REJECTED: "/rejected",
};

export const EXCLUDE_API_FROM_INFLIGHT_COUNT = [
  PROFILE_ACTION.GET_NOTIFICATIONS,
  PROFILE_ACTION.GET_USER_PROFILE_PICTURE,
  FILE_STORAGE.DOWNLOAD_FILE,
];

export const CONNECTION_REFUSED_RESPONSE = {
  TITLE: "Connection Error",
  MESSAGE: "Unable to establish a server connection at the moment. Please check back shortly.",
};

export const COMMON_TRANSFORM_RESPONSE_FUNCTION = {
  transformResponse: ({ data }, _, originalArgs) => ({
    status: 200,
    originalArgs,
    data,
  }),
  transformErrorResponse: (response, _, arg) => {
    const fetchError = isNaN(response.status);
    return {
      status: fetchError ? 503 : response.status,
      originalArgs: arg,
      data: fetchError
        ? { title: CONNECTION_REFUSED_RESPONSE.TITLE, message: CONNECTION_REFUSED_RESPONSE.MESSAGE }
        : {
            message:
              response.data?.error && typeof response.data.error === "string"
                ? response.data.error
                : "Unexpected error encountered trying fulfil your request. Contact admin for information",
          },
    };
  },
};
