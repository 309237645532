import { Autorenew, DownloadForOffline, Warning } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { Fragment, memo, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { ERROR } from "../../app/constants/common";
import { setAlert } from "../../features/Alerts/slice/alertSlice";
import { useGetFileForRefMutation } from "../../hooks/ApiHooks";
import { MuiTooltip } from "../../styled/commonStyles";
import { StyledChip } from "../../styled/tableStyles";
import { LoadingProgress, UiIconButton } from "../ui/UiUtils";
import { RenderFileChip } from "./RenderFileChip";
import { downloadFilesAsZip } from "./fileUtils";

const RenderAttachedChip = memo(({ isLoading, isError, fileData, fileChipSx, color }) => {
  return isError || isLoading || !fileData?.signed_url || !fileData?.file_name || !fileData?.file_mime ? (
    <MuiTooltip title={isLoading ? "Loading..." : isError ? "Error Loading File" : null} fontSize={10}>
      <StyledChip
        icon={
          isLoading ? <LoadingProgress color={color} size={20} sx={{ width: "fit-content", ml: 0.5 }} /> : isError ? <Warning color={color} /> : null
        }
        label={isLoading ? "Loading..." : isError ? "Error" : fileData ? fileData.file_name : "Not Found"}
        variant="outlined"
        color={color}
        minWidth="fit-content"
        sx={{ borderRadius: 1, border: 0, "& .MuiChip-label": { textTransform: "none" }, ...fileChipSx }}
      />
    </MuiTooltip>
  ) : (
    <RenderFileChip fileData={fileData.signed_url} fileMime={fileData.file_mime} fileName={fileData.file_name} sx={fileChipSx} color={color} />
  );
});

const LoadAttachedFiles = memo(({ color = undefined, containerSx = {}, fileChipSx = {}, fileReferences = [], downloadAllRawName = "download" }) => {
  const dispatch = useDispatch();
  const [fileStatuses, setFileStatuses] = useState(
    fileReferences.map((fileRef) => ({
      fileRef,
      isLoading: true,
      isError: false,
      data: null,
    }))
  );
  const { triggerMutation, isLoading, isError, data } = useGetFileForRefMutation();

  useEffect(() => {
    if (fileReferences.length > 0) {
      triggerMutation(fileReferences);
    }
  }, [triggerMutation, fileReferences]);

  useEffect(() => {
    setFileStatuses(
      fileReferences.map((fileRef) => ({
        fileRef,
        isLoading,
        isError: !data?.data || isError,
        fileData: isError ? null : data?.data?.results.find((file) => file.file_ref === fileRef) || null,
      }))
    );
  }, [isLoading, isError, data, fileReferences]);

  const onDownloadAll = async () => {
    const response = await downloadFilesAsZip(
      fileStatuses.map(({ fileData }) => fileData),
      downloadAllRawName
    );
    if (response === "rejected") {
      dispatch(setAlert("Error Downloading Files", "Attempt to download files failed due to unexpected error. Please try again", ERROR));
    }
  };

  const onRefetchFiles = () => {
    setFileStatuses(fileReferences.map((fileRef) => ({ fileRef, isLoading: true, isError: false, fileData: null })));
    triggerMutation(fileReferences);
  };

  const allFilesLoaded = useMemo(() => fileStatuses.every(({ isLoading }) => !isLoading), [fileStatuses]);

  return (
    <Stack direction="row" alignItems="center" flexWrap="wrap" sx={containerSx}>
      {fileStatuses.map(({ fileRef, ...file }) => (
        <RenderAttachedChip key={fileRef} {...file} fileChipSx={fileChipSx} color={color} />
      ))}
      {!!allFilesLoaded && (
        <Fragment>
          {!!data?.data && !isError && (
            <UiIconButton
              title="Download All"
              iconColor={`${color}.main`}
              arrow
              titleFontSize={11}
              onClickIcon={onDownloadAll}
              iconSize={22}
              sx={{ "&:hover": { backgroundColor: "transparent" } }}
            >
              <DownloadForOffline />
            </UiIconButton>
          )}
          <UiIconButton
            title="Refetch Files"
            iconColor={`${color}.main`}
            arrow
            titleFontSize={11}
            onClickIcon={onRefetchFiles}
            sx={{ ml: -1, "&:hover": { backgroundColor: "transparent" } }}
            iconSize={22}
          >
            <Autorenew />
          </UiIconButton>
        </Fragment>
      )}
    </Stack>
  );
});

export default LoadAttachedFiles;