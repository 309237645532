import { AlternateEmail, Lock, Token, Visibility, VisibilityOff } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import { UiIconButton } from "../../../components/ui/UiUtils";

const AUTH_FIELD_CONFIG = {
  LOGIN_EMAIL: {
    id: "email",
    type: "email",
    fieldProps: {
      required: true,
      autoFocus: true,
      name: "email",
      id: "email",
      label: "Email",
      placeholder: "Enter your work email",
      autoComplete: "email",
      helperText: null,
    },
    inputProps: () => ({
      startAdornment: (
        <InputAdornment position="start">
          <AlternateEmail />
        </InputAdornment>
      ),
    }),
  },
  LOGIN_PASSWORD: {
    id: "password",
    type: "password",
    fieldProps: {
      required: true,
      name: "password",
      id: "password",
      label: "Password",
      placeholder: "Enter your password",
      autoComplete: "current-password",
      helperText: null,
    },
    inputProps: (pwVisible, setPwVisible) => ({
      startAdornment: (
        <InputAdornment position="start">
          <Lock />
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment position="end">
          <UiIconButton tabIndex={-1} iconSize={20} type="button" title={pwVisible ? "Hide Password" : "Show Password"} onClickIcon={setPwVisible}>
            {pwVisible ? <VisibilityOff /> : <Visibility />}
          </UiIconButton>
        </InputAdornment>
      ),
    }),
  },
  get NEW_PASSWORD() {
    return {
      ...this.LOGIN_PASSWORD,
      id: "new-password",
      fieldProps: {
        ...this.LOGIN_PASSWORD.fieldProps,
        name: "new-password",
        id: "new-password",
        label: "New Password",
        placeholder: "Enter your new password",
        autoComplete: "new-password",
      },
    };
  },
  get CONFIRM_PASSWORD() {
    return {
      ...this.LOGIN_PASSWORD,
      id: "confirm-password",
      fieldProps: {
        ...this.LOGIN_PASSWORD.fieldProps,
        name: "confirm-password",
        id: "confirm-password",
        label: "Confirm Password",
        autoComplete: "new-password",
        placeholder: "Confirm your new password",
      },
    };
  },
  TOKEN: {
    id: "token",
    type: "number",
    fieldProps: {
      required: true,
      autoFocus: true,
      name: "token",
      id: "token",
      label: "Confirmation Token",
      placeholder: "Enter the token sent via mail",
      autoComplete: "off",
      helperText: null,
    },
    inputProps: () => ({
      startAdornment: (
        <InputAdornment position="start">
          <Token />
        </InputAdornment>
      ),
    }),
  },
};

export const authLoginFields = [AUTH_FIELD_CONFIG.LOGIN_EMAIL, AUTH_FIELD_CONFIG.LOGIN_PASSWORD];
export const requestResetPasswordFields = [AUTH_FIELD_CONFIG.LOGIN_EMAIL];
export const resetPasswordFieldsExcludingEmail = [AUTH_FIELD_CONFIG.TOKEN, AUTH_FIELD_CONFIG.NEW_PASSWORD, AUTH_FIELD_CONFIG.CONFIRM_PASSWORD];
export const signupUserFields = [AUTH_FIELD_CONFIG.LOGIN_EMAIL, AUTH_FIELD_CONFIG.NEW_PASSWORD, AUTH_FIELD_CONFIG.CONFIRM_PASSWORD];
