import { ATTENDANCE_ADJUSTMENT, ROUTINE_ATTENDANCE } from "../../../features/EmployeeAttendance/config/attendanceUtils";
import { USER_ACTIVITY_ATTENDANCE_DETAILS, USER_ACTIVITY_CONFLICTED_DETAILS } from "../../../features/UserAnalysis/config/tableDetailsTabData";

export const adjustedApiTimeFormatConfig = (type) => {
  switch (type) {
    case USER_ACTIVITY_CONFLICTED_DETAILS:
    case ATTENDANCE_ADJUSTMENT:
      return {
        proposed: true,
        actual: true,
      };
    case USER_ACTIVITY_ATTENDANCE_DETAILS:
    case ROUTINE_ATTENDANCE:
      return {
        in: true,
        out: true,
      };
    default:
      return null;
  }
};
