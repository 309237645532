import { createSelector, createSlice } from "@reduxjs/toolkit";
import { API_LABEL, CONFIG_ACTION, EXCLUDE_API_FROM_INFLIGHT_COUNT, TASKS_ACTION } from "../../app/constants/api";
import { CONFIG_CONSTANTS_OPTIONS, THEME_MODE } from "../../app/constants/config/AppSettings";
import { employeeFallback } from "../../app/constants/fallback";
import { apiSlice } from "../../app/redux/apiSlice";

const appSlice = createSlice({
  name: "app",
  initialState: {
    themeMode: null,
    configApiStatus: {
      [CONFIG_ACTION.TASK_CONFIG]: true,
      [TASKS_ACTION.TASKS_LIST]: true,
    },
    lastFetchedConfigApi: null,
    filterOptionPreset: {},
    inFlightApiCount: 0,
  },
  reducers: {
    initializeThemeMode: (state, { payload: defaultPreferredMode }) => {
      const theme = localStorage.getItem(THEME_MODE.KEY) ?? (defaultPreferredMode ? THEME_MODE.DARK : THEME_MODE.LIGHT);
      if (!localStorage.getItem(THEME_MODE.KEY)) localStorage.setItem(THEME_MODE.KEY, theme);
      state.themeMode = theme;
    },

    toggleThemeMode: (state) => {
      const newThemeMode = state.themeMode === THEME_MODE.DARK ? THEME_MODE.LIGHT : THEME_MODE.DARK;
      localStorage.setItem(THEME_MODE.KEY, newThemeMode);
      state.themeMode = newThemeMode;
    },

    setApiPendingStatus: (state, { payload: [id, boolVal] }) => {
      if (id in state.configApiStatus) state.configApiStatus[id] = boolVal !== undefined && boolVal !== null ? boolVal : false;
    },

    setRefreshApiFlag: (state) => {
      state.lastFetchedConfigApi = new Date().getTime();
    },

    updateConfig: (state, { payload }) => {
      state.filterOptionPreset = {
        ...state.filterOptionPreset,
        ...payload,
      };
    },

    updateEmployeeObject: (state, { payload: [id, url] = [] }) => {
      if (Object.values(state.configApiStatus).some((val) => val)) return;
      if (!id || url === undefined) return;
      state.filterOptionPreset.employee_list = state.filterOptionPreset.employee_list.map((employee) =>
        employee.ern === id ? { ...employee, picture: url } : employee
      );
    },

    updateInFlightApiCount: (state, { payload }) => {
      if (payload === undefined) return;
      state.inFlightApiCount += payload ? 1 : -1;
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(apiSlice.endpoints.configAction.matchFulfilled, (state, { payload }) => {
        if (payload.originalArgs.action === CONFIG_ACTION.TASK_CONFIG) {
          appSlice.caseReducers.updateConfig(state, { payload: payload.data || {} });
          appSlice.caseReducers.setApiPendingStatus(state, { payload: [CONFIG_ACTION.TASK_CONFIG, false] });
        }
      })
      .addMatcher(apiSlice.endpoints.tasksAction.matchFulfilled, (state, { payload }) => {
        if (payload.originalArgs.action === TASKS_ACTION.TASKS_LIST) {
          appSlice.caseReducers.updateConfig(state, { payload: { [CONFIG_CONSTANTS_OPTIONS.TASKS]: payload.data || {} } });
          appSlice.caseReducers.setApiPendingStatus(state, { payload: [TASKS_ACTION.TASKS_LIST, false] });
        }
      })
      .addMatcher(apiSlice.endpoints.configAction.matchPending, (state, { meta }) => {
        if (meta.arg.originalArgs.action === CONFIG_ACTION.TASK_CONFIG) {
          appSlice.caseReducers.setApiPendingStatus(state, { payload: [CONFIG_ACTION.TASK_CONFIG, true] });
          appSlice.caseReducers.setRefreshApiFlag(state);
        }
      })
      .addMatcher(apiSlice.endpoints.tasksAction.matchPending, (state, { meta }) => {
        if (meta.arg.originalArgs.action === TASKS_ACTION.TASKS_LIST) {
          appSlice.caseReducers.setApiPendingStatus(state, { payload: [TASKS_ACTION.TASKS_LIST, true] });
        }
      })
      .addMatcher(
        (action) => action.type.endsWith(API_LABEL.PENDING) && action.type.startsWith(API_LABEL.BASE),
        (state, { meta }) => {
          if (EXCLUDE_API_FROM_INFLIGHT_COUNT.includes(meta.arg.originalArgs.action)) return;
          appSlice.caseReducers.updateInFlightApiCount(state, { payload: true });
        }
      )
      .addMatcher(
        (action) => action.type.startsWith(API_LABEL.BASE) && (action.type.endsWith(API_LABEL.SUCCESS) || action.type.endsWith(API_LABEL.REJECTED)),
        (state, { meta }) => {
          if (EXCLUDE_API_FROM_INFLIGHT_COUNT.includes(meta.arg.originalArgs.action)) return;
          appSlice.caseReducers.updateInFlightApiCount(state, { payload: false });
        }
      ),
});

export const { toggleThemeMode, updateEmployeeObject, initializeThemeMode } = appSlice.actions;

const appState = (state) => state.app;

export const selectIsThemeDark = createSelector([appState], (app) => app.themeMode === THEME_MODE.DARK);
export const selectPresetConfig = createSelector([appState, (_, configId) => configId], (app, configId) => app.filterOptionPreset[configId] || []);
export const isAppSettingLoading = createSelector([appState], (app) => Object.values(app.configApiStatus).some((val) => val));
export const selectLastFetchedConfigTime = createSelector([appState], (app) => app.lastFetchedConfigApi);
export const selectInFlightApiCount = createSelector([appState], (app) => app.inFlightApiCount || 0);
/*
Custom Select Function For New Task Form Feature
*/
export const findStoreByCustomerIdAndStoreId = createSelector(
  [(state) => selectPresetConfig(state, CONFIG_CONSTANTS_OPTIONS.STORE), (_, customerId) => customerId, (_, __, storeId) => storeId],
  (stores, selectedCustomerId, selectedStoreId) =>
    (selectedCustomerId &&
      selectedStoreId &&
      stores.find(({ cust_id, store_id }) => cust_id === selectedCustomerId && store_id === selectedStoreId)) ||
    null
);

export const findEquipmentById = createSelector(
  [(state) => selectPresetConfig(state, CONFIG_CONSTANTS_OPTIONS.EQUIPMENT), (_, equipmentId) => equipmentId],
  (equipments, selectedEquipmentId) => (selectedEquipmentId && equipments.find(({ eq_id }) => eq_id === selectedEquipmentId)) || null
);

export const findTaskById = createSelector(
  [(state) => selectPresetConfig(state, CONFIG_CONSTANTS_OPTIONS.TASKS), (_, taskId) => taskId],
  (tasks, taskId) => (tasks?.length && tasks.find(({ task_id }) => task_id === taskId)) || null
);

export const findEmployeeOptionById = createSelector(
  [(state) => selectPresetConfig(state, CONFIG_CONSTANTS_OPTIONS.EMPLOYEES), (_, employeeId) => employeeId],
  (employees, employeeId) => (employees?.length && employees.find(({ ern }) => ern === employeeId)) || employeeFallback
);

export const selectFieldOptionsByCurrentValue = createSelector(
  [appState, (_, configId) => configId, (_, __, currentValue) => currentValue],
  (app, configId, currentValue) => app.filterOptionPreset?.[configId]?.[currentValue] || []
);

export default appSlice.reducer;
