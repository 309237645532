import { Clear, FilterAltOffOutlined, FilterAltOutlined, LibraryAdd, Search, Tune } from "@mui/icons-material";
import { Button, Checkbox, Divider, InputAdornment, ListItemText, MenuItem, TablePagination, TextField, Typography } from "@mui/material";
import { debounce } from "lodash";
import { Fragment, createElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { filterDataBasedOnAttributes } from "../../app/utils/middleware";
import { MuiBadge, MuiTooltip } from "../../styled/commonStyles";
import {
  selectActiveColumnsIds,
  selectActiveFilters,
  selectActiveFiltersCount,
  selectFilterTableStatus,
  selectOriginalColumns,
  selectPage,
  selectRowCount,
  selectRowsPerPage,
  selectRowsPerPageOptions,
  selectSearchString,
  selectSelectedRowsCount,
  setActiveShowColumns,
  setFilterTableStatus,
  setPaginationChange,
  setSearchString,
} from "../slices/advancedTable";
import FilterDataDialogModal from "../ui/FilterDataModal";
import { SelectOptionsField } from "../ui/InputUI";
import { UiIconButton } from "../ui/UiUtils";

export const SearchBar = ({
  columnProps,
  searchConfig: { inputPlaceholder, applyOrClearFilterButton, clearSearchDisplay, clearSearchIconLabel },
}) => {
  const dispatch = useDispatch();
  const search = useSelector(selectSearchString);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => setInputValue(search), [search]);
  useEffect(() => {
    const debouncedSearch = debounce((searchValue) => {
      if (searchValue === "" || searchValue.trim() !== "") {
        dispatch(filterDataBasedOnAttributes({ payload: { searchStr: searchValue }, columnProps }));
        dispatch(setSearchString(searchValue));
      }
    }, 400);

    debouncedSearch(inputValue);

    return () => debouncedSearch.cancel();
  }, [inputValue, dispatch, columnProps]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const clearSearch = () => {
    setInputValue("");
    dispatch(setSearchString(""));
  };

  return (
    <TextField
      name="search_table"
      id="search_table"
      type="text"
      placeholder={inputPlaceholder || "Search Data"}
      sx={{
        flex: 1,
        fontSize: "1.05rem",
        backgroundColor: "primary.sectionContainer",
        borderRadius: 1,
        "& .MuiInputBase-root": { paddingLeft: "12px", paddingRight: "4px" },
        "& .MuiInputBase-input": { py: "12.5px" },
      }}
      value={inputValue}
      onChange={handleChange}
      size="medium"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search sx={{ color: "text.primary" }} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {!!clearSearchDisplay && !!inputValue && (
              <UiIconButton title={clearSearchIconLabel} iconColor="text.secondary" onClickIcon={clearSearch} iconSize={22}>
                <Clear />
              </UiIconButton>
            )}
            {!!clearSearchDisplay && !!inputValue && <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />}
            {!!applyOrClearFilterButton && <ApplyOrClearFilterButtonComponent columnProps={columnProps} />}
          </InputAdornment>
        ),
      }}
    />
  );
};

export const SelectableColumnsComponent = () => {
  const dispatch = useDispatch();
  const originalColumns = useSelector(selectOriginalColumns);
  const activeColumnIds = useSelector(selectActiveColumnsIds);

  const handleChange = (value) => dispatch(setActiveShowColumns(value));

  return (
    <SelectOptionsField
      label="Show Columns"
      value={activeColumnIds}
      onChange={handleChange}
      multiple
      fieldStyleProps={{ m: 1, width: 250 }}
      renderValue={(selected) =>
        selected.length === originalColumns.length
          ? "All Columns"
          : originalColumns
              .filter((column) => selected.includes(column.id))
              .map((selectedColumn) => selectedColumn.label)
              .join(", ")
      }
    >
      {originalColumns.map((column) => (
        <MenuItem key={column.id} value={column.id} disabled={column.primaryKey}>
          <Checkbox checked={activeColumnIds.includes(column.id)} />
          <ListItemText primary={column.label} />
        </MenuItem>
      ))}
    </SelectOptionsField>
  );
};

export const MuiTablePagination = ({ config: { paginationTitle } }) => {
  const dispatch = useDispatch();
  const rowLength = useSelector(selectRowCount);
  const page = useSelector(selectPage);
  const rowsPerPage = useSelector(selectRowsPerPage) || rowLength;
  const rowsPerPageOptions = useSelector(selectRowsPerPageOptions) || [rowLength];
  const handleChangePage = (_, newPage) => dispatch(setPaginationChange({ newPage }));
  const handleChangeRowsPerPage = (event) => dispatch(setPaginationChange({ newPage: 0, rowsPerPage: parseInt(event.target.value, 10) }));

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={rowLength}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage={paginationTitle}
      slotProps={{
        select: {
          sx: {
            borderRadius: "4px",
            border: "1px solid",
            borderColor: "primary.sectionBorder600",
          },
        },
      }}
      sx={{
        "& .MuiToolbar-root": { pl: 0 },
        "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows": {
          fontSize: "0.95rem",
        },
        "& .MuiTablePagination-spacer": {
          display: "none",
        },
      }}
    />
  );
};

export const ApplyOrClearFilterButtonComponent = ({ columnProps }) => {
  const filterTable = useSelector(selectFilterTableStatus);
  const activeFilters = useSelector(selectActiveFilters);
  const dispatch = useDispatch();
  const handleFilterClick = () =>
    dispatch(
      filterDataBasedOnAttributes({
        payload: { filterTable: !filterTable },
        columnProps,
        dispatchFn: () => setFilterTableStatus(!filterTable),
      })
    );

  return (
    <UiIconButton
      title={activeFilters.length === 0 ? "" : `Current Data View : ${filterTable ? "Filtered" : "Original"}`}
      disabled={activeFilters.length === 0}
      onClickIcon={handleFilterClick}
      iconSize={22}
    >
      {filterTable ? <FilterAltOffOutlined /> : <FilterAltOutlined />}
    </UiIconButton>
  );
};

export const SelectedRowsTitleComponent = ({ config }) => {
  const selectedRowsLength = useSelector(selectSelectedRowsCount);
  const rCount = useSelector(selectRowCount);
  return (
    <Typography variant="h6" component="div" sx={{ color: "primary.main", fontWeight: 500 }}>
      {config.display ? `${selectedRowsLength} ${config.title}` : `Total Records: ${rCount}`}
    </Typography>
  );
};

export const AddNewRowButtonComponent = ({ config: { navigateToURL, url, title, hasAddRowDialog, newRowDialogComponent }, refetchFn }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const nav = useNavigate();
  const onHandleClick = () => {
    if (hasAddRowDialog) setOpenDialog(true);
    if (navigateToURL) nav(url);
  };

  return (
    <Fragment>
      <UiIconButton title={title} onClickIcon={onHandleClick}>
        <LibraryAdd />
      </UiIconButton>
      {!!hasAddRowDialog && !!openDialog && createElement(newRowDialogComponent, { onHandleClose: () => setOpenDialog(false), refetchFn })}
    </Fragment>
  );
};

export const CustomizeFilterOptionsButton = ({ columnProps, filterTableConfig }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const filterCount = useSelector(selectActiveFiltersCount);
  const isFilterActive = useSelector(selectFilterTableStatus);

  return (
    <Fragment>
      <MuiTooltip title={filterCount && isFilterActive ? `Active Filters : ${filterCount}` : null} fontSize={11}>
        <MuiBadge
          badgeContent={isFilterActive ? filterCount : 0}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          color={(theme) => theme.palette.text.reverse}
          backgroundColor={(theme) => theme.palette.primary.main}
          bottom="6px"
          right="6px"
        >
          <Button variant="outlined" sx={{ fontWeight: 600, mx: 0.5 }} startIcon={<Tune />} onClick={() => setDialogOpen(true)}>
            Customize Filters
          </Button>
        </MuiBadge>
      </MuiTooltip>
      {!!dialogOpen && (
        <FilterDataDialogModal columnProps={columnProps} filterTableConfig={filterTableConfig} handleClose={() => setDialogOpen(false)} />
      )}
    </Fragment>
  );
};
