import { Autorenew, Edit } from "@mui/icons-material";
import { alpha, Grid, ListItemText, Skeleton, Stack } from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DATE_TIME_FORMAT } from "../../app/constants/common";
import { MANAGE_DATA_FORM_TYPE } from "../../app/constants/config/DynamicFields";
import { routeToManageDataForm, routeUserAnalysisParam } from "../../app/constants/routes";
import { serverDate, uiToServer } from "../../app/utils/utilityFunctions";
import { EmployeeAvatar, EmptyDataVisualComp, StatisticBoard, UiIconButton } from "../../components/ui/UiUtils";
import { useGetAttendanceStatsMutation, useGetUserProfileDetailsMutation } from "../../hooks/ApiHooks";
import { sectionUiLookBase } from "../../styled/inlineCssStyles";
import { profileCards, profileDisplayIcons } from "./config/profileUtils";
import { selectUserAnalysisSelectedDateRange, selectUserAnalysisTasksCount } from "./slice/userAnalysisSlice";

const ProfileDetailsSkeletonLoader = () => (
  <Fragment>
    <Stack direction="row" gap={3} alignItems="center" flexWrap="wrap">
      <Skeleton variant="circular" width={140} height={140} animation="wave" />
      <Stack sx={{ flex: 1, height: "100%" }} gap={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <ListItemText
            primary={<Skeleton width="60%" height={28} animation="wave" />}
            secondary={<Skeleton width="40%" height={26} animation="wave" />}
            disableTypography
          />
          <Skeleton variant="rectangular" width={24} height={24} />
        </Stack>
        <Grid container>
          {[...Array(Object.keys(profileDisplayIcons).length)].map((_, index) => (
            <Grid
              item
              key={index}
              gap={1.5}
              sx={{ display: "flex", flexDirection: "row", alignItems: "center", pr: 2 }}
              {...{ xs: 12, sm: 12, md: 6, lg: 6, xl: 4 }}
            >
              <Skeleton variant="rectangular" width={24} height={24} animation="pulse" />
              <ListItemText
                primary={<Skeleton width="50%" height={17} animation="wave" />}
                secondary={<Skeleton width="70%" height={23} animation="wave" />}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Stack>
    <Stack gap={2} direction="row" alignItems="center" justifyContent="stretch" flexWrap="wrap">
      {[...Array(4)].map((_, index) => (
        <Skeleton key={index} height={116} variant="rounded" sx={{ flex: 1 }} animation="wave" />
      ))}
    </Stack>
  </Fragment>
);

const ProfileCards = ({ activeErn }) => {
  const [statConfig, setStatConfig] = useState(profileCards());
  const { triggerMutation, isLoading, data } = useGetAttendanceStatsMutation();
  const { from, to } = useSelector(selectUserAnalysisSelectedDateRange);
  const tasksCount = useSelector(selectUserAnalysisTasksCount);

  useEffect(() => {
    triggerMutation(uiToServer(serverDate(from), DATE_TIME_FORMAT.SERVER_DATE), uiToServer(serverDate(to), DATE_TIME_FORMAT.SERVER_DATE), activeErn);
  }, [triggerMutation, from, to, activeErn]);

  useEffect(() => {
    if (!isLoading && data?.data) {
      setStatConfig(profileCards({ ...data.data, tasksCount }));
    }
  }, [isLoading, data?.data, tasksCount]);

  return (
    <Stack gap={2} direction="row" alignItems="center" justifyContent="stretch" flexWrap="wrap">
      {Object.values(statConfig).map(({ id, ...restProps }) => (
        <StatisticBoard
          cardProps={{ variant: "elevation", elevation: 3 }}
          statBoxSx={{ flex: 1, border: "1px solid", borderColor: "primary.sectionBorder600", minWidth: 250, height: "fit-content" }}
          key={id}
          loading={isLoading}
          {...restProps}
        />
      ))}
    </Stack>
  );
};

export default function UserProfileView() {
  const { [routeUserAnalysisParam]: activeErn } = useParams();
  const nav = useNavigate();
  const { triggerMutation, isLoading, isError, data } = useGetUserProfileDetailsMutation();
  const [profileData, setProfileData] = useState({ config: [], profile: {} });

  const refetchData = useCallback(() => {
    triggerMutation(activeErn);
  }, [triggerMutation, activeErn]);

  useEffect(() => refetchData(), [refetchData]);
  useEffect(() => {
    if (!isLoading && data)
      setProfileData({
        config: data.data.form_fields
          .map(({ fld_col_name: id, fld_disp_name: label }) => profileDisplayIcons[id] && { id, label, icon: profileDisplayIcons[id] })
          .filter(Boolean),
        profile: data.data.form_data,
      });
  }, [isLoading, data, setProfileData]);

  return (
    <Stack
      gap={2}
      sx={{
        my: 2,
        mx: 3,
        p: 3,
        ...sectionUiLookBase,
        background: (theme) =>
          `linear-gradient(200deg, ${alpha(theme.palette.primary.main, 0.12)} 0%, ${alpha(theme.palette.background.paper, 1)} 60%)`,
        borderRadius: 4,
        border: (theme) => `1px solid ${alpha(theme.palette.primary.main, 0.12)}`,
      }}
    >
      {isLoading ? (
        <ProfileDetailsSkeletonLoader />
      ) : isError ? (
        <EmptyDataVisualComp />
      ) : (
        profileData.config.length > 0 && (
          <Fragment>
            <Stack direction="row" gap={3} alignItems="center" flexWrap="wrap" justifyContent="center">
              <EmployeeAvatar empId={activeErn} size="140px" fontSize="30px" sx={{ fontWeight: 600 }} />
              <Stack sx={{ flex: 1, height: "100%" }} gap={2}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <ListItemText
                    primary={profileData.profile.name}
                    secondary={profileData.profile.ern}
                    primaryTypographyProps={{ fontSize: "1.3rem", fontWeight: 600, color: "primary" }}
                    secondaryTypographyProps={{ variant: "h6", color: "text.primary" }}
                  />
                  <Stack direction="row" alignItems="center" justifyContent="stretch">
                    <UiIconButton
                      title="Edit Profile"
                      arrow
                      onClickIcon={() => nav(routeToManageDataForm("employee", MANAGE_DATA_FORM_TYPE.UPDATE, activeErn))}
                    >
                      <Edit />
                    </UiIconButton>
                    <UiIconButton title="Re-fetch Employee Data" arrow onClickIcon={refetchData}>
                      <Autorenew />
                    </UiIconButton>
                  </Stack>
                </Stack>
                <Grid container>
                  {profileData.config.map(({ id, icon, label }) => (
                    <Grid
                      item
                      key={id}
                      gap={1.5}
                      sx={{ display: "flex", flexDirection: "row", alignItems: "center", pr: 2 }}
                      {...{ xs: 12, sm: 12, md: 6, lg: 6, xl: 4 }}
                    >
                      {icon}
                      <ListItemText
                        primary={label}
                        secondary={profileData.profile[id] || "N/A"}
                        primaryTypographyProps={{ noWrap: true, variant: "body2", color: "text.secondary" }}
                        secondaryTypographyProps={{ variant: "subtitle1", color: "text.primary" }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            </Stack>
            <ProfileCards activeErn={activeErn} />
          </Fragment>
        )
      )}
    </Stack>
  );
}
