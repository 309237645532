import Link from "@tiptap/extension-link";
import Mention from "@tiptap/extension-mention";
import Placeholder from "@tiptap/extension-placeholder";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { PluginKey } from "@tiptap/pm/state";
import StarterKit from "@tiptap/starter-kit";
import {
  MenuButtonBlockquote,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonHorizontalRule,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonRedo,
  MenuButtonStrikethrough,
  MenuButtonTaskList,
  MenuButtonUnderline,
  MenuButtonUndo,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  MenuSelectTextAlign,
} from "mui-tiptap";
import { Fragment, memo } from "react";
import { routeToSingleTask, routeToSingleUserAnalysisPage } from "../../app/constants/routes";
import { SuggestionOptionsTasks } from "./SuggestionOptionsRender";
import TasksSuggestionListUI from "./TasksSuggestionListUi";
import UsersSuggestionListUI from "./UsersSuggestionListUI";

export const extensions = (placeholder = "Type Description Here...", taskListOptions = [], employeeListOptions = [], styleEnabled) => [
  Placeholder.configure({ placeholder }),
  ...(styleEnabled
    ? [
        StarterKit,
        Link.configure({
          validate: (href) => /^https?:\/\//.test(href),
          openOnClick: false,
        }),
        Underline,
        TaskList,
        TaskItem,
        TextAlign.configure({ types: ["heading", "paragraph"] }),
        Mention.extend({
          name: "taskMention",
        }).configure({
          renderHTML({ node }) {
            return ["a", { target: "_blank", class: "ms", href: routeToSingleTask(node.attrs.id) }, `task/${node.attrs.id}`];
          },
          suggestion: {
            char: "!",
            pluginKey: new PluginKey("taskMention"),
            ...SuggestionOptionsTasks(
              taskListOptions.map(({ task_id: id, task_title: label }) => ({ id, label })),
              TasksSuggestionListUI
            ),
          },
        }),
        Mention.extend({
          name: "userMention",
        }).configure({
          renderHTML({ options, node }) {
            return [
              "a",
              { target: "_blank", class: "ms", href: routeToSingleUserAnalysisPage(node.attrs.id) },
              `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`,
            ];
          },
          suggestion: {
            char: "@",
            pluginKey: new PluginKey("userMention"),
            ...SuggestionOptionsTasks(
              employeeListOptions.map(({ ern: id, name: label }) => ({ id, label })),
              UsersSuggestionListUI
            ),
          },
        }),
      ]
    : [
        StarterKit.configure({
          blockquote: false,
          bold: false,
          italic: false,
          history: false,
          strike: false,
          bulletList: false,
          code: false,
          codeBlock: false,
          heading: false,
          listItem: false,
          orderedList: false,
          hardBreak: false,
          horizontalRule: false,
        }),
      ]),
];

export const BaseEditorMenuControls = memo(({ styleEnabled, children }) => (
  <MenuControlsContainer>
    {!!styleEnabled && (
      <Fragment>
        <MenuSelectHeading />
        <MenuDivider />
        <MenuButtonBold />
        <MenuButtonItalic />
        <MenuButtonUnderline />
        <MenuButtonStrikethrough />
        <MenuDivider />
        <MenuSelectTextAlign />
        <MenuDivider />
        <MenuButtonOrderedList />
        <MenuButtonBulletedList />
        <MenuButtonTaskList tooltipLabel="Add Sub-Task Checklist" />
        <MenuDivider />
        <MenuButtonBlockquote />
        <MenuButtonHorizontalRule />
        <MenuDivider />
        <MenuButtonUndo />
        <MenuButtonRedo />
        <MenuDivider />
      </Fragment>
    )}
    {children}
  </MenuControlsContainer>
));
