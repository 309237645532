import { Chip, TableCell, TableRow, tableCellClasses, tableRowClasses } from "@mui/material";
import { alpha, styled } from "@mui/system";
import React from "react";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.sectionContainer,
    fontWeight: 500,
    textTransform: "capitalize",
    color: theme.palette.text.primary,
    fontSize: 15,
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 400,
    color: "inherit",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&:not(.${tableRowClasses.head})`]: {
    borderTop: 0,
    borderBottom: 0,
    borderLeft: "2px solid",
    borderRight: "2px solid",
    borderColor: "transparent",

    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.primary.sectionContainer,
    },
  },
  "& td, & th, & a": {
    border: 0,
    textDecoration: "none",
  },
}));

export const StyledChip = styled(
  // eslint-disable-next-line no-unused-vars
  React.forwardRef(({ color, backgroundColor, fontSize, minWidth, variant = "outlined", size = "small", ...props }, ref) => (
    <Chip component="div" ref={ref} size={size} variant={variant} {...props} />
  ))
)(({ theme, color, backgroundColor = "primary", variant = "outlined", fontSize = 12, minWidth = "110px" }) => ({
  "&.MuiChip-root": {
    minWidth,
    backgroundColor: `${theme.palette[color ?? backgroundColor]?.background ?? backgroundColor} !important`,
    color: `${theme.palette[color]?.main ?? color} !important`,
    ...(variant === "outlined" && { borderColor: `${alpha(theme.palette[color]?.main ?? color, 0.7)} !important` }),
    "& .MuiSvgIcon-root, & .MuiIcon-root": {
      color: `${theme.palette[color]?.main ?? color} !important`,
    },
  },
  "& .MuiChip-label": {
    fontSize: fontSize,
    textTransform: "capitalize",
    fontWeight: 500,
    textAlign: "center",
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
}));
