import { FileDownload } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { sectionUiLookBase } from "../../styled/inlineCssStyles";
import { LoadingProgress, Transition, UiIconButton } from "../ui/UiUtils";
import { VIEW_IMAGE, VIEW_PDF, VIEW_VIDEO } from "./fileUtils";

export default function ViewFileDialog({ onClose, downloadFileFn, blobFileSrc, viewType, isViewable, loading = false, error = false }) {
  const [src, setSrc] = useState(null);
  useEffect(() => {
    let objectURL;
    if (blobFileSrc) {
      objectURL = URL.createObjectURL(blobFileSrc);
      setSrc(objectURL);
    }
    return () => URL.revokeObjectURL(objectURL);
  }, [blobFileSrc]);

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={true}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{ sx: sectionUiLookBase }}
      scroll="paper"
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography fontSize="inherit" fontWeight="inherit" color="inherit">
          View File
        </Typography>
        <UiIconButton title="Download File" titleFontSize={10} onClickIcon={downloadFileFn} iconSize={24} loading={loading}>
          <FileDownload />
        </UiIconButton>
      </DialogTitle>
      {loading ? (
        <LoadingProgress sx={{ minHeight: "200px" }} />
      ) : error ? (
        <Typography sx={{ textAlign: "center" }} variant="h6">
          Error Loading Media File
        </Typography>
      ) : (
        <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", overflow: "hidden" }}>
          {isViewable ? (
            viewType === VIEW_IMAGE ? (
              <img src={src} alt="Image File" style={{ maxHeight: "750px", maxWidth: "100%", height: "auto", width: "auto", objectFit: "cover" }} />
            ) : viewType === VIEW_VIDEO ? (
              <video src={src} controls autoPlay style={{ maxHeight: "750px", maxWidth: "100%", height: "auto", width: "auto", objectFit: "cover" }}>
                Your browser does not support the video type
              </video>
            ) : viewType === VIEW_PDF ? (
              <iframe src={src} style={{ height: "100vh", width: "100%", border: "none", overflow: "hidden" }}>
                Unable to load the PDF
              </iframe>
            ) : (
              <Typography variant="h6">Unknown File Type Detected. Download to Open</Typography>
            )
          ) : (
            <Typography variant="h6">File Type Cannot Be Viewed. Download to View</Typography>
          )}
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
