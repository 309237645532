export const PUBLIC_ROUTES = {
  homeRoute: "/",
  login: "/auth/login",
  resetPassword: "/auth/reset-password",
  signup: "/auth/signup",
  verifyEmail: "/auth/verify",
};

export const PRIVATE_ROUTES = {
  dashboard: "/home",
  tasks: "/task-list",
  singleTask: "/task/detail/:taskId",
  newTask: "/tasks/create",
  employeeLocation: "/employee/location",
  userProfile: "/user/profile",
  userAnalysis: "/user/data-analysis/:userId?",
  employeeAttendance: "/employee/attendance",
  vehicleInventory: "/inventory/vehicle/:vehicleId?",
  vehicleInventoryReport: "/inventory/report",
  manageDataInventory: "/inventory/manage/:datatype?",
  manageDataFormAddition: "/inventory/manage/:datatype/use-form/:formType/:keycol?",
  knowledgeBaseFile: "/inventory/knowledge-base/files",
  errorRoute: "/caught/error404",
};

export const routeTaskParam = "taskId";
export const routeVehicleParam = "vehicleId";
export const routeManageDataDataTypeParam = "datatype";
export const routeManageDataFormTypeParam = "formType";
export const routeManageDataKeycolParam = "keycol";
export const routeUserAnalysisParam = "userId";
export const verifyEmailQueryParam = "email";
export const nextURLLocationParam = "next";
export const routeToSingleTask = (id) => PRIVATE_ROUTES.singleTask.replace(`:${routeTaskParam}`, id);
export const routeToInventoryVehicle = (id) => PRIVATE_ROUTES.vehicleInventory.replace(`:${routeVehicleParam}?`, id);
export const routeToInventoryTableType = (type) => (type === "vehicle" ? routeToInventoryVehicle("") : PRIVATE_ROUTES.vehicleInventoryReport); 
export const routeToManageDataTableEntries = (id) =>
  PRIVATE_ROUTES.manageDataInventory.replace(`:${routeManageDataDataTypeParam}?`, id.toLowerCase());
export const routeToSingleUserAnalysisPage = (id) => PRIVATE_ROUTES.userAnalysis.replace(`:${routeUserAnalysisParam}?`, id);

export const routeToManageDataForm = (dataTypeId, formType, keycol = "") =>
  PRIVATE_ROUTES.manageDataFormAddition
    .replace(`:${routeManageDataDataTypeParam}`, dataTypeId.toLowerCase())
    .replace(`:${routeManageDataFormTypeParam}`, formType.toLowerCase())
    .replace(`:${routeManageDataKeycolParam}`, keycol);
