import { Box, MenuItem } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PRIVATE_ROUTES, routeTaskParam, routeToInventoryTableType } from "../../app/constants/routes";
import { matchRoute } from "../../app/utils/utilityFunctions";
import { selectStockManagementPageType, setStockManagementPageType } from "../../features/InventoryManagement/slice/inventorySlice";
import { SelectOptionsField } from "./InputUI";

export const SingleTaskLabel = () => {
  const { [routeTaskParam]: id } = useParams();
  return `System Task : ${id}`;
};

export const InventoryManagementToggleSelector = ({ title }) => {
  const selectedPageType = useSelector(selectStockManagementPageType);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const location = useLocation();

  const onChange = (value) => {
    dispatch(setStockManagementPageType(value));
    nav(routeToInventoryTableType(value));
  };

  useEffect(() => {
    dispatch(setStockManagementPageType(matchRoute(location.pathname, PRIVATE_ROUTES.vehicleInventoryReport).isMatch ? "inventory" : "vehicle"));
  }, [dispatch, location.pathname]);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Box component="div">{title}</Box>
      <SelectOptionsField
        label={null}
        value={selectedPageType}
        onChange={onChange}
        size="small"
        fieldStyleProps={{
          backgroundColor: "primary.sectionContainer",
          m: 0.5,
          maxWidth: "120px",
        }}
      >
        <MenuItem value="vehicle">Vehicle</MenuItem>
        <MenuItem value="inventory">Inventory</MenuItem>
      </SelectOptionsField>
    </Box>
  );
};
