import { TAB_OPTIONS } from "../../../app/constants/config/systemTasks";
import { tasksConfig } from "./viewTasks";

export const tabItems = [
  {
    id: TAB_OPTIONS.OPENED_TASKS.ID,
    label: TAB_OPTIONS.OPENED_TASKS.TAB_LABEL,
    isLoading: true,
    count: 0,
  },
  {
    id: TAB_OPTIONS.COMPLETED_TASKS.ID,
    label: TAB_OPTIONS.COMPLETED_TASKS.TAB_LABEL,
    isLoading: true,
    count: 0,
  },
  {
    id: TAB_OPTIONS.ALL_TASKS.ID,
    label: TAB_OPTIONS.ALL_TASKS.TAB_LABEL,
    isLoading: true,
    count: 0,
  },
];

export const slicesConfigReferenceMap = {
  [TAB_OPTIONS.OPENED_TASKS.ID]: {
    id: TAB_OPTIONS.OPENED_TASKS.ID,
    taskStatusArray: TAB_OPTIONS.OPENED_TASKS.FETCH_BY_STATUS_ARRAY,
    getConfig: () => tasksConfig(TAB_OPTIONS.OPENED_TASKS.ID, TAB_OPTIONS.OPENED_TASKS.TABLE_LABEL),
  },
  [TAB_OPTIONS.COMPLETED_TASKS.ID]: {
    id: TAB_OPTIONS.COMPLETED_TASKS.ID,
    taskStatusArray: TAB_OPTIONS.COMPLETED_TASKS.FETCH_BY_STATUS_ARRAY,
    getConfig: () => tasksConfig(TAB_OPTIONS.COMPLETED_TASKS.ID, TAB_OPTIONS.COMPLETED_TASKS.TABLE_LABEL),
  },
  [TAB_OPTIONS.ALL_TASKS.ID]: {
    id: TAB_OPTIONS.ALL_TASKS.ID,
    taskStatusArray: TAB_OPTIONS.ALL_TASKS.FETCH_BY_STATUS_ARRAY,
    getConfig: () => tasksConfig(TAB_OPTIONS.ALL_TASKS.ID, TAB_OPTIONS.ALL_TASKS.TABLE_LABEL),
  },
};
