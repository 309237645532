import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ALERT_ICON } from "../../app/constants/common";
import { AlertDescription, AlertIcon, AlertTitle } from "./AlertComponents";
import "./classes/alert.css";
import { AlertContainerProps, AlertStackContainer } from "./classes/styledComponents";
import { destroyAlert, selectAllAlerts } from "./slice/alertSlice";

const Alert = ({ id, type, title, description, removeMaxTimer }) => {
  const [alertAnimationEnter, setAlertAnimationEnter] = useState(true);
  const [isHoveringOnIcon, setIsHoveringOnIcon] = useState(false);
  const dispatch = useDispatch();
  const handleMouseOver = () => setIsHoveringOnIcon(true);
  const handleMouseOut = () => setIsHoveringOnIcon(false);

  const closeAlert = useCallback(() => {
    setAlertAnimationEnter(false);
    setTimeout(() => dispatch(destroyAlert(id)), 1000);
  }, [dispatch, id]);

  useEffect(() => {
    const x = setTimeout(() => closeAlert(), removeMaxTimer);
    return () => clearTimeout(x);
  }, [closeAlert, removeMaxTimer]);

  return (
    <AlertContainerProps
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      alertAnimationEnter={alertAnimationEnter}
      type={type}
      removeMaxTimer={removeMaxTimer}
    >
      <Box className="iconContainerStyle" sx={{ backgroundColor: `${type}.background` }}>
        <IconButton onClick={isHoveringOnIcon ? closeAlert : () => {}}>
          {isHoveringOnIcon ? (
            <Close sx={{ width: ALERT_ICON.ICON_WIDTH, height: ALERT_ICON.ICON_HEIGHT, color: `${type}.main` }} />
          ) : (
            <AlertIcon type={type} sx={{ color: `${type}.main` }} />
          )}
        </IconButton>
      </Box>
      <Box className="alertDescription">
        <AlertTitle title={title} type={type} />
        <AlertDescription description={description} />
      </Box>
    </AlertContainerProps>
  );
};

export default function AlertStack() {
  const alerts = useSelector(selectAllAlerts);
  return (
    <AlertStackContainer>
      {alerts.map((notification) => (
        <Alert
          key={notification.id}
          id={notification.id}
          type={notification.type}
          title={notification.title}
          description={notification.description}
          removeMaxTimer={notification.maxTimer}
        />
      ))}
    </AlertStackContainer>
  );
}
