import { Box, styled } from "@mui/system";
import { Children } from "react";

export const AlertStackContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  right: 0,
  top: "70px",
  paddingLeft: "32px",
  width: "fit-content",
  height: "fit-content",
  maxHeight: "100%",
  overflow: "hidden",
  zIndex: theme.zIndex.snackbar,
  display: "flex",
  alignItems: "flex-end",
  flexDirection: "column",
}));

export const AlertContainerProps = ({ onMouseEnter, onMouseLeave, alertAnimationEnter, type, removeMaxTimer, children }) => {
  const components = children ? Children.toArray(children) : [];
  return (
    <Box
      className={alertAnimationEnter ? "addAlert" : "removeAlert"}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{
        boxShadow: "rgb(0 0 0 / 27%) 0px 2px 3px 2px",
        borderRadius: "60px 0 0 60px",
        display: "flex",
        alignItems: "center",
        marginTop: 2,
        marginBottom: 1,
        width: "400px",
        transform: `translate3d(${alertAnimationEnter ? 1000 : 0}px, 0, 0)`,
        backgroundColor: "primary.sectionContainer",
        float: "right",
        borderLeft: "2px solid",
        borderLeftColor: `${type}.main`,

        "&::after": alertAnimationEnter
          ? {
              content: '""',
              position: "absolute",
              bottom: "-2px",
              right: 0,
              width: "93%",
              height: "2px",
              backgroundColor: `${type}.main`,
              animation: `closeEaseTransition ${removeMaxTimer}ms ease-in-out both`,
            }
          : {},
      }}
    >
      {components.map((child) => child)}
    </Box>
  );
};
