import { Dashboard, PlaylistAdd, QueryStats } from "@mui/icons-material";
import { PRIVATE_ROUTES } from "../../app/constants/routes";
import Overview from "../../features/Dashboard/Dashboard";
import EmployeeAttendance from "../../features/EmployeeAttendance/EmployeeAttendance";
import EmployeeLocation from "../../features/EmployeeLocation/EmployeeLocation";
import FileKBManager from "../../features/FileKBManager/FileKBManager";
import { InventoryReportTable, VehicleInventoryManagement } from "../../features/InventoryManagement/InventoryManagement";
import ManageData from "../../features/ManageData/ManageData";
import ManageDataEntryDialogForm from "../../features/ManageData/Modals/AddOrEditModal";
import TasksLayout from "../../features/SystemTasks/SystemTasks";
import NewTaskForm from "../../features/Tasks/NewTaskForm/NewTaskForm";
import TaskDetails from "../../features/Tasks/TaskDetails/TaskDetails";
import UserAnalysis from "../../features/UserAnalysis/UserAnalysis";
import { GMaterialIcon } from "../ui/IconUtils";
import { InventoryManagementToggleSelector, SingleTaskLabel } from "../ui/LabelComponents";
/**
 * @typedef {Object} NavigationPermissions
 * @property {string[]} [excludedRoles] - Roles that cannot access this route
 * @property {string[]} [requiredRoles] - Roles that can access this route
 */

/**
 * @typedef {Object} BaseNavigationItem
 * @property {string} id - Unique identifier for the route/item
 * @property {string} label - Text shown in the sidebar/drawer
 */

/**
 * @typedef {Object} NavigationLeafItem
 * @property {'item'} type - Type must be 'item' for leaf nodes
 * @property {import('react').ReactNode} icon - Icon component
 * @property {string} pageTitle - Page title shown in browser tab (used for tooltip if no tooltip is provided)
 * @property {string} tooltip - Text shown in tooltip - optional (pageTitle is used by default)
 * @property {string} path - Route path for navigation
 * @property {import('react').ReactNode} component - Component to render
 * @property {string[]} [childRoutes] - Additional routes that should highlight this item,
 * @property {string} parentId - id of the parent route,
 * @property {boolean} showInSidebar - decides whether to show in sidebar as a item
 * @property {NavigationPermissions} [permissions] - Access control settings
 */

/**
 * @typedef {Object} NavigationGroupItem
 * @property {'group'} type - Type must be 'group' for group nodes
 * @property {NavigationItem[]} items - Child navigation items in this group
 */

/**
 * @typedef {BaseNavigationItem & (NavigationLeafItem | NavigationGroupItem)} NavigationItem
 */

/**
 * Main navigation configuration for the application
 * @type {NavigationItem[]}
 */
export const appNavigationItems = [
  {
    id: "dashboard",
    type: "item",
    icon: <Dashboard />,
    label: "Dashboard",
    pageTitle: "Nikgil Dashboard",
    path: PRIVATE_ROUTES.dashboard,
    component: <Overview />,
    permissions: {
      excludedRoles: ["LEADER"],
    },
  },
  {
    id: "tasks",
    type: "group",
    label: "Tasks",
    items: [
      {
        id: "tasks-overview",
        type: "item",
        icon: <QueryStats />,
        label: "Tasks Overview",
        pageTitle: "View Tasks Overview",
        path: PRIVATE_ROUTES.tasks,
        component: <TasksLayout />,
        childRoutes: [PRIVATE_ROUTES.singleTask],
      },
      {
        id: "single-task",
        type: "item",
        label: "Task Details",
        pageTitle: <SingleTaskLabel />,
        path: PRIVATE_ROUTES.singleTask,
        component: <TaskDetails />,
        parentId: "tasks-overview",
        refreshAppConfig: true,
        showInSidebar: false,
      },
      {
        id: "new-task",
        type: "item",
        icon: <PlaylistAdd />,
        label: "New Task",
        pageTitle: "Add New Task",
        path: PRIVATE_ROUTES.newTask,
        component: <NewTaskForm />,
        refreshAppConfig: true,
      },
    ],
  },
  {
    id: "monitoring",
    type: "group",
    label: "Monitoring",
    items: [
      {
        id: "employee-location",
        type: "item",
        icon: <GMaterialIcon icon="move_location" />,
        label: "Employee Location",
        pageTitle: "View Employee Location",
        path: PRIVATE_ROUTES.employeeLocation,
        component: <EmployeeLocation />,
        refreshAppConfig: true,
        permissions: {
          excludedRoles: ["LEADER"],
        },
      },
    ],
  },
  {
    id: "accounts",
    type: "group",
    label: "Accounts",
    items: [
      {
        id: "view-user-analysis",
        type: "item",
        icon: <GMaterialIcon icon="groups_3" />,
        label: "User Analysis",
        pageTitle: "Employee Activity Analysis",
        path: PRIVATE_ROUTES.userAnalysis,
        component: <UserAnalysis />,
        permissions: {
          excludedRoles: ["LEADER"],
        },
      },
    ],
  },
  {
    id: "reports",
    type: "group",
    label: "Reports",
    items: [
      {
        id: "view-employee-attendance",
        type: "item",
        icon: <GMaterialIcon icon="event_note" filled={true} />,
        label: "Employee Attendance",
        pageTitle: "Employee Attendance Report",
        path: PRIVATE_ROUTES.employeeAttendance,
        component: <EmployeeAttendance />,
      },
    ],
  },
  {
    id: "inventory-management",
    type: "group",
    label: "Inventory Management",
    items: [
      {
        id: "stock-management",
        type: "item",
        icon: <GMaterialIcon icon="home_storage" />,
        label: "Stock Management",
        pageTitle: <InventoryManagementToggleSelector title="Stock Management" />,
        tooltip: "Stock Management",
        path: PRIVATE_ROUTES.vehicleInventory,
        component: <VehicleInventoryManagement />,
        childRoutes: [PRIVATE_ROUTES.vehicleInventoryReport],
        permissions: {
          excludedRoles: ["LEADER"],
        },
      },
      {
        id: "stock-management-inventory-report",
        type: "item",
        label: "Inventory Report",
        pageTitle: <InventoryManagementToggleSelector title="Stock Inventory Report" />,
        path: PRIVATE_ROUTES.vehicleInventoryReport,
        component: <InventoryReportTable />,
        parentId: "stock-management",
        enableBackNav: false,
        showInSidebar: false,
        permissions: {
          excludedRoles: ["LEADER"],
        },
      },
      {
        id: "data-management",
        type: "item",
        icon: <GMaterialIcon icon="manage_search" />,
        label: "Data Management",
        pageTitle: "Manage Data",
        path: PRIVATE_ROUTES.manageDataInventory,
        component: <ManageData />,
        childRoutes: [PRIVATE_ROUTES.manageDataFormAddition],
        permissions: {
          excludedRoles: ["LEADER"],
        },
      },
      {
        id: "form-data-management",
        type: "item",
        label: "Form Data Management",
        pageTitle: "Manage Form Data",
        path: PRIVATE_ROUTES.manageDataFormAddition,
        component: <ManageDataEntryDialogForm />,
        parentId: "data-management",
        showInSidebar: false,
        permissions: {
          excludedRoles: ["LEADER"],
        },
      },
      {
        id: "knowledge_base_files",
        type: "item",
        icon: <GMaterialIcon icon="smb_share" />,
        label: "File Management",
        pageTitle: "Knowledge Base File Management",
        path: PRIVATE_ROUTES.knowledgeBaseFile,
        component: <FileKBManager />,
        permissions: {
          excludedRoles: ["LEADER"],
        },
      },
    ],
  },
];