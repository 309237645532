import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { TASKS_ACTION } from "../../../app/constants/api";
import { TAB_OPTIONS } from "../../../app/constants/config/systemTasks";
import { apiSlice } from "../../../app/redux/apiSlice";
import { slicesConfigReferenceMap } from "../config/tabOptions";

const tabAdapter = createEntityAdapter();

const handleLocalStorage = (key, value = null) =>
  value ? localStorage.setItem(key, value) : localStorage.getItem(key) ?? TAB_OPTIONS.OPENED_TASKS.ID;

export const tabSlice = createSlice({
  name: "tabSlice",
  initialState: tabAdapter.getInitialState({
    activeTab: handleLocalStorage(TAB_OPTIONS.KEY),
  }),
  reducers: {
    initializeTabs: (state, { payload }) => {
      tabAdapter.addMany(state, payload);
    },

    setActiveTaskTab: (state, { payload }) => {
      handleLocalStorage(TAB_OPTIONS.KEY, payload);
      state.activeTab = payload;
    },

    setTasksCount: (state, { payload }) => {
      let statusCountMap = {};

      if (payload?.data) {
        statusCountMap = payload?.data.reduce((map, task) => {
          map[task.task_status] = parseInt(task.count, 10);
          return map;
        }, {});
      }

      const tasksCountChanges = Object.values(slicesConfigReferenceMap).map((config) => ({
        id: config.id,
        changes: {
          count: config.taskStatusArray.reduce((sum, status) => sum + (statusCountMap[status] || 0), 0),
          isLoading: false,
        },
      }));

      tabAdapter.updateMany(state, tasksCountChanges);
    },

    setLoading: (state, { payload }) => {
      tabAdapter.updateMany(
        state,
        Object.values(slicesConfigReferenceMap).map(({ id }) => ({ id, changes: { isLoading: payload } }))
      );
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(apiSlice.endpoints.tasksAction.matchFulfilled, (state, { payload }) => {
        if (payload.originalArgs.action === TASKS_ACTION.COUNT) {
          tabSlice.caseReducers.setTasksCount(state, { payload });
        }
      })
      .addMatcher(apiSlice.endpoints.tasksAction.matchPending, (state, { meta }) => {
        if (meta.arg.originalArgs.action === TASKS_ACTION.COUNT) {
          tabSlice.caseReducers.setLoading(state, { payload: true });
        }
      })
      .addMatcher(apiSlice.endpoints.tasksAction.matchRejected, (state, { payload }) => {
        if (payload.originalArgs.action === TASKS_ACTION.COUNT) {
          tabSlice.caseReducers.setTasksCount(state, { payload: null });
        }
      }),
});

export const { initializeTabs, setActiveTaskTab, setTasksCount, setIndividualLoading } = tabSlice.actions;
export const selectActiveTaskTab = (state) => state.tabSlice.activeTab;
export const { selectById: selectTabItemById } = tabAdapter.getSelectors((state) => state.tabSlice);

export default tabSlice.reducer;
