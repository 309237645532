import { COMMON_TRANSFORM_RESPONSE_FUNCTION, FILE_STORAGE } from "../../../app/constants/api";
import { apiSlice } from "../../../app/redux/apiSlice";

export const taskApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fileStorage: builder.mutation({
      query: ({ action, payload = {} }) => ({
        url: FILE_STORAGE.BASE,
        method: "POST",
        body: { action, payload },
      }),
      ...COMMON_TRANSFORM_RESPONSE_FUNCTION,
    }),
  }),
});

export const { useFileStorageMutation } = taskApiSlice;
