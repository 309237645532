import { Notifications } from "@mui/icons-material";
import { Badge, Box } from "@mui/material";
import { memo, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { NOTIFICATION_ACTION } from "../../app/constants/config/notificationSettings";
import { LoadingProgress, UiIconButton } from "../../components/ui/UiUtils";
import { MuiDropDownMenu } from "../../styled/commonStyles";
import { isAppSettingLoading } from "../AppSettings/appSlice";
import { NoNotificationAvailable, Notification, NotificationHeader } from "./notificationUi";
import { removeNotification, selectAllNotifications, selectNewNotificationsCount } from "./slice/notificationSlice";

const NotificationItem = memo(({ onClose, isVisible, notification, onRemoveNotification }) => {
  const ref = useRef();
  return (
    <CSSTransition nodeRef={ref} in={isVisible} timeout={300} classNames="notification-slide" unmountOnExit onExited={onRemoveNotification}>
      <Notification onClose={onClose} nodeRef={ref} {...notification} />
    </CSSTransition>
  );
});

const NotificationStack = ({ onClose }) => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectAllNotifications);
  const onRemoveNotification = useCallback((notificationId) => () => dispatch(removeNotification(notificationId)), [dispatch]);

  return (
    <TransitionGroup component="div">
      {notifications.map(({ [NOTIFICATION_ACTION.VISIBLE]: isVisible, ...notification }) => (
        <NotificationItem
          key={notification.notf_id}
          onClose={onClose}
          notification={notification}
          isVisible={isVisible}
          onRemoveNotification={onRemoveNotification(notification.notf_id)}
        />
      ))}
      <NoNotificationAvailable visible={notifications.length === 0} />
    </TransitionGroup>
  );
};

export const NotificationDropdownButton = ({ onSetRef }) => {
  const newNotificationCount = useSelector(selectNewNotificationsCount);
  return (
    <Badge
      invisible={newNotificationCount === 0}
      variant="dot"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      color="error"
      sx={{
        "& .MuiBadge-badge": { bottom: "14px", right: "17px", boxShadow: (theme) => `0px 0px 6px 0px ${theme.palette["error"].main}` },
        "@keyframes ripple": {
          "0%": {
            transform: "scale(.8)",
            opacity: 1,
          },
          "100%": {
            transform: "scale(2.4)",
            opacity: 0,
          },
        },
        "& .MuiBadge-badge::after": {
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          animation: "ripple 1.2s infinite ease-in-out",
          border: "1px solid",
          borderColor: "error.main",
          content: '""',
        },
      }}
    >
      <UiIconButton arrow title="Notifications" titleFontSize={12} onClickIcon={onSetRef} iconColor="primary.main">
        <Notifications />
      </UiIconButton>
    </Badge>
  );
};

export const NotificationDropdownContainer = ({ anchorEl, onClose }) => {
  const isLoading = useSelector(isAppSettingLoading);
  return (
    <MuiDropDownMenu
      anchorEl={anchorEl}
      id="account-menu"
      open={Boolean(anchorEl)}
      onClose={onClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            marginTop: "20px !important",
            width: "450px !important",
            border: "1px solid",
            borderTop: 0,
            borderColor: "primary.sectionBorder100",
          },
        },
      }}
    >
      <NotificationHeader />
      <Box sx={{ overflowX: "hidden", overflowY: "auto", maxHeight: "75vh" }}>
        {isLoading ? <LoadingProgress sx={{ height: "250px" }} /> : <NotificationStack onClose={onClose} />}
      </Box>
    </MuiDropDownMenu>
  );
};
