import * as MIcons from "@mui/icons-material";
import { alpha, Avatar, Box, Card, CardContent, CardHeader, Grid, ListItemAvatar, ListItemText, Skeleton, Stack, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { createElement, Fragment, useMemo } from "react";
import Chart from "react-apexcharts";
import { getAdjustedColorForContrast } from "../../app/utils/utilityFunctions";
import { UiIconButton } from "../../components/ui/UiUtils";

const getChartOptions = (theme, labels, colors) => {
  return {
    chart: {
      background: "transparent",
      type: "donut",
    },
    colors,
    labels,
    stroke: { width: 0 },
    plotOptions: {
      pie: {
        donut: {
          size: "75%",
          labels: {
            show: true,
            total: {
              show: true,
              fontSize: "22px",
              fontWeight: 700,
              color: theme.palette.text.secondary,
            },
          },
        },
      },
    },
    dataLabels: { enabled: false },
    legend: { show: false },
    states: {
      active: {
        filter: {
          type: "none",
        },
      },
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    theme: { mode: theme.palette.mode },
    tooltip: { fillSeriesColor: false },
  };
};

const ChartLabelItemSkeleton = ({ color }) => {
  const theme = useTheme();
  const cardColor = theme.palette[color]?.main || theme.palette.primary.main;

  return (
    <Fragment>
      <Skeleton
        variant="rounded"
        animation="wave"
        sx={{
          height: 56,
          width: 56,
          borderRadius: 2,
          backgroundColor: alpha(cardColor, 0.1),
        }}
      />
      <Stack spacing={1}>
        <Skeleton
          variant="text"
          animation="wave"
          width={80}
          height={40}
          sx={{
            backgroundColor: alpha(cardColor, 0.1),
          }}
        />
        <Skeleton
          variant="text"
          animation="wave"
          width={120}
          height={20}
          sx={{
            backgroundColor: alpha(cardColor, 0.1),
          }}
        />
      </Stack>
      <Skeleton
        variant="rounded"
        animation="wave"
        sx={{
          height: 20,
          width: 20,
          borderRadius: 2,
          backgroundColor: alpha(cardColor, 0.1),
        }}
      />
    </Fragment>
  );
};

const DonutChartSkeleton = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "relative",
        width: 320,
        height: 320,
        margin: "auto",
      }}
    >
      <Skeleton
        variant="circular"
        width={320}
        height={320}
        animation="wave"
        sx={{
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 200,
          height: 200,
          borderRadius: "50%",
          backgroundColor: theme.palette.background.paper,
        }}
      />
    </Box>
  );
};

const BoxItemComponent = motion.create(Box);

const ChartLabelItem = ({ chartSerial, theme, color, icon, label, percentage, loading = true }) => (
  <BoxItemComponent
    whileHover={{
      y: -8,
      transition: { duration: 0.2 },
    }}
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      p: 2,
      borderRadius: 2,
      background: `linear-gradient(135deg, ${alpha(color, 0.08)} 0%, ${alpha(theme.palette.background.paper, 1)} 100%)`,
      border: `1px solid ${alpha(color, 0.1)}`,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        boxShadow: `0 8px 24px ${alpha(color, 0.12)}`,
        border: `1px solid ${alpha(color, 0.2)}`,
      },
    }}
  >
    {loading ? (
      <ChartLabelItemSkeleton color={color} />
    ) : (
      <Fragment>
        <ListItemAvatar>
          <Avatar
            sx={{
              p: 1.5,
              borderRadius: 2,
              backgroundColor: alpha(color, 0.12),
              color: color,
            }}
          >
            {MIcons[icon] ? createElement(MIcons[icon]) : <MIcons.Quiz />}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={label}
          secondary={
            <Box
              component="span"
              sx={{
                color: color,
                fontSize: theme.typography.h5.fontSize,
                fontWeight: 600,
              }}
            >
              {chartSerial}
            </Box>
          }
          primaryTypographyProps={{
            variant: "body2",
            color: "text.secondary",
            sx: { mb: 0.5 },
            fontWeight: 500,
          }}
          secondaryTypographyProps={{
            component: "div",
            sx: { mt: 0.5 },
          }}
          sx={{ flex: 1 }}
        />
        <Box
          sx={{
            px: 1.5,
            py: 0.75,
            borderRadius: 1.5,
            fontSize: "0.75rem",
            fontWeight: 600,
            backgroundColor: alpha(color, 0.12),
            color: color,
          }}
        >
          {percentage}%
        </Box>
      </Fragment>
    )}
  </BoxItemComponent>
);

export default function TasksDonutChart({ refetchData, data, loading }) {
  const theme = useTheme();

  const chartData = useMemo(() => {
    const originalData = data?.data?.pieChart;
    if (!originalData) {
      return {
        chartSeries: [],
        items: Array(9).fill({ label: "Error", percentage: 0, icon: "NotInterested" }),
        colors: Array(9)
          .fill(["primary", "secondary", "warning", "error", "info"][Math.floor(Math.random(5))])
          .map((color) => theme.palette[color].main),
        labels: Array(9).fill("Error"),
      };
    }

    return {
      chartSeries: originalData.series,
      items: originalData.labels,
      colors: originalData.labels.map(({ color }) => getAdjustedColorForContrast(color, theme)[0]),
      labels: originalData.labels.map(({ label }) => label),
    };
  }, [theme, data]);

  return (
    <Card
      sx={{
        p: 3,
        height: "100%",
        borderRadius: 4,
        border: "1px solid",
        borderColor: theme.palette.primary.sectionBorder600,
      }}
    >
      <CardHeader
        title="Tasks Distribution Report"
        sx={{ pb: 0 }}
        action={
          <UiIconButton title="Refetch Data" onClickIcon={refetchData} disabled={loading}>
            <MIcons.Autorenew />
          </UiIconButton>
        }
      />
      <CardContent>
        <Grid container spacing={2} sx={{ "& .MuiSvgIcon-root, & .MuiIcon-root": { fontSize: 24 } }}>
          <Grid item xs={12} md={8}>
            {loading ? (
              <DonutChartSkeleton />
            ) : (
              <Chart
                style={{ flex: 1 }}
                height={350}
                options={getChartOptions(theme, chartData.labels, chartData.colors)}
                series={chartData.chartSeries}
                width="100%"
                type="donut"
              />
            )}
          </Grid>
          <Grid item xs={12} md={4} container spacing={2}>
            {chartData.items.slice(0, 3).map(({ label, icon, percentage } = {}, index) => (
              <Grid item xs={12} key={`label-${index}`}>
                <ChartLabelItem
                  chartSerial={chartData.chartSeries[index]}
                  color={chartData.colors[index]}
                  icon={icon}
                  theme={theme}
                  label={label}
                  percentage={percentage}
                  loading={loading}
                />
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} container spacing={2}>
            {chartData.items.slice(3).map(({ label, icon, percentage } = {}, index) => (
              <Grid item xs={12} sm={6} md={4} key={`label-${index + 3}`}>
                <ChartLabelItem
                  chartSerial={chartData.chartSeries[index + 3]}
                  color={chartData.colors[index + 3]}
                  icon={icon}
                  theme={theme}
                  label={label}
                  percentage={percentage}
                  loading={loading}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
