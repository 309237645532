import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, useTheme } from "@mui/material";
import { GridLabelValueUI, Transition } from "../../../components/ui/UiUtils";

export default function ViewRowDataModal({ columns, row, onClose }) {
  const theme = useTheme();
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={true}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{ sx: { backgroundColor: "background.default", border: "1px solid", borderColor: "primary.sectionBorder600" } }}
    >
      <DialogTitle>View Row Data : [{row.id}]</DialogTitle>
      <DialogContent>
        <Grid container gap={1}>
          {columns.map(({ id, label, renderContent, alternateValue }) => {
            return (
              <Grid key={id} item xs={12}>
                <GridLabelValueUI label={label} valueSx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
                  <span>:</span>
                  {row[id] !== undefined && row[id] !== null ? renderContent(row[id], "", theme) : alternateValue}
                </GridLabelValueUI>
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
