import { AlignHorizontalRight, Autorenew, KeyboardArrowRightOutlined } from "@mui/icons-material";
import { AccordionDetails, Box, Link, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink, useParams } from "react-router-dom";
import { routeTaskParam, routeToSingleTask } from "../../../app/constants/routes";
import { EmptyDataVisualComp, LoadingProgress, UiIconButton } from "../../../components/ui/UiUtils";
import { useGetTaskTreeListMutation } from "../../../hooks/ApiHooks";
import { MuiAccordion, MuiAccordionSummary } from "../../../styled/accordionStyles";
import { StyledChip } from "../../../styled/tableStyles";
import { findTaskById } from "../../AppSettings/appSlice";

const RenderTypographyContent = ({ currentTaskId, task, hasNoChildren }) => {
  const isParent = task?.child_task?.includes(currentTaskId) || false;
  const isCurrentTask = task?.task_id === currentTaskId;
  return (
    !!task && (
      <Box component="div" sx={{ display: "flex", alignItems: "center", my: 1 }}>
        {!!hasNoChildren && <AlignHorizontalRight fontSize="1rem" />}
        <Typography component="div" sx={{ fontSize: 14, fontWeight: 500, ml: 1 }}>
          <Link underline="hover" component={RouterLink} to={routeToSingleTask(task.task_id)} sx={{ color: "text.primary" }}>
            {task.task_id}
          </Link>
          {!!(isParent || isCurrentTask) && (
            <StyledChip
              label={isParent ? "Parent Task" : "Current Task"}
              color={isCurrentTask ? "info" : "warning"}
              minWidth="fit-content"
              sx={{
                height: "20px",
                borderRadius: 1,
                ml: 1,
                borderColor: "transparent",
                "& .MuiChip-label": { px: 1.5 },
              }}
            />
          )}
          <Typography sx={{ fontSize: 13, mt: 0.5, color: "text.secondary" }}>{task.task_title}</Typography>
        </Typography>
      </Box>
    )
  );
};

const TaskAccordion = ({ currentTaskId, treeData, startTask, expandedStates, setExpandedStates }) => {
  const children = startTask?.child_task?.map((taskId) => treeData[taskId]) || [];
  const hasNoChildren = children.length === 0;
  const handleChange = () => {
    setExpandedStates((prevStates) => ({
      ...prevStates,
      [startTask.task_id]: !prevStates[startTask.task_id],
    }));
  };

  return hasNoChildren ? (
    <RenderTypographyContent currentTaskId={currentTaskId} task={startTask} hasNoChildren={true} />
  ) : (
    <MuiAccordion expanded={expandedStates[startTask.task_id] || false} onChange={handleChange} TransitionProps={{ unmountOnExit: true }}>
      <MuiAccordionSummary
        expandIcon={<KeyboardArrowRightOutlined />}
        aria-controls={`panel-${startTask.task_id}-content`}
        id={`panel-${startTask.task_id}-header`}
      >
        <RenderTypographyContent currentTaskId={currentTaskId} task={startTask} hasNoChildren={false} />
      </MuiAccordionSummary>
      <AccordionDetails>
        {children.map((childTask) => (
          <TaskAccordion
            key={childTask.task_id}
            currentTaskId={currentTaskId}
            startTask={childTask}
            treeData={treeData}
            expandedStates={expandedStates}
            setExpandedStates={setExpandedStates}
          />
        ))}
      </AccordionDetails>
    </MuiAccordion>
  );
};

const RenderTaskTreeData = ({ currentTaskId, treeData = {} }) => {
  const [expandedStates, setExpandedStates] = useState(
    Object.keys(treeData).reduce((acc, taskId) => {
      acc[taskId] = true;
      return acc;
    }, {})
  );

  return (
    <TaskAccordion
      currentTaskId={currentTaskId}
      treeData={treeData}
      startTask={treeData[treeData[currentTaskId]?.parent_task ?? currentTaskId]}
      expandedStates={expandedStates}
      setExpandedStates={setExpandedStates}
    />
  );
};

export default function TaskTreeList() {
  const { [routeTaskParam]: id } = useParams();
  const currentTask = useSelector((state) => findTaskById(state, id));
  const [treeData, setTreeData] = useState();
  const { triggerMutation, isLoading, isError, data } = useGetTaskTreeListMutation();
  const refetchTreeList = useCallback(() => {
    triggerMutation(id);
  }, [id, triggerMutation]);

  useEffect(() => {
    refetchTreeList();
  }, [refetchTreeList]);

  useEffect(() => {
    if (!isLoading && data?.data) {
      const { parent_task: [parentTask] = [null], child_tasks: children = [] } = data.data;
      const transformedData = {
        [id]: {
          ...currentTask,
          parent_task: parentTask?.task_id || null,
          child_task: children.map(({ task_id }) => task_id),
        },
      };

      children.forEach(({ task_id, ...childRest }) => {
        transformedData[task_id] = { ...childRest, task_id, parent_task: id, child_task: [] };
      });

      if (parentTask) {
        const { task_id } = parentTask;
        transformedData[task_id] = { ...parentTask, parent_task: null, child_task: [id] };
      }
      setTreeData(transformedData);
    }
  }, [currentTask, id, isLoading, data]);

  return (
    <Box
      component="section"
      sx={{
        width: "300px",
        overflowY: "auto",
        position: "sticky",
        top: 0,
        py: 1,
        mt: 1,
        "& .MuiButtonBase-root": {
          pl: 0,
        },
        "& .MuiAccordionSummary-content": {
          m: 0,
        },
        "& .MuiAccordionDetails-root": {
          p: 0,
          ml: 1,
          pl: 2.5,
          borderLeft: "1px dashed",
          borderColor: "primary.main",
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
          m: 0,
        },
      }}
    >
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pb: 1,
        }}
      >
        <Typography variant="h6">Task Tree List</Typography>
        <UiIconButton title="Refetch Task Tree" onClickIcon={refetchTreeList}>
          <Autorenew />
        </UiIconButton>
      </Box>
      {isLoading ? (
        <LoadingProgress sx={{ height: "70vh" }} />
      ) : isError ? (
        <EmptyDataVisualComp />
      ) : (
        !!treeData && <RenderTaskTreeData currentTaskId={id} treeData={treeData} />
      )}
    </Box>
  );
}
