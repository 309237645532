import { KeyboardDoubleArrowDown, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from "@mui/icons-material";
import { Box, Tab, Tabs } from "@mui/material";
import { useCallback, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { routeToInventoryVehicle } from "../../app/constants/routes";
import { UiIconButton } from "../../components/ui/UiUtils";
import { tabStyle } from "../../styled/inlineCssStyles";
import { ExtendedTabViewPopper, TabLabelComp } from "./InventoryUIComponents";

export default function TabLayout({ activeTabId, tabItems }) {
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const tabContainerRef = useRef();

  const handleMouseEnter = useCallback(() => setIsHovered(true), []);
  const handleMouseLeave = useCallback(() => setIsHovered(false), []);
  const handleClickIcon = useCallback(() => {
    setAnchorEl(tabContainerRef.current);
    setIsHovered(false);
  }, [tabContainerRef]);
  const handleClose = useCallback(() => setAnchorEl(null), []);

  const renderedTabs = useMemo(
    () =>
      tabItems.map(({ id, label, availability, isActive }) => (
        <Tab
          disableRipple
          key={id}
          value={id}
          LinkComponent={Link}
          to={routeToInventoryVehicle(id)}
          label={<TabLabelComp isActive={isActive} label={label} availability={availability} />}
        />
      )),
    [tabItems]
  );

  return (
    <Box component="div" sx={{ position: "sticky", top: 0, zIndex: 100 }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Tabs
        ref={tabContainerRef}
        slots={{
          StartScrollButtonIcon: KeyboardDoubleArrowLeft,
          EndScrollButtonIcon: KeyboardDoubleArrowRight,
        }}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        value={activeTabId}
        sx={tabStyle()}
      >
        {renderedTabs}
      </Tabs>
      <Box sx={{ display: "flex", justifyContent: "center", visibility: !anchorEl && isHovered ? "visible" : "hidden" }}>
        <UiIconButton title="Click to open extended tab view" iconColor="text.primary" fontSize={10} onClickIcon={handleClickIcon}>
          <KeyboardDoubleArrowDown
            sx={{
              transform: `translateY(${!anchorEl && isHovered ? "-40px" : "0"})`,
              animation: "bounce 2s infinite",
              zIndex: -1,
              transition: "all 5ms",
            }}
          />
        </UiIconButton>
      </Box>
      <ExtendedTabViewPopper tabItems={tabItems} handleClose={handleClose} anchorEl={anchorEl} />
    </Box>
  );
}
