import { COMMON_TRANSFORM_RESPONSE_FUNCTION, TASKS_ACTION } from "../../../app/constants/api";
import { apiSlice } from "../../../app/redux/apiSlice";

export const systemTasksApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    tasksAction: builder.mutation({
      query: ({ action, payload = {} }) => ({
        url: TASKS_ACTION.BASE,
        method: "POST",
        body: { action, payload },
      }),
      ...COMMON_TRANSFORM_RESPONSE_FUNCTION,
    }),
  }),
});

export const { useTasksActionMutation } = systemTasksApiSlice;
