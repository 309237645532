import { Check, Clear, InsertDriveFile } from "@mui/icons-material";
import { Popover, Stack, TextField } from "@mui/material";
import { saveAs } from "file-saver";
import { Fragment, memo, useEffect, useRef, useState } from "react";
import { MuiTooltip } from "../../styled/commonStyles";
import { sectionUiLookBase } from "../../styled/inlineCssStyles";
import { StyledChip } from "../../styled/tableStyles";
import { UiIconButton } from "../ui/UiUtils";
import ViewFileDialog from "./ViewFileDialog";
import { GENERAL_MIME_TYPES, getDownloadAttributes } from "./fileUtils";

const RenameFileTextFieldPopper = memo(({ handleClose, anchorEl, extension, previousFileName, onChangeFile }) => {
  const [newFileName, setNewFileName] = useState(previousFileName.split(".")[0]);
  const setChangedFile = (e) => {
    e.preventDefault();
    if (newFileName.trim() !== "") onChangeFile(newFileName + extension);
    else {
      setNewFileName(previousFileName.split(".")[0]);
      onChangeFile(previousFileName);
    }
    handleClose();
  };

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      slotProps={{
        paper: {
          sx: {
            minWidth: 150,
            maxWidth: "fit-content",
            mt: 1,
            ...sectionUiLookBase,
          },
        },
      }}
    >
      <Stack
        component="form"
        direction="row"
        gap={1}
        alignItems="center"
        onSubmit={setChangedFile}
        sx={{ backgroundColor: "primary.background", p: 0.5 }}
      >
        <TextField
          autoFocus
          value={newFileName}
          size="small"
          onChange={(e) => setNewFileName(e.target.value)}
          label={null}
          variant="outlined"
          InputProps={{
            endAdornment: extension,
          }}
        />
        <UiIconButton type="submit" title="Rename" iconSize={24}>
          <Check />
        </UiIconButton>
      </Stack>
    </Popover>
  );
});

export const RenderFileChip = memo(({ color = "secondary", fileData, fileName, fileMime, onCancelFile = null, onRenameFileName = null, sx = {} }) => {
  const anchorContainer = useRef();
  const [isFileViewOpened, setIsFileViewOpened] = useState(false);
  const [renamePopperAnchor, setRenamePopperAnchor] = useState(null);
  const [clickTimer, setClickTimer] = useState(null);
  const [blobURL, setBlobURL] = useState(null);
  const [downloadName, setDownloadName] = useState(null);
  const isBlobObjectBaseLoading = useRef(false);

  useEffect(() => {
    if (fileData && fileMime && !isBlobObjectBaseLoading.current) {
      isBlobObjectBaseLoading.current = true;
      getDownloadAttributes(fileData, fileName, fileMime).then((attributes) => {
        setBlobURL(attributes.blobURL);
        setDownloadName(attributes.downloadName);
        isBlobObjectBaseLoading.current = false;
      });
    }
  }, [fileData, fileName, fileMime]);

  const downloadFile = () => (blobURL ? saveAs(blobURL, downloadName) : null);
  const handleOnFileActions = () => {
    if (clickTimer === null) {
      const timer = setTimeout(() => {
        /* INFO: Single Click Action */
        if (GENERAL_MIME_TYPES[fileMime]?.IS_VIEWABLE) setIsFileViewOpened(true);
        else downloadFile();
        setClickTimer(null);
      }, 300);
      setClickTimer(timer);
    } else {
      clearTimeout(clickTimer);
      setClickTimer(null);
      /* INFO: Double Click Action */
      if (onRenameFileName) setRenamePopperAnchor(anchorContainer?.current);
      else downloadFile();
    }
  };

  return (
    <Fragment>
      <MuiTooltip
        arrow
        title={
          fileData
            ? (GENERAL_MIME_TYPES[fileMime]?.IS_VIEWABLE ? "Click to view the attachment." : "Attachment cannot be viewed. Click to download.") +
              (onRenameFileName ? " Double click to rename file" : " Double click to download attachment")
            : null
        }
        fontSize={11}
      >
        <StyledChip
          ref={anchorContainer}
          variant="outlined"
          icon={GENERAL_MIME_TYPES[fileMime]?.ICON || <InsertDriveFile />}
          label={fileName || ""}
          minWidth="fit-content"
          color={color}
          sx={{ borderRadius: 1, border: 0, "& .MuiChip-label": { textTransform: "none" }, ...sx }}
          onClick={handleOnFileActions}
          onDelete={onCancelFile}
          deleteIcon={<Clear />}
          clickable
        />
      </MuiTooltip>
      {!!isFileViewOpened && (
        <ViewFileDialog
          onClose={() => setIsFileViewOpened(false)}
          blobFileSrc={blobURL}
          viewType={GENERAL_MIME_TYPES[fileMime]?.VIEW_TYPE}
          isViewable={GENERAL_MIME_TYPES[fileMime]?.IS_VIEWABLE}
          downloadFileFn={downloadFile}
        />
      )}
      {!!onRenameFileName && (
        <RenameFileTextFieldPopper
          anchorEl={renamePopperAnchor}
          handleClose={() => setRenamePopperAnchor(null)}
          previousFileName={fileName}
          onChangeFile={onRenameFileName}
          extension={GENERAL_MIME_TYPES[fileMime]?.EXTENSION || ".bin"}
        />
      )}
    </Fragment>
  );
});
