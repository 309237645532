import { LoadingButton } from "@mui/lab";
import { Box, Grid, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate, useOutletContext } from "react-router-dom";
import { ERROR, INFO, SUCCESS, WARNING } from "../../app/constants/common";
import { PUBLIC_ROUTES } from "../../app/constants/routes";
import { useSignupMutation } from "../../hooks/ApiHooks";
import { setAlert } from "../Alerts/slice/alertSlice";
import { RenderFormField } from "./AuthUiComponents";
import { signupUserFields } from "./config/authConfig";

const SignupUserFieldData = ({ setSuccessful }) => {
  const dispatch = useDispatch();
  const [setAlertMessage] = useOutletContext();
  const { triggerMutation: signupUser, isLoading } = useSignupMutation();

  const onRequestSignupUser = async (e) => {
    e.preventDefault();
    const [email, password, confirmPassword] = new FormData(e.target).values();
    if (!email.trim() || !password.trim() || !confirmPassword.trim()) {
      setAlertMessage("Either Fields are empty");
      return;
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email.trim())) {
      setAlertMessage("Invalid email! Please enter a valid email");
      return;
    } else if (!/^(?=\S*([A-Z]){1,})(?=\S*[!@#$%^&?]{1,})(?=\S*[0-9]{2,})(?=\S*[a-z]{1,})\S{8,16}$/.test(password.trim())) {
      setAlertMessage(
        "Your password should be 8-16 characters, including at least one uppercase letter, one lowercase letter, two consecutive digits, and at least one special character (!@#$%^&?)"
      );
      return;
    } else if (password.trim() !== confirmPassword.trim()) {
      setAlertMessage("Passwords do not match. Make sure new password is same as confirm password");
      return;
    }
    try {
      await signupUser(email.trim(), password.trim(), confirmPassword.trim()).unwrap();
      dispatch(setAlert(`Password Creation Complete`, "Check your email to verify your account and get started", INFO));
      setAlertMessage("Password was created successfully. Please check your email to verify your account and proceed to login", SUCCESS);
      setSuccessful();
    } catch (err) {
      const serviceUnavailable = err.status === 503;
      setAlertMessage(err.data.message);
      dispatch(setAlert(serviceUnavailable ? err.data.title : "Password Creation Failed", err.data.message, serviceUnavailable ? ERROR : WARNING));
    }
  };

  return (
    <Box component="form" onSubmit={onRequestSignupUser} id="signup-user">
      <Grid container>
        {signupUserFields.map((item) => (
          <RenderFormField field={item} key={item.id} />
        ))}
      </Grid>
      <LoadingButton variant="contained" type="submit" fullWidth loading={isLoading} sx={{ mt: 2, mb: 1 }} size="large">
        <span>Sign Up</span>
      </LoadingButton>
    </Box>
  );
};

export default function SignupUser() {
  const navigateTo = useNavigate();
  const [isRequestSuccessful, setIsRequestSuccessful] = useState(false);
  const [count, setCount] = useState(10);

  useEffect(() => {
    let timeInterval = null;
    if (isRequestSuccessful) {
      timeInterval = setInterval(() => setCount((prev) => prev - 1), 1000);
    }
    return () => clearInterval(timeInterval);
  }, [isRequestSuccessful]);

  useEffect(() => {
    if (count === 0) navigateTo(PUBLIC_ROUTES.login);
  }, [count, navigateTo]);

  return (
    <Box>
      <Typography variant="h5" sx={{ mt: 2 }} fontWeight={600}>
        Welcome 👋
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }} fontWeight={400} color="text.secondary">
        Create a new password to verify your account
      </Typography>
      {isRequestSuccessful ? (
        <Typography variant="h6" sx={{ my: 3 }}>
          Redirecting you to the login page in... {count}
        </Typography>
      ) : (
        <SignupUserFieldData setSuccessful={() => setIsRequestSuccessful(true)} />
      )}

      <Link variant="body1" component={RouterLink} to={PUBLIC_ROUTES.login} color="text.secondary" fontWeight={500} underline="hover">
        {isRequestSuccessful ? "Click to redirect now" : "Already with us? Login here"}
      </Link>
    </Box>
  );
}
