import { serverToUi } from "../../../../app/utils/utilityFunctions";

export const renderStoreDetailsConfig = {
  cust_id: {
    id: "cust_id",
    label: "Customer Id",
    alternate: "N/A",
  },
  store_id: {
    id: "store_id",
    label: "Store Id",
    alternate: "N/A",
  },
  store_id_ext: {
    id: "store_id_ext",
    label: "Store External Id",
    alternate: "N/A",
  },
  store_name: {
    id: "store_name",
    label: "Store Name",
    alternate: "N/A",
  },
  store_adr_1: {
    id: "store_adr_1",
    label: "Store Address",
    alternate: "N/A",
  },
  store_adr_city: {
    id: "store_adr_city",
    label: "City",
    alternate: "N/A",
  },
  store_adr_zip: {
    id: "store_adr_zip",
    label: "ZIP Code",
    alternate: "N/A",
  },
  store_adr_state: {
    id: "store_adr_state",
    label: "State",
    alternate: "N/A",
  },
  store_dt_open: {
    id: "store_dt_open",
    label: "Store Opening Date",
    alternate: "N/A",
    formatData: (data) => serverToUi(data),
  },
  store_type: {
    id: "store_type",
    label: "Store Type",
    alternate: "N/A",
  },
  store_email: {
    id: "store_email",
    label: "Email Address",
    alternate: "N/A",
  },
};

export const renderEquipmentDetailsConfig = {
  eq_id: {
    id: "eq_id",
    label: "Equipment Id",
    alternate: "N/A",
  },
  eq_model_id: {
    id: "eq_model_id",
    label: "Equipment Model ID",
    alternate: "N/A",
  },
  eq_model_name: {
    id: "eq_model_name",
    label: "Equipment Model Name",
    alternate: "N/A",
  },
  eq_store_id: {
    id: "eq_store_id",
    label: "Store Id for Equipment",
    alternate: "N/A",
  },
  eq_serial: {
    id: "eq_serial",
    label: "Equipment Serial Number",
    alternate: "N/A",
  },
  eq_wrnty_dt: {
    id: "eq_wrnty_dt",
    label: "Warranty Date",
    alternate: "N/A",
    formatData: (data) => serverToUi(data),
  },
};
