// eslint-disable-next-line no-undef
const ACCOUNT_AUTH_KEY = process.env.REACT_APP_MAPS_AUTH_KEY;

export const MAP_CONFIG = {
  LIGHT_VECTOR_STYLE: `https://api.maptiler.com/maps/streets-v2/style.json?key=${ACCOUNT_AUTH_KEY}`,
  DARK_VECTOR_STYLE: `https://api.maptiler.com/maps/streets-v2-dark/style.json?key=${ACCOUNT_AUTH_KEY}`,
  LIGHT_RASTER_STYLE: `https://api.maptiler.com/maps/streets-v2/{z}/{x}/{y}@2x.png?key=${ACCOUNT_AUTH_KEY}`,
  DARK_RASTER_STYLE: `https://api.maptiler.com/maps/streets-v2-dark/{z}/{x}/{y}@2x.png?key=${ACCOUNT_AUTH_KEY}`,
  ATTRIBUTION:
    '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
};
