import { AppBar, Drawer } from "@mui/material";
import { styled } from "@mui/system";
import { SIDE_BAR_WIDTH } from "../app/constants/common";

export const MuiAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: `${SIDE_BAR_WIDTH}px`,
    width: `calc(100% - ${SIDE_BAR_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const openedMixin = (theme) => ({
  width: `${SIDE_BAR_WIDTH}px`,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(9)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export const MuiDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: `${SIDE_BAR_WIDTH}px`,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const sideBarListItemButtonStyles = (open, active) => {
  const baseStyle = {
    color: "#FFF",
    backgroundColor: active ? "rgba(255, 255, 255, 0.07)" : "none",
    "& .MuiListItemIcon-root": {
      color: active ? "#FFF" : "rgba(255, 255, 255, 0.7)",
    },
    "& .MuiListItemText-root": {
      color: active ? "#FFF" : "rgba(255, 255, 255, 0.8)",
      "& .MuiTypography-root": {
        fontWeight: active ? 500 : 400,
      },
    },
    px: 2,
    mx: 1.5,
    mb: 0.5,
    minHeight: 48,
  };

  const activeOnClose = {
    color: "#000",
    backgroundColor: "#FFF",
    "& .MuiListItemIcon-root": {
      color: "#000",
    },
    "& .MuiListItemText-root": {
      color: "#000",
    },
  };

  return {
    ...baseStyle,
    ...(!open && active && activeOnClose),
    ":hover": activeOnClose,
  };
};
