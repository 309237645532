import * as MIcons from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { saveAs } from "file-saver";
import { Fragment, createElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SUCCESS, WARNING } from "../../app/constants/common";
import { highlightText } from "../../app/utils/utilityFunctions";
import ViewFileDialog from "../../components/AttachFileHandler/ViewFileDialog";
import { getDownloadAttributes } from "../../components/AttachFileHandler/fileUtils";
import { ConfirmationDialog, UiIconButton } from "../../components/ui/UiUtils";
import { useDeleteKnowledgeBaseFilesData, useGetFileForRefMutation } from "../../hooks/ApiHooks";
import { setAlert } from "../Alerts/slice/alertSlice";

export const DisplayPrimaryFileIdContent = ({ icon, color, label, searchStr }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      "& .MuiSvgIcon-root, & .MuiIcon-root": { color, fontSize: 22, marginRight: 1 },
    }}
  >
    {MIcons[icon] ? createElement(MIcons[icon]) : <MIcons.Quiz />}
    {highlightText(label, searchStr)}
  </Box>
);

export const ViewDataFileDialog = ({ dialogProps, setDialogProps }) => {
  const dispatch = useDispatch();
  const [blobURL, setBlobURL] = useState(null);
  const [downloadName, setDownloadName] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { row } = dialogProps;
  const { triggerMutation, isLoading, isError, data } = useGetFileForRefMutation();
  const onHandleClose = () => setDialogProps((prev) => ({ ...prev, row: null, open: false }));
  const downloadFile = () => (isError || !blobURL ? null : saveAs(blobURL, downloadName));

  useEffect(() => {
    if (row?.id) {
      setIsDataLoading(true);
      triggerMutation(row.id);
    }
  }, [triggerMutation, row.id]);

  useEffect(() => {
    if (!isLoading && data?.data)
      getDownloadAttributes(data.data.signed_url, data.data.file_name, data.data.file_mime).then((attributes) => {
        setDownloadName(attributes.downloadName);
        setBlobURL(attributes.blobURL);
        setIsDataLoading(false);
      });
  }, [isLoading, data]);

  useEffect(() => {
    if (isError) {
      setIsDataLoading(false);
      dispatch(setAlert(`Error Retrieving File: ${row.id}`, "Encountered an error trying to download file. Try again later", WARNING));
    }
  }, [isError, dispatch, row.id]);

  return (
    <ViewFileDialog
      blobFileSrc={blobURL}
      downloadFileFn={downloadFile}
      onClose={onHandleClose}
      loading={isDataLoading}
      error={isError}
      viewType={row.contentType}
      isViewable={row.isViewable}
    />
  );
};

const DownloadDataFile = ({ file_ref }) => {
  const dispatch = useDispatch();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { triggerMutation, isLoading, isError, data } = useGetFileForRefMutation();

  useEffect(() => {
    if (!isLoading && data?.data)
      getDownloadAttributes(data.data.signed_url, data.data.file_name, data.data.file_mime).then((attributes) => {
        saveAs(attributes.blobURL, attributes.downloadName);
        setIsDataLoading(false);
      });
  }, [isLoading, data]);

  useEffect(() => {
    if (isError) {
      setIsDataLoading(false);
      dispatch(setAlert(`Error Retrieving File: ${file_ref}`, "Encountered an error trying to download file. Try again later", WARNING));
    }
  }, [isError, dispatch, file_ref]);

  const onDownloadFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDataLoading) {
      setIsDataLoading(true);
      triggerMutation(file_ref);
    }
  };

  return (
    <UiIconButton iconSize={22} title="Download File" iconColor="secondary.main" arrow loading={isDataLoading} onClickIcon={onDownloadFile}>
      <MIcons.FileDownload />
    </UiIconButton>
  );
};

const DeleteDataFile = ({ file_ref, refetchData }) => {
  const dispatch = useDispatch();
  const [openDialog, setDialogOpen] = useState(false);
  const { triggerMutation, isLoading, isError, data } = useDeleteKnowledgeBaseFilesData();

  useEffect(() => {
    if (!isLoading && data) {
      dispatch(setAlert(`File ${file_ref} Deletion Successful`, "The file was successfully deleted. Please wait while we refetch new data", SUCCESS));
      refetchData();
    }
  }, [dispatch, refetchData, isLoading, data, file_ref]);

  useEffect(() => {
    if (isError) dispatch(setAlert(`Error Deleting File: ${file_ref}`, "There was an unexpected error deleting file. Try again later", WARNING));
  }, [isError, dispatch, file_ref]);

  const onDeleteFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDialogOpen(true);
  };
  const deleteTrigger = (e) => {
    e.stopPropagation();
    triggerMutation(file_ref);
  };

  return (
    <Fragment>
      <UiIconButton iconSize={22} title="Download File" iconColor="error.main" arrow loading={isLoading} onClickIcon={onDeleteFile}>
        <MIcons.Delete />
      </UiIconButton>
      <ConfirmationDialog
        open={openDialog}
        onClose={(e) => {
          e.stopPropagation();
          setDialogOpen(false);
        }}
        title={
          <span>
            Confirm Deletion of File ID:{" "}
            <Typography component="span" variant="inherit" color="primary">
              {file_ref}
            </Typography>
          </span>
        }
        subTitle={
          <span>
            <b>Warning:</b> This action is permanent and cannot be undone. Please review carefully before proceeding.
          </span>
        }
        onCancelTitle="Go Back"
        onSubmitTitle="Delete Anyway"
        loading={isLoading}
        onSubmit={deleteTrigger}
      />
    </Fragment>
  );
};

export const RenderActionsForRow = ({ rowId, refetchData }) => {
  return (
    <Stack component="div" direction="row" sx={{ alignItems: "center", justifyContent: "center" }}>
      <DownloadDataFile file_ref={rowId} />
      <DeleteDataFile file_ref={rowId} refetchData={refetchData} />
    </Stack>
  );
};
