import { PrecisionManufacturing, TimelapseOutlined } from "@mui/icons-material";
import { Box, InputAdornment } from "@mui/material";
import {
  GET_INPUT_FIELD_TYPE,
  IS_COLOR,
  IS_DATE,
  IS_MULTI_SELECT,
  IS_SELECT,
  MAX_COLOR_LENGTH,
  MIN_NUMBER,
  SERVER_FIELD_TYPE,
} from "../../../app/constants/config/DynamicFields";

export const getFormConfig = (row, regexData, inputData) => {
  const result = inputData.reduce(
    (
      acc,
      {
        fld_disp_name: fieldLabel,
        fld_col_name: fieldId,
        fld_seq: order,
        fld_mand_mode: isFieldRequired,
        fld_dsbl_mode: isFieldDisabled,
        fld_default_val: fieldDefaultValue,
        fld_null_value: fieldAlternateValue = "-",
        fld_data_type: fieldType,
        fld_max_len: fieldMaxLength = 1,
        fld_parent: fieldParentId = null,
        fld_child: fieldChild = null,
        fld_regex: fieldRegexId = null,
        combo_data: fieldOptions = null,
        combo_id: fieldGetOptionsId,
      }
    ) => {
      const { regex_error: errorString = null, regex: fieldRegex = ".*" } = regexData.find(({ regex_id }) => regex_id === fieldRegexId) || {};
      const label = (
        <Box component="span">
          {fieldLabel}
          <Box component="b" sx={{ fontWeight: 700, color: "error.main" }}>
            {!!isFieldRequired && " ** "}
          </Box>
        </Box>
      );

      const constructedFieldConfig = {
        id: fieldId,
        order: parseInt(order),
        isEditable: !isFieldDisabled,
        fieldRegex,
        errorString,
        defaultValue: fieldDefaultValue,
        alternateValue: fieldAlternateValue,
        fieldType,
        textFieldProps: {
          id: fieldId,
          name: fieldId,
          size: "medium",
          label,
          placeholder: IS_COLOR(fieldType) ? `Choose ${fieldLabel}` : IS_SELECT(fieldType) ? `Select ${fieldLabel}` : `Enter ${fieldLabel} here...`,
          type: GET_INPUT_FIELD_TYPE(fieldType),
          inputProps: {
            readOnly: isFieldDisabled,
            ...(fieldType === SERVER_FIELD_TYPE.NUMBER
              ? { max: parseInt(fieldMaxLength), min: MIN_NUMBER }
              : { maxLength: IS_COLOR(fieldType) ? MAX_COLOR_LENGTH : parseInt(fieldMaxLength) }),
          },
          InputProps: {
            ...(!(IS_DATE(fieldType) || IS_COLOR(fieldType)) && {
              startAdornment: (
                <InputAdornment position="start">
                  <PrecisionManufacturing />
                </InputAdornment>
              ),
            }),
          },
          ...(IS_SELECT(fieldType) && {
            disableClearable: isFieldRequired,
            uniqueKeyId: "value",
            ...(IS_MULTI_SELECT(fieldType) && { multiple: true, limitTags: 2 }),
            getOptionLabel: (option) => option.display || "N/A",
            filterFunction: ({ display, value }, inputValue) =>
              [display, value].some((entity) => String(entity).toLowerCase().includes(inputValue.toLowerCase())),
            renderOptionComponent: ({ display }) => display,
            isOptionEqualToValue: (option, value) => option.value === value.value,
            noOptionsText: `No ${fieldLabel} Available`,
          }),
        },
        ...(IS_SELECT(fieldType) && {
          ...(IS_MULTI_SELECT(fieldType)
            ? {
                getFromValue: (options, value) => options.filter((option) => value.includes(option.value)),
                setToValue: (options) => options.map((option) => option?.value).filter(Boolean),
              }
            : {
                getFromValue: (options, value) => options.find((option) => option.value === value),
                setToValue: (option) => option?.value || "",
              }),
          valueProviderProps: {
            parentFieldId: fieldParentId,
            dependentFieldId: fieldChild,
            options: fieldOptions ? fieldOptions : row?.[fieldId] ? [row[fieldId]] : [],
            optionsId: fieldGetOptionsId,
          },
        }),

        ...(IS_DATE(fieldType) && {
          componentProps: {
            id: fieldId,
            size: "medium",
            label,
            slotProps: { inputAdornment: { position: "start" } },
            slots: { openPickerIcon: TimelapseOutlined },
          },
        }),
      };

      acc[0].push(constructedFieldConfig);
      acc[1].push({
        id: fieldId,
        fieldType,
        fieldRegex,
        value: row?.[fieldId] || fieldDefaultValue,
        isEditable: !isFieldDisabled,
        isRequired: isFieldRequired,
        isError: false,
      });

      return acc;
    },
    [[], []]
  );
  result[0].sort((a, b) => a.order - b.order);
  return result;
};
