import { Cancel } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from "@mui/material";
import { uniqueId } from "lodash";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ERROR, INFO, SUCCESS, WARNING } from "../../app/constants/common";
import { KB_ALLOWED_MIME_TYPES } from "../../components/AttachFileHandler/fileUtils";
import MultiLineRTE from "../../components/RichTextEditor/RichTextEditor";
import { ProgressIndicator, Transition, UiIconButton } from "../../components/ui/UiUtils";
import { useUploadKnowledgeBaseFileData } from "../../hooks/ApiHooks";
import { sectionUiLookBase } from "../../styled/inlineCssStyles";
import { setAlert } from "../Alerts/slice/alertSlice";

const NewKBFileUploadComponent = memo(
  ({ componentAttributes, isVisible, onSetNewRemark, onSetNewAttachments, onHideAttachment, onRemoveAttachments, disabled }) => {
    const ref = useRef();
    const scrollElementIntoView = () =>
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    return (
      <CSSTransition
        nodeRef={ref}
        in={isVisible}
        timeout={300}
        classNames="new-comment"
        unmountOnExit
        onEntered={scrollElementIntoView}
        onExited={onRemoveAttachments}
      >
        <Box sx={{ position: "relative", m: 2 }} ref={ref}>
          <MultiLineRTE
            value={componentAttributes.remark}
            currentAttachedFiles={componentAttributes.attachments}
            onChange={onSetNewRemark}
            fileUploadEnabled
            onChangeFileData={onSetNewAttachments}
            placeholder="file remark"
            id={`new-file-${componentAttributes.id}`}
            name={`new-file-${componentAttributes.id}`}
            disabled={disabled}
            maxCharactersAllowed={400}
            fileRenderColor="info"
            styleEnabled={false}
            maxUploadsAllowedLength={1}
            allowedFileTypes={KB_ALLOWED_MIME_TYPES}
            sx={{ "& .MuiTiptap-RichTextContent-root .tiptap.ProseMirror": { minHeight: "100px", maxHeight: "100px" } }}
          />
          <UiIconButton
            title="Cancel File"
            arrow
            iconColor="error.main"
            iconSize={22}
            sx={{ position: "absolute", top: componentAttributes.attachments.length ? "38px" : "5px", right: "2px", zIndex: 10 }}
            onClickIcon={onHideAttachment}
          >
            <Cancel />
          </UiIconButton>
        </Box>
      </CSSTransition>
    );
  }
);

const AddFilesContainerBox = ({ onDone, isUploading, isStarted, onUpdateUploadStatus }) => {
  const dispatch = useDispatch();
  const { triggerMutation: triggerKBFileUpload } = useUploadKnowledgeBaseFileData();
  const [filesData, setFilesData] = useState([{ id: uniqueId("file-id"), isVisible: true, remark: "", attachments: [] }]);

  const updateFilesData = useCallback(
    (id, actionType) => (payload) => {
      setFilesData((prevFilesData) =>
        prevFilesData.map((file) =>
          file.id === id
            ? {
                ...file,
                ...(actionType === "hide" ? { isVisible: false } : {}),
                ...(actionType === "remark" ? { remark: payload } : {}),
                ...(actionType === "attachments" ? { attachments: payload } : {}),
              }
            : file
        )
      );
    },
    []
  );

  const addNewAttachmentComponent = () => {
    if (filesData.every((file) => file.attachments.length > 0))
      setFilesData((prevFilesData) => [...prevFilesData, { id: uniqueId("file-id"), isVisible: true, remark: "", attachments: [] }]);
  };
  const removeAttachmentComponentByIdArray = useCallback(
    (idx) => () => setFilesData((prevFilesData) => prevFilesData.filter((file) => !idx.includes(file.id))),
    []
  );

  useEffect(() => {
    const uploadFiles = async () => {
      if (!filesData.length) {
        onUpdateUploadStatus((prev) => ({ ...prev, loading: false }));
        return;
      }

      if (filesData.some((file) => file.attachments.length === 0)) {
        dispatch(
          setAlert("Missing Attachments", "Every upload component should at least have 1 attachment. Please press X to cancel file upload", INFO)
        );
        onUpdateUploadStatus((prev) => ({ ...prev, loading: false }));
        return;
      }

      if (filesData.length !== new Set(filesData.map((file) => file.attachments[0].fileName)).size) {
        dispatch(
          setAlert("Duplicate Attachments Detected", "Some files have same names. Double click on file to rename and then try uploading again", INFO)
        );
        onUpdateUploadStatus((prev) => ({ ...prev, loading: false }));
        return;
      }

      onUpdateUploadStatus((prev) => ({ ...prev, isStarted: true, count: 0 }));
      const totalCountIncrease = Math.round(100 / (filesData.length + 1));
      try {
        const results = await Promise.allSettled(
          filesData.map(({ id, remark, attachments }) => {
            const { fileName, fileMime, fileData } = attachments[0];
            return triggerKBFileUpload(remark, fileName, fileMime, fileData)
              .unwrap()
              .then((response) => ({ id, success: true, ref: response.data.file_ref }))
              .catch(() => ({ id, success: false }))
              .finally(() => onUpdateUploadStatus((prev) => ({ ...prev, count: prev.count + totalCountIncrease })));
          })
        );

        const successfulUploads = results.filter((result) => result.status === "fulfilled" && result.value.success).map((result) => result.value.id);

        if (successfulUploads.length > 0) {
          dispatch(
            setAlert(
              `${successfulUploads.length} Files Uploaded Successfully`,
              "The files upload was successful. Successful uploads have been removed from the queue",
              SUCCESS
            )
          );
        }

        if (successfulUploads.length === filesData.length) {
          onUpdateUploadStatus((prev) => ({ ...prev, count: 100 }));
          setTimeout(onDone, 600);
        } else {
          setFilesData((prevFilesData) => prevFilesData.map((file) => (successfulUploads.includes(file.id) ? { ...file, isVisible: false } : file)));
          dispatch(
            setAlert(
              `${filesData.length - successfulUploads.length} Files Upload Failed`,
              "Few file have failed to upload. Please try again",
              WARNING
            )
          );
          onUpdateUploadStatus({ count: 0, loading: false, isStarted: false });
        }
      } catch (error) {
        dispatch(setAlert("Upload Failed", "Unexpected error occurred while uploading files", ERROR));
        onUpdateUploadStatus({ count: 0, loading: false, isStarted: false });
      }
    };

    if (isUploading && !isStarted) uploadFiles();
  }, [isUploading, filesData, dispatch, onDone, onUpdateUploadStatus, triggerKBFileUpload, isStarted, removeAttachmentComponentByIdArray]);

  return (
    <Stack sx={{ maxHeight: "70vh" }}>
      <TransitionGroup component="div" style={{ margin: "12px 0", overflowY: "auto", maxHeight: "100%" }}>
        {filesData.map((fileData) => (
          <NewKBFileUploadComponent
            key={fileData.id}
            componentAttributes={fileData}
            isVisible={fileData.isVisible}
            onSetNewAttachments={updateFilesData(fileData.id, "attachments")}
            onSetNewRemark={updateFilesData(fileData.id, "remark")}
            onHideAttachment={updateFilesData(fileData.id, "hide")}
            onRemoveAttachments={removeAttachmentComponentByIdArray([fileData.id])}
            disabled={isUploading}
          />
        ))}
      </TransitionGroup>
      <Button size="large" color="primary" variant="outlined" onClick={addNewAttachmentComponent} sx={{ mx: 2 }} disabled={isUploading}>
        Add New File
      </Button>
    </Stack>
  );
};

export default function NewKBUploadDialog({ onHandleClose, refetchFn }) {
  const [isUploadTriggered, setIsUploadTriggered] = useState({ loading: false, count: 0, isStarted: false });

  const onClose = useCallback(() => {
    onHandleClose();
    refetchFn();
  }, [onHandleClose, refetchFn]);

  const onDone = useCallback(() => {
    setIsUploadTriggered({ count: 100, loading: false });
    onClose();
  }, [onClose]);

  return (
    <Dialog
      open={true}
      onClose={isUploadTriggered.loading ? null : onClose}
      TransitionComponent={Transition}
      PaperProps={{ sx: sectionUiLookBase }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ fontWeight: 500 }}>Add New Files</DialogTitle>
      <DialogContent style={{ overflow: "hidden" }}>
        <DialogContentText>You may add one or more files with remarks (optional) for each file </DialogContentText>
        <AddFilesContainerBox
          onDone={onDone}
          isUploading={isUploadTriggered.loading}
          isStarted={isUploadTriggered.isStarted}
          onUpdateUploadStatus={setIsUploadTriggered}
        />
        {!!isUploadTriggered.loading && (
          <ProgressIndicator displayPercentage outerProgressSx={{ marginLeft: "16px" }} boxSx={{ mt: 1, mb: -1 }} value={isUploadTriggered.count} />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={onClose} disabled={isUploadTriggered.loading}>
          Cancel Uploads
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={isUploadTriggered.loading}
          onClick={() => setIsUploadTriggered((prev) => ({ ...prev, loading: true, isStarted: false }))}
        >
          <span>Upload File Attachments</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
