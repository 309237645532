import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setAlert } from "../../features/Alerts/slice/alertSlice";
import { logIn, logOut } from "../../features/Auth/slice/authSlice";
import { AUTH_ACTION } from "../constants/api";
import { WARNING } from "../constants/common";

/* GLOBAL TRACKERS TO REFRESH API TOKEN AND SCHEDULE REMAINING APIS INCASE OF TOKEN EXPIRATION */
let isRefreshing = false;
let refreshSubscribers = [];

const baseQuery = fetchBaseQuery({
  // eslint-disable-next-line no-undef
  baseUrl: process.env.REACT_APP_BE_URL,
  paramsSerializer: (params) =>
    Object.entries(params)
      .flatMap(([key, value]) =>
        value == null
          ? []
          : Array.isArray(value)
            ? value.map((item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`)
            : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&"),
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) headers.set("authorization", `Bearer ${token}`);
    return headers;
  },
});

const subscribeToRefresh = (callback) => refreshSubscribers.push(callback);
const onRefreshed = () => {
  refreshSubscribers.forEach((callback) => callback());
  refreshSubscribers = [];
};

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  try {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        const refreshResult = await baseQuery({ url: AUTH_ACTION.BASE, method: "POST", body: { action: AUTH_ACTION.REFRESH } }, api, extraOptions);

        if (refreshResult?.data?.success) {
          api.dispatch(logIn(refreshResult.data.data));
          onRefreshed();
          isRefreshing = false;
          return baseQuery(args, api, extraOptions);
        } else {
          await baseQuery({ url: AUTH_ACTION.BASE, method: "POST", body: { action: AUTH_ACTION.LOGOUT } }, api, extraOptions);
          api.dispatch(setAlert(`Security Alert !!`, "We have logged you out for your account security", WARNING));
          api.dispatch(logOut());
          isRefreshing = false;
          return null;
        }
      } else {
        return new Promise((resolve) => subscribeToRefresh(() => resolve(baseQuery(args, api, extraOptions))));
      }
    }
    return result;
  } catch (error) {
    console.debug("An unexpected error occurred:", error);
    isRefreshing = false;
    return null;
  }
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReAuth,
  endpoints: () => ({}),
});
