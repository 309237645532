import * as MIcons from "@mui/icons-material";
import { Avatar, Stack, Tab, Tabs, Typography } from "@mui/material";
import { createElement, memo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { routeToManageDataTableEntries } from "../../app/constants/routes";
import { selectDataTypeFormId, selectManageFormTabOptions } from "./slices/ManageDataSlice";

const VerticalTabLabelComponent = memo(({ color, icon, label, id }) => {
  return (
    <Stack direction="row" spacing={2}>
      <Avatar
        sx={{
          backgroundColor: `${color}.background`,
          border: "1px solid",
          height: 42,
          width: 42,
          "& .MuiSvgIcon-root, & .MuiIcon-root": {
            color: `${color}.main`,
            fontSize: 18,
          },
        }}
      >
        {MIcons[icon] ? createElement(MIcons[icon]) : <MIcons.Quiz />}
      </Avatar>
      <Stack direction="column">
        <Typography component="p" variant="h6" color="textPrimary" fontWeight={500} noWrap>
          {label}
        </Typography>
        <Typography color="textSecondary" noWrap>
          {id}
        </Typography>
      </Stack>
    </Stack>
  );
});

export default function FormListTabLayout() {
  const activeFormId = useSelector(selectDataTypeFormId);
  const tabOptions = useSelector(selectManageFormTabOptions);

  return (
    <Tabs
      slots={{
        StartScrollButtonIcon: MIcons.KeyboardDoubleArrowLeft,
        EndScrollButtonIcon: MIcons.KeyboardDoubleArrowRight,
      }}
      orientation="vertical"
      variant="scrollable"
      scrollButtons="auto"
      allowScrollButtonsMobile
      value={activeFormId}
      sx={{
        "& .MuiTabs-scroller": {
          flexGrow: 0,
        },
        "& .MuiTab-root": {
          alignItems: "start",
          textAlign: "start",
        },
        "& .MuiTabs-flexContainer": {
          gap: 1.5,
        },
        justifyContent: "center",
        backgroundColor: "background.default",
        borderRight: "1px solid",
        borderColor: "primary.sectionBorder100",
        minWidth: "230px",
      }}
    >
      {tabOptions.map(({ id, label, icon }) => (
        <Tab
          LinkComponent={Link}
          to={routeToManageDataTableEntries(id)}
          disableRipple
          key={id}
          value={id}
          label={<VerticalTabLabelComponent id={id} color="primary" icon={icon} label={label} />}
        />
      ))}
    </Tabs>
  );
}
