import { Box, Tab, Tabs } from "@mui/material";
import { memo, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { routeUserAnalysisParam } from "../../app/constants/routes";
import AdvancedTable from "../../components/advancedTable/AdvancedTable";
import { ModernTabLabelComponent } from "../../components/ui/UiUtils";
import { useFetchUserAnalysisData } from "../../hooks/advancedTableHook";
import { modernTabStyle } from "../../styled/inlineCssStyles";
import { USER_ACTIVITY_TASK_DETAILS, userAnalysisTableDetailsTabData } from "./config/tableDetailsTabData";

const UserDetailsTabLayout = ({ items, activeTab, onHandleChange }) => (
  <Tabs
    value={activeTab}
    variant="fullWidth"
    centered
    onChange={onHandleChange}
    sx={{ ...modernTabStyle, my: 2 }}
    TabIndicatorProps={{ sx: { backgroundColor: `transparent` } }}
  >
    {items.map(({ id, color, label, icon }) => (
      <Tab
        sx={{ padding: 0.5 }}
        disableRipple
        key={id}
        value={id}
        label={<ModernTabLabelComponent color={color} isActive={id === activeTab} label={label} icon={icon} />}
      />
    ))}
  </Tabs>
);

const UserDetailsTable = memo(({ config, activeTab, activeErn }) => {
  const tableConfig = config();
  const apiHookWithParams = [useFetchUserAnalysisData, [activeTab, tableConfig.tableProps.columnProps, activeErn]];
  return <AdvancedTable config={tableConfig} apiHookWithParams={apiHookWithParams} />;
});

export default function UserActivityDetails() {
  const { [routeUserAnalysisParam]: activeErn } = useParams();
  const [activeTab, setActiveTab] = useState(USER_ACTIVITY_TASK_DETAILS);
  const onHandleChange = useCallback((_, changedTab) => setActiveTab(changedTab), []);

  return (
    <Box>
      <UserDetailsTabLayout items={Object.values(userAnalysisTableDetailsTabData)} activeTab={activeTab} onHandleChange={onHandleChange} />
      <UserDetailsTable config={userAnalysisTableDetailsTabData[activeTab].getConfig} activeTab={activeTab} activeErn={activeErn} />
    </Box>
  );
}
