import { Delete, ModeEdit, Visibility } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { memo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MANAGE_DATA_FORM_TYPE } from "../../app/constants/config/DynamicFields";
import { routeToManageDataForm } from "../../app/constants/routes";
import { UiIconButton } from "../../components/ui/UiUtils";
import { DeleteSingleRowOption } from "./Modals/DeleteModal";
import ViewRowDataModal from "./Modals/ViewRowDataModal";
import { selectDataTypeFormId } from "./slices/ManageDataSlice";

export const RenderPrimaryKeyWithEditOption = memo(({ displayData: [columns, row] = [[], {}], rowId, keyCol, refetchData }) => {
  const nav = useNavigate();
  const activeDataTypeId = useSelector(selectDataTypeFormId);
  const [openDialogOptions, setOpenDialogOptions] = useState({ view: false, delete: false });
  const onChange = (key) => () => setOpenDialogOptions((prev) => ({ ...prev, [key]: !prev[key] }));

  return (
    <Stack component="div" direction="row" sx={{ alignItems: "center", justifyContent: "center" }}>
      <UiIconButton iconSize={18} title="View Row Details" iconColor="secondary.main" arrow onClickIcon={onChange("view")}>
        <Visibility />
      </UiIconButton>
      <UiIconButton
        iconSize={18}
        title="Edit Row Details"
        arrow
        onClickIcon={() => nav(routeToManageDataForm(activeDataTypeId, MANAGE_DATA_FORM_TYPE.UPDATE, keyCol))}
      >
        <ModeEdit />
      </UiIconButton>
      <UiIconButton iconSize={18} title="Delete Rows" iconColor="error.main" arrow onClickIcon={onChange("delete")}>
        <Delete />
      </UiIconButton>
      {!!openDialogOptions.view && <ViewRowDataModal columns={columns} row={row} onClose={onChange("view")} />}
      {!!openDialogOptions.delete && (
        <DeleteSingleRowOption dataTypeId={activeDataTypeId} rowId={rowId} keyCol={keyCol} onClose={onChange("delete")} refetchData={refetchData} />
      )}
    </Stack>
  );
});
