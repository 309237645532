import { alpha } from "@mui/system";

export const sectionUiLookBase = {
  backgroundColor: "primary.sectionContainer",
  borderRadius: 2.5,
  border: "1px solid",
  borderColor: "primary.sectionBorder600",
  boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 0px 0px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
};

export const authLayoutUIStyles = {
  ...sectionUiLookBase,
  padding: [2, 3],
  borderRadius: "8px",
  maxWidth: "420px",
  minWidth: "340px",
  minHeight: "595px",
  textAlign: "center",
  margin: [0, 2],
  backgroundColor: "transparent",
  background: (theme) => alpha(theme.palette.primary.sectionContainer, 0.21),
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  backdropFilter: "blur(4.9px)",
  webkitBackdropFilter: "blur(4.9px)",
  "& .MuiFormControl-root": {
    backgroundColor: "primary.sectionContainer",
    "& .MuiSvgIcon-root, & .MuiIcon-root": { color: "text.primary" },
    "& .MuiInputBase-input": { fontSize: "0.975rem" },
  },
  "& .MuiAlert-root .MuiAlert-message": { fontSize: "0.875rem" },
  "& .MuiAvatar-root": {
    margin: "auto",
    width: "70px !important",
    height: "70px !important",
    "& .MuiAvatar-img": { height: "60px !important", width: "fit-content !important" },
  },
};

export const exportAccordionButtonDropdownStyles = {
  "&.Mui-disabled": { backgroundColor: `primary.sectionContainer` },
  "& .MuiAccordionSummary-root.Mui-expanded": { minHeight: "fit-content" },
  "& .MuiAccordionSummary-content.Mui-expanded": { marginTop: "12px", marginBottom: "0px" },
  "& .MuiAccordionDetails-root": { py: 0 },
};

export const assignedEmployeeTableCellStyle = {
  "&.cell-container": { display: "inline-block", p: 2, maxWidth: "220px", width: "100%" },
  "& .MuiButtonBase-root, & .MuiAccordionDetails-root, & .MuiAccordionSummary-root": { p: 0, minHeight: 0 },
  "& .MuiAccordionSummary-content": { m: 0, ml: 1, justifyContent: "space-between" },
  "& .MuiAccordionSummary-content.Mui-expanded": { my: 1.5 },
  "& .MuiAccordionDetails-root": { ml: 1.25, pl: 2, borderLeft: "1px dashed", borderColor: "primary.main" },
  "& .employee-item": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontSize: "inherit",
      fontWeight: "inherit",
      color: "inherit",
    },
  },
  "& .MuiChip-root": { height: "20px", borderRadius: 1, ml: 1 },
  "& .MuiTypography-root": { textAlign: "start", lineHeight: "1", minWidth: "120px" },
};

export const inlineHighlightTextStyle = {
  display: "inline-block",
  backgroundColor: "text.secondary",
  p: 0.375,
  borderRadius: 0.75,
  color: "text.reverse",
};

export const autocompleteGroupPopperStyle = {
  "& .MuiAutocomplete-groupLabel": {
    fontWeight: 700,
    backgroundColor: "primary.background",
    color: "primary.main",
    textTransform: "uppercase",
    borderLeft: "2px solid",
    borderRight: "2px solid",
    borderColor: "primary.main",
    lineHeight: "36px",
  },
};

export const employeeLocationMainPageStyles = {
  display: "flex",
  padding: 3,
  flex: 1,
  "& .select-location": {
    position: "absolute",
    top: 0,
    left: 0,
    m: 2,
    display: "flex",
    alignItems: "center",
    width: "350px",
  },
  "& .location-details": {
    position: "absolute",
    bottom: 0,
    left: 0,
    m: 2,
    p: 2,
    boxShadow: "4px 9px 15px 5px rgba(0,0,0,0.1)",
    ...sectionUiLookBase,
    width: "278px",
  },
  "& .maplibregl-popup-tip, & .maplibregl-popup-content": {
    transform: "translate(-12px,-26px)",
  },
  "& .maplibregl-popup-tip": {
    borderTopColor: "primary.main",
  },
  "& .maplibregl-popup-content": {
    background: "none",
    px: 2,
    py: 1,
    boxShadow: "4px 9px 15px 5px rgba(0,0,0,0.1)",
    ...sectionUiLookBase,
    borderColor: "primary.main",
    borderBottomRightRadius: 0,
  },
};

export const quantityPopperCellStyles = {
  position: "relative",
  display: "inline-flex",
  maxWidth: "200px",
  width: "100%",
  height: "35.70px",
  borderRadius: 2,
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
};

export const quantityPopperCellPaperStyle = {
  width: 500,
  height: 300,
  boxShadow: 24,
  m: 1,
  textAlign: "start",
  overflowY: "auto",
  ...sectionUiLookBase,
  "& .popper-paper-head-box": { backgroundColor: "primary.sectionContainer", position: "sticky", top: 0, p: 2 },
  "& .popper-paper-heading": { fontSize: "1.05rem", lineHeight: 1.4, fontWeight: 600, color: "primary.main" },
  "& .popper-paper-subheading": { fontWeight: 500, color: "text.secondary" },
};

export const tabStyle = (includeBorderTop = true) => ({
  backgroundColor: "background.default",
  borderBottom: "1px solid",
  borderColor: "primary.sectionBorder600",
  justifyContent: "center",
  "& .MuiTabs-scroller": {
    flexGrow: 0,
  },
  "& .Mui-selected": includeBorderTop
    ? {
        borderTop: "2.5px solid",
        borderColor: "primary.main",
      }
    : {},
  ".MuiTabScrollButton-root .MuiSvgIcon-root": {
    color: "primary.main",
    fontSize: 22,
  },
});

export const modernTabStyle = {
  px: 3,
  "& .MuiTab-root": {
    minHeight: 48,
    textTransform: "none",
    fontSize: "0.875rem",
    fontWeight: 500,
    marginRight: (theme) => theme.spacing(1),
    color: (theme) => theme.palette.text.secondary,
    "&.Mui-selected": {
      color: (theme) => theme.palette.text.primary,
    },
    "& .MuiTab-iconWrapper": {
      marginRight: (theme) => theme.spacing(1),
    },
  },
  borderBottom: 0,
  borderColor: "divider",
  "& .MuiTabs-indicator": {
    height: 3,
    borderRadius: "3px 3px 0 0",
  },
};

export const commentListItemSx = (sx = {}) => ({
  position: "relative",
  "&::before": {
    content: "''",
    position: "absolute",
    left: "36px",
    top: 0,
    bottom: 0,
    width: 2,
    border: 0,
    borderLeft: "2px solid",
    borderColor: "primary.sectionBorder600",
    ...sx,
  },
});

export const richTextEditorReadOnlyStyles = {
  p: 1,
  pl: 2,
  position: "relative",
  width: "100%",
  "& .tiptap.ProseMirror .ms": { fontWeight: 600 },
  "&::before": {
    content: "''",
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    borderRadius: 2,
    width: 3,
    backgroundColor: "primary.main",
  },
};

export const richTextEditorStyles = {
  position: "relative",
  width: "100%",
  "& .MuiTiptap-RichTextField-content": {
    backgroundColor: "background.paper",
    "& .MuiSvgIcon-root, & .MuiIcon-root": { color: (theme) => `${theme.palette.text.primary} !important` },
  },
  "& .MuiTiptap-RichTextContent-root": { backgroundColor: "primary.sectionContainer", overflowY: "auto" },
  "& .MuiTiptap-RichTextContent-root .tiptap.ProseMirror .ms": { fontWeight: 600 },
};

// INFO: Make sure --loader-size and --loader-color is defined before rendering loader css styles
export const loaderStyles = {
  width: "var(--loader-size)",
  height: "var(--loader-size)",
  color: "var(--color)",
  position: "relative",
  background: "radial-gradient(calc(var(--loader-size) * 0.25), currentColor 94%, #0000)",
  "&:before": {
    content: '""',
    position: "absolute",
    inset: 0,
    borderRadius: "50%",
    background: `radial-gradient(calc(var(--loader-size) * 0.225) at bottom right, #0000 94%, currentColor) top left, radial-gradient(calc(var(--loader-size) * 0.225) at bottom left, #0000 94%, currentColor) top right, radial-gradient(calc(var(--loader-size) * 0.225) at top right, #0000 94%, currentColor) bottom left, radial-gradient(calc(var(--loader-size) * 0.225) at top left, #0000 94%, currentColor) bottom right`,
    backgroundSize: `calc(var(--loader-size) * 0.5) calc(var(--loader-size) * 0.5)`,
    backgroundRepeat: "no-repeat",
    animation: "loader 1.5s infinite cubic-bezier(0.3, 1, 0, 1)",
  },
  "@keyframes loader": {
    "33%": {
      inset: "calc(var(--loader-size) * -0.25)",
      transform: "rotate(0deg)",
    },
    "66%": {
      inset: "calc(var(--loader-size) * -0.25)",
      transform: "rotate(90deg)",
    },
    "100%": {
      inset: 0,
      transform: "rotate(90deg)",
    },
  },
};
