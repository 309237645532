import { Button, Grid, ListItem, ListItemAvatar, ListItemText, Popover } from "@mui/material";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { routeToSingleUserAnalysisPage } from "../../app/constants/routes";
import { EmployeeAvatar } from "../../components/ui/UiUtils";
import { sectionUiLookBase } from "../../styled/inlineCssStyles";

export const UserTabLayoutLabelComp = memo(({ isActive, userRole, userName, userId }) => (
  <ListItem
    sx={{
      opacity: isActive ? 1 : 0.6,
      minWidth: "150px",
      maxWidth: "fit-content",
      mx: 1,
      textTransform: "capitalize",
    }}
  >
    <ListItemAvatar>
      <EmployeeAvatar empId={userId} />
    </ListItemAvatar>
    <ListItemText
      sx={{ m: 0, p: 0 }}
      primary={userName}
      secondary={`${userRole} (${userId})`}
      primaryTypographyProps={{ fontWeight: 500, color: "primary.main", fontSize: "15px", noWrap: true }}
      secondaryTypographyProps={{ fontSize: "12px", noWrap: true }}
    />
  </ListItem>
));

export const UserExtendedTabLayoutPopperView = memo(({ activeTabId, tabItems, handleClose, anchorEl }) => (
  <Popover
    open={!!anchorEl}
    anchorEl={anchorEl}
    onClose={handleClose}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    slotProps={{
      paper: {
        sx: {
          minWidth: 280,
          maxWidth: "calc(100vw - 280px)",
          maxHeight: "450px",
          overflowX: "hidden",
          overflowY: "auto",
          mt: 1.5,
          ...sectionUiLookBase,
        },
      },
    }}
  >
    <Grid component="div" container spacing={2} sx={{ p: 2 }}>
      {tabItems.map(({ ern, role, name }) => {
        const isActive = ern === activeTabId;
        return (
          <Grid item xs={12} sm={6} md={4} lg={3} key={ern} sx={{ flex: 1 }}>
            <Button
              onClick={() => handleClose()}
              LinkComponent={Link}
              to={routeToSingleUserAnalysisPage(ern)}
              size="small"
              sx={{ border: "1px solid", borderRadius: 1, borderColor: isActive ? "primary.main" : "transparent", width: "100%", display: "block" }}
            >
              <UserTabLayoutLabelComp isActive={isActive} userRole={role} userId={ern} userName={name} />
            </Button>
          </Grid>
        );
      })}
    </Grid>
  </Popover>
));
