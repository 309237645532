import { HowToRegOutlined, ManageAccounts } from "@mui/icons-material";
import { GMaterialIcon } from "../../../components/ui/IconUtils";

export const ROUTINE_ATTENDANCE = "routine-attendance";
export const ATTENDANCE_ADJUSTMENT = "attendance-with-issues";
export const ATTENDANCE_OVERTIME = "attendance-with-overtime";

export const attendanceStatsConfig = (count = {}) => ({
  total_employees: {
    id: "totalEmp",
    count: count.totalEmp || 0,
    label: "Total Employees",
    color: "info",
    icon: <GMaterialIcon icon="groups_3" />,
  },
  active_employees: {
    id: "present",
    count: count.present || 0,
    label: "Active Employees",
    color: "success",
    icon: <HowToRegOutlined />,
  },
  employees_on_leave: {
    id: "conflict",
    count: count.conflict || 0,
    label: "Attendance Adjustments",
    color: "error",
    icon: <ManageAccounts />,
  },
  late: {
    id: "lateCount",
    count: count.lateCount || 0,
    label: "Late Arrivals",
    color: "warning",
    icon: <GMaterialIcon icon="acute" />,
  },
});

export const routineAttendanceStatusMapping = {
  late: { label: "Late", color: "error" },
  present: { label: "Present", color: "success" },
  absent: { label: "Absent", color: "warning" },
  pending: { label: "Pending", color: "secondary" },
  time_off: { label: "Time Off", color: "info" },
};

export const conflictTypeMapping = {
  IN: { label: "Clock In", color: "info" },
  OUT: { label: "Clock Out", color: "secondary" },
};

export const overtimeAttendanceStatusMapping = {
  PDG: { label: "Pending", color: "warning" },
  APR: { label: "Approved", color: "success" },
  DCL: { label: "Rejected", color: "error" },
};