import { AddRoad, CheckCircle, ErrorOutline, Hardware, PriorityHigh, TrendingUp } from "@mui/icons-material";
import { ERROR, INFO, SECONDARY, SUCCESS, WARNING } from "../../../app/constants/common";
import { attendanceStatsConfig } from "../../EmployeeAttendance/config/attendanceUtils";

export const attendanceStatsDataConfig = (data) => {
  const inheritedData = attendanceStatsConfig(data ?? {});
  return [inheritedData.total_employees, inheritedData.active_employees];
};

export const tasksPriorityData = (data) => [
  {
    id: "LOW",
    label: "Normal Priority Tasks",
    color: SUCCESS,
    icon: <CheckCircle />,
    get count() {
      return data?.[this.id] || 0;
    },
  },
  {
    id: "MEDIUM",
    label: "Medium Priority Tasks",
    color: INFO,
    icon: <TrendingUp />,
    get count() {
      return data?.[this.id] || 0;
    },
  },
  {
    id: "HIGH",
    label: "High Priority Tasks",
    color: WARNING,
    icon: <PriorityHigh />,
    get count() {
      return data?.[this.id] || 0;
    },
  },
  {
    id: "CRITICAL",
    label: "Critical Priority Tasks",
    color: ERROR,
    icon: <ErrorOutline />,
    get count() {
      return data?.[this.id] || 0;
    },
  },
];

export const taskTypeCount = (data) => [
  {
    id: "QMP",
    label: "QMP",
    color: SECONDARY,
    icon: <Hardware />,
    get count() {
      return data?.[this.id] || 0;
    },
  },
  {
    id: "INSTALLATION",
    label: "Installation",
    color: WARNING,
    icon: <AddRoad />,
    get count() {
      return data?.[this.id] || 0;
    },
  },
];
