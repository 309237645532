import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { WARNING } from "../../app/constants/common";
import { PUBLIC_ROUTES, nextURLLocationParam } from "../../app/constants/routes";
import { setAlert } from "../../features/Alerts/slice/alertSlice";
import { isTokenValid, selectCurrentToken } from "../../features/Auth/slice/authSlice";
import { useLogoutMutation } from "../../hooks/ApiHooks";
import { useAppNavigation } from "../../hooks/navigationHook";
import { appNavigationItems } from "../config/sidebarConfig";
import { LoadingProgress } from "../ui/UiUtils";
import DashBoardLayout from "./DashboardLayout";

export const RedirectLoggedInOutUser = () => {
  const nextLocation = new URLSearchParams(useLocation().search).get(nextURLLocationParam);
  const userToken = useSelector(selectCurrentToken);
  const isUserLoggedIn = useSelector(isTokenValid);
  const { currentRouteProperties } = useAppNavigation(appNavigationItems);
  const [loading, setLoading] = useState(true);
  const { triggerMutation: logoutUserFromServer, data, isLoading: dataLoading } = useLogoutMutation();

  useEffect(() => {
    if (data && !dataLoading) {
      setLoading(false);
    }
  }, [data, dataLoading]);

  useEffect(() => {
    if (userToken && !isTokenValid) logoutUserFromServer();
    else setLoading(false);
  }, [userToken, isUserLoggedIn, logoutUserFromServer]);

  return loading ? (
    <LoadingProgress sx={{ height: "100vh" }} />
  ) : isUserLoggedIn ? (
    <Navigate to={nextLocation ?? currentRouteProperties.defaultRoute} replace />
  ) : (
    <Outlet />
  );
};

export const RequireAuthForRoutes = () => {
  const location = useLocation();
  const isUserLoggedIn = useSelector(isTokenValid);
  const { currentRouteProperties } = useAppNavigation(appNavigationItems);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentRouteProperties.isAccessible) {
      dispatch(setAlert("Unauthorized Access", currentRouteProperties.reason, WARNING));
    }
  }, [dispatch, currentRouteProperties]);

  if (!isUserLoggedIn) {
    return <Navigate to={`${PUBLIC_ROUTES.login}?${nextURLLocationParam}=${location.pathname}`} replace />;
  }

  if (!currentRouteProperties.isAccessible) {
    return <Navigate to={currentRouteProperties.defaultRoute} replace />;
  }

  return <DashBoardLayout />;
};
