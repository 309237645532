import { ArrowBack, Autorenew, Clear, ClearAll, Close, FileUpload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AppBar,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Collapse,
  Container,
  Dialog,
  Grid,
  IconButton,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { Fragment, memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { ERROR, SUCCESS, WARNING } from "../../../app/constants/common";
import { CONFIG_CONSTANTS_OPTIONS } from "../../../app/constants/config/AppSettings";
import { IS_NEW_FORM, IS_VALID_FORM_TYPE } from "../../../app/constants/config/DynamicFields";
import {
  PRIVATE_ROUTES,
  routeManageDataDataTypeParam,
  routeManageDataFormTypeParam,
  routeManageDataKeycolParam,
  routeToManageDataForm,
  routeToManageDataTableEntries,
} from "../../../app/constants/routes";
import {
  convertFileToBase64File,
  getAdjustedColorForContrast,
  parseFieldValue,
  reducedName,
  stringToColor,
} from "../../../app/utils/utilityFunctions";
import { IMAGE_MIME_TYPES, filterAcceptedFileTypes } from "../../../components/AttachFileHandler/fileUtils";
import { RenderOnDemandField } from "../../../components/ui/InputUI";
import { AppThemeMode, ConfirmationDialog, EmptyDataVisualComp, LoadingProgress, Transition, UiIconButton } from "../../../components/ui/UiUtils";
import { useGetManageDataFormMutation, useSubmitFormDataMutation } from "../../../hooks/ApiHooks";
import { useGetOptionsForManageRecordForm, useUpdateProfilePicture } from "../../../hooks/valueProviderHooks";
import { MuiTooltip } from "../../../styled/commonStyles";
import { sectionUiLookBase } from "../../../styled/inlineCssStyles";
import { setAlert } from "../../Alerts/slice/alertSlice";
import { selectPresetConfig } from "../../AppSettings/appSlice";
import { selectUserProfile, updateProfilePicture } from "../../Auth/slice/authSlice";
import { getFormConfig } from "../config/formConfig";
import { clearAllFormFields, selectAllFieldsWithId, selectAreRequiredFieldsValid, setFieldConfigForCurrentForm } from "../slices/ManageDataSlice";

const UserProfilePicture = ({ setProfilePictureData, isLoading }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef();
  const { ern, name: userName, picture } = useSelector(selectUserProfile);
  const [openPreventRemoveProfile, setOpenPreventRemoveProfile] = useState(false);
  const [fileSrc, setFileSrc] = useState(picture);
  const theme = useTheme();

  useEffect(() => setFileSrc(picture), [picture]);
  const onErrorRefetchCurrentProfile = () => (fileSrc === picture ? dispatch(updateProfilePicture([ern, undefined])) : null);

  const onReset = () => {
    setFileSrc(picture);
    setProfilePictureData(0);
  };
  const onClearPicture = () => {
    if (fileSrc) setOpenPreventRemoveProfile(true);
  };
  const onConfirmClearPicture = () => {
    setFileSrc(null);
    setProfilePictureData({ fileData: null });
    setOpenPreventRemoveProfile(false);
  };

  const onHandleUpload = (event) => {
    const [files, fileAcceptedSuccess] = filterAcceptedFileTypes(event.target.files, undefined, IMAGE_MIME_TYPES);
    if (!fileAcceptedSuccess) {
      dispatch(setAlert("File Upload Error", "The chosen file was not accepted due to either file extension type or size limit of 50 MB", WARNING));
      return;
    }
    convertFileToBase64File(files[0]).then((fileObject) => setProfilePictureData(fileObject));
    setFileSrc(URL.createObjectURL(files[0]));
    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  return (
    <Stack alignItems="center" justifyContent="center" gap={2}>
      <Avatar
        sx={{
          height: 140,
          width: 140,
          fontSize: "30px",
          fontWeight: 600,
          border: "0.5px solid",
          borderColor: "primary.main",
          ...(!fileSrc && { backgroundColor: getAdjustedColorForContrast(stringToColor(userName), theme)[0] }),
        }}
        src={fileSrc ?? undefined}
        slotProps={{ img: { onError: onErrorRefetchCurrentProfile } }}
      >
        {reducedName(userName)}
      </Avatar>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="contained-button-profile-picture-file"
        type="file"
        ref={fileInputRef}
        onChange={onHandleUpload}
      />
      <ButtonGroup variant="outlined" disabled={isLoading}>
        <Button color="error" startIcon={<Clear />} onClick={onClearPicture}>
          Remove Picture
        </Button>
        <Button color="primary" startIcon={<ClearAll />} onClick={onReset}>
          Reset Picture
        </Button>
        <Button color="info" startIcon={<FileUpload />} onClick={() => fileInputRef.current.click()}>
          Upload Picture
        </Button>
      </ButtonGroup>
      <ConfirmationDialog
        open={openPreventRemoveProfile}
        onClose={() => setOpenPreventRemoveProfile(false)}
        onSubmit={onConfirmClearPicture}
        title="Confirm Profile Picture Removal?"
        subTitle="All the existing (current profile picture) and newly uploaded profile pictures will be removed. Do you want to continue?"
      />
    </Stack>
  );
};

const RenderFormField = memo(({ field, isFormSubmitted }) => {
  const { isLoading, isError, fieldIsError, currentFieldValue, optionsData, onChange, isDisabled } = useGetOptionsForManageRecordForm(
    field.id,
    field.fieldType,
    field.fieldRegex,
    field.valueProviderProps || {}
  );

  return (
    <MuiTooltip title={!field.isEditable ? "This field is not editable" : null} arrow fontSize={10}>
      <div>
        <RenderOnDemandField
          field={field}
          fieldType={field.fieldType}
          fieldValue={currentFieldValue}
          isFieldDisabled={isFormSubmitted || isDisabled}
          optionsData={optionsData}
          onChange={onChange}
          getFromValue={field.getFromValue}
          setToValue={field.setToValue}
          errorString={field.errorString}
          fieldError={fieldIsError}
          loadingOptions={isLoading}
          errorLoading={isError}
        />
      </div>
    </MuiTooltip>
  );
});

const FormFields = ({ formConfig, isFormSubmitted }) => (
  <Box sx={{ mt: 3 }}>
    <Grid container spacing={3}>
      {Object.values(formConfig || {}).map((field) => (
        <Grid item xs={12} sm={12} md={6} key={field.id}>
          <RenderFormField field={field} isFormSubmitted={isFormSubmitted} />
        </Grid>
      ))}
    </Grid>
  </Box>
);

const FormHeader = memo(({ isFormSubmitted, refetchData }) => {
  const { [routeManageDataKeycolParam]: formKey } = useParams();
  return (
    <Box sx={{ mb: 2 }}>
      <Stack direction="row" justifyContent="space-between">
        <Box>
          <Typography variant="h6" fontWeight={500} color="primary">
            {formKey ? "Update" : "Create New"} Data Record
          </Typography>
          <Typography color="text.secondary" fontWeight={300}>
            Please Review and {formKey ? "Modify" : "Add"} Required Fields. <br />
          </Typography>
        </Box>
        <UiIconButton title="Refetch Form" arrow disabled={isFormSubmitted} onClickIcon={refetchData}>
          <Autorenew />
        </UiIconButton>
      </Stack>
      <Typography color="text.secondary" fontWeight={300}>
        <br />
        <b>Instructions: </b>
        <br />
        {!!formKey && "- Editable fields are indicated."} {!!formKey && <br />}
        - Fields marked with ** are required. <br />
        - Review the field error (if any) by adhering to the helper text present below upon form submission. <br />- For more assistance, please
        contact the administrator.
      </Typography>
    </Box>
  );
});

const RenderSubmitButton = memo(({ errorMessage, onCloseErrorMessage, isFormSubmitted }) => {
  const { [routeManageDataKeycolParam]: formKey } = useParams();
  const areFieldsValid = useSelector(selectAreRequiredFieldsValid);
  const formData = useSelector(selectAllFieldsWithId);

  return (
    <Fragment>
      <LoadingButton
        fullWidth
        variant="contained"
        loading={isFormSubmitted}
        disabled={Object.keys(formData).length === 0 || !areFieldsValid}
        type="submit"
        sx={{ mt: 4 }}
        size="large"
      >
        {formKey ? "Update Existing" : "Add New"} Record
      </LoadingButton>
      <Collapse in={!!errorMessage}>
        <Alert
          sx={{ my: 2, fontWeight: 400, "& .MuiAlert-message": { fontSize: "0.95rem" } }}
          severity="error"
          variant="filled"
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={onCloseErrorMessage}>
              <Close fontSize="inherit" />
            </IconButton>
          }
        >
          {errorMessage}
        </Alert>
      </Collapse>
    </Fragment>
  );
});

const RenderManageForm = ({ refetchData, formConfig }) => {
  const { [routeManageDataDataTypeParam]: dataType, [routeManageDataFormTypeParam]: formType, [routeManageDataKeycolParam]: formKey } = useParams();
  const [errorMessage, setErrorMessage] = useState();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const formData = useSelector(selectAllFieldsWithId);
  const { triggerMutation, isLoading, isError, error, data } = useSubmitFormDataMutation();
  const [isPreRequitesLoaded, setProfilePictureData, { triggerUploadProfilePicture, isProfilePictureUpdateFailed }] = useUpdateProfilePicture(
    dataType,
    formKey
  );

  const dispatch = useDispatch();
  const nav = useNavigate();

  /* INFO: Profile Picture (If Form is for current employee) uploads after the form submit is successful */
  const onSubmit = (e) => {
    e.preventDefault();
    setIsFormSubmitted(true);
    triggerMutation(dataType, formData, formType, formKey);
  };

  useEffect(() => {
    const successAction = () => {
      dispatch(
        setAlert(
          `Successfully ${formKey ? "Edited" : "Added"} Data Entry`,
          "We have successfully recorded the data. Please check the entries for the change",
          SUCCESS
        )
      );
      nav(routeToManageDataTableEntries(dataType), { replace: true });
    };

    if (!isLoading && data) {
      if (!isPreRequitesLoaded) triggerUploadProfilePicture();
      else successAction();
    }
  }, [dispatch, data, dataType, formKey, isLoading, isPreRequitesLoaded, nav, triggerUploadProfilePicture]);

  useEffect(() => {
    if (isProfilePictureUpdateFailed) {
      setIsFormSubmitted(false);
      setErrorMessage("An unexpected error occurred. We were unable to set the profile picture. Try again later");
    }
  }, [isProfilePictureUpdateFailed]);

  useEffect(() => {
    if (isError) {
      setIsFormSubmitted(false);
      const serviceUnavailable = error.status === 503;
      dispatch(
        setAlert(
          serviceUnavailable ? error.data.title : `Error On ${formKey ? "Editing" : "Adding"} Data Entry`,
          error.data.message,
          serviceUnavailable ? ERROR : WARNING
        )
      );
      setErrorMessage(error.data.message);
    }
  }, [dispatch, error, formKey, isError]);

  return (
    <Box component="form" onSubmit={onSubmit}>
      <Stack gap={2}>
        <FormHeader isFormSubmitted={isFormSubmitted} refetchData={refetchData} />
        {!isPreRequitesLoaded && <UserProfilePicture setProfilePictureData={setProfilePictureData} isLoading={isFormSubmitted} />}
        <FormFields isFormSubmitted={isFormSubmitted} formConfig={formConfig} />
      </Stack>
      <RenderSubmitButton errorMessage={errorMessage} onCloseErrorMessage={() => setErrorMessage(null)} isFormSubmitted={isFormSubmitted} />
    </Box>
  );
};

const ManageForm = ({ formData, refetchData }) => {
  const theme = useTheme();
  const regexConfigData = useSelector((state) => selectPresetConfig(state, CONFIG_CONSTANTS_OPTIONS.FIELD_REGEX_LIST));
  const [formConfig, setFormConfig] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (formData?.form_fields) {
      const [transformedFields, reduxFieldData] = getFormConfig(formData.form_data, regexConfigData, formData.form_fields);
      const transformedReduxFieldData = reduxFieldData.map(({ id, fieldType, value: oldValue, fieldRegex, isEditable, isRequired }) => {
        const [value, isInvalid] = parseFieldValue(fieldType, oldValue, fieldRegex, theme.palette.primary.sectionContainer);
        return { id, value, isEditable, isRequired, isError: isInvalid };
      });

      dispatch(setFieldConfigForCurrentForm(transformedReduxFieldData));
      setFormConfig(transformedFields);
    }
    return () => dispatch(clearAllFormFields());
  }, [formData, dispatch, regexConfigData, theme.palette.primary.sectionContainer]);

  return !!formConfig && <RenderManageForm formConfig={formConfig} refetchData={refetchData} />;
};

const LoadManageDataEntryForm = () => {
  const location = useLocation();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const { [routeManageDataDataTypeParam]: dataType, [routeManageDataFormTypeParam]: formType, [routeManageDataKeycolParam]: formKey } = useParams();
  const { triggerMutation, isLoading, isError, data, error } = useGetManageDataFormMutation();

  const onClose = () => nav(location.key === "default" ? "/" : -1);

  const fetchData = useCallback(() => {
    triggerMutation(dataType, formType, formKey);
  }, [triggerMutation, dataType, formType, formKey]);

  useEffect(() => fetchData(), [fetchData]);

  useEffect(() => {
    if (isError) {
      const serviceUnavailable = error.status === 503;
      dispatch(setAlert(serviceUnavailable ? error.data.title : "Error Loading Data", error.data.message, serviceUnavailable ? ERROR : WARNING));
      if (/invalid form_id/i.test(error.data.message)) nav(PRIVATE_ROUTES.errorRoute, { replace: true });
      else if (/record not found/i.test(error.data.message)) nav(routeToManageDataTableEntries(dataType), { replace: true });
    }
  }, [dispatch, isError, error, nav, dataType]);

  return (
    <Dialog fullScreen open={true} TransitionComponent={Transition} PaperProps={{ sx: { backgroundColor: "background.default" } }}>
      <AppBar sx={{ position: "relative", backgroundColor: "primary.sectionContainer", color: "text.primary", backgroundImage: "none !important" }}>
        <Toolbar>
          <UiIconButton title="Return to table view" edge="start" iconColor="inherit" onClickIcon={onClose}>
            <ArrowBack />
          </UiIconButton>
          <ListItemText
            primary={formKey ? "Edit Data Entry" : "Add New Data Entry"}
            secondary={formKey ? `Edit the fields below to update record` : "Fill up the below form to add new record data"}
            primaryTypographyProps={{ fontSize: "1.161rem", fontWeight: 600, color: "primary" }}
            secondaryTypographyProps={{ fontSize: "1rem", color: "textPrimary", fontWeight: 400 }}
            sx={{ p: 1 }}
          />
          <AppThemeMode edge="end" />
        </Toolbar>
      </AppBar>
      <Box flex={1} padding={2} sx={{ overflowY: "auto" }}>
        <Container maxWidth="lg">
          <Box sx={{ ...sectionUiLookBase, p: 5, "& .MuiFormControl-root": { m: 0 }, minHeight: "300px" }}>
            {isLoading ? (
              <LoadingProgress sx={{ minHeight: "300px" }} />
            ) : !isError && data?.data ? (
              <ManageForm formData={data.data} refetchData={fetchData} />
            ) : (
              <EmptyDataVisualComp
                title="Error Loading Form"
                subTitle="Looks like we encountered an error while fetching form. Try checking if the form link given to you exists. Additionally, please contact admin for more information"
              />
            )}
          </Box>
        </Container>
      </Box>
    </Dialog>
  );
};

export default function ManageDataEntryDialogForm() {
  const { [routeManageDataDataTypeParam]: dataType, [routeManageDataFormTypeParam]: formType, [routeManageDataKeycolParam]: formKey } = useParams();
  if (IS_NEW_FORM(formType) && formKey) return <Navigate replace to={routeToManageDataForm(dataType, formType)} />;
  if (!IS_VALID_FORM_TYPE(formType) || (!IS_NEW_FORM(formType) && !formKey)) return <Navigate replace to={PRIVATE_ROUTES.errorRoute} />;
  return <LoadManageDataEntryForm />;
}
