import { Avatar, Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PUBLIC_ROUTES } from "../../app/constants/routes";
import _404Image from "../../asset/images/404Image.gif";
import logo from "../../asset/images/logo.png";
import { useLogoutMutation } from "../../hooks/ApiHooks";
import { logOut } from "../Auth/slice/authSlice";

export default function ErrorUI({
  errorPicture = _404Image,
  errorInt = 404,
  errorTitle = "Oops! It Seems You've Stumbled Upon a Missing Page",
  errorSubtitle = "Sorry about that! Please visit the homepage to get where you need to go.",
  buttonTitle = "Take Me There",
  logoutEnabled = false,
}) {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const getRandomPosition = () => ({
    top: `${Math.random() * 100}%`,
    left: `${Math.random() * 100}%`,
    transform: `translate(-50%, -50%)`,
  });
  const { triggerMutation: logoutUserFromServer, isLoading, isError } = useLogoutMutation();

  useEffect(() => {
    if (logoutEnabled && isError) {
      dispatch(logOut());
    }
  }, [logoutEnabled, isError, dispatch]);

  return (
    <Box
      sx={{
        flex: 1,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {Array.from(new Array(12)).map((_, index) => (
        <Avatar
          key={index}
          src={logo}
          alt="Burst-Logo"
          sx={{
            position: "absolute",
            width: "50px",
            height: "50px",
            opacity: 0,
            animation: "burst-animation 2s cubic-bezier(0.075, 0.82, 0.165, 1) infinite alternate",
            ...getRandomPosition(),
          }}
        />
      ))}
      <Box
        sx={{
          backgroundImage: `url(${errorPicture})`,
          height: "100%",
          maxHeight: "300px",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          textAlign: "center",
        }}
      >
        <Typography sx={{ color: "primary.main", fontSize: "90px", fontWeight: 900, letterSpacing: "6px" }}>{errorInt}</Typography>
      </Box>
      <Box sx={{ textAlign: "center", mt: -1 }}>
        <Typography variant="h5" color="text.primary" fontWeight={600}>
          {errorTitle}
        </Typography>
        <Typography variant="h6" color="text.secondary" fontWeight={500}>
          {errorSubtitle}
        </Typography>
        <Button
          sx={{ mt: 2, fontSize: "1rem", px: 7 }}
          variant="contained"
          size="large"
          disabled={isLoading}
          onClick={() => (logoutEnabled ? logoutUserFromServer() : nav(PUBLIC_ROUTES.homeRoute))}
        >
          {buttonTitle}
        </Button>
      </Box>
    </Box>
  );
}
