import { Badge, Menu, Tooltip, alpha, tooltipClasses } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

export const MuiTooltip = styled(
  // eslint-disable-next-line no-unused-vars
  React.forwardRef(({ className, fontSize, ...props }, ref) => <Tooltip ref={ref} {...props} classes={{ popper: className }} />)
)(({ theme, fontSize }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.text.reverse,
    fontSize: fontSize || 12,
    textAlign: "center",
    "& .MuiTooltip-arrow": {
      color: theme.palette.text.primary,
    },
  },
}));

export const MuiDropDownMenu = styled(React.forwardRef((props, ref) => <Menu ref={ref} elevation={0} {...props} />))(({ theme }) => ({
  "& .MuiMenu-paper": {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    marginTop: theme.spacing(1.5),
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      clipPath: "polygon(50% 0, 0 50%, 100% 50%)",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
      transform: "translateY(-50%)",
      zIndex: 0,
    },
    borderRadius: 6,
    minWidth: 180,
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export const MuiBadge = styled(
  // eslint-disable-next-line no-unused-vars
  React.forwardRef(({ top, left, right, bottom, fontWeight, enableRipple, color, backgroundColor, ...props }, ref) => <Badge ref={ref} {...props} />)
)(({ theme, color = "primary", backgroundColor, top, left, right, bottom, fontWeight = 600, enableRipple = false }) => ({
  "& .MuiBadge-badge": {
    top,
    left,
    right,
    bottom,
    fontWeight,
    color: theme.palette[color]?.background ?? color(theme),
    backgroundColor: typeof backgroundColor === "function" ? backgroundColor(theme) : backgroundColor || (theme.palette[color]?.main ?? color(theme)),
    boxShadow: `0px 0px 6px 0px ${theme.palette[color]?.main ?? color(theme)}`,
    ...(enableRipple && {
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid",
        borderColor: theme.palette[color]?.main ?? color(theme),
        content: '""',
      },
      "@keyframes ripple": {
        "0%": {
          transform: "scale(.8)",
          opacity: 1,
        },
        "100%": {
          transform: "scale(2.4)",
          opacity: 0,
        },
      },
    }),
  },
}));
