import { Autorenew, CheckCircle, DirectionsCar, RemoveCircle } from "@mui/icons-material";
import { Box, Divider, Drawer, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DATE_TIME_FORMAT } from "../../app/constants/common";
import { serverToUi } from "../../app/utils/utilityFunctions";
import no_content from "../../asset/images/no_content.png";
import { GenericSearchBar } from "../../components/ui/Search";
import { EmployeeUIContent, EmptyDataVisualComp, LoadingProgress, UiIconButton } from "../../components/ui/UiUtils";
import { useGetVehicleHistory } from "../../hooks/ApiHooks";
import { MuiBadge, MuiTooltip } from "../../styled/commonStyles";
import { commentListItemSx, sectionUiLookBase } from "../../styled/inlineCssStyles";
import {
  selectIsVehicleDrawerPageOpen,
  selectVehicleLogHistorySearchStr,
  setIsVehicleDrawerPageOpen,
  setVehicleLogHistorySearchStr,
} from "./slice/inventorySlice";

const LogListItem = ({ logItem, availability, latestItem = false }) => {
  return (
    <ListItem alignItems="flex-start" sx={commentListItemSx({ left: "16px", ...(latestItem && { top: "32px" }) })}>
      {latestItem ? (
        <ListItemAvatar sx={{ zIndex: 2, minWidth: "25px" }}>
          <MuiTooltip title={`Status: ${availability === undefined ? "Not Chosen" : availability ? " Available" : "Not Available"}`} fontSize={11}>
            <MuiBadge
              badgeContent={availability ? <CheckCircle fontSize="small" color="warning" /> : <RemoveCircle fontSize="small" color="error" />}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              color={(theme) => theme.palette.text.reverse}
              backgroundColor="transparent"
              top="1px"
              left="1px"
              sx={{ "& .MuiBadge-badge": { opacity: 0, boxShadow: "none", animation: "appear 1.5s 1.5s forwards" } }}
            >
              <DirectionsCar sx={{ ml: -1.75, animation: "moveUp 1.5s cubic-bezier(0.77, 0.16, 0.33, 1.01)" }} fontSize="large" color="primary" />
            </MuiBadge>
          </MuiTooltip>
        </ListItemAvatar>
      ) : (
        <Box component="div" sx={{ flexShrink: 0, minWidth: "25px" }} />
      )}
      <ListItemText
        disableTypography
        primary={
          <Box sx={{ ...sectionUiLookBase, backgroundColor: "background.default", p: 1 }}>
            <List sx={{ p: 0 }}>
              <EmployeeUIContent empId={logItem.ern} />
              <ListItem sx={{ ...commentListItemSx({ left: "32.5px", top: "50%" }), ml: 1.75, pt: 0.25 }}>
                <ListItemAvatar sx={{ zIndex: 2, minWidth: "36px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <RemoveCircle color="error" />
                </ListItemAvatar>
                <ListItemText primary="Unassigned At" secondary={serverToUi(logItem.tms_end, DATE_TIME_FORMAT.UI_FULL_FORMAT) ?? "Vehicle In Use"} />
              </ListItem>
              <ListItem sx={{ ...commentListItemSx({ left: "32.5px", bottom: "50%" }), ml: 1.75, pb: 0.25 }}>
                <ListItemAvatar sx={{ zIndex: 2, minWidth: "36px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <CheckCircle color="warning" />
                </ListItemAvatar>
                <ListItemText primary="Assigned At" secondary={serverToUi(logItem.tms_start, DATE_TIME_FORMAT.UI_FULL_FORMAT)} />
              </ListItem>
            </List>
          </Box>
        }
      />
    </ListItem>
  );
};

const DrawerDetails = forwardRef(({ vehicleId, availability }, ref) => {
  const { triggerMutation, isLoading, isError, data } = useGetVehicleHistory();
  const searchStr = useSelector(selectVehicleLogHistorySearchStr);

  const refetchData = useCallback(() => {
    triggerMutation(vehicleId);
  }, [triggerMutation, vehicleId]);

  useEffect(() => {
    refetchData();
  }, [refetchData]);

  useImperativeHandle(ref, () => ({
    refetchData,
  }));

  const transformedData = useMemo(() => {
    if (!data?.data) return [];

    const sortedLogs = [...data.data].sort((a, b) => {
      const compareStart = a.tms_start.localeCompare(b.tms_start);
      if (a.tms_end === null || b.tms_end === null) {
        return a.tms_end === null ? -1 : 1;
      }
      return compareStart || a.tms_end.localeCompare(b.tms_end);
    });

    if (!searchStr) return sortedLogs;
    const lowerCaseSearchStr = searchStr.toLowerCase();
    return sortedLogs.filter((log) => {
      return (
        (log.tms_end && serverToUi(log.tms_end).toLowerCase().includes(lowerCaseSearchStr)) ||
        (log.tms_start && serverToUi(log.tms_start).toLowerCase().includes(lowerCaseSearchStr)) ||
        (log.ern && log.ern.toLowerCase().includes(lowerCaseSearchStr))
      );
    });
  }, [data, searchStr]);

  return (
    <List sx={{ width: "100%" }}>
      {isLoading ? (
        <LoadingProgress sx={{ height: "150px" }} />
      ) : isError ? (
        <EmptyDataVisualComp subTitle="Unable to load logs due to an error." />
      ) : data?.data?.length === 0 ? (
        <EmptyDataVisualComp src={no_content} title="No Logs" subTitle="There are no vehicle logs for this vehicle yet." />
      ) : transformedData.length === 0 ? (
        <EmptyDataVisualComp src={no_content} title="No Matching Logs" subTitle="No logs match your search criteria." />
      ) : (
        transformedData.map((item, index) => <LogListItem key={index} logItem={item} availability={availability} latestItem={index === 0} />)
      )}
    </List>
  );
});

export default function VehicleUsedLogDrawer({ vehicleId, availability }) {
  const isOpen = useSelector(selectIsVehicleDrawerPageOpen);
  const dispatch = useDispatch();
  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) return;
    dispatch(setIsVehicleDrawerPageOpen(open));
  };
  const drawerDetailsRef = useRef();
  return (
    <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)} sx={{ zIndex: 1400 }}>
      <Box sx={{ width: 450, p: 2, pt: 0 }} role="presentation" onClick={toggleDrawer(true)} onKeyDown={toggleDrawer(true)}>
        <Box sx={{ position: "sticky", top: 0, zIndex: 3, backgroundColor: "primary.sectionContainer" }}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", pt: 2 }}>
            <ListItemText
              primary={`Vehicle Log - ${vehicleId}`}
              secondary="View the vehicle assigned log history below"
              primaryTypographyProps={{ color: "primary", fontWeight: "bold", variant: "h6" }}
            />
            <UiIconButton title="Refetch Vehicle Assigned Log" onClickIcon={() => drawerDetailsRef.current.refetchData()}>
              <Autorenew />
            </UiIconButton>
          </Box>
          <Divider sx={{ my: 1 }} />
          <GenericSearchBar
            searchSelector={selectVehicleLogHistorySearchStr}
            setSearchAction={setVehicleLogHistorySearchStr}
            placeholder="Search logs by ern, assigned time and unassigned time..."
            clearButtonIconSize={22}
            sx={{ my: 1 }}
          />
        </Box>
        <DrawerDetails ref={drawerDetailsRef} vehicleId={vehicleId} availability={availability} />
      </Box>
    </Drawer>
  );
}
