import { Autorenew } from "@mui/icons-material";
import { Box, Divider, TableContainer } from "@mui/material";
import { Fragment, createElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sectionUiLookBase } from "../../styled/inlineCssStyles";
import { selectCurrentTableId, setSelectedTable } from "../slices/advancedTable";
import ExportDataDropDownMenu from "../ui/ExportDataMenu";
import { SelectOptionsFieldWithCustomConfig } from "../ui/InputUI";
import { EmptyDataVisualComp, LoadingProgress, UiIconButton } from "../ui/UiUtils";
import DataTable from "./DataTableComponents";
import {
  AddNewRowButtonComponent,
  CustomizeFilterOptionsButton,
  MuiTablePagination,
  SearchBar,
  SelectableColumnsComponent,
  SelectedRowsTitleComponent,
} from "./TableUIComponents";

const TMainHead = ({ tableHeaderSelects: { tableActions, textTypographyComponent }, refetchFn, isLoadingData }) => {
  const { display: displayActions, selectSortDataBy, selectDateRange, refetchDataIcon } = tableActions;
  const { display: displayTypography, component } = textTypographyComponent;
  return (
    <Box
      sx={{
        my: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: displayActions && displayTypography ? "space-between" : displayActions ? "flex-end" : "flex-start",
      }}
    >
      {!!displayTypography && component}
      {!!displayActions && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {!!selectSortDataBy.display && <SelectOptionsFieldWithCustomConfig selectConfig={selectSortDataBy} />}
          {!!selectDateRange.display && <SelectOptionsFieldWithCustomConfig selectConfig={selectDateRange} />}
          {!!refetchDataIcon.display && (
            <UiIconButton title={refetchDataIcon.iconLabel} onClickIcon={refetchFn} loading={isLoadingData}>
              <Autorenew />
            </UiIconButton>
          )}
        </Box>
      )}
    </Box>
  );
};

const TableSubHeader = ({ columnProps, tableSubHeader: { searchInput, filterTableConfig } }) => (
  <Box
    component="div"
    sx={{
      display: "flex",
      alignItems: "stretch",
      justifyContent: searchInput.display && filterTableConfig.display ? "space-between" : searchInput.display ? "flex-start" : "flex-end",
    }}
    gap={0.5}
  >
    {!!searchInput.display && <SearchBar columnProps={columnProps} searchConfig={searchInput} />}
    {!!filterTableConfig.display && <CustomizeFilterOptionsButton columnProps={columnProps} filterTableConfig={filterTableConfig} />}
  </Box>
);

const TablePaginationHeaderWithSelectableColumns = ({ config: { selectShowColumns, pagination } }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: selectShowColumns.display && pagination.display ? "space-between" : selectShowColumns.display ? "flex-start" : "flex-end",
        alignItems: "center",
      }}
    >
      {!!selectShowColumns.display && <SelectableColumnsComponent />}
      {!!pagination.display && <MuiTablePagination config={pagination} />}
    </Box>
  );
};

const TableHeaderWithSelectedMoreOptions = ({
  config: {
    selectableRows,
    actionOptions: { display: displayActions, extraButtonComponent, exportButton, deleteRowsButton, addRowButton },
  },
  refetchFn,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: 2,
      }}
    >
      <SelectedRowsTitleComponent config={selectableRows} />
      {!!displayActions && (
        <Box sx={{ display: "flex", alignItems: "stretch", minHeight: "35px" }}>
          {createElement(extraButtonComponent, { refetchFn })}
          {!!(exportButton.display || deleteRowsButton.display) && (
            <ExportDataDropDownMenu exportButtonConfig={exportButton} deleteRowsConfig={deleteRowsButton} refetchFn={refetchFn} />
          )}
          {!!addRowButton.display && <AddNewRowButtonComponent config={addRowButton} refetchFn={refetchFn} />}
        </Box>
      )}
    </Box>
  );
};

const AdvancedTableLayout = ({ config, apiHookWithParams, sx }) => {
  const [useTriggerHook, params] = apiHookWithParams;
  const [isLoadingData = false, isErrorData = false, triggerForceReFetch = () => null] = useTriggerHook(...params);

  return (
    <Box sx={{ py: 2, px: 3, ...sx }}>
      {!!config.tableHeaderSelects.display && (
        <TMainHead tableHeaderSelects={config.tableHeaderSelects} refetchFn={triggerForceReFetch} isLoadingData={isLoadingData} />
      )}
      {!!config.tableSubHeader.display && <TableSubHeader columnProps={config.tableProps.columnProps} tableSubHeader={config.tableSubHeader} />}
      <Box sx={{ my: 2 }} />
      <Box
        component="div"
        sx={{
          ...sectionUiLookBase,
          p: 1,
        }}
      >
        {!!config.tablePageWithSelectColumns.display && (
          <Fragment>
            <TablePaginationHeaderWithSelectableColumns config={config.tablePageWithSelectColumns} />
            <Divider sx={{ mt: 1, mb: 1.5 }} variant="middle" />
          </Fragment>
        )}
        <TableHeaderWithSelectedMoreOptions config={config.dataTableHeaderOptions} refetchFn={triggerForceReFetch} />
        <TableContainer
          sx={{
            my: 2,
            px: 1.6,
            minHeight: "40vh",
            //TODO: For responsive, check DataTable Component also|| transform: "rotateX(180deg)"
          }}
        >
          {isLoadingData ? (
            <LoadingProgress sx={{ height: "300px" }} />
          ) : isErrorData ? (
            <EmptyDataVisualComp
              altSrc="Error Loading Table Data"
              title="Error Loading Table Data"
              subTitle="Try refetching the data using the button above. If it does not help, try contacting your administrator"
            />
          ) : (
            <DataTable config={config.tableProps} refetchData={triggerForceReFetch} />
          )}
        </TableContainer>
      </Box>
    </Box>
  );
};

export default function AdvancedTable({ config, apiHookWithParams = [() => {}, []], sx = {} }) {
  const hasTableInitialized = useSelector(selectCurrentTableId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setSelectedTable({
        id: config.id,
        columns: config.tableProps.columns,
        resetTable: config.resetTableOnEveryRender,
        paginationRowsOptions: config.tablePageWithSelectColumns.pagination.paginationRowsOptions,
        defaultSort: config.selectSortDataByDefault.defaultDataValue,
        defaultDateRange: config.selectDateRangeDefault.defaultDataValue,
        ...(config.tableHeaderSelects.display &&
          config.tableHeaderSelects.tableActions.display && {
            ...(config.tableHeaderSelects.tableActions.selectSortDataBy.display && {
              defaultSort: config.tableHeaderSelects.tableActions.selectSortDataBy.defaultDataValue,
            }),
            ...(config.tableHeaderSelects.tableActions.selectDateRange.display && {
              defaultDateRange: config.tableHeaderSelects.tableActions.selectDateRange.defaultDataValue,
            }),
          }),
      })
    );
  }, [dispatch, config]);

  return hasTableInitialized ? <AdvancedTableLayout sx={sx} config={config} apiHookWithParams={apiHookWithParams} /> : <LoadingProgress />;
}
