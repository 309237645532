import { Assessment, UnfoldMore } from "@mui/icons-material";
import { AccordionDetails, Box, Divider, MenuItem, Stack, Typography } from "@mui/material";
import { saveAs } from "file-saver";
import { Fragment, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ERROR, WARNING } from "../../../app/constants/common";
import { getAdjustedColorForContrast, highlightText } from "../../../app/utils/utilityFunctions";
import { LoadingProgress } from "../../../components/ui/UiUtils";
import { useExportReportMutation } from "../../../hooks/ApiHooks";
import { MuiAccordion, MuiAccordionSummary } from "../../../styled/accordionStyles";
import { MuiBadge, MuiTooltip } from "../../../styled/commonStyles";
import { assignedEmployeeTableCellStyle } from "../../../styled/inlineCssStyles";
import { StyledChip } from "../../../styled/tableStyles";
import { setAlert } from "../../Alerts/slice/alertSlice";
import { selectPresetConfig } from "../../AppSettings/appSlice";

export const RenderMappedTaskContent = memo(
  ({ mappedData: { nullValue, label, color, tooltipLabel = null }, searchStr = "", minWidth, dataValue = null }) => {
    return (
      <MuiBadge
        badgeContent={dataValue}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        color={color || "primary"}
        top="50%"
        left="12px"
        enableRipple={[1, 2].includes(parseInt(dataValue))}
      >
        <MuiTooltip title={tooltipLabel} fontSize={10}>
          <StyledChip minWidth={minWidth} label={label ? highlightText(label, searchStr) : nullValue || "N/A"} color={color || "primary"} />
        </MuiTooltip>
      </MuiBadge>
    );
  }
);

export const RenderTaskTags = memo(({ theme, data = [], searchStr, configKey }) => {
  const tags = useSelector((state) => selectPresetConfig(state, configKey));
  return (
    !!tags.length && (
      <Stack
        display="inline-flex"
        gap={1}
        sx={{ maxWidth: "220px", width: "100%" }}
        flexWrap="wrap"
        direction="row"
        alignItems="center"
        justifyContent="stretch"
      >
        {data.map((dataTag) => {
          const existingTag = tags.find(({ tag_id }) => tag_id === dataTag);
          if (!existingTag) return null;
          const [color, backgroundColor] = getAdjustedColorForContrast(existingTag.tag_colour, theme);
          return (
            <MuiTooltip key={dataTag} title={existingTag.tag_desc} fontSize={10}>
              <StyledChip
                minWidth="fit-content"
                variant="filled"
                label={highlightText(existingTag.tag_name, searchStr)}
                sx={{ flex: 1, borderRadius: 1, borderColor: "transparent" }}
                color={color}
                backgroundColor={backgroundColor}
              />
            </MuiTooltip>
          );
        })}
      </Stack>
    )
  );
});

export const RenderAssignedEmployee = memo(({ dataValue: assignedEmployees = [], searchStr, openedProp, setOpenedProp, alternate }) => {
  const [localOpened, setLocalOpened] = useState(openedProp ?? false);
  const opened = searchStr ? localOpened : openedProp ?? false;
  const setOpened = searchStr ? setLocalOpened : setOpenedProp;

  const [titleEmployee, otherEmployee] = useMemo(() => {
    const [firstEmployee, ...restEmployees] = [...assignedEmployees].sort();
    return [firstEmployee, restEmployees];
  }, [assignedEmployees]);

  useEffect(() => {
    if (searchStr) setOpened(otherEmployee.some((name) => name.toLowerCase().includes(searchStr)));
  }, [otherEmployee, searchStr, setOpened]);

  const handleChange = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setOpened(!opened);
    },
    [opened, setOpened]
  );

  const renderEmployee = (name, displayCount = false) =>
    name ? (
      <Box component="div" className="employee-item">
        <Typography component="span">{highlightText(name, searchStr)}</Typography>
        {!!displayCount && <StyledChip label={assignedEmployees.length} color="primary" minWidth="fit-content" fontSize={11} />}
      </Box>
    ) : (
      <Typography component="span" fontSize="inherit">
        {alternate}
      </Typography>
    );

  return assignedEmployees.length ? (
    <Box component="div" className="cell-container" sx={assignedEmployeeTableCellStyle} onClick={handleChange}>
      <MuiAccordion expanded={opened} TransitionProps={{ unmountOnExit: true }}>
        <MuiAccordionSummary expandIcon={<UnfoldMore />}>{renderEmployee(titleEmployee, true)}</MuiAccordionSummary>
        <AccordionDetails>
          {otherEmployee.map((employee, index) => (
            <Fragment key={index}>
              {renderEmployee(employee)}
              {index !== otherEmployee.length - 1 && <Divider variant="middle" sx={{ my: 1 }} />}
            </Fragment>
          ))}
        </AccordionDetails>
      </MuiAccordion>
    </Box>
  ) : (
    <Typography component="span" fontSize="inherit">
      {alternate}
    </Typography>
  );
});

export const ExportFullReport = ({ onClose }) => {
  const dispatch = useDispatch();
  const [isExportingReport, setIsExportingReport] = useState(false);
  const { triggerMutation, isLoading, isError, error, data } = useExportReportMutation();

  const onExportReport = () => {
    setIsExportingReport(true);
    triggerMutation();
  };

  useEffect(() => {
    if (!isLoading && data) {
      const {
        body: { data: bodyData },
        headers,
      } = data.data;
      const byteArray = new Uint8Array(bodyData);
      const blob = new Blob([byteArray], { type: headers["Content-Type"] });
      const filename = headers["Content-Disposition"].split("=")[1].replace(/"/g, "");
      const url = URL.createObjectURL(blob);
      saveAs(url, filename);
      setIsExportingReport(false);
      onClose();
    }
  }, [isLoading, data, onClose]);

  useEffect(() => {
    if (isError) {
      const serviceUnavailable = error.status === 503;
      setIsExportingReport(false);
      dispatch(setAlert(serviceUnavailable ? error.data.title : "Error Exporting Report", error.data.message, serviceUnavailable ? ERROR : WARNING));
    }
  }, [isError, error, dispatch]);

  return (
    <Fragment>
      <Divider />
      <MenuItem disableRipple sx={{ my: 1.5 }} onClick={isExportingReport ? null : onExportReport}>
        {isExportingReport ? (
          <LoadingProgress size={28} />
        ) : (
          <Fragment>
            <Assessment color="primary" />
            <Typography sx={{ fontSize: "14px", color: "primary.main", mx: 1, fontWeight: 500 }} component="span">
              Export Monthly Report
            </Typography>
          </Fragment>
        )}
      </MenuItem>
    </Fragment>
  );
};
