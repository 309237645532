import { createSlice } from "@reduxjs/toolkit";

const inventorySlice = createSlice({
  name: "inventory",
  initialState: {
    hasTableDataChanged: false,
    isVehicleEditOn: false,
    isVehicleUpdateInProgress: false,
    stockManagementPageType: "vehicle",
    isVehicleDrawerPageOpen: false,
    vehicleLogHistorySearchStr: "",
  },
  reducers: {
    setHasInventoryPartsTableDataChanged: (state, { payload }) => {
      state.hasTableDataChanged = payload;
    },
    setIsVehicleEditOn: (state, { payload }) => {
      state.isVehicleEditOn = payload !== undefined ? payload : !state.isVehicleEditOn;
    },
    setIsVehicleUpdateInProgress: (state, { payload }) => {
      state.isVehicleUpdateInProgress = payload !== undefined ? payload : !state.isVehicleUpdateInProgress;
    },
    setStockManagementPageType: (state, { payload }) => {
      state.stockManagementPageType = payload === undefined ? "vehicle" : payload;
    },
    setIsVehicleDrawerPageOpen: (state, { payload }) => {
      state.isVehicleDrawerPageOpen = payload !== undefined ? payload : !state.isVehicleDrawerPageOpen;
    },
    setVehicleLogHistorySearchStr: (state, { payload }) => {
      state.vehicleLogHistorySearchStr = payload !== undefined ? payload : "";
    },
  },
});

export const {
  setHasInventoryPartsTableDataChanged,
  setIsVehicleUpdateInProgress,
  setIsVehicleEditOn,
  setStockManagementPageType,
  setIsVehicleDrawerPageOpen,
  setVehicleLogHistorySearchStr,
} = inventorySlice.actions;

export const selectHasInventoryPartsTableDataChanged = (state) => state.inventory.hasTableDataChanged;
export const selectIsVehicleEditOn = (state) => state.inventory.isVehicleEditOn;
export const selectIsVehicleUpdateInProgress = (state) => state.inventory.isVehicleUpdateInProgress;
export const selectStockManagementPageType = (state) => state.inventory.stockManagementPageType;
export const selectIsVehicleDrawerPageOpen = (state) => state.inventory.isVehicleDrawerPageOpen;
export const selectVehicleLogHistorySearchStr = (state) => state.inventory.vehicleLogHistorySearchStr;

export default inventorySlice.reducer;
