import { alpha, Avatar, Card, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { Fragment } from "react";

const StatCardSkeleton = ({ color }) => {
  const theme = useTheme();
  const cardColor = theme.palette[color]?.main || theme.palette.primary.main;

  return (
    <Fragment>
      <Skeleton
        variant="rounded"
        animation="wave"
        sx={{
          height: 56,
          width: 56,
          borderRadius: 2,
          backgroundColor: alpha(cardColor, 0.1),
        }}
      />

      <Stack spacing={1}>
        <Skeleton
          variant="text"
          animation="wave"
          width={80}
          height={40}
          sx={{
            backgroundColor: alpha(cardColor, 0.1),
          }}
        />
        <Skeleton
          variant="text"
          animation="wave"
          width={120}
          height={20}
          sx={{
            backgroundColor: alpha(cardColor, 0.1),
          }}
        />
      </Stack>
    </Fragment>
  );
};

export default function StatCard({ color, icon, label, count, sx, loading = true }) {
  const theme = useTheme();
  const cardColor = theme.palette[color].main;
  return (
    <Card
      component={motion.div}
      whileHover={{ y: -5 }}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 3,
        p: 3,
        height: "100%",
        background: `linear-gradient(135deg, ${alpha(cardColor, 0.1)} 0%, ${alpha(theme.palette.background.paper, 1)} 60%)`,
        borderRadius: 4,
        border: `1px solid ${alpha(cardColor, 0.1)}`,
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          boxShadow: `0 8px 24px ${alpha(cardColor, 0.15)}`,
        },
        ...sx,
      }}
    >
      {loading ? (
        <StatCardSkeleton color={cardColor} />
      ) : (
        <Fragment>
          <Avatar
            sx={{
              p: 2,
              borderRadius: 2,
              backgroundColor: alpha(cardColor, 0.1),
              color: cardColor,
              height: 56,
              width: 56,
              "& .MuiSvgIcon-root, & .MuiIcon-root": { fontSize: 24 },
            }}
          >
            {icon}
          </Avatar>
          <Stack spacing={1}>
            <Typography variant="h4" fontWeight="bold">
              {count}
            </Typography>
            <Typography variant="body2" color="text.secondary" fontWeight={500}>
              {label}
            </Typography>
          </Stack>
        </Fragment>
      )}
    </Card>
  );
}
