export const SERVER_FIELD_TYPE = {
  TEXT: "string",
  NUMBER: "number",
  SELECT: "combosingle",
  MULTI_SELECT: "combomulti",
  DATE_TMS8: "tms8",
  DATE_TMS14: "tms14",
  MULTI_LINE: "string-multiline",
  COLOR: "color",
  NONE: "none",
  CHECK_BOX: "checkbox",
};

const UI_RENDER_FIELD_TYPES = {
  SELECT: "select",
  DATE: "date",
  TEXT: "text",
  NUMBER: "number",
  COLOR: "color",
};

export const MANAGE_DATA_FORM_TYPE = {
  UPDATE: "UPDATE",
  NEW: "NEW",
};

export const MAX_COLOR_LENGTH = 7;
export const MIN_NUMBER = 1;
export const IS_DATE = (serverFieldType) => serverFieldType === SERVER_FIELD_TYPE.DATE_TMS8 || serverFieldType === SERVER_FIELD_TYPE.DATE_TMS14;
export const IS_SELECT = (serverFieldType) => serverFieldType === SERVER_FIELD_TYPE.SELECT || serverFieldType === SERVER_FIELD_TYPE.MULTI_SELECT;
export const IS_MULTI_SELECT = (serverFieldType) => serverFieldType === SERVER_FIELD_TYPE.MULTI_SELECT;
export const IS_MULTILINE = (serverFieldType) => serverFieldType === SERVER_FIELD_TYPE.MULTI_LINE;
export const IS_COLOR = (serverFieldType) => serverFieldType === SERVER_FIELD_TYPE.COLOR;
export const IS_CHECKBOX = (serverFieldType) => serverFieldType === SERVER_FIELD_TYPE.CHECK_BOX;
export const IS_NEW_FORM = (formType) => formType.toUpperCase() === MANAGE_DATA_FORM_TYPE.NEW;
export const IS_VALID_FORM_TYPE = (formType) => formType.toUpperCase() === MANAGE_DATA_FORM_TYPE.UPDATE || IS_NEW_FORM(formType);

export const GET_INPUT_FIELD_TYPE = (serverFieldType) => {
  switch (serverFieldType) {
    case SERVER_FIELD_TYPE.TEXT:
      return UI_RENDER_FIELD_TYPES.TEXT;
    case SERVER_FIELD_TYPE.NUMBER:
      return UI_RENDER_FIELD_TYPES.NUMBER;
    case SERVER_FIELD_TYPE.COLOR:
    case SERVER_FIELD_TYPE.DATE_TMS8:
    case SERVER_FIELD_TYPE.DATE_TMS14:
    case SERVER_FIELD_TYPE.SELECT:
    default:
      return null;
  }
};
