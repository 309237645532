import { Box, ListItemText } from "@mui/material";
import { DATE_TIME_FORMAT } from "../../../app/constants/common";
import { MANAGE_DATA_FORM_TYPE, SERVER_FIELD_TYPE } from "../../../app/constants/config/DynamicFields";
import { routeToManageDataForm } from "../../../app/constants/routes";
import {
  createTableColumn,
  getAdjustedColorForContrast,
  highlightText,
  normalizeConfigForTable,
  serverToUi,
} from "../../../app/utils/utilityFunctions";
import { generalTableConfig } from "../../../components/config/generalTableConfig";
import { useDeleteDataEntryRecords } from "../../../hooks/advancedTableHook";
import { StyledChip } from "../../../styled/tableStyles";
import { RenderPrimaryKeyWithEditOption } from "../CellActionComponent";

export const generateBrowseTableConfig = (dataTypeFormId, dataTypeFormLabel, serverColumns, renderChipFieldsData) => {
  const displayColumns = serverColumns
    .map((column) =>
      createTableColumn({
        order: column.fld_seq,
        id: column.fld_col_name,
        label: column.fld_disp_name,
        align: column.fld_browse_align,
        primaryKey: column.fld_pk_ind,
        disablePadding: column.fld_pk_ind,
        alternateValue: column.fld_null_value,
        renderContent: (data, searchStr, theme) => {
          if (column.fld_data_type === SERVER_FIELD_TYPE.DATE_TMS8)
            return highlightText(serverToUi(data, DATE_TIME_FORMAT.UI_DATE_FORMAT), searchStr);
          if (column.fld_data_type === SERVER_FIELD_TYPE.DATE_TMS14)
            return (
              <ListItemText
                primary={highlightText(serverToUi(data, DATE_TIME_FORMAT.UI_DATE_FORMAT), searchStr)}
                secondary={serverToUi(data, DATE_TIME_FORMAT.UI_TIME_FORMAT)}
              />
            );
          if (column.fld_data_type === SERVER_FIELD_TYPE.SELECT && column.fld_chip_id) {
            const chipData = renderChipFieldsData[column.fld_chip_id].find(({ chip_value }) => chip_value === data);
            return chipData ? (
              <StyledChip color={chipData.chip_colour} label={highlightText(chipData.chip_label, searchStr)} />
            ) : (
              highlightText(data, searchStr)
            );
          }
          // For a scenario where multi select happens and each data is chips associated. Here it is assumed that data[0] has chipId = column.fld_chip_id[0] and so on.
          if (column.fld_data_type === SERVER_FIELD_TYPE.SELECT && column.fld_chip_id && Array.isArray(data) && Array.isArray(column.fld_chip_id)) {
            return data.map((dataItem, index) => {
              const chipId = column.fld_chip_id[index];
              const chipData = renderChipFieldsData[chipId]?.find(({ chip_value }) => chip_value === dataItem);

              return chipData ? (
                <StyledChip color={chipData.chip_colour} label={highlightText(chipData.chip_label, searchStr)} />
              ) : (
                highlightText(dataItem, searchStr)
              );
            });
          }
          if (column.fld_data_type === SERVER_FIELD_TYPE.COLOR)
            return (
              <Box
                display="inline-block"
                sx={{
                  height: 24,
                  width: 24,
                  borderRadius: 1,
                  backgroundColor: getAdjustedColorForContrast(data, theme)[0],
                }}
              />
            );
          return highlightText(Array.isArray(data) ? data.join(", ") : data, searchStr);
        },
        renderPDF: (data) => {
          if (column.fld_data_type === SERVER_FIELD_TYPE.DATE_TMS8) return serverToUi(data, DATE_TIME_FORMAT.UI_DATE_FORMAT);
          if (column.fld_data_type === SERVER_FIELD_TYPE.DATE_TMS14) return serverToUi(data, DATE_TIME_FORMAT.UI_FULL_FORMAT);
          if (column.fld_data_type === SERVER_FIELD_TYPE.SELECT && column.fld_chip_id) {
            const chipData = renderChipFieldsData[column.fld_chip_id].find(({ chip_value }) => chip_value === data);
            return chipData ? chipData.chip_label : data;
          }
          return data;
        },
        renderCSV: (data) => {
          if (column.fld_data_type === SERVER_FIELD_TYPE.DATE_TMS8) return serverToUi(data, DATE_TIME_FORMAT.UI_DATE_FORMAT);
          if (column.fld_data_type === SERVER_FIELD_TYPE.DATE_TMS14) return serverToUi(data, DATE_TIME_FORMAT.UI_FULL_FORMAT);
          if (column.fld_data_type === SERVER_FIELD_TYPE.SELECT && column.fld_chip_id) {
            const chipData = renderChipFieldsData[column.fld_chip_id].find(({ chip_value }) => chip_value === data);
            return chipData ? chipData.chip_label : data;
          }
          return data;
        },
      })
    )
    .sort((a, b) => a.order - b.order);

  const transformedColumns = [
    ...displayColumns,
    createTableColumn({
      order: displayColumns.length + 1,
      id: "actions",
      label: "Row Actions",
      align: "center",
      isTrueColumnField: false,
      renderContent: (rowData, refetchData) =>
        !!rowData && (
          <RenderPrimaryKeyWithEditOption
            displayData={[
              displayColumns.map(({ id, label, renderContent, alternateValue }) => ({ id, label, renderContent, alternateValue })),
              rowData,
            ]}
            rowId={rowData.id}
            keyCol={rowData.keycol}
            refetchData={refetchData}
          />
        ),
    }),
  ];

  return normalizeConfigForTable(
    generalTableConfig({
      tableId: "records-browse-table",
      tableTitle: `Browse ${dataTypeFormLabel} Records`,
      tableViewColumns: transformedColumns,
      resetTableOnEveryRender: true,
      preventDisplayOf: {
        selectDateRangeDisplay: false,
        filterOptionsDisplay: false,
        deleteRowsButtonDisplay: true,
      },
      customFunctions: {
        deleteHookWithParams: [useDeleteDataEntryRecords, [dataTypeFormId]],
      },
      otherDefaultsVariables: {
        paginationTitle: "Records / Page",
        newRowTooltipTitle: "Add New Record",
      },
      paginationRowsOptions: {
        pageOptions: [10, 20, 30],
      },
      addRowButtonNavigateOptions: {
        addRowNavigateToURL: true,
        addRowURL: routeToManageDataForm(dataTypeFormId, MANAGE_DATA_FORM_TYPE.NEW),
      },
    })
  );
};
