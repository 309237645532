import { Stack } from "@mui/material";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { routeManageDataDataTypeParam } from "../../app/constants/routes";
import no_content from "../../asset/images/no_content.png";
import { EmptyDataVisualComp, LoadingProgress } from "../../components/ui/UiUtils";
import { useGetFormListDataMutation } from "../../hooks/ApiHooks";
import FormBrowseTable from "./FormBrowseTable";
import FormListTabLayout from "./FormSideTabLayout";
import { selectFormTypeById, setActiveForm, setManageFormTabOptions } from "./slices/ManageDataSlice";

export default function ManageData() {
  const { [routeManageDataDataTypeParam]: dataType } = useParams();
  const isFormSelectedValid = useSelector((state) => selectFormTypeById(state, dataType));
  const dispatch = useDispatch();
  const { triggerMutation, isLoading, isError, data } = useGetFormListDataMutation();

  useEffect(() => {
    triggerMutation();
  }, [triggerMutation]);

  useEffect(() => {
    if (!isLoading && data?.data) dispatch(setManageFormTabOptions(data.data));
  }, [dispatch, isLoading, data?.data]);

  useEffect(() => {
    dispatch(setActiveForm(isFormSelectedValid ? dataType : false));
  }, [isFormSelectedValid, dispatch, dataType]);

  return (
    <Stack sx={{ flex: 1, overflow: "auto" }} direction="row">
      {isLoading ? (
        <LoadingProgress />
      ) : isError ? (
        <EmptyDataVisualComp />
      ) : (
        !!data?.data && (
          <Fragment>
            <FormListTabLayout />
            {!isFormSelectedValid ? (
              <EmptyDataVisualComp
                src={no_content}
                title="No Form Data Selected"
                subTitle="Please select an option from form list to update or add new data to the database"
              />
            ) : (
              <FormBrowseTable />
            )}
          </Fragment>
        )
      )}
    </Stack>
  );
}
