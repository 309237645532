import { isEqual } from "lodash";
import { setDataRows, setPaginationChange } from "../../components/slices/advancedTable";

export const filterDataBasedOnAttributes =
  ({ payload = {}, columnProps = {}, dispatchFn = null } = {}) =>
  (dispatch, getState) => {
    if (dispatchFn) dispatch(dispatchFn());
    const { entities, currentTableId } = getState().advancedTable;
    const entity = entities[currentTableId];
    if (!entity) return;

    const { originalRows, rowData: oldRows, searchStr = "", savedFilters, activeFilters, filterTable } = { ...entity, ...payload };
    const lowerCaseSearchStr = String(searchStr).trim().toLowerCase();
    const activeFiltersSet = new Set(activeFilters);

    if (lowerCaseSearchStr === "" && (!filterTable || activeFiltersSet.size === 0)) {
      const newRows = originalRows.map((row) => row.id);
      if (!isEqual(oldRows, newRows)) {
        dispatch(setPaginationChange({ newPage: 0 }));
        dispatch(setDataRows(newRows));
      }
      return;
    }

    const filteredSavedFilters = savedFilters.filter((filter) => activeFiltersSet.has(filter.columnId));
    const filteredRows = filterRows(originalRows, filteredSavedFilters, lowerCaseSearchStr, columnProps, filterTable);
    if (!isEqual(oldRows, filteredRows)) {
      dispatch(setPaginationChange({ newPage: 0 }));
      dispatch(setDataRows(filteredRows));
    }
  };

function filterRows(rows, filters, searchStr, columnProps, filterTable) {
  return rows
    .filter((row) => matchesFilters(row, filters, columnProps, filterTable) && matchesSearch(row, searchStr, columnProps))
    .map((row) => row.id);
}

/* 
NOTE: columnProps[columnId] is checked since API returns rows unknown to table columns as well
*/
function matchesFilters(row, filters, columnProps, filterTable) {
  return (
    !filterTable ||
    filters.length === 0 ||
    filters.every(({ columnId, columnValues }) => columnValues.some((value) => columnProps[columnId]?.filterBoolean(row[columnId], value.valueId)))
  );
}

/* 
NOTE: columnProps[columnId] is checked since API returns rows unknown to table columns as well
*/
function matchesSearch(row, searchStr, columnProps) {
  return searchStr === "" || Object.entries(row).some(([columnId, value]) => columnProps[columnId]?.searchBoolean(value, searchStr));
}
