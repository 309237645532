import { Close } from "@mui/icons-material";
import { Alert, Avatar, Box, Collapse, Container, IconButton, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ERROR } from "../../app/constants/common";
import logo from "../../asset/images/logo.png";
import { AppThemeMode } from "../../components/ui/UiUtils";
import { ErrorBoundaryWrapper } from "../../features/Error404/ErrorLog";
import { authLayoutUIStyles } from "../../styled/inlineCssStyles";

export default function AuthLayout() {
  const [alertPopup, setAlertPopup] = useState({ message: null, severity: ERROR });
  const onSetAlertPopup = useCallback((message = null, severity = ERROR) => setAlertPopup({ message, severity }), []);
  const location = useLocation();

  useEffect(() => {
    onSetAlertPopup();
  }, [onSetAlertPopup, location.pathname]);

  return (
    <ErrorBoundaryWrapper functionFeature="Login Authentication Layout">
      <Box sx={{ display: "flex", height: "100vh" }}>
        <Container component="main" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <Stack justifyContent="space-between" alignItems="center" sx={authLayoutUIStyles}>
            <Box>
              <Avatar alt="Logo" src={logo} />
              <Typography variant="h4" fontFamily="'Satisfy', cursive" fontWeight={600} color="primary" sx={{ my: 1, textDecoration: "underline" }}>
                Nikgil Management System
              </Typography>
            </Box>
            <Box>
              <Outlet context={[onSetAlertPopup]} />
              <Collapse in={!!alertPopup.message}>
                <Alert
                  sx={{ my: 2 }}
                  severity={alertPopup.severity}
                  variant="filled"
                  action={
                    <IconButton aria-label="close" color="inherit" size="small" onClick={() => onSetAlertPopup()}>
                      <Close fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {alertPopup.message}
                </Alert>
              </Collapse>
            </Box>
            <Box sx={{ mt: 3, mb: 1 }}>
              <Typography fontWeight={400} sx={{ my: 0.5 }}>
                Contact Site Admin For Any Assistance
              </Typography>
              <AppThemeMode color="primary" />
            </Box>
          </Stack>
        </Container>
      </Box>
    </ErrorBoundaryWrapper>
  );
}
