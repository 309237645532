import { Grid, TextField } from "@mui/material";
import { useState } from "react";
import { MuiTooltip } from "../../styled/commonStyles";

export const RenderFormField = ({ field, ...props }) => {
  const [isTypeText, setIsTypeText] = useState(false);
  const onTypeToggle = () => setIsTypeText((prev) => !prev);

  return (
    <Grid item xs={12}>
      <MuiTooltip title={!!props?.inputProps?.readOnly && "This field cannot be changed"} fontSize={11}>
        <TextField
          margin="normal"
          fullWidth
          {...field.fieldProps}
          InputProps={field.inputProps(...(field.type === "password" ? [isTypeText, onTypeToggle] : []))}
          type={field.type === "password" ? (isTypeText ? "text" : "password") : field.type}
          sx={{ width: "370px" }}
          {...props}
        />
      </MuiTooltip>
    </Grid>
  );
};
